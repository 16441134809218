import AscendActionTypes from "./ascend.types";

export const signInAscend = (payload) => ({
    type: AscendActionTypes.SIGN_IN,
    payload
})

export const openAscendSession = (payload) => ({
    type: AscendActionTypes.OPEN_ASCEND_SESSION,
    payload
})

export const ascendCasesLoaded = (payload) => ({
    type: AscendActionTypes.ASCEND_CASES_LOADED,
    payload
})

export const selectAscendCase = (payload) => ({
    type: AscendActionTypes.SELECT_ASCEND_CASE,
    payload
})

export const acquireImages = (payload) => ({
    type: AscendActionTypes.SET_ACQUIRED_IMAGES,
    payload
})

export const setAscendKey = (payload) => ({
    type: AscendActionTypes.SET_ASCEND_KEY,
    payload
})

export const imagesSelectedForAcquire = (payload) => ({
    type: AscendActionTypes.IMAGES_SELECTED_FOR_ACQUIRE,
    payload
})

export const getCases = (payload) => ({
    type: AscendActionTypes.GET_CASES,
    payload
})

export const getSession = (payload) => ({
    type: AscendActionTypes.GET_SESSION,
    payload
})





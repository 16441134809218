const UIActionTypes = {
    SHOW_USER_MENU : 'SHOW_USER_MENU',
    HIDE_USER_MENU : 'HIDE_USER_MENU',
    SHOW_WORK_AREA_MENU : 'SHOW_WORK_AREA_MENU',
    HIDE_WORK_AREA_MENU : 'HIDE_WORK_AREA_MENU',
    SHOW_PROFILE : 'SHOW_PROFILE',
    HIDE_PROFILE : 'HIDE_PROFILE',

    TOGGLE_PHOTO_UPLOAD_PROGRESS: 'TOGGLE_PHOTO_UPLOAD_PROGRESS',

    SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
    SHOW_NOTIFICATION_DIALOG: 'SHOW_NOTIFICATION_DIALOG',
    SHOW_LIGHT_HEADER: 'SHOW_LIGHT_HEADER',
    HIDE_LIGHT_HEADER: 'HIDE_LIGHT_HEADER',
    START_PROCESS_PHOTO: 'START_PROCESS_PHOTO',
};

export default UIActionTypes;

import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getUrlKeys } from "../../helpers/url";
import PhotoPage from "../elements/PhotoPage";
import Header from "../Header";
import { themeLight } from "../../config/app";
import PageWithSupportFooter from "../elements/PageWithSupportFooter";
import Form from "../elements/Form";
import InputEmail from "../elements/form/input/InputEmail";
import Button from "../elements/form/Button";


class CreateTrialProfile extends React.PureComponent {

    state = {
        trialKey: null,
        email: null,
        isTrialCodeValid: false,
        errorMessage: null,
        isCreatingProfile: false,
        role_id: false
    }

    componentDidMount() {
        const { search } = this.props.location;
        const { key } = getUrlKeys(search);
        
        if (key) {
            this.setState({trialKey: key})
            this.props.checkTrialLink(key);
        }
    }

    componentDidUpdate(prevProps) {
        const { isValid, showNotification, trialProfileCreationResult } = this.props;
        
        if (isValid != prevProps.isValid) {
            if (typeof isValid === "object" ) {
                console.log('Error isValid', isValid.message)
                showNotification(
                    {
                        type: 'error',
                        title: 'Error',
                        message: isValid.message
                    }
                    
                )
                this.setState({
                    errorMessage: isValid.message
                })
            }
            else {
                this.setState({
                    isTrialCodeValid: true,
                    role_id: isValid
                })
            }
        }

        if (trialProfileCreationResult != '') {
            this.setState({
                isCreatingProfile: false
            })
        }
        
    }

    emailChangeHandler = (value) => {
        this.setState({email:value});
    };

    buttonClickHandler = () => {
        const { createTrialProfile } = this.props;
        const { trialKey, email, role_id } = this.state;
        this.setState({
            isCreatingProfile: true
        });
        createTrialProfile(trialKey, email, role_id)
    }

    render() {
        const {email, isTrialCodeValid, errorMessage, isCreatingProfile} = this.state;

        return <div className={'light-page create-dentist-profile'}>
                    <PhotoPage>
                        <Header theme={themeLight} isUserMenu={false}/>

                        <PageWithSupportFooter className={'profile-create-inner'}>

                        <div className={'title'}>
                            Create Trial Profile
                            {
                            !isTrialCodeValid &&
                            <div className={'sub-title'}>
                                {errorMessage}
                            </div>
                            }
                        </div>
                         { isTrialCodeValid &&
                            <Form onSubmit={this.buttonClickHandler} isAjax={true}>
                                <InputEmail name={'email'} onChange={this.emailChangeHandler} value={email} placeholder={'Email Address'} isRequired={true} isDisabled={false} />
                                <Button type={'submit'} isDisabled={isCreatingProfile}>Sign Up</Button>
                            </Form>
                        }
                        
                        </PageWithSupportFooter>
                    </PhotoPage>
                </div>

    }
}

export default withRouter(CreateTrialProfile);
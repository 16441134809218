import React from "react";
import PropTypes from "prop-types";
import { Resizable } from "re-resizable";
import { caseShape } from '../../helpers/case';
import subMenus, { TYPE_RESIZEABLE, closeMenuOnResizeOffset } from '../../config/subMenus';
import Modal from '../elements/Modal';

export default class SubMenu extends React.PureComponent {
    state = {
        width: 219,
    };

    getMenu = (menu) => {
        if (typeof subMenus[menu] !== 'undefined') {
            return subMenus[menu].component;
        }

        return false;
    };
    getMenuWithProps = (menu) => {
        let { hideSubMenu } = this.props;

        if (typeof subMenus[menu] !== 'undefined') {
            return React.cloneElement(subMenus[menu].component, {hideSubMenu: hideSubMenu});
        }

        return false;
    };
    getTitle = (menu) => {
        if (typeof subMenus[menu] !== 'undefined') {
            return subMenus[menu].title;
        }

        return menu;
    };
    titleClickHandler = () => {

    };
    render() {
        let { menu, hideSubMenu } = this.props;
        let { width } = this.state;

        let title = this.getTitle(menu);

        if (typeof subMenus[menu] === 'undefined') {
            return false;
        }

        switch (subMenus[menu].type) {
            case TYPE_RESIZEABLE:
                return (
                    <div className={'sub-menu' + (menu !== '' ? ' active' : '')}>
                        <Resizable
                            onResize={(e, direction, ref, d) => {
                               if (d.width <= closeMenuOnResizeOffset) {
                                   hideSubMenu()
                               }
                            }}
                            handleWrapperClass={'resize-handler'}
                            enable={{
                                right: true,
                            }}
                            defaultSize={{width: width}}
                            >
                            <div className={'title'} onClick={hideSubMenu}>
                                <img alt={title} src={require('../../assets/images/arrow-left.png')}/>
                                <span>{title}</span>
                            </div>

                            {this.getMenu(menu)}
                        </Resizable>
                    </div>
                );
            //case TYPE_MODAL:
            default:
                return (
                    <Modal className={`${menu}-modal`} overlayClassName={`${menu}-overlay`} onClose={hideSubMenu}>
                        {this.getMenuWithProps(menu)}
                    </Modal>
                )
        }


    }
}

SubMenu.propTypes = {
    menu: PropTypes.string.isRequired,
    hideSubMenu: PropTypes.func.isRequired,
    currentCase: caseShape,
};

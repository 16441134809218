import { connect } from "react-redux";
import Process from "../../../components/case/subMenus/Process";
import {
	getShadeGuides,
	isShadeGuideExpanded,
	getColorCorrectButtons,
	getRegionButtons,
	getPhotoContainerRef,
	getIsRestartAllowed,
	getIsColorCorrectionAllowed,
    getCorrectedImageButtons,
} from "../../../selectors/workArea.selector";
import {
	selectShadeGuide,
	expandShadeGuide,
	unexpandShadeGuide,
	choseRegion,
	changeImageType,
    changeCorrectedImageType,
} from "../../../actions/workArea.actions";
import {
	getShadeGuide,
	restartOriginalImage,
	runColorCorrect,
	selectedPhotoType,
} from "../../../actions/case.actions";

const mapStateToProps = (state) => ({
	shadeGuides: getShadeGuides(state),
	isShadeGuideExpanded: isShadeGuideExpanded(state),
	colorCorrectButtons: getColorCorrectButtons(state),
    correctedImageButtons: getCorrectedImageButtons(state),
	regionButtons: getRegionButtons(state),
	photoContainerRef: getPhotoContainerRef(state),
	currentShadeReferenceAnalysis: state.workArea.currentShadeReferenceAnalysis,
	isColorCorrectionAllowed: getIsColorCorrectionAllowed(state),
    isRestartAllowed: getIsRestartAllowed(state),
    shadeValue: state.workArea.shadeValue,
    master3dValue: state.workArea.master3dValue,
    brightnessValue: state.workArea.brightnessValue,
});

const mapDispatchToProps = (dispatch) => ({
	selectShadeGuide: (guideId) => dispatch(selectShadeGuide(guideId)),
	expandShadeGuide: () => dispatch(expandShadeGuide()),
	unexpandShadeGuide: () => dispatch(unexpandShadeGuide()),
	changeImageType: (name) => dispatch(changeImageType(name)),
	choseRegion: (name) => dispatch(choseRegion(name)),
	getShadeGuide: (guideId) => dispatch(getShadeGuide(guideId)),
	runColorCorrect: () => dispatch(runColorCorrect()),
	selectedPhotoType: (imageType) => dispatch(selectedPhotoType(imageType)),
	restartOriginalImage: () => dispatch(restartOriginalImage()),
    changeCorrectedImageType: (name) => dispatch(changeCorrectedImageType(name)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Process);

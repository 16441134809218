import { createSelector } from 'reselect'
import { isStateExists } from '../helpers/state';
import * as caseFuncs from "../helpers/case";


const getCase = state => state.case;

export const getActivePhotoId = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return '';
        }

        return caseState.activePhotoId;
    }
);


export const getCurrentCase = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return null;
        }

        return caseFuncs.modifyCaseInfo(caseState.currentCase);
    }
);

export const getPhotos = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return [];
        }

        return caseState.photos;
    }
);

export const getCurrentPhoto = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState) || caseState.photos.length === 0) {
            return null;
        }
        if (!caseState.activePhotoId )
            return null;

        const currentPhoto = caseState.photos.find(item => item.id === caseState.activePhotoId);

        return {
            ...currentPhoto,
            xmarks: (currentPhoto && currentPhoto.metadata && currentPhoto.metadata.coordinates) ? currentPhoto.metadata.coordinates : [],
        };
    }
);

export const getCasesList = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return null;
        }

        return caseFuncs.modifyCasesList(caseState.cases);
    }
);

export const hasAscendCases = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return false;
        }

        return caseState.cases?.find(c => c.is_ascend) != null;
    }
)

export const shadeTabsLoadingByKey = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return null;
        }

        return caseState.shadeTabsLoadingByKey;
    }
);

export const getShadeTabsByKey = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return null;
        }

        return caseState.shadeTabsByKey;
    }
);

/*export const getCreatedCaseId = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return null;
        }

        return caseState.createdCaseId;
    }
);*/

export const getCaseNum = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return null;
        }

        return caseState.newCaseNum;
    }
);

export const getLabs = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return null;
        }

        return caseState.labs;
    }
)
export const getConnectedSpecialists = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return null;
        }

        return caseState.connectedSpecialists;
    }
);

export const getCurrentModifiersList = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState))
        {
            return null;
        }

        if (!caseState.shadeGuide)
            return null;

        let shadeGuideId = caseState.shadeGuide.id ?? 0;

        if (!caseState.standardModifiers)
            return null;

        // eslint-disable-next-line eqeqeq
        let modifier = caseState.standardModifiers.find(item => item.id == shadeGuideId);

        return modifier.modifiers;
    }
)

export const getCurrentModifiersSelectList = createSelector(
    [getCurrentModifiersList],
    (modifiersList) => {
        if (!isStateExists(modifiersList))
            return null;
        let result = [];

        modifiersList.forEach((item) => {
            result.push({name: item.name, value: item.id});
        });

        return result;

    }
)

export const getCurrentModifier = createSelector (
    [getCurrentModifiersList, getCase],
    (modifiersList, caseState) => {
        if (!isStateExists(modifiersList))
            return null;
        // eslint-disable-next-line eqeqeq
        let modifier = modifiersList.find(item => item.id == caseState.currentModifierId);
        return modifier;
    }
)

export const getCurrentShadeMapLabels = createSelector (
    [getCurrentPhoto],
    (currentPhoto) => {
        if (!isStateExists(currentPhoto))
            return [];
        return currentPhoto.shadeMapLabels;
    }
)

export const getCurrentModifierLabels = createSelector (
    [getCurrentPhoto],
    (currentPhoto) => {
        if (!isStateExists(currentPhoto))
        return [];
        return currentPhoto.modifierLabels;
    }
)



export const getIsCaseCompleted = createSelector (
    [getCurrentCase],
    (currentCase) => {
        if (!isStateExists(currentCase))
            return false;
        return currentCase.status === 'done';
    }
)

export const getIsAscendCase = createSelector (
    [getCurrentCase],
    (currentCase) => {
        if (!isStateExists(currentCase))
            return false;
        return currentCase.is_ascend;
    }
)


export const getLeftQcPhoto = createSelector (
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState))
            return null;
        if (!isStateExists(caseState.leftqc))
            return null;
        if (!caseState.isQcMode)
            return null;
        let photo = caseState.photos.find(p => p.id === caseState.leftqc.photo);
        if (!photo)
            return null;
        return caseState.isQcOriginal ? photo.imgOriginal : photo.imgViewCorrected;
    }
)

export const getRightQcPhoto = createSelector (
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState))
            return null;
        if (!isStateExists(caseState.rightqc))
            return null;
        if (!caseState.isQcMode)
            return null;
        let photo = caseState.photos.find(p => p.id === caseState.rightqc.photo);
        if (!photo)
            return null;
        return caseState.isQcOriginal ? photo.imgOriginal : photo.imgViewCorrected;
    }
)

export const getIsQcModeDisabled = createSelector (
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState))
            return true;

        let photos = caseState.photos.filter(p => p.metadata && p.metadata.manualCorrected);
        if (!photos)
            return true;
        return photos.length < 2;
    }
)

export const getLeftShadeValues = createSelector (
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState))
            return null;
        if (!isStateExists(caseState.leftqc))
            return null;
        if (!caseState.isQcMode)
            return null;
        let photo = caseState.photos.find(p => p.id === caseState.leftqc.photo);
        if (!photo || !photo.metadata)
            return null;
        let leftShadeGuide = 'N/A';
        if (caseState.availableShadeGuides) {
            let shadeGuide = caseState.availableShadeGuides.find(sg => sg.id === photo.metadata.shadeGuideId);
            if (shadeGuide) {
                leftShadeGuide = shadeGuide.name;
            }
        }
        return {
            valueBarBrightness: photo.metadata.valueBarBrightness,
            valueBarMaster: photo.metadata.valueBarMaster,
            valueBarShade: photo.metadata.valueBarShade,
            shadeTabLabel: photo.metadata.shadeTabLabel,
            rightShadeTabLabel: photo.metadata.rightShadeTabLabel,
            shadeGuide: leftShadeGuide,
        };
    }
)

export const getRightShadeValues = createSelector (
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState))
            return null;
        if (!isStateExists(caseState.rightqc))
            return null;
        if (!caseState.isQcMode)
            return null;
        let photo = caseState.photos.find(p => p.id === caseState.rightqc.photo);
        if (!photo || !photo.metadata)
            return null;
        let rightShadeGuide = 'N/A';
        if (caseState.availableShadeGuides) {
            let shadeGuide = caseState.availableShadeGuides.find(sg => sg.id === photo.metadata.shadeGuideId);
            if (shadeGuide) {
                rightShadeGuide = shadeGuide.name;
            }
        }
        return {
            valueBarBrightness: photo.metadata.valueBarBrightness,
            valueBarMaster: photo.metadata.valueBarMaster,
            valueBarShade: photo.metadata.valueBarShade,
            shadeTabLabel: photo.metadata.shadeTabLabel,
            rightShadeTabLabel: photo.metadata.rightShadeTabLabel,
            shadeGuide: rightShadeGuide,
        };
    }
)

export const getLeftUsedShadeTab = createSelector (
    [getCase, getCurrentPhoto],
    (caseState, photo) => {
        if (!isStateExists(caseState))
            return '';
        if (photo && photo.metadata && !!photo.metadata.shadeTabLabel)
            return  photo.metadata.shadeTabLabel;

        if (caseState.currentCase.metadata) {
            let mData = JSON.parse(caseState.currentCase.metadata);
            if (mData) {
               return mData.leftShadeTab?.name ?? '';
            }
        }
        return '';
    }
)

export const getRightUsedShadeTab = createSelector (
    [getCase, getCurrentPhoto],
    (caseState, photo) => {
        if (!isStateExists(caseState))
            return '';
        if (photo && photo.metadata && !!photo.metadata.rightShadeTabLabel)
            return  photo.metadata.rightShadeTabLabel;

        if (caseState.currentCase.metadata) {
            let mData = JSON.parse(caseState.currentCase.metadata);
            if (mData) {
               return mData.rightShadeTab?.name ?? '';
            }
        }
        return '';
    }
)

export const getShadeTabCoords = createSelector (
    [getCase, getCurrentPhoto],
    (caseState, photo) => {
        if (!isStateExists(caseState))
            return { left: {x: -1, y: -1}, right: {x: -1, y: -1} };
        if (photo && !!photo.shadeTabCoords) 
            return photo.shadeTabCoords;
        if (photo && photo.metadata && !!photo.metadata.shadeTabCoords)
            return  photo.metadata.shadeTabCoords;

        return { left: {x: -1, y: -1}, right: {x: -1, y: -1} };
    }
)

export const getShadeTabSettings = createSelector (
    [getCase, getCurrentPhoto],
    (caseState, photo) => {
        if (!isStateExists(caseState))
            return { left: {color: '#000000', fontSize: 40}, right: {color: '#000000', fontSize: 40} };
        if (photo && photo.metadata && !!photo.metadata.shadeTabSettings)
            return  photo.metadata.shadeTabSettings;

        return { left: {color: '#000000', fontSize: 40}, right: {color: '#000000', fontSize: 40} };
    }
)

export const getTextAnnotations = createSelector (
    [getCurrentPhoto],
    (photo) => {
        
        if (photo && photo.textAnnotations )
            return  photo.textAnnotations;

        return [];
    }
)

export const getGraphicAnnotations = createSelector (
    [getCurrentPhoto],
    (photo) => {
        
        if (photo && photo.graphic_annotations )
            return  photo.graphic_annotations;

        return [];
    }
)

export const getCrossHairCoords = createSelector (
    [getCase, getCurrentPhoto],
    (caseState, photo) => {
        if (!isStateExists(caseState))
            return { x: null, y: null };
        if (photo && photo.metadata && !!photo.metadata.crossHairPos)
            return  photo.metadata.crossHairPos;

        return { x: null, y: null };
    }
)

export const getShadeTransPos = createSelector (
    [getCase, getCurrentPhoto],
    (caseState, photo) => {
        if (!isStateExists(caseState))
            return null;
        if (photo && photo.metadata && !!photo.metadata.shadeTransPos)
            return  photo.metadata.shadeTransPos;

        return null;
    }
)




import { connect } from "react-redux";
import PublicRoute from "../../components/routes/PublicRoute";


const mapStateToProps = state => ({
    isLogged: state.user.isLogged,
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
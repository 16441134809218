const cookieMiddleware = ({ getState }) => {
    return next => action => {
        const result = next(action);

        // TODO logic here

        return result;
    };
};

export default cookieMiddleware;

const AscendActionTypes = {
    SIGN_IN : 'SIGN_IN',
    OPEN_ASCEND_SESSION: 'OPEN_ASCEND_SESSION',
    ASCEND_CASES_LOADED: 'ASCEND_CASES_LOADED',
    SELECT_ASCEND_CASE: 'SELECT_ASCEND_CASE',
    SET_ACQUIRED_IMAGES: 'SET_ACQUIRED_IMAGES',
    GET_CASES: 'GET_CASES',
    SET_ASCEND_KEY: 'SET_ASCEND_KEY',
    IMAGES_SELECTED_FOR_ACQUIRE: 'IMAGES_SELECTED_FOR_ACQUIRE',
    GET_SESSION: 'GET_SESSION',
};

export default AscendActionTypes;
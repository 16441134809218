import React from "react";
import PropTypes from "prop-types";
import { Label, Text} from "react-konva";
import Konva from "konva";

export default class ShadeImageLabel extends React.PureComponent {
    render() {
        let { x, y, text, index, ratio, fontSizeBase } = this.props;

        let fontSize = fontSizeBase ? fontSizeBase : 20
        //console.log('fontSize', fontSize)
        if (ratio && ratio > 0.0) {
            fontSize *= ratio
        }
        if (fontSize < 10)
            fontSize = 10
        
        let testText = new Konva.Text({
            text: text,
            padding: 1, 
            fontSize: fontSize,
            fontStyle: 'bold',
        });

        x = x - testText.getWidth() / 2;
        y = y - testText.getHeight() / 2;

        return (
            <Label x={x} y={y} >
                
                <Text text={text} fill={'black'} padding={1} fontStyle={'bold'} fontSize={fontSize} index={index} />
            </Label>
        )
    }
}

ShadeImageLabel.propTypes = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
};

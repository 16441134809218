import React from "react";
import PropTypes from "prop-types";

export default class RadioGroup extends React.PureComponent {
    renderOptions = (options) => {
        let { onClick } = this.props;

        return options.map((option, index) => {
            return (
                <label key={index}>
                    <input type={'radio'} name={option.name} value={option.value} key={index} className={'radio-elem' + (option.isCurrent ? ' active' : '')} onChange={() => onClick(option.value)} checked={option.isCurrent}/>
                    <div className={'checkmark'}></div>

                    <span>{option.text}</span>
                </label>
            )
        })
    };
    render() {
        let { options, className, children } = this.props;

        return (
            <div className={'radio-group' + (className ? ` ${className}` : '')}>
                {children}
                {this.renderOptions(options)}
            </div>
        )
    }
}

RadioGroup.propTypes = {
    options: PropTypes.array.isRequired,
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

import React from "react";
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";

export default class Link extends React.PureComponent {
    render() {
        let { children, isExternal, to, ...rest } = this.props;

        if (isExternal) {
            return <a href={to} {...rest}>{children}</a>
        }
        return (
            <NavLink to={to} {...rest}>{children}</NavLink>
        )
    }
}

Link.propTypes = {
    isExternal: PropTypes.bool,
    children: PropTypes.node,
    to: PropTypes.string.isRequired,
};

export const dayOfWeekName = (date) => {
    return [
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ][date.getDay()]
};
export const monthName = (date) => {
    return [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',
    ][date.getMonth()]
};
export const monthString = (date) => {
    let m = (date.getUTCMonth() + 1).toString();

    if (m.length === 1) {
        m = `0${m}`;
    }

    return m;
};

export const dateString = (date) => {
    let d = date.getDate().toString();

    if (d.length === 1) {
        d = `0${d}`;
    }

    return d;
};

export const dateToString = (date) => {
    return monthString(date) + '/' + dateString(date) + '/' + date.getFullYear();
};
export const dateToSystemString = (date) => {
    return  date.getFullYear() + '-' + monthString(date) + '-' + dateString(date);
};

export const stringToSystemString = (string) => {
    const parts = string.split('/');
    return `${parts[2]}-${parts[0]}-${parts[1]}`
};
export const stringToDate = (string) => {
    return new Date(stringToSystemString(string));
};

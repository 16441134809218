import React from "react";
import DraggableComponent from 'react-draggable';
import PropTypes from 'prop-types';

const Draggable = props => {
    const nodeRef = React.useRef(null);

    return (
        <DraggableComponent
            nodeRef={nodeRef}
            handle={props.handle || "strong"}
            bounds={props.bounds}
        >
            <div ref={nodeRef}>
                {props.children}
            </div>
        </DraggableComponent>
    )
};

export default Draggable;

Draggable.propTypes = {
    bounds: PropTypes.oneOfType([
        PropTypes.shape({
            left: PropTypes.number.isRequired,
            right: PropTypes.number.isRequired,
            top: PropTypes.number.isRequired,
            bottom: PropTypes.number.isRequired,
        }),
        PropTypes.bool
    ]).isRequired,
    handle: PropTypes.string,
};

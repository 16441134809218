import { connect } from 'react-redux';
import TopMenu from '../../components/case/TopMenu';
import { checkAllMessagesRead, emptyCaseMessagePhotos, loadAllCasePhoto, loadCaseMessages, markCaseComplete, restartOriginalImage, selectedPhotoType } from "../../actions/case.actions";
import { getIsPhotoCorrectStartAllowed, getIsRestartAllowed } from '../../selectors/workArea.selector';
import { getIsAscendCase, getIsCaseCompleted, getLeftUsedShadeTab, getRightUsedShadeTab } from '../../selectors/case.selector';

const mapStateToProps = state => ({
    caseId: state.case.caseId,
    isPhotoCorrectStartAllowed: getIsPhotoCorrectStartAllowed(state),
    isRestartAllowed: getIsRestartAllowed(state),
    isCaseCompleted: getIsCaseCompleted(state),
    isQcMode: state.case.isQcMode,
    leftShadeTab: getLeftUsedShadeTab(state),
    rightShadeTab: getRightUsedShadeTab(state),
    isAllMessagesRead: state.case.isAllMessagesRead,
    hasUnreadReceived: state.case.hasUnreadReceived,
    isAscendCase: getIsAscendCase(state),
});

const mapDispatchToProps = dispatch => ({
    loadCaseMessages: caseId => dispatch(loadCaseMessages(caseId)),
    loadAllCasePhoto: caseId => dispatch(loadAllCasePhoto(caseId)),
    emptyCaseMessagePhotos: () => dispatch(emptyCaseMessagePhotos()),
    selectedPhotoType: (imageType) => dispatch(selectedPhotoType(imageType)),
    restartOriginalImage: () => dispatch(restartOriginalImage()),
    markCaseComplete: (caseId) => dispatch(markCaseComplete(caseId)),
    checkAllMessagesRead: (caseId) => dispatch(checkAllMessagesRead(caseId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);

import React from "react";
import PropTypes from 'prop-types';

export default class Form extends React.PureComponent {
    submitHandler = (e) => {
        let { onSubmit, isAjax } = this.props;

        if (isAjax) {
            e.preventDefault();

            return onSubmit();
        }

        return onSubmit();
    };
    render() {
        let { className, id } = this.props;

        return (
            <form id={id ? id : ''} method={'post'} className={'form ' + (className ? className : '')} onSubmit={this.submitHandler}>
                {this.props.children}
            </form>
        );
    }
}

Form.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isAjax: PropTypes.bool,
};


import React, { PureComponent } from "react";
import { Arrow, Circle, Group, Line } from "react-konva";
import { GRAPHIC_ARROW, GRAPHIC_CIRCLE, GRAPHIC_LINE, NO_DRAW } from "../../../constants/graphicTypes";
import EditButtonKonva from "./EditButtonKonva";

class GraphAnnotation extends PureComponent {
    state = {};
    render() {
        const {
            graphObj,
            ratio,
            drawMode,
            convertImageToLayerPositionsArray,
            onLineDragEnd,
            setSelectedGraphObject,
            onLineEndsDragMove,
            onOpenGraphEdit,
            selected
        } = this.props;

        if (graphObj.graph_type === GRAPHIC_LINE || graphObj.graph_type === GRAPHIC_ARROW) {
            let points = convertImageToLayerPositionsArray(graphObj.points);
            if (points == null) return null
            if (graphObj.graph_type === GRAPHIC_LINE) {
                
                return (
                    <Group
                        key={graphObj.uid + "-g"}
                        id={graphObj.uid + "-g"}
                        draggable={drawMode === NO_DRAW}
                        onDragEnd={(e) => {
                            onLineDragEnd(e, graphObj.uid);
                        }}
                        _useStrictMode
                    >
                        <Line
                            key={graphObj.uid}
                            id={graphObj.uid}
                            points={points}
                            stroke={graphObj.color}
                            strokeWidth={graphObj.size}
                            tension={0.5}
                            dashEnabled={graphObj.dashed ?? false}
                            dash={[20, 10]}
                            //lineCap="round"
                            onClick={() => {
                                setSelectedGraphObject(graphObj.uid);
                            }}
                            onTouchEnd={(e) => {
                                //console.log('line touch start')
                                e.evt.preventDefault()
                                setSelectedGraphObject(graphObj.uid)
                            }}
                            hitStrokeWidth={graphObj.size + 6}
                            _useStrictMode
                        />
                        {selected && (
                            <Circle
                                id={graphObj.uid + "0"}
                                key={graphObj.uid + "0"}
                                x={points[0]}
                                y={points[1]}
                                stroke="#3366ff"
                                strokeWidth={7}
                                tension={0.5}
                                lineCap="round"
                                radius={5}
                                draggable={drawMode === NO_DRAW}
                                onDragMove={(e) => {
                                    onLineEndsDragMove(e, graphObj.uid, 0);
                                }}
                                _useStrictMode
                            />
                        )}
                        {selected && (
                            <Circle
                                id={graphObj.uid + "1"}
                                key={graphObj.uid + "1"}
                                x={points[2]}
                                y={points[3]}
                                stroke="#3366ff"
                                strokeWidth={7}
                                tension={0.5}
                                lineCap="round"
                                radius={5}
                                onDragMove={(e) => {
                                    onLineEndsDragMove(e, graphObj.uid, 1);
                                }}
                                draggable={drawMode === NO_DRAW}
                                _useStrictMode
                            />
                        )}
                        {selected && (
                            <EditButtonKonva
                                id={graphObj.uid + "e"}
                                key={graphObj.uid + "e"}
                                x={(points[0] + points[2]) / 2 - 15}
                                y={(points[1] + points[3]) / 2 - 15}
                                width={30}
                                height={30}
                                onClick={(e) => {
                                    onOpenGraphEdit(e, graphObj.uid);
                                }}
                                onTouchStart={e => {
                                    e.evt.preventDefault()
                                    onOpenGraphEdit(e, graphObj.uid);
                                }}
                                _useStrictMode
                            />
                        )}
                    </Group>
                );
            } else if (graphObj.graph_type === GRAPHIC_ARROW) {
                /*var tempx = points[0];
                var tempy = points[1];
                points[0] = points[2];
                points[1] = points[3];
                points[2] = tempx;
                points[3] = tempy;*/
                return (
                    <Group
                        key={graphObj.uid + "-g"}
                        id={graphObj.uid + "-g"}
                        draggable={drawMode === NO_DRAW}
                        onDragEnd={(e) => {
                            onLineDragEnd(e, graphObj.uid);
                        }}
                        _useStrictMode
                    >
                        <Arrow
                            id={graphObj.uid}
                            key={graphObj.uid}
                            points={points}
                            stroke={graphObj.color}
                            strokeWidth={graphObj.size}
                            tension={0}
                            fillAfterStroke={true}
                            fill={graphObj.color}
                            fillEnabled={true}
                            hitStrokeWidth={graphObj.size + 6}
                            dashEnabled={graphObj.dashed ?? false}
                            dash={[20, 10]}
                            onClick={() => {
                                setSelectedGraphObject(graphObj.uid);
                            }}
                            onTouchEnd={(e) => {
                                setSelectedGraphObject(graphObj.uid);
                            }}
                            _useStrictMode
                        />
                        {selected && (
                            <Circle
                                id={graphObj.uid + "0"}
                                key={graphObj.uid + "0"}
                                x={points[0]}
                                y={points[1]}
                                stroke="#3366ff"
                                strokeWidth={7}
                                tension={0.5}
                                lineCap="round"
                                radius={5}
                                draggable={drawMode === NO_DRAW}
                                onDragMove={(e) => {
                                    onLineEndsDragMove(e, graphObj.uid, 0);
                                }}
                                _useStrictMode
                            />
                        )}
                        {selected && (
                            <Circle
                                id={graphObj.uid + "1"}
                                key={graphObj.uid + "1"}
                                x={points[2]}
                                y={points[3]}
                                stroke="#3366ff"
                                strokeWidth={7}
                                tension={0.5}
                                lineCap="round"
                                radius={5}
                                onDragMove={(e) => {
                                    onLineEndsDragMove(e, graphObj.uid, 1);
                                }}
                                draggable={drawMode === NO_DRAW}
                                _useStrictMode
                            />
                        )}
                        {selected && (
                            <EditButtonKonva
                                id={graphObj.uid + "e"}
                                x={(points[0] + points[2]) / 2 - 15}
                                y={(points[1] + points[3]) / 2 - 15}
                                width={30}
                                height={30}
                                onClick={(e) => {
                                    onOpenGraphEdit(e, graphObj.uid);
                                }}
                                onTouchStart={(e) => {
                                    e.evt.preventDefault();
                                    onOpenGraphEdit(e, graphObj.uid);
                                }}
                                _useStrictMode
                            />
                        )}
                    </Group>
                );
            }
        } else if (graphObj.graph_type === GRAPHIC_CIRCLE) {
            let pts = convertImageToLayerPositionsArray([graphObj.x, graphObj.y]);
            if (pts == null) return null
            let radius = graphObj.radius * ratio;
            return (
                <Group
                    draggable={drawMode === NO_DRAW}
                    key={graphObj.uid + "c-g"}
                    id={graphObj.uid + "c-g"}
                    onDragEnd={(e) => {
                        onLineDragEnd(e, graphObj.uid);
                    }}
                >
                    <Circle
                        ref={(node) => {
                            this.circle = node;
                        }}
                        key={graphObj.uid}
                        id={graphObj.uid}
                        x={pts[0]}
                        y={pts[1]}
                        stroke={graphObj.color}
                        strokeWidth={graphObj.size}
                        hitStrokeWidth={graphObj.size + 6}
                        tension={0.5}
                        //lineCap="round"
                        radius={radius}
                        fillEnabled={false}
                        dashEnabled={graphObj.dashed ?? false}
                        dash={[20, 10]}
                        onClick={() => {
                            setSelectedGraphObject(graphObj.uid);
                        }}
                        onTouchEnd={(e) => {
                            e.evt.preventDefault();
                            setSelectedGraphObject(graphObj.uid);
                        }}
                    />
                    {selected && (
                        <Circle
                            key={graphObj.uid + "0"}
                            id={graphObj.uid + "0"}
                            x={pts[0] - radius}
                            y={pts[1]}
                            stroke="#3366ff"
                            strokeWidth={7}
                            tension={0.5}
                            lineCap="round"
                            radius={5}
                            draggable={drawMode === NO_DRAW}
                            onDragMove={(e) => {
                                onLineEndsDragMove(e, graphObj.uid, 2);
                            }}
                            dragBoundFunc={(pos) => {
                                return { x: pos.x, y: this.circle.absolutePosition().y };
                            }}
                        />
                    )}
                    {selected && (
                        <Circle
                            key={graphObj.uid + "1"}
                            id={graphObj.uid + "1"}
                            x={pts[0] + radius}
                            y={pts[1]}
                            stroke="#3366ff"
                            strokeWidth={7}
                            tension={0.5}
                            lineCap="round"
                            radius={5}
                            onDragMove={(e) => {
                                onLineEndsDragMove(e, graphObj.uid, 2);
                            }}
                            draggable={drawMode === NO_DRAW}
                            dragBoundFunc={(pos) => {
                                return { x: pos.x, y: this.circle.absolutePosition().y };
                            }}
                        />
                    )}
                    {selected && (
                        <Circle
                            key={graphObj.uid + "2"}
                            id={graphObj.uid + "2"}
                            x={pts[0]}
                            y={pts[1] - radius}
                            stroke="#3366ff"
                            strokeWidth={7}
                            tension={0.5}
                            lineCap="round"
                            radius={5}
                            draggable={drawMode === NO_DRAW}
                            onDragMove={(e) => {
                                onLineEndsDragMove(e, graphObj.uid, 2);
                            }}
                            dragBoundFunc={(pos) => {
                                return { y: pos.y, x: this.circle.absolutePosition().x };
                            }}
                        />
                    )}
                    {selected && (
                        <Circle
                            key={graphObj.uid + "3"}
                            id={graphObj.uid + "3"}
                            x={pts[0]}
                            y={pts[1] + radius}
                            stroke="#3366ff"
                            strokeWidth={7}
                            tension={0.5}
                            lineCap="round"
                            radius={5}
                            onDragMove={(e) => {
                                onLineEndsDragMove(e, graphObj.uid, 2);
                            }}
                            draggable={drawMode === NO_DRAW}
                            dragBoundFunc={(pos) => {
                                return { y: pos.y, x: this.circle.absolutePosition().x };
                            }}
                        />
                    )}
                    {selected && (
                        <EditButtonKonva
                            id={graphObj.uid + "e"}
                            x={pts[0] - 15}
                            y={pts[1] - 15}
                            width={30}
                            height={30}
                            onClick={(e) => {
                                onOpenGraphEdit(e, graphObj.uid);
                            }}
                            onTouchStart={(e) => {
                                e.evt.preventDefault();
                                onOpenGraphEdit(e, graphObj.uid);
                            }}
                        />
                    )}
                </Group>
            );
        }
    }
}

export default GraphAnnotation;

import axios from "axios";
import ShadeMatchLib from "./shadeMatchLib.1.0.20";

export default class photoUtils {
	//static url = 'https://app.shadewave.com/swapi/?contentType=application/json';
	static baseUrl = process.env.REACT_APP_API_URL;
	static service = "ShadeWaveService";
	static module = null;

	static load = (url) => {
		let image = new window.Image();

		return new Promise((imageResult) =>
			axios
				.get(photoUtils.baseUrl + url, {
					headers: {},
					responseType: "blob",
					timeout: 600000,
					withCredentials: true,
				})
				.then(function (response) {
					let blob = response.data;
					console.log(blob.size);
					let imageSize = blob.size / (1024 * 1024)

					image.addEventListener("load", () => {
						console.log("loaded image:" + image.height + "x" + image.width);
						let canvas = document.createElement("canvas");
						let context = canvas.getContext("2d");

						canvas.width = image.width;
						canvas.height = image.height;
						context.drawImage(image, 0, 0);
                        //createImageBitmap(image).then((canvas) => {
							console.log('image bitmap created:'+ canvas.height + "x" + canvas.width)
                            let canvasThumb = document.createElement("canvas");
                            let contextThumb = canvasThumb.getContext("2d");
                            canvasThumb.width = 200;
                            canvasThumb.height = Math.round( canvas.height * 200 / canvas.width );
                            contextThumb.drawImage(canvas, 0,0,canvas.width,canvas.height,0,0,canvasThumb.width,canvasThumb.height);
						    imageResult({canvas, canvasThumb, imageSize});
                        /*}).catch(error => {
                            console.log(error)
                        })*/ 

					});
					image.addEventListener("error", (err) => console.log(err));
					image.src = URL.createObjectURL(blob);
				})
		);
	};

	static getShadeMatchModule() {
		return new Promise((result) => {
			if (!photoUtils.module) {
				ShadeMatchLib({
					noInitialRun: true,
					noExitRuntime: true,
				}).then((module) => {
					photoUtils.module = module;
					result(module);
				});
			} else result(photoUtils.module);
		});
	}

    static clearCanvases(photos) {
        if (!photos) return;
        photos.forEach(p => {
            if (p) {
                p.width = 0;
                p.height = 0;
                //p.close();
                p = null;
            }
        });
    }

	static correctImage(image, colors, refcolors, studiocolors, isViewImage = false) {
		return new Promise((imageResult) => {
			
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");

			canvas.width = image.width;
			canvas.height = image.height;
			context.drawImage(image, 0, 0);
			const myImage = context.getImageData(0, 0, image.width, image.height);
			const numBytes = image.width * image.height * 4;

			const ptr = photoUtils.module._malloc(numBytes);
			photoUtils.module.HEAPU8.set(myImage.data, ptr);

			const ptrColors = photoUtils.module._malloc(4 * colors.length);
			const ptrRefColors = photoUtils.module._malloc(4 * refcolors.length);
			const ptrStudioColors =
				(studiocolors && studiocolors.length > 0) ? photoUtils.module._malloc(4 * studiocolors.length) : null;
			const uColors = new Uint32Array(colors.length);
			const uRefColors = new Uint32Array(refcolors.length);
			const uStudioColors = (studiocolors && studiocolors.length > 0) ? new Uint32Array(studiocolors.length) : null;

			for (let i = 0; i < colors.length; i++) {
				uColors[i] = colors[i];
				uRefColors[i] = refcolors[i];
				if (studiocolors && studiocolors.length > i) uStudioColors[i] = studiocolors[i];
			}

			photoUtils.module.HEAPU32.set(uColors, ptrColors >> 2);
			photoUtils.module.HEAPU32.set(uRefColors, ptrRefColors >> 2);
			if (ptrStudioColors) photoUtils.module.HEAPU32.set(uStudioColors, ptrStudioColors >> 2);

			if (ptrStudioColors) {
				photoUtils.module._shadeMatchGammaSplineMultipleOneKlik(
					ptr,
					image.height,
					image.width,
					4,
					ptrColors,
					ptrStudioColors,
					ptrRefColors,
					colors.length,
					(isViewImage) ? 0 : 1,
				);
			} else {
				photoUtils.module._shadeMatchGammaSplineMultiple(
					ptr,
					image.height,
					image.width,
					4,
					ptrColors,
					ptrRefColors,
					colors.length,
					(isViewImage) ? 0 : 1,
				);
			}

            myImage.data.set(new Uint8ClampedArray(photoUtils.module.HEAPU8.buffer, ptr, numBytes));
			context.putImageData(myImage, 0, 0);
			photoUtils.module._free(ptr);

			imageResult(canvas);
            //context.putImageData(myImage, 0, 0);
			

			/*const resultImg = new window.Image();

      resultImg.addEventListener("load", () => {
        imageResult(resultImg);
      });
      resultImg.src = canvas.toDataURL("image/jpeg", 1.0);*/
		});
	}

	static segmentImage(image, colors, bgColor, sensitivity, limits, drawContours, fillColors, sensitivityLimits, maskTooth, rect, fullRect) {
		return new Promise((imageResult) => {

			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");

			canvas.width = image.width;
			canvas.height = image.height;
			context.drawImage(image, 0, 0);
			const myImage = context.getImageData(0, 0, image.width, image.height);
			const numBytes = image.width * image.height * 4;

			

			const ptr = photoUtils.module._malloc(numBytes);
			photoUtils.module.HEAPU8.set(myImage.data, ptr);
			const ptr1 = photoUtils.module._malloc(numBytes);
			const ptr2 = photoUtils.module._malloc((numBytes * 3) / 4);
			photoUtils.module.HEAPU8.set(new Uint8Array(myImage.data), ptr1);

			
			const ptrMask = photoUtils.module._malloc(fullRect.width*fullRect.height);
			if (maskTooth == null) {
				maskTooth = new Uint8Array(Array(fullRect.width*fullRect.height).fill(0));
			}
			console.log('maskTooth', rect, maskTooth, (fullRect.width*fullRect.height))
			photoUtils.module.HEAPU8.set(maskTooth, ptrMask); 

			const ptrColors = photoUtils.module._malloc(4 * colors.length);
			const uColors = new Uint32Array(colors.length);
			for (let i = 0; i < colors.length; i++) {
				uColors[i] = colors[i];
			}
			photoUtils.module.HEAPU32.set(uColors, ptrColors >> 2);

			const minsize = image.height < image.width ? image.height : image.width;
			let scaleFactor = minsize / 450;
			if (scaleFactor < 2) scaleFactor = 2;
			if (scaleFactor > 3) scaleFactor = 3;
            const filterValue = (1.0 * image.width) / scaleFactor;
            
            const minSensitivity = sensitivityLimits.minSens;
            const maxSensitivity = sensitivityLimits.maxSens;

            const sens = 160 - ((sensitivity / 100)*(maxSensitivity - minSensitivity) + minSensitivity);

			photoUtils.module._teethSegmenationProcess2(
				ptrColors,
				colors.length,
				image.height,
				image.width,
				ptr,
				ptr1,
				ptr2,
				ptrMask,
				rect.x, rect.y, rect.width, rect.height, fullRect.width, fullRect.height,
				bgColor,
				sens,
				filterValue,
				limits,
				drawContours,
				scaleFactor,
				null,
				0
			);

			const res2 = new Uint8Array(photoUtils.module.HEAP8.buffer, ptr2, image.width * image.height * 3);

			const res1 = new Uint8Array(photoUtils.module.HEAP8.buffer, ptr1, image.width * image.height * 4);

			if (!drawContours && fillColors && fillColors.length > 0) {
				const numPixels = image.width * image.height;
                const colorsLen = fillColors.length;
                
				for (let i = 0; i < numPixels; i++) {
                    const pix = res2[i * 3];
					if (pix > 0 && colorsLen >= pix) {
                        
						let clr = fillColors[pix - 1];
						const idx = i * 4;
						res1[idx] = (clr >> 16) & 0xff;
						res1[idx + 1] = (clr >> 8) & 0xff;
						res1[idx + 2] = clr & 0xff;
					}
				}
			}

			let canvasRes = document.createElement("canvas");
			const contextRes = canvasRes.getContext("2d");

			canvasRes.width = image.width;
			canvasRes.height = image.height;
			contextRes.drawImage(image, 0, 0);
			let imageRes = contextRes.getImageData(0, 0, image.width, image.height);

			imageRes.data.set(new Uint8ClampedArray(photoUtils.module.HEAPU8.buffer, ptr1, numBytes));

			contextRes.putImageData(imageRes, 0, 0);
			photoUtils.module._free(ptr);
			photoUtils.module._free(ptr1);
			photoUtils.module._free(ptr2);

			imageResult({ resultImg: canvasRes, mask: res2.slice() });
            
			
			
		});
	}

	static convertGrayScale(image) {
		return new Promise((imageResult) => {});
	}

	static cropRegion(image, rect) {
		return new Promise((imageResult) => {
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");
		
			canvas.width = rect.width;
			canvas.height = rect.height;
			context.drawImage(image, rect.x, rect.y, rect.width, rect.height, 0, 0, rect.width, rect.height);

            imageResult({ resultImg: canvas });

			
		});
	}

	static generateTripleImages(imgCorrected, imgShadeMap, imgModifier, maskShadeMap, maskModifier) {
		return new Promise((Result) => {
			const width = imgCorrected.width;
			const height = imgCorrected.height;

			const canvas1 = document.createElement("canvas");
			const context1 = canvas1.getContext("2d");

			canvas1.width = width * 3 + 40;
			canvas1.height = height;
			context1.drawImage(imgShadeMap, 0, 0, width, height, 0, 0, width, height);
			context1.drawImage(imgCorrected, 0, 0, width, height, width + 20, 0, width, height);
			context1.drawImage(imgModifier, 0, 0, width, height, width * 2 + 40, 0, width, height);

			const canvas2 = document.createElement("canvas");
			const context2 = canvas2.getContext("2d");

			canvas2.width = width * 2 + 20;
			canvas2.height = height * 2 + 20;
			context2.drawImage(imgShadeMap, 0, 0, width, height, 0, 20 + height, width, height);
			context2.drawImage(imgCorrected, 0, 0, width, height, width / 2 + 10, 0, width, height);
            context2.drawImage(imgModifier, 0, 0, width, height, width + 20, height + 20, width, height);

			const w1 = width * 3 + 40;
			const h1 = height;
			const mask1 = new Uint8ClampedArray(w1 * h1 * 3);
			mask1.fill(0);
			for (var i = 0; i < height; i++) {
				let startSrc = i * width * 3;
				let endSrc = startSrc + width * 3;
				let startDestMap = i * w1 * 3;
				let startDestModifier = startDestMap + 3 * (2 * width + 40);
				mask1.set(maskShadeMap.subarray(startSrc, endSrc), startDestMap);
				mask1.set(maskModifier.subarray(startSrc, endSrc), startDestModifier);
            }

			const w2 = width * 2 + 20;
			const h2 = height * 2 + 20;
			const mask2 = new Uint8ClampedArray(w2 * h2 * 3);
			mask2.fill(0);
			for (let j = 0; j < height; j++) {
				const startSrc = j * width * 3;
				const endSrc = startSrc + width * 3;
				const startDestMap = (j + height + 20) * w2 * 3;
				const startDestModifier = startDestMap + 3 * (width + 20);
				mask2.set(maskShadeMap.subarray(startSrc, endSrc), startDestMap);
				mask2.set(maskModifier.subarray(startSrc, endSrc), startDestModifier);
			}

            Result({
				imageResult1: canvas1,
				imageResult2: canvas2,
				maskResult1: mask1,
				maskResult2: mask2,
			});

			
		});
    }

	static roundedImage(ctx, x,y,width,height,radius){
		ctx.beginPath();
		/*ctx.moveTo(x + radius, y);
		ctx.lineTo(x + width - radius, y);
		ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
		ctx.lineTo(x + width, y + height - radius);
		ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
		ctx.lineTo(x + radius, y + height);
		ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
		ctx.lineTo(x, y + radius);
		ctx.quadraticCurveTo(x, y, x + radius, y);*/
		ctx.roundRect(x,y,width,height,radius)
		ctx.closePath();
	  }

	static generateDoubleImage(imgShadeMap, imgModifier, maskShadeMap, maskModifier) {
		return new Promise((Result) => {
			const width = imgShadeMap.width;
			const height = imgShadeMap.height;

			console.log('double image', width, height)
			console.log('dbl image', imgShadeMap)

			const canvas1 = document.createElement("canvas");
			const context1 = canvas1.getContext("2d");

			canvas1.width = width * 2 + 20;
			canvas1.height = height;
			const minDimension = Math.min(canvas1.width, canvas1.height)
			const radius = 0.15 * minDimension
			context1.save()
			photoUtils.roundedImage(context1, 0, 0, canvas1.width, canvas1.height, radius);
			context1.strokeStyle = "black";
			context1.stroke()
			context1.clip()
			context1.fillStyle = "black";
			context1.fillRect(width, 0, 20, height);
			context1.drawImage(imgShadeMap, 0, 0, width, height, 0, 0, width, height);
			context1.drawImage(imgModifier, 0, 0, width, height, width + 20, 0, width, height);
			
			context1.lineWidth = 20;
			context1.beginPath();
			context1.roundRect(0, 0, canvas1.width, canvas1.height, radius);
			context1.stroke();
			context1.restore();
			const w1 = width * 2 + 20;
			const h1 = height;
			const mask1 = new Uint8ClampedArray(w1 * h1 * 3);
			mask1.fill(0);
			for (let i = 0; i < height; i++) {
				const startSrc = i * width * 3;
				const endSrc = startSrc + width * 3;
				const startDestMap = i * w1 * 3;
				const startDestModifier = startDestMap + 3 * (width + 20);
				mask1.set(maskShadeMap.subarray(startSrc, endSrc), startDestMap);
				mask1.set(maskModifier.subarray(startSrc, endSrc), startDestModifier);
            }

			//const imageData = context1.getImageData(0, 0, w1, h1);
            const destData = context1.getImageData(0, 0, w1, h1);

            const src = mask1;
            const dest = destData.data;

            const numBytes = w1 * h1 * 3;
            /*for (var j = 0; j < numBytes; j+=3) {
                let luma = (src[j] + src[j+1] + src[j+2] );
				if (luma == 0) {
					let k = j*4/3;
                	dest[k] = dest[k+1] = dest[k+2] = dest[k+3] = 0;
				}
				else {

				}
            }*/

			context1.putImageData(destData,0,0);

            Result({
				imageResult: canvas1,
				maskResult: mask1
			});

			
		});
    }
    
    static generateViewRefColors(refColors, standardViewColors) {
        const white0 = refColors[0];
        const black0 = refColors[1];
        const white1 = parseInt( standardViewColors.white ) >>> 0;
        const black1 = parseInt( standardViewColors.black ) >>> 0;
        const white00 = white0 & 0xFF;
        const white01 = (white0 >> 8) & 0xFF;
        const white02 = (white0 >> 16) & 0xFF;
        const white10 = white1 & 0xFF;
        const white11 = (white1 >> 8) & 0xFF;
        const white12 = (white1 >> 16) & 0xFF;
        const black00 = black0 & 0xFF;
        const black01 = (black0 >> 8) & 0xFF;
        const black02 = (black0 >> 16) & 0xFF;
        const black10 = black1 & 0xFF;
        const black11 = (black1 >> 8) & 0xFF;
        const black12 = (black1 >> 16) & 0xFF;

        let result = [white1, black1];
                
        for (let i = 2; i < refColors.length; i++) {
            let v0, v1, v2;
            let shadeColor = refColors[i];
            if ((shadeColor & 0xFF) < black00)
                v0 = black10;
            else
                v0 = Math.round(black10 + ((shadeColor & 0xFF) - black00)*(white10-black10)/(white00-black00));
            if (((shadeColor >> 8) & 0xFF) < black01)
                v1 = black11;
            else
                v1 = Math.round(black11 + (((shadeColor >> 8) & 0xFF) - black01)*(white11-black11)/(white01-black01));
            if (((shadeColor >> 16) & 0xFF) < black02)
                v2 = black12;
            else
                v2 = Math.round(black12 + (((shadeColor >> 16) & 0xFF) - black02)*(white12-black12)/(white02-black02));
            result.push((v2 << 16) + (v1 << 8) + v0);
        }

        return result;
    }

    static getRegionColorValues(image, rect, colors, master3dColors, brightnessColors) {
        return new Promise((Result) => {
            const width = rect.width;
			const height = rect.height;

			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");

			canvas.width = width;
			canvas.height = height;
            context.drawImage(image, rect.x, rect.y, width, height, 0, 0, width, height);
            
            const imageData = context.getImageData(0, 0, width, height);
            const greyData = context.createImageData(width, height);

            const src = imageData.data;
            const dest = greyData.data;

            const numBytes = width * height * 4;
            for (let j = 0; j < numBytes; j+=4) {
                let luma = Math.round(src[j] * 0.2126 + src[j+1] * 0.7152 + src[j+2] * 0.0722);
                dest[j] = dest[j+1] = dest[j+2] = luma;
                dest[j+3] = src[j+3];
            }

			const ptr = photoUtils.module._malloc(numBytes);
            photoUtils.module.HEAPU8.set(imageData.data, ptr);

            const ptr1 = photoUtils.module._malloc(numBytes);
            photoUtils.module.HEAPU8.set(greyData.data, ptr1);
            
            const ptrColors = photoUtils.module._malloc(4 * colors.length);
			const uColors = new Uint32Array(colors.length);
			for (let i = 0; i < colors.length; i++) {
				uColors[i] = colors[i];
			}
            photoUtils.module.HEAPU32.set(uColors, ptrColors >> 2);

            const ptrColors2 = photoUtils.module._malloc(4 * master3dColors.length);
			const uColors2 = new Uint32Array(master3dColors.length);
			for (let i = 0; i < master3dColors.length; i++) {
				uColors2[i] = master3dColors[i];
			}
            photoUtils.module.HEAPU32.set(uColors2, ptrColors2 >> 2);

            const ptrColors3 = photoUtils.module._malloc(4 * brightnessColors.length);
			const uColors3 = new Uint32Array(brightnessColors.length);
			for (let i = 0; i < brightnessColors.length; i++) {
				uColors3[i] = brightnessColors[i];
			}
            photoUtils.module.HEAPU32.set(uColors3, ptrColors3 >> 2);
            
            const colorCode = photoUtils.module._getShadeValueForRegion(ptr, height, width, ptrColors, colors.length );
            const masterCode = photoUtils.module._getShadeValueForRegion(ptr1, height, width, ptrColors2, master3dColors.length );
            const brightnessCode = photoUtils.module._getShadeValueForRegion(ptr1, height, width, ptrColors3, brightnessColors.length );

            canvas.width = 0;
            canvas.height = 0;
            photoUtils.module._free(ptr);
            photoUtils.module._free(ptr1);
            photoUtils.module._free(ptrColors);
            photoUtils.module._free(ptrColors2);
            photoUtils.module._free(ptrColors3);
            
            Result( { colorCode: colorCode, masterCode: masterCode, brightnessCode: brightnessCode} );            

        });
    }

    static getAverageColor(data) {
        const len = data.length;
        let result = new Uint8ClampedArray(4);
  
        //let R = 0;
        //let G = 0;
        //let B = 0;
        //let A = 0;
        let wR = 0;
        let wG = 0;
        let wB = 0;
        let wTotal = 0;
        for (let i = 0; i < len; i += 4) {
            // A single pixel (R, G, B, A) will take 4 positions in the array:
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
            const a = data[i + 3];
            
            // Update components for solid color and alpha averages:
            //R += r;
            //G += g;
            //B += b;
            //A += a;
            
            // Update components for alpha-weighted average:
            const w = a / 255;
            wR += r * w;
            wG += g * w;
            wB += b * w;
            wTotal += w;
        }
        
        //const pixelsPerChannel = len / 4;
        // The | operator is used here to perform an integer division:
        //R = R / pixelsPerChannel | 0;
        //G = G / pixelsPerChannel | 0;
        //B = B / pixelsPerChannel | 0;
        wR = wR / wTotal | 0;
        wG = wG / wTotal | 0;
        wB = wB / wTotal | 0;

        if (wR > 255) wR = 255;
        if (wG > 255) wG = 255;
        if (wB > 255) wB = 255;

        result[0] = wR;
        result[1] = wG;
        result[2] = wB;
        result[3] = 255;  

        return result;
    }

    static isRectsEqual(rect1, rect2) {
        return rect1.x === rect2.x && rect1.y === rect2.y && rect1.width === rect2.width && rect1.height === rect2.height;
    }

	static getBorders(canvas) {
		const context = canvas.getContext("2d");

		const myImage = context.getImageData(0, 0, canvas.width, canvas.height);
		const numBytes = canvas.width * canvas.height * 4;
		const data = myImage.data;
		let xMin = 0; 
		let found = false;
		for (let i = 0; i < canvas.width; i++) {
			for (let j = 0; j < canvas.height; j++) {
				let index = j * canvas.width * 4 + i * 4;
				let value = data[index] + data[index+1] + data[index+2];
				if (value > 0) {
					xMin = i;
					found = true;
					break;
				}

			}
			if (found)
			break;
		}
		let xMax = canvas.width-1; 
		found = false;
		for (let i = canvas.width - 1; i >= 0; i--) {
			for (let j = 0; j < canvas.height; j++) {
				let index = j * canvas.width * 4 + i * 4;
				let value = data[index] + data[index+1] + data[index+2];
				if (value > 0) {
					xMax = i;
					found = true;
					break;
				}

			}
			if (found)
			break;
		}
		let yMin = 0; 
		found = false;
		for (let j = 0; j < canvas.height; j++) {
			for (let i = 0; i < canvas.width; i++) {
				let index = j * canvas.width * 4 + i * 4;
				let value = data[index] + data[index+1] + data[index+2];
				if (value > 0) {
					yMin = j;
					found = true;
					break;
				}

			}
			if (found)
			break;
		}
		let yMax = 0; 
		found = false;
		for (let j = canvas.height-1; j >= 0; j--) {
			for (let i = 0; i < canvas.width; i++) {
				let index = j * canvas.width * 4 + i * 4;
				let value = data[index] + data[index+1] + data[index+2];
				if (value > 0) {
					yMax = j;
					found = true;
					break;
				}

			}
			if (found)
			break;
		}
		xMin = xMin - 1;
		yMin = yMin - 1;
		xMax = xMax + 1;
		yMax = yMax + 1;
		if (xMin < 0) xMin = 0;
		if (yMin < 0) yMin = 0;
		if (xMax > canvas.width - 1) xMax = canvas.width - 1;
		if (yMax > canvas.height - 1) yMax = canvas.height - 1;
		return {xMin: xMin, xMax: xMax, yMin: yMin, yMax: yMax};
	}

	static getToothMask(image, threshold) {
		return new Promise((imageResult) => {

			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");

			canvas.width = image.width;
			canvas.height = image.height;
			context.drawImage(image, 0, 0);
			const myImage = context.getImageData(0, 0, image.width, image.height);
			const numBytes = image.width * image.height * 4;

			const ptr = photoUtils.module._malloc(numBytes);
			photoUtils.module.HEAPU8.set(new Uint8Array(myImage.data), ptr);
			const ptr1 = photoUtils.module._malloc(numBytes / 4);

			photoUtils.module._getToothArea(
				ptr,
				image.width,
				image.height,
				ptr1, threshold
			);

			const res1 = new Uint8ClampedArray(photoUtils.module.HEAP8.buffer, ptr1, image.width * image.height);
			let counter = 0
			for (let p of res1) {
				if (p > 0) counter++;
			}
			console.log('counter', counter);

			photoUtils.module._free(ptr);
			photoUtils.module._free(ptr1);

			imageResult({ mask: res1.slice() });
			
		});
	}
}

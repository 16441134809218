import { takeLatest, put, all, call } from 'redux-saga/effects';

import DentistActionTypes from '../actions/dentist.types';
import swApi from "../libraries/swApi";
import { searchLabsSuccess } from "../actions/dentist.actions";

export function* onSearchLabs() {
    yield takeLatest(DentistActionTypes.SEARCH_LABS, function* () {
        const response = yield call(swApi.makeRequest, 'searchLabUser', [ ] );
        yield put(searchLabsSuccess(response));
    });
}

export function* dentistSagas() {
    yield all([
        //call(onSearchLabs),
    ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import MatDialog, { MatDialogHeader, MatDialogContent } from './elements/DraggableDialog';
import Textarea from './elements/form/Textarea';
import Checkbox from './elements/form/Checkbox';
import GreenButton from './elements/form/buttons/GreenButton';
import Uploader from './elements/Uploader';
import AlertDialog from './elements/AlertDialog';

const INITIAL_STATE = {
    secureNote: '',
    visibleByDentist: true,
    visibleByLab: true,
    visibleBySpecialist: true,
    submitDisabled: true,
    showLoadingMessage: false,
    showSavingMessage: false,
    uploaderRef: null,
    showPhotoProgressBar: false,
    showAlertDialog: false,
    secureNoteTextareaDisabled: false,
    dentistCheckBoxDisabled: false,
    labCheckBoxDisabled: false,
    specialistCheckBoxDisabled: false,
};

export default class SecureNotes extends React.PureComponent {
    state = {
        ...INITIAL_STATE,
    };
    componentDidUpdate(prevProps) {
        const {isOpen } = this.props;
        const { isOpen: prevOpen } = prevProps;

        if (isOpen) {
            if (!prevOpen) {
                this.toggleWaitingMessage(true);
                this.toggleProgressBar(true);
            }
            const {
                casePhotosLoaded,
                caseMessagesLoaded,
                savingCaseMessageProcessFinished,
                savingCaseMessagePhotoProcessFinished,
                resetCaseMessagesSaved,
                resetCaseMessagePhotosSaved,
                resetCasePhotoLoaded,
                showNotification,
                notLoadedPhotos,
                notSavedPhotos,
            } = this.props;
            const { uploaderRef } = this.state;
            if (caseMessagesLoaded) {
                this.toggleWaitingMessage(false);
            }
            if (casePhotosLoaded) {
                this.toggleProgressBar(false);
                notLoadedPhotos && notLoadedPhotos.forEach(notLoadedPhoto => {
                    showNotification({
                        type: 'error',
                        title: `Loading error`,
                        message: `Failed to load ${notLoadedPhoto} photo`
                    });
                });
                resetCasePhotoLoaded();
            }

            if (savingCaseMessageProcessFinished && savingCaseMessagePhotoProcessFinished) {
                this.disableControls(false);
                notSavedPhotos && notSavedPhotos.forEach(notSavedPhoto => {
                    showNotification({
                        type: 'error',
                        title: `Saving error`,
                        message: `Failed to save ${notSavedPhoto} photo`
                    });
                });
                if (uploaderRef) {
                    uploaderRef.reset();
                }
                this.setState({secureNote: '', submitDisabled: true});
                resetCaseMessagesSaved();
                resetCaseMessagePhotosSaved();
            }
        }
    }
    toggleWaitingMessage = (showLoadingMessage) => {
        this.setState({showLoadingMessage});
    };
    toggleSavingMessage = (showSavingMessage) => {
        this.setState({showSavingMessage});
    };
    toggleProgressBar = (showPhotoProgressBar) => {
        this.setState({showPhotoProgressBar});
    };
    handleClose = () => {
        const { showSavingMessage, showLoadingMessage, showPhotoProgressBar } = this.state;

        if (!showSavingMessage && !showLoadingMessage && !showPhotoProgressBar) {
            this.setState({...INITIAL_STATE});
            this.props.handleClose();
        }
    };
    secureNoteChangeHandler = secureNote => {
        this.setState({
            secureNote,
            submitDisabled: !secureNote
        });
    };
    toByteArray(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function (event) {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });
    }
    handleSubmit = async () => {
        const { isPerUseLicense, canCreateCase } = this.props;

        if (!isPerUseLicense) {
            if (!canCreateCase) {
                this.setState({showAlertDialog: true});
                return;
            }
        }

        const { saveCaseMessage, saveCaseAttachment, caseId } = this.props;
        const { uploaderRef } = this.state;
        const { message, roleList } = this.getMessage();
        const files = uploaderRef.getFiles();
        const binary = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const byteArray = await this.toByteArray(file);
            binary.push({
                data: byteArray,
                name: file.name,
            });
        }
        if (message && message.length) {
            this.disableControls(true);
            saveCaseMessage(caseId, message, roleList);
            saveCaseAttachment(caseId, binary);
        }
    };
    getMessage = () => {
        const { secureNote, visibleByDentist, visibleByLab, visibleBySpecialist } = this.state;
        const roleList = [];
        let roleId = -1;

        if(visibleByDentist) {
            roleId = this.findRole("dentist");

            if (roleId !== -1) {
                roleList.push(1);
            }
            roleId = this.findRole("dentist-mobile");
            if (roleId !== -1) {
                roleList.push(6);
            }
        }
        if(visibleByLab) {
            roleId = this.findRole("lab");
            if (roleId !== -1) {
                roleList.push(2);
            }
            roleId = this.findRole("lab-mobile");
            if (roleId !== -1) {
                roleList.push(7);
            }
            roleId = this.findRole("lab-mobile-case-free");
            if (roleId !== -1) {
                roleList.push(9);
            }
        }
        if(visibleBySpecialist) {
            roleId = this.findRole("specialist");
            if (roleId !== -1) {
                roleList.push(5);
            }
        }
        return {message: secureNote, roleList};
    };
    findRole = name => {
        const role = this.props.roles.find(role => role.name === name);

        if (role) {
            return role.id;
        }
        return -1;
    };
    onImageLoadError(e) {
        e.target.src = require('../assets/images/not-loaded.png');
    }
    renderPhotos = () => {
        const photos = this.props.casePhotos.map((photo, i) => (
            <div className="photo-container" key={i}>
                <div className="photo">
                    <img src={photo.uri} alt={photo.filename} onError={this.onImageLoadError} />
                </div>
                <div className="photo-name" title={photo.filename}>
                    <label>{photo.filename}</label>
                </div>
            </div>
        ));
        return (
            <div className="photos-container">
                {photos}
            </div>
        );
    };
    renderMessages = () => {
        const messages = this.props.caseMessages.map((caseMessage, i) => (
            <div className="message-container" key={i}>
                <div className="message-info">
                    <label>Date: {caseMessage.created_at}.</label>
                    <label>Posted By: {caseMessage.posted_by}:</label>
                </div>
                <div className="message">
                    <label>{caseMessage.message_body}</label>
                </div>
            </div>
        ));
        return (
            <div className="messages-container">
                {messages}
            </div>
        );
    };
    getAlertContent = () => {
        return (
            <div>
                <label>Your account license has expired.</label><br/><br/>
                <label>Please visit the <a onClick={this.closeAlertDialog} href="https://store.shadewave.com/" target="_blank" rel={'noopener noreferrer'}>ShadeWave Store</a> to renew your license by purchasing the license that matches your role.</label>
            </div>
        );
    };
    closeAlertDialog = () => {
        this.setState({showAlertDialog: false});
    };
    disableControls = disable => {
        this.toggleSavingMessage(disable);
        this.setState({
            secureNoteTextareaDisabled: disable,
            submitDisabled: disable,
            dentistCheckBoxDisabled: disable,
            labCheckBoxDisabled: disable,
            specialistCheckBoxDisabled: disable,
        });
    };
    render() {
        const {
            secureNote,
            visibleByDentist,
            visibleByLab,
            visibleBySpecialist,
            submitDisabled,
            showLoadingMessage,
            showSavingMessage,
            showPhotoProgressBar,
            uploaderRef,
            showAlertDialog,
            secureNoteTextareaDisabled,
            dentistCheckBoxDisabled,
            labCheckBoxDisabled,
            specialistCheckBoxDisabled,
        } = this.state;
        const {
            isOpen,
            caseMessages,
            casePhotos,
            caseMessagePhotoCount,
            loadedCaseMessagePhotoCount,
            savedPhotoCount,
            photosToSaveCount,
            notSavedPhotos
        } = this.props;

        const hasMessages = caseMessages && caseMessages.length > 0;
        const hasPhotos = casePhotos && casePhotos.length > 0;

        return (
            <MatDialog
                isOpen={isOpen ?? false}
                onClose={this.handleClose}
                classes={{
                    container: 'overlay',
                    paper: `modal secure-notes-popup ${hasMessages ? 'has-messages' : ''} ${hasPhotos ? 'has-photos' : ''}`,
                }}>
                {this.renderPhotos()}
                <div className="secure-note">
                    <MatDialogHeader onClose={this.handleClose} title={'Compose new messages'}>
                    </MatDialogHeader>

                    <MatDialogContent>
                        <div className="body">
                            <div className={'label-container'}>
                                <label className={'dicom-label'}>
                                    To view DICOM files, you can download a FREE software from here
                                    <a href={'http://www.santesoft.com/win/sante_dicom_viewer_3d_free/sante_dicom_viewer_3d_free.html'} target={'_blank'} rel={'noopener noreferrer'}>SanteSoft DICOM 3D Free</a>
                                </label>
                                <label className={'stl-label'}>
                                    To view STL files, you can download a FREE software from here
                                    <a href={'http://app.shadewave.com/stlviewer.msi'} target={'_blank'} rel={'noopener noreferrer'}>ADA 3D STL viewer Free</a>
                                </label>
                            </div>
                            <div className="textarea-container">
                                <Textarea disabled={secureNoteTextareaDisabled} placeholder={'Write a Secure Notes Here...'} onChange={this.secureNoteChangeHandler} value={secureNote} name={'secureNote'}/>
                            </div>
                            <div className="checkboxes-container">
                                <Checkbox
                                    className={'visible-by-dentist-checkbox'}
                                    text={'Visible by Dentist'}
                                    disable={dentistCheckBoxDisabled}
                                    onChange={() => this.setState({visibleByDentist: !visibleByDentist})}
                                    isChecked={visibleByDentist} />
                                <Checkbox
                                    text={'Visible by Lab'}
                                    disable={labCheckBoxDisabled}
                                    onChange={() => this.setState({visibleByLab: !visibleByLab})}
                                    isChecked={visibleByLab} />
                                <Checkbox
                                    text={'Visible by Specialist'}
                                    disable={specialistCheckBoxDisabled}
                                    onChange={() => this.setState({visibleBySpecialist: !visibleBySpecialist})}
                                    isChecked={visibleBySpecialist} />
                            </div>
                            <div className="footer">
                                <div className="buttons-container">
                                    <Uploader ref={(e) => {
                                        if (!e || uploaderRef) {
                                            return false;
                                        }

                                        this.setState({uploaderRef: e});
                                    }} text={'Attach files to current case/ topic'} acceptedFormats={'image/jpeg, image/jpg, image/png'} />
                                    <GreenButton disabled={submitDisabled} className="submit-button" text="Submit" onClick={this.handleSubmit} />
                                </div>
                                {this.renderMessages()}
                            </div>
                        </div>
                    </MatDialogContent>
                </div>
                {(showLoadingMessage || showSavingMessage || showPhotoProgressBar) && (
                    <div className="progress-bars-container">
                        {showLoadingMessage && <div className="loading-message"><label>Loading Secure Notes, please wait!</label></div>}
                        {showSavingMessage && <div className="saving-message">
                            <div>
                                <label>Saving Secure Note, please wait!</label>
                                {!!photosToSaveCount && <div className="saving-photos-message">
                                    <label>Saved {savedPhotoCount + notSavedPhotos.length} photo{savedPhotoCount > 1 ? 's' : ''} from {photosToSaveCount}</label>

                                    <div className="progress-bar-container">
                                        <div className="progress-bar" style={{width: `${((savedPhotoCount + notSavedPhotos.length) / photosToSaveCount) * 100}%`}} />
                                    </div>
                                </div>}
                            </div>
                        </div>}
                        {showPhotoProgressBar && <div className="loaded-photo-progress-bar">
                            <div>
                                <label>Loaded {loadedCaseMessagePhotoCount} photos from {caseMessagePhotoCount}</label>
                                <div className="progress-bar-container">
                                    <div className="progress-bar" style={{width: `${(loadedCaseMessagePhotoCount / caseMessagePhotoCount) * 100}%`}} />
                                </div>
                            </div>
                        </div>}
                    </div>
                )}
                <AlertDialog
                    className="secure-note-alert-dialog"
                    message={this.getAlertContent()}
                    isOpen={showAlertDialog}
                    hideOkButton={true}
                    closeOnOutsideClick={true}
                    handleClose={this.closeAlertDialog}
                    ok={this.closeAlertDialog} />
            </MatDialog>
        )
    }

}

SecureNotes.propTypes = {
    handleClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    caseMessages: PropTypes.array.isRequired,
    casePhotos: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    caseId: PropTypes.string.isRequired,
    saveCaseMessage: PropTypes.func.isRequired,
    saveCaseAttachment: PropTypes.func.isRequired,
    caseMessagePhotoCount: PropTypes.number.isRequired,
    loadedCaseMessagePhotoCount: PropTypes.number.isRequired,
    savingCaseMessageProcessFinished: PropTypes.bool.isRequired,
    savingCaseMessagePhotoProcessFinished: PropTypes.bool.isRequired,
    casePhotosLoaded: PropTypes.bool.isRequired,
    caseMessagesLoaded: PropTypes.bool.isRequired,
    canCreateCase: PropTypes.bool.isRequired,
    isPerUseLicense: PropTypes.bool.isRequired,
    resetCaseMessagesSaved: PropTypes.func.isRequired,
    resetCaseMessagePhotosSaved: PropTypes.func.isRequired,
    resetCasePhotoLoaded: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    photosToSaveCount: PropTypes.number.isRequired,
    savedPhotoCount: PropTypes.number.isRequired,
    notLoadedPhotos: PropTypes.array.isRequired,
    notSavedPhotos: PropTypes.array.isRequired,
};
import React from "react";
import PropTypes from "prop-types";
import GreenFileUploadButton from '../../elements/form/buttons/GreenFileUploadButton';
import MenuButton from './elements/MenuButton';
import ConfirmDialog from '../../elements/ConfirmDialog';
import AlertDialog from '../../elements/AlertDialog';
import { photosShape } from '../../../helpers/case';
import { Menu, MenuItem } from "@material-ui/core";
import jsPDF from 'jspdf';
import GreenButton from "../../elements/form/buttons/GreenButton";

export default class Photos extends React.PureComponent {
    state = {
        deletePhotoId: null,
        selectedPhotos: [],
        isShowContextMenu : false, 
        contextMenuX : -1, 
        contextMenuY : -1,
        currentQCPhotoId : null,
        savePhotoId: null,
        newActivePhotoId: null,
    };

    deletePhotoHandler = photoId => {
        this.setState({
            deletePhotoId: photoId,
        });
    };

    switchPhoto = (photoId) => {
        let { setActivePhoto, rotateDegrees, activePhotoId } = this.props;
        
        if (rotateDegrees && rotateDegrees !== 0) {
            this.setState(
                {
                    savePhotoId: activePhotoId,
                    newActivePhotoId: photoId
                }
            )
        }
        else {
            setActivePhoto(photoId);
        }
    }

    saveRotation = (photoId) => {
        let { saveRotatedPhoto, rotateDegrees } = this.props;

        saveRotatedPhoto(photoId, rotateDegrees);
        this.closeSaveRotation();
    }

    closeSaveRotation = () => {
        let { setActivePhoto } = this.props;
        let { newActivePhotoId } = this.state;

        setActivePhoto(newActivePhotoId);
        this.setState({
            newActivePhotoId: null,
            savePhotoId: null,
        });
    }

    renderButtons = () => {
        let { activePhotoId, photos, isQcMode } = this.props;

        if (!photos) {
            return false;
        }

        if (isQcMode) {
            photos = photos.filter((item) => item.metadata?.manualCorrected );
        }

        return photos.map((photo, index) => {
            let onClick = () => {
                if (isQcMode) {
                    
                }
                else {
                    this.switchPhoto(photo.id);
                }
                
            };
            let onSelect = (photoId) => {
                if (this.state.selectedPhotos.includes(photoId))
                {
                    this.setState({ selectedPhotos: this.state.selectedPhotos.filter(item => item !== photoId)});
                }
                else {
                    this.setState({ selectedPhotos: [...this.state.selectedPhotos, photoId] });
                }
            }
            let onMouseDown = (e) => {
                if (isQcMode) {
                    e.preventDefault();
        
                    this.setState({
                        isShowContextMenu: true,
                        contextMenuX: e.clientX,
                        contextMenuY: e.clientY,
                        currentQCPhotoId: photo.id,
                    });
                }
            }
            //let photoUrl = photo.imgOriginalSrc;//photo.url.includes(process.env.REACT_APP_API_URL) ? photo.url : process.env.REACT_APP_API_URL + photo.url;
            return <MenuButton onDelete={() => { this.deletePhotoHandler(photo.id)}} 
                    photoId={photo.id} 
                    key={index} 
                    onClick={onClick} 
                    onSelect={onSelect}
                    onMouseDown={onMouseDown}
                    text={photo.filenameOrig} 
                    fileSize={photo.imgSize ? (photo.imgSize.toFixed(2) + ' Mb'): ''}
                    image={photo.imgOriginalSrc} 
                    isChecked={this.state.selectedPhotos.includes(photo.id)}
                    isCorrected={photo.metadata && photo.metadata.manualCorrected}
                    isActive={activePhotoId === photo.id}
                    isQcMode={isQcMode}/>
        });
    };
    uploadHandler = (files) => {
        let { uploadPhoto, currentCase, togglePhotoUploadProgress, notifyLabAboutPhotos } = this.props;

        if (files.length === 0) {
            return false;
        }

        togglePhotoUploadProgress(true);

        for(let i = 0; i < files.length; i++) {
            let file = files[i];
            let reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = function (evt) {
                let fileContent = evt.target.result;

                uploadPhoto(file, fileContent, currentCase.caseId);
            }
        }

        notifyLabAboutPhotos(currentCase.caseId);
    };
    deleteDo = (photoId) => {
        let { deletePhoto } = this.props;

        deletePhoto(photoId);

        this.onDeleteClose();
    };
    onDeleteClose = () => {
        this.setState({
            deletePhotoId: null,
        })
    };
    renderUploadPhotoError = () => {
        let { uploadPhotoError, hideUploadPhotoError } = this.props;

        if (uploadPhotoError === '') {
            return false;
        }

        return <AlertDialog ok={hideUploadPhotoError} message={uploadPhotoError} isOpen={true}/>
    };

    handleContextClose = () => {
        this.setState({
            isShowContextMenu: false,
        });
    }

    handleExportZip = () => {
        let { downloadPhotos, currentCase } = this.props;
        let { selectedPhotos } = this.state;

        this.setState({
            isShowContextMenu: false,
        });
        downloadPhotos(currentCase.caseId, selectedPhotos);

    }

    handleClearSelection = () => {
        this.setState({
            isShowContextMenu: false,
            selectedPhotos: [],
        });
    }

    handleExportPDF = () => {
        let { photos, currentCase } = this.props;
        let { selectedPhotos } = this.state;
        this.setState({
            isShowContextMenu: false,
        });

        const doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'letter'
        });

        let pageWidth = doc.internal.pageSize.getWidth() - 20;
        let pageHeight = doc.internal.pageSize.getHeight() - 20;
        let pageAdded = false;

        photos.forEach((photo) => {
            if (selectedPhotos.includes(photo.id)) {
                if (pageAdded) doc.addPage('letter', 'l');
                let image = photo.imgOriginal;
                let xRatio = pageWidth / image.width;
                let yRatio = pageHeight / image.height;
                let ratio = Math.min(xRatio, yRatio);
                let w = image.width * ratio;
                let h = image.height * ratio;
                doc.addImage(image, 'JPEG', (pageWidth - w)/2 + 10, (pageHeight - h)/2 + 10, w, h, '', 'MEDIUM', 0);
                pageAdded = true;
            }
        });

        //doc.autoPrint();
        //window.open(doc.output('datauristring'));
        doc.save("Case "+ currentCase.caseId + " Originals.pdf");

    }

    handleSetLeftQcImage = () => {
        let { setQcLeftImage, setActivePhoto } = this.props;
        let { currentQCPhotoId } = this.state;
        this.handleContextClose();
        setActivePhoto(currentQCPhotoId);
        setQcLeftImage(currentQCPhotoId);
    }

    handleSetRightQcImage = () => {
        let { setQcRightImage, setActivePhoto } = this.props;
        let { currentQCPhotoId } = this.state;
        this.handleContextClose();
        setActivePhoto(currentQCPhotoId);
        setQcRightImage(currentQCPhotoId);
    }

    renderContextMenu = () => {
        let { isShowContextMenu, contextMenuX, contextMenuY, selectedPhotos } = this.state;
        let { isQcMode } = this.props;
        

        return (
        <Menu
            keepMounted
            open={isShowContextMenu}
            onClose={this.handleContextClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenuY !== null && contextMenuX !== null
                ? { top: contextMenuY, left: contextMenuX }
                : undefined
            }
            classes={{ paper: 'context-menu', list: 'context-menu' }}
            onContextMenu={(e) => e.preventDefault()}
        >
            { isQcMode && <MenuItem onClick={this.handleSetLeftQcImage}>Set as Left Image</MenuItem> }
            { isQcMode && <MenuItem onClick={this.handleSetRightQcImage}>Set as Right Image</MenuItem> }
            { !isQcMode && selectedPhotos.length === 0 &&   <MenuItem divider={true} disabled={true}>Select images by checkboxes to see actions</MenuItem>}
            { !isQcMode && selectedPhotos.length > 0 &&   <MenuItem divider={true} disabled={true}>Actions with Checked:</MenuItem>}
            { !isQcMode && selectedPhotos.length > 0 &&    <MenuItem onClick={this.handleExportZip}>Export to Zip</MenuItem> }
            { !isQcMode && selectedPhotos.length > 0 &&    <MenuItem onClick={this.handleExportPDF}>Export to PDF</MenuItem> }
            { !isQcMode && selectedPhotos.length > 0 &&    <MenuItem onClick={this.handleClearSelection}>Clear selection</MenuItem> }

        </Menu>
      );
    };

    onContextMenu = (e) => {
        e.preventDefault();
       
        this.setState({
            isShowContextMenu: true,
            contextMenuX: e.clientX,
            contextMenuY: e.clientY,
        });
    };

    qcModeHandler = () => {
        let { isQcMode, setQcMode } = this.props;
        setQcMode(!isQcMode);
    }

    render() {
        let { isPhotoUploadProgress, isQcMode, isQcModeDisabled } = this.props;
        let { deletePhotoId, savePhotoId } = this.state;

        return (
            <div className={'photos'}>
                {this.renderUploadPhotoError()}
                <div className={'button-container'}>
                    <GreenButton onClick={this.qcModeHandler} text={isQcMode ? 'Close QC' : 'QC' } disabled={isQcModeDisabled}/>
                </div>
                <div className={'button-container'}>
                    <GreenFileUploadButton onChange={this.uploadHandler} text={'Upload'} isProgress={isPhotoUploadProgress} disabled={isQcMode}/>
                </div>
                <div className={'buttons'} onContextMenu={this.onContextMenu}>
                    {this.renderButtons()}
                </div>
                {this.renderContextMenu()}
                <ConfirmDialog
                    isOpen={deletePhotoId !== null}
                    title={'Delete Photo'}
                    message={'Do you want to delete this photo?'}
                    no={this.onDeleteClose}
                    yes={() => {this.deleteDo(deletePhotoId)}} />
                <ConfirmDialog
                    isOpen={savePhotoId !== null}
                    title={'Edited Photo'}
                    message={'Current image is rotated. Would you like to save the rotation? It will overwrite original image.'}
                    no={this.closeSaveRotation}
                    yes={() => {this.saveRotation(savePhotoId)}} />
            </div>
        )
    }
}

Photos.propTypes = {
    hideUploadPhotoError: PropTypes.func.isRequired,
    uploadPhotoError: PropTypes.string.isRequired,
    activePhotoId: PropTypes.string.isRequired,
    isPhotoUploadProgress: PropTypes.bool.isRequired,
    setActivePhoto: PropTypes.func.isRequired,
    deletePhoto: PropTypes.func.isRequired,
    togglePhotoUploadProgress: PropTypes.func.isRequired,
    photos: photosShape,
    currentCase: PropTypes.object.isRequired,
    downloadPhotos: PropTypes.func,
    isQcMode: PropTypes.bool.isRequired,
    setQcLeftImage: PropTypes.func,
    setQcRightImage: PropTypes.func,
    isQcModeDisabled: PropTypes.bool,
    setQcMode: PropTypes.func,
    rotateDegrees: PropTypes.number,
    notifyLabAboutPhotos: PropTypes.func.isRequired,
};

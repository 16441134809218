import { connect } from 'react-redux';
import { showNotification } from "../../actions/ui.actions";
import { checkTrialLink, createTrialProfile } from "../../actions/user.actions";
import CreateTrialProfile from "../../components/profile/CreateTrialProfile";


const mapStateToProps = state => ({
    isValid: state.user.isTrialLinkValid,
    trialProfileCreationResult: state.user.trialProfileCreationResult
});

const mapDispatchToProps = dispatch => ({
    checkTrialLink: (key) => dispatch(checkTrialLink(key)),
    showNotification: notificationData => dispatch(showNotification(notificationData)),
    createTrialProfile: (trialKey, email, role_id) => dispatch(createTrialProfile({trialKey, email, role_id}))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTrialProfile);
import AscendActionTypes from "../actions/ascend.types"

const initialState = {
    ascendkey: localStorage.ascendkey ? localStorage.ascendkey : '',
    session: null,
    cases: [],
    currentCase: localStorage.currentAscendCase ? localStorage.currentAscendCase : null,
    error: false,
    message: '',
    imagesSelected: false
}

function ascendReducer (state = initialState, { type, payload }) {
    switch (type) {

    case AscendActionTypes.OPEN_ASCEND_SESSION:
        localStorage.ascendkey = payload.ascendkey;
        return { ...state, ascendkey: payload.ascendkey, session : payload.session?.data?.session }
    case AscendActionTypes.ASCEND_CASES_LOADED:
        return {...state, cases: payload?.data?.cases }
    case AscendActionTypes.SELECT_ASCEND_CASE:
        localStorage.currentAscendCase = payload.caseId;
        return {...state, currentCase: payload.caseId }
    case AscendActionTypes.IMAGES_SELECTED_FOR_ACQUIRE:
        localStorage.ascendkey = '';
        localStorage.currentAscendCase = null;
        return {...state, ascendkey: '', session: null, cases: [], currentCase: null, imagesSelected: payload.length > 0}
    default:
        return state
    }
}

export default ascendReducer;
import React from "react";
import PropTypes from 'prop-types';
import BaseInput from '../BaseInput';
//import MailIcon from '../../../../assets/images/icons/mail.svg';

export default class InputEmail extends React.PureComponent {
    render() {
        let { placeholder, value, name, onChange, errors, isRequired, isDisabled, isMultiple } = this.props;
        
        return (
            <BaseInput
                isRequired={isRequired}
                errors={errors}
                type={'email'}
                value={value}
                name={name}
                placeholder={placeholder}
                isDisabled={isDisabled}
                //icon={MailIcon}
                onChange={onChange} 
                attributes={ {autoComplete: 'email', disabled: isDisabled, multiple: isMultiple} } 
                />
        );
    }
}

InputEmail.propTypes = {
    isRequired: PropTypes.bool,
    errors: PropTypes.array,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
};

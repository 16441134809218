import { createSelector } from 'reselect'
import { isStateExists, replaceStateValues, formatStateButtons, formatShadeTabs } from '../helpers/state';
import { initialEditValues, initialMapsValues, initialPreferencesValues, initialState as initialWorkAreaState } from '../reducers/workArea.reducer';
import { IMAGE_CORRECTED, IMAGE_DOUBLE_VIEW, IMAGE_MODIFIER, IMAGE_ORIGINAL, IMAGE_SHADE_MAP, IMAGE_TRIPLE_VIEW } from '../constants/imageTypes';
import { ROLE_LAB, ROLE_LAB_MOBILE_CASE_FREE } from '../constants/user';
import {getCurrentPhoto, getLeftShadeValues, getRightShadeValues} from './case.selector';


const getWorkArea = state => state.workArea;
const getCase = state => state.case;
const getCurrentCase = state => state.case.currentCase;
const getUserProfile = state => state.user.userProfile;


export const getSubMenu = createSelector(
    [getWorkArea],
    (workArea) => {
        if (!isStateExists(workArea)) {
            return '';
        }

        return workArea.subMenu;
    }
);



export const getShadeGuides = createSelector(
    [getWorkArea, getCase],
    (workArea, caseState) => {
        if (!isStateExists(workArea)) {
            return {};
        }

        let shadeTabFilter = caseState.defaultSettings.other ? JSON.parse(caseState.defaultSettings.other)?.shadeTabFilter : false;
        shadeTabFilter = shadeTabFilter ? ('' + shadeTabFilter).split(',') : [];

        return {
            options: caseState.availableShadeGuides
            .filter(shadeGuide => shadeTabFilter.length > 0 ? (shadeTabFilter.includes(shadeGuide.id) || shadeTabFilter.includes(''+shadeGuide.id) || (workArea.currentShadeGuideId === shadeGuide.id) ) : true)
            .map(guide => {
                return {
                    name: guide.name,
                    value: guide.id
                }
            }),
            // TODO get real selected shade guide
            selected: workArea.currentShadeGuideId,
        };/*
        return workArea.shadeGuides.map(guide => {
            return {...guide, isCurrent: workArea.currentShadeGuideId === guide.id}
        });*/
    }
);

export const isShadeGuideExpanded = createSelector(
    [getWorkArea],
    (workArea) => {
        if (!isStateExists(workArea)) {
            return '';
        }

        return workArea.isShadeGuideExpanded;
    }
);

export const getEditValues = createSelector(
    [getWorkArea],
    (workArea) => {
        if (!isStateExists(workArea)) {
            return initialEditValues;
        }

        return replaceStateValues(initialEditValues, workArea);
    }
);

export const getCorrectedImageButtons = createSelector(
    [getWorkArea, getCurrentPhoto],
    (workArea, currentPhoto) => {
        if (!isStateExists(workArea)) {
            return '';
        }

        return workArea.correctedImageButtons.map(button => {
            let isDisabled = true;
            if (currentPhoto && currentPhoto.imgCorrected)
                isDisabled = false;
            return {...button, isCurrent: workArea.correctedImageType === button.name, isDisabled: isDisabled}
        });
    }
);

export const getColorCorrectButtons = createSelector(
    [getWorkArea, getCurrentPhoto],
    (workArea, currentPhoto) => {
        if (!isStateExists(workArea)) {
            return '';
        }

        return workArea.colorCorrectButtons.map(button => {
            let isDisabled = true;
            if (button.name === IMAGE_ORIGINAL && currentPhoto && currentPhoto.imgOriginal)
                isDisabled = false;
            if (button.name === IMAGE_CORRECTED && currentPhoto && currentPhoto.imgCorrected)
                isDisabled = false;
            return {...button, isCurrent: workArea.activeImageType === button.name, isDisabled: isDisabled}
        });
    }
);

export const getRegionButtons = createSelector(
    [getWorkArea, getCurrentPhoto],
    (workArea, currentPhoto) => {
        if (!isStateExists(workArea)) {
            return '';
        }

        return workArea.regionButtons.map(button => {
            return {...button, isCurrent: workArea.activeRegion === button.name, isDisabled : !(currentPhoto && currentPhoto.imgCorrected && currentPhoto.currentPhotoType === IMAGE_CORRECTED)}
        });
    }
);

export const getIsPhotoCorrectStartAllowed = createSelector(
    [getCurrentPhoto, getWorkArea, getUserProfile, getCurrentCase],
    (currentPhoto, workArea, userProfile, currentCase) => {
        return currentPhoto && currentPhoto.currentPhotoType === IMAGE_ORIGINAL && (workArea.currentPhotoProcessingStep === 0) 
        && (userProfile.canProcessCase > 0) && (userProfile.role_id !== 2 || ( ((userProfile.role_id === ROLE_LAB_MOBILE_CASE_FREE) ? (userProfile.credit >= 0) : (userProfile.credit > 0)) || parseInt(currentCase.is_charged) > 0 ) );
    }
);

export const getIsRestartAllowed = createSelector(
    [getCurrentPhoto, getWorkArea, getCase],
    (currentPhoto, workArea, caseState) => {
        return (!caseState.isQcMode) && currentPhoto && ((currentPhoto.imgCorrected !== null) || (workArea.currentPhotoProcessingStep > 0));
    }
);

export const getIsColorCorrectionAllowed = createSelector(
    [getWorkArea, getCurrentPhoto, getCase],
    (workArea, currentPhoto, caseState) => {
        return (!caseState.isQcMode) && (currentPhoto) && (currentPhoto.currentPhotoType === IMAGE_ORIGINAL) &&
         ((workArea.currentShadeReferenceAnalysis === 'off') ? (workArea.currentPhotoProcessingStep > 0)  : (workArea.currentPhotoProcessingStep > 3));
    }
);

export const getDisabledButtons = createSelector(
    [getCurrentPhoto, getUserProfile, getCase],
    (currentPhoto, userProfile, caseState) => {
        if (!isStateExists(caseState) || caseState.isQcMode) {
            return ['maps', 'process', 'edit', 'preferences', 'details', 'cases'];
        }
        if (!isStateExists(currentPhoto)) {
            return ['maps', 'process', 'edit'];
        }
        if (!userProfile.canProcessCase || (userProfile.role_id === ROLE_LAB && userProfile.credit < 1) || (userProfile.role_id === ROLE_LAB_MOBILE_CASE_FREE && userProfile.credit < 0))
            return ['maps', 'process'];
        
        return currentPhoto.imgCorrected === null ? ['maps'] : [];
    }
);

export const getIsOriginalOnly = createSelector(
    [getCurrentPhoto],
    (currentPhoto) => {
        return currentPhoto && currentPhoto.imgOriginal && !currentPhoto.imgCorrected && !currentPhoto.imgShadeMap && currentPhoto.currentPhotoType === IMAGE_ORIGINAL;
    }
)

export const getIsShadeMapAvailable = createSelector(
    [getCurrentPhoto],
    (currentPhoto) => {
        return currentPhoto && currentPhoto.imgShadeMap;
    }
)

export const getIsModifierAvailable = createSelector(
    [getCurrentPhoto],
    (currentPhoto) => {
        return currentPhoto && currentPhoto.imgModifier;
    }
)

export const getIsOriginalSelected = createSelector(
    [getCurrentPhoto],
    (currentPhoto) => {
        return currentPhoto && currentPhoto.imgOriginal && currentPhoto.currentPhotoType === IMAGE_ORIGINAL;
    }
)

export const getUndoAvailable = createSelector(
    [getCurrentPhoto],
    (currentPhoto) => {
        return (currentPhoto && currentPhoto.last_undo_operation) ? currentPhoto.last_undo_operation : '';
    }
)

export const getRedoAvailable = createSelector(
    [getCurrentPhoto],
    (currentPhoto) => {
        return (currentPhoto && currentPhoto.last_redo_operation) ? currentPhoto.last_redo_operation : '';
    }
)

export const getIsRegionActive = createSelector(
    [getWorkArea],
    (workArea) => {
        return workArea.activeRegion === 'on';
    }
)

export const getIsChangeOrientationDisabled = createSelector(
    [getWorkArea, getCurrentPhoto],
    (workArea, currentPhoto) => {
        return !isStateExists(currentPhoto) || currentPhoto.currentPhotoType !== IMAGE_ORIGINAL || currentPhoto.corrected; //workArea.activeRegion === 'on' || workArea.isCrop;
    }
)

export const getSystemShadeColors = createSelector(
    [getCase],
    (caseState) => {
        let sysColors = [];
        if (caseState && caseState.shadeGuide && caseState.currentCase) {

            sysColors.push( { 'name' : 'WHITE', 'rgb': caseState.shadeGuide.plateColors.plateNormWhite,
                            'rgb_studio' : ((parseInt(caseState.shadeGuide.lifelinePlate.white) >>> 0) - 4278190080).toString(16)} );
            sysColors.push( { 'name' : 'GRAY', 'rgb': caseState.shadeGuide.plateColors.plateNormGrey,
                            'rgb_studio' : ((parseInt(caseState.shadeGuide.lifelinePlate.gray) >>> 0) - 4278190080).toString(16)} );
            sysColors.push( { 'name' : 'BLACK', 'rgb': caseState.shadeGuide.plateColors.plateNormBlack,
                            'rgb_studio' : ((parseInt(caseState.shadeGuide.lifelinePlate.black) >>> 0) - 4278190080).toString(16) } );
            sysColors.push( { 'name' : 'SHADE', 'rgb': caseState.shadeGuide.plateColors.plateNormShade,
                            'rgb_studio' : ((parseInt(caseState.shadeGuide.lifelinePlate.shade) >>> 0) - 4278190080).toString(16)} );

            let metadata = JSON.parse(caseState.currentCase.metadata);
            if (metadata) {
                if (metadata.leftShadeTab && metadata.leftShadeTab.value)
                    sysColors.push( { 'name' : 'L (' + metadata.leftShadeTab.name + ')', 'rgb': metadata.leftShadeTab.value, 'rgb_studio' : metadata.leftShadeTab.studioValue} );
                if (metadata.rightShadeTab && metadata.rightShadeTab.value)
                    sysColors.push( { 'name' : 'R (' + metadata.rightShadeTab.name + ')', 'rgb': metadata.rightShadeTab.value, 'rgb_studio' : metadata.rightShadeTab.studioValue} );
            }
        }
        return sysColors;
    }
)

export const getMapsValues = createSelector(
    [getWorkArea, getCase, getCurrentPhoto, getUserProfile],
    (workArea, caseState, currentPhoto, userProfile) => {
        if (!isStateExists(workArea)) {
            return initialMapsValues;
        }

        let values = replaceStateValues(initialMapsValues, workArea);

        values.backgroundButtons = formatStateButtons(workArea.backgroundButtons, workArea.currentBackground);
        values.mapTypesButtons = formatStateButtons(workArea.mapTypesButtons, workArea.currentMapType);
        values.teethSegmentButtons = formatStateButtons(workArea.teethSegmentButtons, workArea.isTeethSegment);
        values.isTeethSegmentName = workArea.isTeethSegment;
        values.teethSegmentSensitivity = workArea.teethSegmentSensitivity;
        values.shadeGuide = caseState.shadeGuide;
        values.currentPhotoType = currentPhoto.currentPhotoType;
        values.sensitivityLimits = {
            shadeMap: {
                minSens: userProfile.minSensitivity,
                maxSens: userProfile.maxSensitivity,
            },
            modifier: {
                minSens: userProfile.minTransSensitivity,
                maxSens: userProfile.maxTransSensitivity,
            }
        };
        values.shadeLimits =  caseState.defaultSettings?.limits ? parseInt(caseState.defaultSettings.limits) : 255;
        return values;
    }
);

export const getLastShadeMap = createSelector(
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return IMAGE_SHADE_MAP;
        }

        return (JSON.parse(caseState.defaultSettings.other)?.lastMap ?? IMAGE_SHADE_MAP)
    }
)

export const getShadeLimits = createSelector( 
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return 255;
        }

        return caseState.defaultSettings?.limits ? parseInt(caseState.defaultSettings.limits) : 255;
    }
);

export const getCurrentShadeGuide = createSelector (
    [getCase],
    (caseState) => {
        if (!isStateExists(caseState)) {
            return null;
        }

        return caseState.shadeGuide;
    }
)

export const getPreferencesValues = createSelector(
    [getWorkArea, getCase],
    (workArea, caseState) => {
        if (!isStateExists(workArea)) {
            return initialPreferencesValues;
        }

        let metadata = JSON.parse(caseState.currentCase.metadata);
        let selectedShadeIds = [];
        if (metadata) {
            Object.keys(metadata).forEach(key => {
                selectedShadeIds.push(metadata[key].id);
            });
        }

        let values = replaceStateValues(initialMapsValues, workArea);

        values.themeButtons = formatStateButtons(workArea.themeButtons, workArea.currentTheme);
        values.processPhotoWalkthroughButtons = formatStateButtons(workArea.processPhotoWalkthroughButtons, (JSON.parse(caseState.defaultSettings.other)?.showWalkthrough ?? 'on'));
        values.shadeReferenceAnalysisButtons = formatStateButtons(workArea.shadeReferenceAnalysisButtons, workArea.currentShadeReferenceAnalysis);
        values.shadeTabs = formatShadeTabs(caseState.availableShadeGuides, selectedShadeIds);
        values.shadeTabFilter = JSON.parse(caseState.defaultSettings.other ?? '{}')?.shadeTabFilter;
        values.shadeTabFilter = values.shadeTabFilter ? ('' + values.shadeTabFilter).split(',') : [];
        if (!values.shadeTabFilter?.filter(it => !!it)?.length) {
            values.shadeTabFilter = [];
        }
        return values;
    }
);

export const getWalkthroughInfo = createSelector(
    [getWorkArea, getCase],
    (workArea, caseState) => {
        if (!isStateExists(workArea) || !isStateExists(caseState)) {
            return {
                isWalkthrough: false,
                isExpanded: false,
            };
        }

        return {
            isWalkthrough: JSON.parse(caseState.defaultSettings.other ?? '{}')?.showWalkthrough !== 'off' && workArea.currentPhotoProcessingStep > 0,
            isExpanded: workArea.isWallkthroughExpanded,
        }
    }
);

export const getPhotoFilters = createSelector(
    [getWorkArea, getCase],
    (workArea, caseState) => {
        if (!isStateExists(caseState) || !isStateExists(workArea)) {
            return initialEditValues;
        }

        return replaceStateValues(initialEditValues, workArea);
    }
);

export const isWorkAreaProgress = createSelector(
    [getWorkArea],
    (workArea) => {
        if (!isStateExists(workArea)) {
            return initialWorkAreaState.isWorkAreaProgress;
        }

        return workArea.isWorkAreaProgress;
    }
);

export const getPhotoContainerRef = createSelector(
    [getWorkArea],
    (workArea) => {
        if (!isStateExists(workArea)) {
            return initialEditValues;
        }

        return workArea.photoContainerRef;
    }
);

export const getColorMapSensitivity = createSelector(
    [getWorkArea],
    (workArea) => {
        if (!isStateExists(workArea)) {
            return initialMapsValues.colorMapSensitivity;
        }

        return workArea.colorMapSensitivity;
    }
);
export const getModifierSensitivity = createSelector(
    [getWorkArea],
    (workArea) => {
        if (!isStateExists(workArea)) {
            return initialMapsValues.modifierSensitivity;
        }

        return workArea.modifierSensitivity;
    }
);

export const getIsShadeMapActive = createSelector (
    [getCurrentPhoto],
    (currentPhoto) => {
        if (!isStateExists(currentPhoto)) {
            return false;
        }

        return currentPhoto.currentPhotoType === IMAGE_SHADE_MAP;
    }
);

export const getIsModifierActive = createSelector (
    [getCurrentPhoto],
    (currentPhoto) => {
        if (!isStateExists(currentPhoto)) {
            return false;
        }

        return currentPhoto.currentPhotoType === IMAGE_MODIFIER;
    }
);

export const getIsAllMapsActive = createSelector (
    [getCurrentPhoto],
    (currentPhoto) => {
        if (!isStateExists(currentPhoto)) {
            return false;
        }

        return currentPhoto.currentPhotoType === IMAGE_TRIPLE_VIEW;
    }
);

export const getIsDoubleMapActive = createSelector (
    [getCurrentPhoto],
    (currentPhoto) => {
        if (!isStateExists(currentPhoto)) {
            return false;
        }

        return currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW;
    }
);

export const getIsAllMapsAllowed = createSelector (
    [getCurrentPhoto],
    (currentPhoto) => {
        if (!isStateExists(currentPhoto)) {
            return false;
        }

        return (currentPhoto.imgTriple1 !== null) && (currentPhoto.imgTriple2 !== null);
    }
);

export const getIsDoubleMapAllowed = createSelector (
    [getCurrentPhoto],
    (currentPhoto) => {
        if (!isStateExists(currentPhoto)) {
            return false;
        }

        return (currentPhoto.imgDouble !== null) ;
    }
);

export const getIsCurrentImageMap = createSelector (
    [getCurrentPhoto],
    (currentPhoto) => {
        if (!isStateExists(currentPhoto)) {
            return false;
        }

        return currentPhoto.currentPhotoType === IMAGE_MODIFIER || 
                currentPhoto.currentPhotoType === IMAGE_SHADE_MAP ||
                currentPhoto.currentPhotoType === IMAGE_TRIPLE_VIEW ||
                currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW;
    }
);



export const getPrintData = createSelector (
    [getCase, getWorkArea, getCurrentPhoto],
    (caseState, workArea, currentPhoto) => {
        let result = {
            shadeValue: 'N/A',
            master3dValue: 'N/A',
            brightnessValue: 'N/A',
            shadeTabs: 'N/A',
            doctorFirstName: 'N/A',
            doctorLastName: 'N/A',
            labName: 'N/A',
            patientName: 'N/A',
            shadeGuide: 'N/A',
        };
        if (!isStateExists(caseState) || !isStateExists(workArea))
            return result;
        if (workArea.shadeValue)
            result.shadeValue = workArea.shadeValue;
        if (workArea.master3dValue)
            result.master3dValue = workArea.master3dValue;
        if (workArea.brightnessValue)
            result.brightnessValue = workArea.brightnessValue;
        let shadeTab = null;
        let rightShadeTab = null;
        if (caseState.currentCase) {
            if (caseState.currentCase.metadata) {
                let mData = JSON.parse(caseState.currentCase.metadata);
                if (mData) {
                    result.shadeGuide = (mData.leftShadeGuide?.name ?? 'N/A'); //+ ', ' + (mData.leftShadeTab?.name ?? 'N/A');
                    shadeTab = (mData.leftShadeTab?.name ?? 'N/A');
                    rightShadeTab = (mData.rightShadeTab?.name ?? 'N/A')
                    result.shadeTabs = shadeTab + ', ' + rightShadeTab;
                }
            }
            if (currentPhoto && currentPhoto.metadata) {
                let leftShadeGuide = null;
                if (caseState.availableShadeGuides) {
                    let shadeGuide = caseState.availableShadeGuides.find(sg => sg.id === currentPhoto.metadata.shadeGuideId);
                    if (shadeGuide) {
                        leftShadeGuide = shadeGuide.name;
                    }
                }
                if (leftShadeGuide)
                    result.shadeGuide = leftShadeGuide;
                if (!result.shadeGuide)
                    result.shadeGuide = 'N/A';
                if (!!currentPhoto.metadata.shadeTabLabel)
                    result.shadeTabs = currentPhoto.metadata.shadeTabLabel;
                else if (shadeTab)
                    result.shadeTabs = shadeTab;
                else
                    result.shadeTabs = 'N/A';
                
                result.shadeTabs += ', ';

                if (!!currentPhoto.metadata.rightShadeTabLabel)
                    result.shadeTabs += currentPhoto.metadata.rightShadeTabLabel;
                else if (rightShadeTab)
                    result.shadeTabs += rightShadeTab;
                else
                    result.shadeTabs = 'N/A';
                
            }
            
            if (caseState.currentCase.doctorFirst || caseState.currentCase.dentist_first_name)
                result.doctorFirstName = !!caseState.currentCase.doctorFirst ? caseState.currentCase.doctorFirst : caseState.currentCase.dentist_first_name;
            if (caseState.currentCase.doctorLast || caseState.currentCase.dentist_last_name)
                result.doctorLastName = !!caseState.currentCase.doctorLast ? caseState.currentCase.doctorLast : caseState.currentCase.dentist_last_name;
            if (caseState.currentCase.labName)
                result.doctorLastName = caseState.currentCase.labName;
            if (caseState.currentCase.patientFirst && caseState.currentCase.patientLast)
                result.patientName = caseState.currentCase.patientFirst + ' ' + caseState.currentCase.patientLast;
        }
        return result;

    }
)

export const getQCPrintData = createSelector (
    [getCase, getLeftShadeValues, getRightShadeValues],
    (caseState, leftShades, rightShades) => {
        let result = {
            leftShadeValue: 'N/A',
            leftMaster3dValue: 'N/A',
            leftBrightnessValue: 'N/A',
            rightShadeValue: 'N/A',
            rightMaster3dValue: 'N/A',
            rightBrightnessValue: 'N/A',
            shadeTabs: 'N/A',
            doctorFirstName: 'N/A',
            doctorLastName: 'N/A',
            labName: 'N/A',
            patientName: 'N/A',
            shadeGuide: 'N/A',
            secondShadeTabs: 'N/A',
            secondShadeGuide: 'N/A',
        };
        if (!isStateExists(caseState) || !isStateExists(leftShades) || !isStateExists(rightShades))
            return result;
        result.leftBrightnessValue = leftShades.valueBarBrightness;
        result.leftMaster3dValue = leftShades.valueBarMaster;
        result.leftShadeValue = leftShades.valueBarShade;
        result.rightBrightnessValue = rightShades.valueBarBrightness;
        result.rightMaster3dValue = rightShades.valueBarMaster;
        result.rightShadeValue = rightShades.valueBarShade;
        result.shadeGuide = leftShades.shadeGuide;
        result.secondShadeGuide = rightShades.shadeGuide;
        result.shadeTabs = (!!leftShades.shadeTabLabel ? leftShades.shadeTabLabel : 'N/A') + ', ' +(!!leftShades.rightShadeTabLabel ? leftShades.rightShadeTabLabel :'N/A');
        result.secondShadeTabs = (!!rightShades.shadeTabLabel ? rightShades.shadeTabLabel : 'N/A') + ', ' +(!!rightShades.rightShadeTabLabel ? rightShades.rightShadeTabLabel: 'N/A');

        
        if (caseState.currentCase) {
            
            if (caseState.currentCase.doctorFirst || caseState.currentCase.dentist_first_name)
                result.doctorFirstName = !!caseState.currentCase.doctorFirst ? caseState.currentCase.doctorFirst : caseState.currentCase.dentist_first_name;
            if (caseState.currentCase.doctorLast || caseState.currentCase.dentist_last_name)
                result.doctorLastName = !!caseState.currentCase.doctorLast ? caseState.currentCase.doctorLast : caseState.currentCase.dentist_last_name;

            if (caseState.currentCase.labName)
                result.doctorLastName = caseState.currentCase.labName;
            if (caseState.currentCase.patientFirst && caseState.currentCase.patientLast)
                result.patientName = caseState.currentCase.patientFirst + ' ' + caseState.currentCase.patientLast;
        }

        return result;

    }
)

import React from "react";
import PropTypes from 'prop-types';
import Modal, { ModalContent, ModalHeader } from '../elements/Modal';

export default class ProfileEdit extends React.PureComponent {
    renderProfile = () => {
        let { userProfile } = this.props;

        let profile = {
            'Customer': userProfile.customerId,
            'Doctor': `${userProfile.dentistFirstName} ${userProfile.dentistLastName}`,
            'Practice': userProfile.practiceName,
            'Address': userProfile.address,
            'City': userProfile.city,
            'Country': userProfile.country,
            'State/Province': userProfile.stateProvince,
            'Postal Code': userProfile.postalCode,
            'Phone': userProfile.phone,
            'Email Address': userProfile.email,
            'Website': userProfile.webAddress,
            'Username': userProfile.username,
        };

        return Object.keys(profile).map((key, index) => {
            return (
                <div className={'profile-row'} key={index}>
                    <div className={'title'}>{key}</div>
                    <div className={'value'}>: {profile[key]}</div>
                </div>
            )
        });
    };
    render() {
        let { isProfileShow, hideProfile } = this.props;

        if (!isProfileShow) {
            return false;
        }

        return (
            <Modal className={'profile-popup'} onClose={hideProfile} overlayClassName={'profile-overlay'}>
                <ModalHeader title={'My Profile'} onClose={hideProfile}></ModalHeader>
                <ModalContent>
                    {this.renderProfile()}
                </ModalContent>
            </Modal>
        )
    }
}

ProfileEdit.propTypes = {
    userProfile: PropTypes.object.isRequired,
    isProfileShow: PropTypes.bool.isRequired,
    showProfile: PropTypes.func.isRequired,
    hideProfile: PropTypes.func.isRequired,
};

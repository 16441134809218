import { takeLatest, put, all, call, take } from 'redux-saga/effects';
import UserActionTypes from '../actions/user.types';
import {
    signInSuccess,
    signInFailure,
    getUser,
    userLoaded,
    signOutSuccess,
    signIn,
    userNotAuthenticated,
    setLab,
    setSpecialist,
    setLabs,
    setSpecialists,
    setProfileForSubscription,
    setProfileCreated,
    resetProfileData,
    magicTouchLabsListLoaded,
    usernameUniqueResult,
    setCheckTrialLinkResult,
    createTrialProfileError,
    backupUrlGenerated,
    signInExpired,
    exportDentistsUrlGenerated
} from '../actions/user.actions';
import * as loaderText from '../constants/loaderText';
import { showLoader, hideLoader } from '../actions/loader.actions';
import { showNotification } from '../actions/ui.actions';
import { getCountries } from "../actions/settings.action";
import { resetPasswordSuccessMessage, resendUsernameSuccessMessage } from '../config/ui';
import swApi from '../libraries/swApi';
import history from '../history';
import { CREATE_PROFILE, DASHBOARD } from '../constants/url';
import { dateToSystemString } from '../helpers/date';

function convertUserProfileObject(profile) {
    Object.keys(profile).forEach( (key) => {
        switch (key)
        {
            case 'id':
            case 'maxImageWidth':
            case 'maxImageHeight':
            case 'samplingSquareSizeApp':
            case 'maxSensitivity':
            case 'minSensitivity':
            case 'maxTransSensitivity':
            case 'minTransSensitivity':
            case 'role_id':
            case 'subscription_type':
            case 'shade_guide_id':
            case 'shade_tab_id':
            case 'credit':
            case 'canProcessCase':
                profile[key] = Number(profile[key]);
                break;
            case 'canCreateCase':
            case 'profile_created':
            case 'email_updates':
                profile[key] = (profile[key] == '1');
                break;
            case 'oneklik':
            case 'isSubscribed':
            case 'credit_forwarding':
            case 'enable_per_use':
            case 'force_credit':
            case 'login_as_user':
            case 'per_case_dentist':
            
                profile[key] = Boolean(Number(profile[key]));
                break;
            default:
                break;
        }
    }
    );
    return profile;
};

export function* doSignOut() {
    try {
        let params = [  ];
        yield call(swApi.makeRequest, 'signOut', params);
        yield put(signOutSuccess());
    }
    catch(error)
    {

    }
}

export function* checkUserNotAuthenticated(error) {

    if (error.name === '2' || error.message === swApi.authError500)
    {
        yield put(userNotAuthenticated());
        //yield delay(500);
        //yield history.push('/login');
    }
}

export function* doSignIn({ payload: { username, password } }) {
    try {

        let params = [ username, password, true, true ];

        yield put(showLoader(25, loaderText.SIGN_IN));
        const response = yield call(swApi.makeRequest, 'signIn', params);

        yield call(swApi.checkResponse, response);

        const userData = response.data;
        if (userData.validThrough < dateToSystemString(new Date()) ) {
            yield put(signInExpired(response.data))
            yield put(hideLoader());
            yield history.push(DASHBOARD);
            return
        }

        yield put(signInSuccess(response.data));
        if (localStorage.lastpath && localStorage.lastpath != null && localStorage.lastpath != 'null') {
            yield history.push(localStorage.lastpath)
            localStorage.lastpath = null
        }
        else {
            yield history.push(DASHBOARD);
        }
        yield put(getUser());
        yield put(getCountries());
    } catch (error) {
        yield put(showNotification({
            type: 'error',
            title: 'Failed to sign in',
            message: JSON.parse(JSON.stringify(error)).message,
        }));
        yield put(hideLoader());
        yield put(signInFailure(error));
    }
}

export function* doGetUser() {
    try {
        let params = [ ];

        yield put(showLoader(50, loaderText.GET_USER));
        const [ responseUser, responseUserProfile ] = yield all(
            [
                call(swApi.makeRequest, 'getUser', params),
                call(swApi.makeRequest, 'getUserProfile', params),
            ]
        );
        yield put(showLoader(75, loaderText.GET_USER));

        yield call(swApi.checkResponse, responseUser);
        yield call(swApi.checkResponse, responseUserProfile);
        let data = {...responseUser.data, ...responseUserProfile.data};
        data = convertUserProfileObject(data);

        yield put(userLoaded(data));

		yield put(hideLoader());
    } catch (error) {
        yield put(hideLoader());
        yield put(signInFailure(error));
        yield history.push('/login');
    }
}

export function* doResendUsernameOrPassword({ payload: { email, action } }) {
    try {
        const params = [ email ];

        let apiEndpoint = 'forgotPassword';
        let message = resetPasswordSuccessMessage;

        if (action === 'username') {
            apiEndpoint = 'forgotUser';
            message = resendUsernameSuccessMessage;
        }
        const response = yield call(swApi.makeRequest, apiEndpoint, params);

        yield call(swApi.checkResponse, response);

        yield put(showNotification({
            type: 'success',
            title: 'Success',
            message,
        }));
    } catch (error) {
        yield put(showNotification({
            type: 'error',
            title: 'Failed to send username or password',
            message: JSON.parse(JSON.stringify(error)).message,
        }));
        yield checkUserNotAuthenticated(error);
    }
}

export function* doUpdatePassword({ payload: { email, password, key } }) {
    try {

        const params = [ key, email, password ];
        //yield console.log('email:' + email + ' password=' + password + ' key=' + key);
        const response = yield call(swApi.makeRequest, 'resetPassword', params);

        yield call(swApi.checkResponse, response);

        yield put(signIn({username: email, password}));
    } catch (error) {
        yield put(showNotification({
            type: 'error',
            title: 'Failed to reset password',
            message: JSON.parse(JSON.stringify(error)).message,
        }));
        yield checkUserNotAuthenticated(error);
    }
}

export function* doUpdateLabProfile({ payload }) {
    try {
        const response = yield call(swApi.makeRequest, 'updateLabProfile', [payload]);

        yield call(swApi.checkResponse, response);
        yield put(getUser());
        yield put(showNotification({
            type: 'success',
            title: 'Success',
            message: 'Profile successfully updated',
        }));
    } catch (error) {
        yield put(showNotification({
            type: 'error',
            title: 'Failed to update profile',
            message: JSON.parse(JSON.stringify(error)).message,
        }));
    }
}

export function* doUpdateDentist({ payload: { dentistProfile, dentistName } }) {
    try {
        for (const d of dentistName) {
            const dentistNameResponse = yield call(swApi.makeRequest, 'updateDentistName', [d]);
            yield call(swApi.checkResponse, dentistNameResponse);
        };
        //console.log('dentistProfile', dentistProfile);
        const dentistProfileResponse = yield call(swApi.makeRequest, 'updateDentistProfile', [dentistProfile]);
        yield call(swApi.checkResponse, dentistProfileResponse);

        yield put(getUser());

        yield put(showNotification({
            type: 'success',
            title: 'Success',
            message: 'Dentist profile successfully updated',
        }));
        yield history.goBack();
    } catch (error) {
        yield put(showNotification({
            type: 'error',
            title: 'Failed to update dentist profile',
            message: JSON.parse(JSON.stringify(error)).message,
        }));
    }
}

export function* doAddLab({ payload: { lab, getUsers, userId } }) {
    try {
        const response = yield call(swApi.makeRequest, 'addDentistLab', [lab, userId]);

        yield call(swApi.checkResponse, response);
        if (getUsers) {
            yield put(getUser());
        }
        yield put(showNotification({
            type: 'success',
            title: 'Success',
            message: 'Lab successfully added',
        }));
    } catch (error) {
        yield put(showNotification({
            type: 'error',
            title: 'Failed to add lab',
            message: JSON.parse(JSON.stringify(error)).message,
        }));
    }
}

export function* doAddSpecialist({ payload: { specialist, getUsers, userId } }) {
    try {
        const response = yield call(swApi.makeRequest, 'addDentistSpecialist', [specialist, userId]);

        yield call(swApi.checkResponse, response);
        if (getUsers) {
            yield put(getUser());
        }
        yield put(showNotification({
            type: 'success',
            title: 'Success',
            message: 'Specialist successfully added',
        }));
    } catch (error) {
        yield put(showNotification({
            type: 'error',
            title: 'Failed to add specialist',
            message: JSON.parse(JSON.stringify(error)).message,
        }));
    }
}

export function* doGetLab({ payload }) {
    try {
        const response = yield call(swApi.makeRequest, 'getLabById', [payload]);

        yield call(swApi.checkResponse, response);
        yield put(setLab(response.data));
    } catch (error) {
    }
}

export function* doGetSpecialist({ payload }) {
    try {
        const response = yield call(swApi.makeRequest, 'getSpecialistById', [payload]);

        yield call(swApi.checkResponse, response);
        yield put(setSpecialist(response.data));
    } catch (error) {
    }
}

export function* doDeleteLab({ payload }) {
    try {
        const response = yield call(swApi.makeRequest, 'removeDentistLab', [payload]);

        yield call(swApi.checkResponse, response);
        yield put(getUser());
        yield put(showNotification({
            type: 'success',
            title: 'Success',
            message: 'Lab successfully deleted',
        }));
    } catch (error) {
        yield put(showNotification({
            type: 'error',
            title: 'Failed to delete lab',
            message: JSON.parse(JSON.stringify(error)).message,
        }));
    }
}

export function* doDeleteSpecialist({ payload }) {
    try {
        const response = yield call(swApi.makeRequest, 'removeDentistSpecialist', [payload]);

        yield call(swApi.checkResponse, response);
        yield put(getUser());
        yield put(showNotification({
            type: 'success',
            title: 'Success',
            message: 'Specialist successfully deleted',
        }));
    } catch (error) {
        yield put(showNotification({
            type: 'error',
            title: 'Failed to delete specialist',
            message: JSON.parse(JSON.stringify(error)).message,
        }));
    }
}

export function* doSearchLabs({ payload: { lab, userId } }) {
    try {
        const params = [lab, userId];
        const response = yield call(swApi.makeRequest, 'searchLabUser', params);

        yield call(swApi.checkResponse, response);
        yield put(setLabs(response.data));
    } catch (error) {
    }
}

export function* doSearchSpecialists({ payload: { specialist, userId } }) {
    try {
        const params = [specialist, userId];
        const response = yield call(swApi.makeRequest, 'searchSpecialistUser', params);

        yield call(swApi.checkResponse, response);
        yield put(setSpecialists(response.data));
    } catch (error) {
    }
}

export function* doGetProfileForSubscription({ payload: { key, email} }) {
    try {
        const params = [key, email];
        yield call(swApi.makeRequest, 'signOut', []);
        const response = yield call(swApi.makeRequest, 'getProfileForSubscription', params);

        yield call(swApi.checkResponse, response);
        yield put(setProfileForSubscription(response.data));
    } catch (error) {
    }
}

export function* doCheckTrialLink({payload}) {
    try {
        const params = [payload];
        const response = yield call(swApi.makeRequest, 'checkTrialLink', params);

        yield call(swApi.checkResponse, response);
        yield put(setCheckTrialLinkResult(response.data));
    } catch (error) {
        yield put(setCheckTrialLinkResult(error));
    }
}

export function* doCreateTrialProfile({payload : {trialKey, email, role_id}}) {
    try {
        const params = [trialKey, email, role_id];
        const response = yield call(swApi.makeRequest, 'createTrialProfile', params);
        yield call(swApi.checkResponse, response);
        if (response.data == false ) {
            yield put(showNotification({
                type: 'error',
                title: 'Failed to create trial profile',
                message: 'Failed to create trial profile'
            }));
            yield put(createTrialProfileError('Failed to create trial profile'))
            return;
        }
        else if ( response.data.result != 'success') {
            yield put(showNotification({
                type: 'error',
                title: 'Failed to create trial profile',
                message: response.data.message
            }));
            yield put(createTrialProfileError(response.data.message))
            return;
        }
        yield put(createTrialProfileError(''))
        const subKey = response.data.subKey
        const searchParams = new URLSearchParams();
        searchParams.append("key", subKey);
        searchParams.append("email", email);
        yield history.push(CREATE_PROFILE + '/?' + searchParams.toString())
        
    } catch (error) {
        
        yield put(showNotification({
            type: 'error',
            title: 'Failed to create trial profile',
            message: 'Failed to create trial profile'
        }));
    }
}

export function* doCreateLabProfile({ payload }) {
    try {
        const response = yield call(swApi.makeRequest, 'createLabProfile', [payload]);
        yield call(swApi.checkResponse, response);
        yield put(setProfileCreated(true));
        yield put(resetProfileData());
        yield put(showNotification({
            type: 'success',
            title: 'Success',
            message: 'Lab profile successfully created',
        }));
    } catch (error) {
        yield put(setProfileCreated(false));
        yield put(showNotification({
            type: 'error',
            title: 'Failed to create lab profile',
            message: JSON.parse(JSON.stringify(error)).message,
        }));
    }
}

export function* doCreateDentistProfile({ payload: { profile, dentist } }) {
    try {
        const params = [profile, dentist];
        const response = yield call(swApi.makeRequest, 'createDentistProfile', params);
        yield call(swApi.checkResponse, response);
        yield put(setProfileCreated(true));
        yield put(resetProfileData());
        yield put(showNotification({
            type: 'success',
            title: 'Success',
            message: 'Dentist profile successfully created',
        }));
    } catch (error) {
        yield put(setProfileCreated(false));
        yield put(showNotification({
            type: 'error',
            title: 'Failed to create dentist profile',
            message: JSON.parse(JSON.stringify(error)).message,
        }));
    }
}

export function* doDeleteDentist({ payload }) {
    try {
        const response = yield call(swApi.makeRequest, 'removeDentistLabConnection', [payload]);
        yield call(swApi.checkResponse, response);
        yield put(getUser());
        yield put(showNotification({
            type: 'success',
            title: 'Success',
            message: 'Connected dentist successfully deleted',
        }));
    } catch (error) {
        yield put(showNotification({
            type: 'error',
            title: 'Failed to delete connected dentist',
            message: JSON.parse(JSON.stringify(error)).message,
        }));
    }
}

export function* doGetMagicTouchLabsList( { payload: {token} }) {
    try {
        const response = yield call(swApi.makeRequest, 'getMagicTouchLabsList', [token]);
        yield call(swApi.checkResponse, response);
        yield put(magicTouchLabsListLoaded(response.data));
    }
    catch (error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }

}

export function* doGetIsUsernameUnique( { payload: {username, nextPage} }) {
    try {
        const response = yield call(swApi.makeRequest, 'isUsernameUnique', [username]);
        yield call(swApi.checkResponse, response);
        yield put(usernameUniqueResult(response.data));
        if (response.data == true) {
            yield history.push(nextPage);
        }
    }
    catch (error) {
        yield console.log(error);
        yield put(usernameUniqueResult(false));
        yield checkUserNotAuthenticated(error);
    }

}

export function* doGenerateBackup({ payload: {fromDate, toDate} }) {
    try {
        const response = yield call(swApi.makeRequest, "createBackup", [fromDate, toDate]);
        yield call(swApi.checkResponse, response);
        const id = response.data;
        const url = process.env.REACT_APP_API_URL + 'tool/flex-helper.php?action=getZip&cID=' + id;
        yield put(backupUrlGenerated(url));
    }
    catch (error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }
    //yield call (window.open, url, '_blank');
}

export function* doGenerateDentistsExport({ payload: {fromDate, toDate} }) {
    try {
        const response = yield call(swApi.makeRequest, "createDentistsExportFile", [fromDate, toDate]);
        yield call(swApi.checkResponse, response);
        const id = response.data;
        const url = process.env.REACT_APP_API_URL + 'tool/flex-helper.php?action=exportDentists&uid=' + id;
        
        let link = document.createElement("a");
        //link.download = "export.jpg";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        //window.open(url, '_blank', 'noreferrer')
    }
    catch (error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }
    //yield call (window.open, url, '_blank');
}

export function* onUpdatePassword() {
    yield takeLatest(UserActionTypes.UPDATE_PASSWORD, doUpdatePassword);
}

export function* onResendUsernameOrPassword() {
    yield takeLatest(UserActionTypes.RESEND_USERNAME_OR_PASSWORD, doResendUsernameOrPassword);
}

export function* onSignInStart() {
    yield takeLatest(UserActionTypes.SIGN_IN_START, doSignIn);
}

export function* onSignInSuccess() {
    yield takeLatest(UserActionTypes.GET_USER, doGetUser);
}

export function* onSignOutStart() {
    yield takeLatest(UserActionTypes.SIGN_OUT_START, doSignOut);
}

export function* onUpdateLabProfile() {
    yield takeLatest(UserActionTypes.UPDATE_LAB_PROFILE, doUpdateLabProfile);
}

export function* onUpdateDentist() {
    yield takeLatest(UserActionTypes.UPDATE_DENTIST, doUpdateDentist);
}

export function* onAddLab() {
    yield takeLatest(UserActionTypes.ADD_LAB, doAddLab);
}

export function* onAddSpecialist() {
    yield takeLatest(UserActionTypes.ADD_SPECIALIST, doAddSpecialist);
}

export function* onGetLab() {
    yield takeLatest(UserActionTypes.GET_LAB, doGetLab);
}

export function* onGetSpecialist() {
    yield takeLatest(UserActionTypes.GET_SPECIALIST, doGetSpecialist);
}

export function* onDeleteLab() {
    yield takeLatest(UserActionTypes.DELETE_LAB, doDeleteLab);
}

export function* onDeleteSpecialist() {
    yield takeLatest(UserActionTypes.DELETE_SPECIALIST, doDeleteSpecialist);
}


export function* onSearchLabs() {
    yield takeLatest(UserActionTypes.SEARCH_LABS, doSearchLabs);
}

export function* onSearchSpecialists() {
    yield takeLatest(UserActionTypes.SEARCH_SPECIALISTS, doSearchSpecialists);
}

export function* onGetProfileForSubscription() {
    yield takeLatest(UserActionTypes.GET_PROFILE_FOR_SUBSCRIPTION, doGetProfileForSubscription);
}

export function* onCreateLabProfile() {
    yield takeLatest(UserActionTypes.CREATE_LAB_PROFILE, doCreateLabProfile);
}

export function* onCreateDentistProfile() {
    yield takeLatest(UserActionTypes.CREATE_DENTIST_PROFILE, doCreateDentistProfile);
}

export function* onDeleteDentist() {
    yield takeLatest(UserActionTypes.DELETE_DENTIST, doDeleteDentist);
}

export function* onGetMagicTouchLabsList() {
    yield takeLatest(UserActionTypes.GET_MAGICTOUCH_LABS_LIST, doGetMagicTouchLabsList);
}

export function* onGetIsUsernameUnique() {
    yield takeLatest(UserActionTypes.GET_IS_USERNAME_UNIQUE, doGetIsUsernameUnique);
}

export function* onCheckTrialLink() {
    yield takeLatest(UserActionTypes.CHECK_TRIAL_LINK, doCheckTrialLink);
}

export function* onCreateTrialProfile() {
    yield takeLatest(UserActionTypes.CREATE_TRIAL_PROFILE, doCreateTrialProfile);
}

export function* onGenerateBackup() {
    yield takeLatest(UserActionTypes.GENERATE_BACKUP, doGenerateBackup);
}

export function* onGenerateDentistsExport() {
    yield takeLatest(UserActionTypes.GENERATE_EXPORT_DENTISTS, doGenerateDentistsExport);
}

export function* userSagas() {
    yield all([
      call(onSignInStart),
      call(onSignInSuccess),
      call(onSignOutStart),
      call(onResendUsernameOrPassword),
      call(onUpdatePassword),
      call(onUpdateLabProfile),
      call(onUpdateDentist),
      call(onAddLab),
      call(onAddSpecialist),
      call(onGetLab),
      call(onGetSpecialist),
      call(onDeleteLab),
      call(onDeleteSpecialist),
      call(onSearchLabs),
      call(onSearchSpecialists),
      call(onGetProfileForSubscription),
      call(onCreateLabProfile),
      call(onCreateDentistProfile),
      call(onDeleteDentist),
      call(onGetMagicTouchLabsList),
      call(onGetIsUsernameUnique),
      call(onCheckTrialLink),
      call(onCreateTrialProfile),
      call(onGenerateBackup),
      call(onGenerateDentistsExport)
    ]);
}

import React from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ExpandedUserMenu from './ExpandedUserMenu';
import OutsideClickHandler from 'react-outside-click-handler';
import { ROLE_DENTIST, ROLE_DENTIST_MOBILE, ROLE_DENTIST_MOBILE_PREMIUM, ROLE_LAB, ROLE_LAB_MOBILE_CASE_FREE } from '../../constants/user';
import Button from "../elements/form/Button";
import history from "../../history";

class UserMenu extends React.PureComponent {
    userMenuExpandHandler = () => {
        let { isUserMenuShow, showUserMenu, hideUserMenu } = this.props;

        if (isUserMenuShow) {
            return hideUserMenu();
        }

        return showUserMenu();
    };

    render() {
        let { credits, id, username, isUserMenuShow, showProfile, signOut, theme, hideUserMenu, roleId, trialDays, subscriptionType } = this.props;
        let productId = 70
        switch (roleId) {
            case ROLE_LAB:
            case ROLE_LAB_MOBILE_CASE_FREE:
                productId = 51
                break;
            case ROLE_DENTIST_MOBILE_PREMIUM:
                productId = 70
                break;
            case ROLE_DENTIST_MOBILE:
                productId = 60
                break;
            case ROLE_DENTIST:
                productId = 50
                break;
        
            default:
                break;
        }
        return (
            <div className={'user-menu ' + (theme ? theme : 'default')}>

                {(roleId == ROLE_LAB || roleId == ROLE_LAB_MOBILE_CASE_FREE ) && <div className={'credits-info'}>
                    Credits left: <span className={'credits'}>{credits}</span>{(subscriptionType == "3") && <span>,</span>}
                </div>}
                {(subscriptionType == "3" ) && <div className={'credits-info'}>
                    Free Trial days left: <span className={'credits'}>{trialDays}</span>
                    <Button className={'trial-buy-button'} onClick={() => {window.open('https://store.shadewave.com/index.php?route=product/product&product_id='+productId, '_blank', 'noreferrer')}}>Buy Now</Button>
                </div>}
                <OutsideClickHandler useCapture={true} onOutsideClick={hideUserMenu}>
                    <div className={'user-dropdown-button'} onClick={this.userMenuExpandHandler}>
                        <img className={'user-icon'} src={require('../../assets/images/icons/user.svg').default} alt={username}/>

                        <img className={'expand-icon'} src={theme === 'light' ? require('../../assets/images/arrow-down-black.svg').default : require('../../assets/images/arrow-down.svg').default} alt={'expand'}/>
                    </div>

                    <ExpandedUserMenu
                        roleId={roleId}
                        isUserMenuShow={isUserMenuShow}
                        id={id}
                        name={username}
                        showProfile={showProfile}
                        hideUserMenu={hideUserMenu}
                        signOut={signOut} />
                </OutsideClickHandler>
            </div>
        )
    }
}

UserMenu.propTypes = {
    theme: PropTypes.string,
    username: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    credits: PropTypes.number.isRequired,

    isUserMenuShow: PropTypes.bool.isRequired,

    showUserMenu: PropTypes.func.isRequired,
    hideUserMenu: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    roleId: PropTypes.number.isRequired,
    subscriptionType: PropTypes.number,
    trialDays: PropTypes.number
};

export default withRouter(UserMenu);

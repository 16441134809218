import React from "react";
import PropTypes from 'prop-types';
import Header from '../../containers/Header';
import PhotoPage from '../elements/PhotoPage';
import FormSteps from './elements/FormSteps';
import Form from '../elements/Form';
import InputText from '../elements/form/input/InputText';
import Button from '../elements/form/Button';
import TransparentButton from '../elements/form/buttons/TransparentButton';
import Row from '../elements/form/Row';

import { getTargetCountry, getTargetState } from "../../helpers/user";
import Collapse from "@material-ui/core/Collapse";
import LabSpecialistList from "../profile/LabSpecialistList";
import AlertDialog from "../elements/AlertDialog";
import { SPECIALIST_ADD, LOGIN } from "../../constants/url";
import PageWithSupportFooter from '../elements/PageWithSupportFooter';
import { themeLight } from "../../config/app";

export default class LabAdd extends React.PureComponent {
    state = {
        customerNumber: '',
        contactName: '',
        labName: '',
        labPhone: '',
        labEmail: '',
        addLabAfterReceive: false,
        labsCollapseOpen: false,
        showSkipDialog: false,
        hasAddedLabs: false,
    };
    componentDidMount() {
        const { userProfile, history } = this.props;

        if (!userProfile || (userProfile && !Object.keys(userProfile).length)) {
            history.push(LOGIN);
        }
    }
    componentDidUpdate(prevProps) {
        const { activeLab } = this.props;

        if (this.state.addLabAfterReceive && activeLab && Object.keys(activeLab).length) {
            this.handleSave(activeLab);
            this.setState({addLabAfterReceive: false, hasAddedLabs: true});
        }
    }
    customerNumberChangeHandler = (value) => {
        this.setState({customerNumber:value});
    };
    contactNameChangeHandler = (value) => {
        this.setState({contactName:value});
    };
    labNameChangeHandler = (value) => {
        this.setState({labName:value});
    };
    labPhoneChangeHandler = (value) => {
        this.setState({labPhone:value});
    };
    labEmailChangeHandler = (value) => {
        this.setState({labEmail:value});
    };
    submitHandler = () => {
        this.proceedNextStep();
    };
    skipClickHandler = () => {
        this.setState({showSkipDialog: true});
    };
    searchAndAddLab = id =>{
        this.setState({
            addLabAfterReceive: true,
        });
        this.props.getLabById(id);
    };
    searchClickHandler = () => {
        const { customerNumber, contactName, labName, labPhone, labEmail } = this.state;
        const { searchLabs, userId } = this.props;
        const data = [
            `customerId=${encodeURIComponent(customerNumber)}`,
            `contactName=${encodeURIComponent(contactName)}`,
            `name=${encodeURIComponent(labName)}`,
            `phone=${encodeURIComponent(labPhone)}`,
            `email=${encodeURIComponent(labEmail)}`,
        ];

        searchLabs(data.join('&'), userId);
        this.setState({
            labsCollapseOpen: true,
        });
    };
    handleSave = activeLab => {
        const { states, countries, addLab, setLab, userId } = this.props;

        const country = getTargetCountry(countries, activeLab.country);
        const state = getTargetState(states, activeLab.state);
        const targetLab = {
            ...activeLab,
            country,
            state,
        };
        addLab(targetLab, false, userId);
        setLab({});
    };
    handleSkip = () => {
        this.handleClose();
        this.proceedNextStep();
    };
    handleClose = () => {
        this.setState({showSkipDialog: false});
    };
    proceedNextStep = () => {
        this.props.history.push(SPECIALIST_ADD);
    };

    render() {
        const { customerNumber, contactName, labEmail, labName, labPhone, labsCollapseOpen, showSkipDialog, hasAddedLabs } = this.state;
        const { labs } = this.props;
        return (
            <div className={'light-page create-dentist-profile lab-add'}>
                <PhotoPage>
                    <Header theme={themeLight} isUserMenu={false}/>

                    <PageWithSupportFooter className={'lab-add'}>
                    <FormSteps currentStep={2} stepGroup={'dentist'}/>

                    <div className={'title'}>
                        Add your dental lab(s)

                        <div className={'sub-title'}>Search existing lab</div>
                    </div>

                    <Form onSubmit={this.submitHandler} isAjax={true}>
                        <InputText name={'customer_number'} onChange={this.customerNumberChangeHandler} value={customerNumber} placeholder={'Customer Number'} isRequired={false}/>
                        <InputText name={'lab_contact_name'} onChange={this.contactNameChangeHandler} value={contactName} placeholder={'Contact Name'} isRequired={false}/>
                        <InputText name={'lab_name'} onChange={this.labNameChangeHandler} value={labName} placeholder={'Lab Name'} isRequired={false}/>
                        <InputText name={'lab_phone'} onChange={this.labPhoneChangeHandler} value={labPhone} placeholder={'Lab Phone'} isRequired={false}/>
                        <InputText name={'lab_email'} onChange={this.labEmailChangeHandler} value={labEmail} placeholder={'Lab email'} isRequired={false}/>

                        <Row className={'search-control'}>
                            <Button type={'button'} onClick={this.searchClickHandler}>Search</Button>
                        </Row>

                    </Form>
                        <Collapse className="labs-collapse" in={labsCollapseOpen} timeout="auto" unmountOnExit>
                            <div className="labs-container">
                                <LabSpecialistList
                                    className="table-container"
                                    rows={labs}
                                    columns={['Customer#', 'Lab Name', 'eMail']}
                                    handleClick={id => this.searchAndAddLab(id)}
                                />
                            </div>
                        </Collapse>
                    <Form className={'bottom-form'} onSubmit={this.submitHandler} isAjax={true}>
                        <Row className={`submit-control ${labsCollapseOpen ? labs.length ? 'search-result' : 'no-result' : ''}`}>
                            <Button type={'submit'}>Submit</Button>
                            {!hasAddedLabs && <TransparentButton type={'button'} onClick={this.skipClickHandler}>Skip</TransparentButton>}
                        </Row>
                    </Form>
                    <AlertDialog
                        message="You can update this section later in Edit Profile"
                        isOpen={showSkipDialog}
                        closeOnOutsideClick={true}
                        isLightMode={true}
                        handleClose={this.handleClose}
                        ok={this.handleSkip} />
                    </PageWithSupportFooter>
                </PhotoPage>
            </div>
        );
    }
}

LabAdd.propTypes = {
    searchLabs: PropTypes.func.isRequired,
    addLab: PropTypes.func.isRequired,
    setLab: PropTypes.func.isRequired,
    getLabById: PropTypes.func.isRequired,
    getStates: PropTypes.func.isRequired,
    getCountries: PropTypes.func.isRequired,
    setStates: PropTypes.func.isRequired,
    activeLab: PropTypes.object.isRequired,
    labs: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    userId: PropTypes.string.isRequired,
    userProfile: PropTypes.object.isRequired,
};

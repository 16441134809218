import React from "react";
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';

const Dropzone = ({filesToShow, onDrop, onChange, deleteFile}) => {
    const {
        getRootProps,
        getInputProps,
        //inputRef // Ref to the `<input>`
    } = useDropzone({
        onDrop,
        accept: 'image/*'
    });

    const renderFiles = () => {
        if (filesToShow.length === 0) {
            return false;
        }

        return (
            <div className={'files'}>
                {filesToShow.map((file, index) => {
                    return (
                        <div className={'file'} key={index}>
                            {file.result ?  <img src={file.result } alt={file.data.name}/> :  <img src={require('../../assets/images/loader-circle.gif')} alt={'Loading'}/>}
                            <div className={'name'} title={file.data.name}>{file.data.name}</div>
                            <div className={'closer'} onClick={(e) => { e.stopPropagation(); deleteFile(file.data.name); }}></div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderUploadInfo = () => {
        if (filesToShow.length > 0) {
            return <div className={'title title-bottom files-uploaded'}>Drop the image files here or click to upload</div>;
        }

        return (
            <div className={'file-upload-inner'}>
                <img className={'placeholder'} src={require('../../assets/images/file.png')} alt={'File'}/>
                <div className={'title'}>Drop the image files here or click to upload</div>
            </div>
        )
    };

    return (
        <div {...getRootProps()} className={'file-upload'} >
            <input {...getInputProps()} onChange={onChange} />
            {renderFiles()}
            {renderUploadInfo()}
        </div>
    )
};
export default class FileUpload extends React.PureComponent {
    state = {
        files: [],
    };
    saveFileResult = (result, name) => {
        let { files } = this.state;
        let { setFiles } = this.props;

        let newFiles = files.map(file => {
            return file.data.name === name ? {...file, result: result} : file
        });

        this.setState({
            files: newFiles
        });

        setFiles(newFiles);
    };
    processFiles = (filesToProcess) => {
        let { files } = this.state;
        let { onError, allowedFileTypes } = this.props;

        let filesToSave = [];

        for (let i = 0; i < filesToProcess.length; i++) {
            const inputFile = filesToProcess[i];

            if (allowedFileTypes && !allowedFileTypes.includes(inputFile.type)) {
                onError(inputFile, 'Invalid file type');

                continue;
            }

            filesToSave.push({data: inputFile, result: null});

            let reader = new FileReader();

            reader.onload = (e) => {
                this.saveFileResult(e.target.result, inputFile.name)
            };

            reader.readAsDataURL(inputFile);
        }

        this.setState({
            files: [...files, ...filesToSave],
        });
    };
    dropHandler = (files) => {
        this.processFiles(files)
    };
    changeHandler = (e) => {
        this.processFiles(e.target.files)
    };
    deleteFile = (fileName) => {
        let { files } = this.state;

        this.setState({
            files: files.filter(file => {
                return file.data.name !== fileName;
            }),
        })
    };
    render() {
        let { files } = this.state;
        return (
            <Dropzone filesToShow={files} onDrop={this.dropHandler} onChange={this.changeHandler} deleteFile={this.deleteFile}/>
        )
    }
}

FileUpload.propTypes = {
    allowedFileTypes: PropTypes.array,
    setFiles: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

import React from "react";
import PropTypes from 'prop-types';
import Button from '../Button';

export default class GreenFileUploadButton extends React.PureComponent {
    state = {
        fileInput: null,
    };
    clickHandler = () => {
        let { fileInput } = this.state;
        let { isProgress } = this.props;

        if (isProgress) {
            return false;
        }

        fileInput.click();
    };
    render() {
        let { fileInput } = this.state;
        let { text, className, onChange, isProgress, disabled } = this.props;

        return (
            <div className={'upload-button'} >
                <input
                    value={''}
                    accept={'image/*'}
					multiple={'multiple'}
                    ref={(e) => {
                    if (!e || fileInput) {
                        return false;
                    }
                    this.setState({fileInput: e})
                }} onChange={(e) => {
                    onChange(e.target.files)
                }} type={'file'} hidden/>
                <Button onClick={this.clickHandler} className={'green' + (className ? ` ${className}` : '') + (isProgress ? ' progress' : '')} text={text} disabled={disabled}/>
            </div>
        )
    }
}

GreenFileUploadButton.propTypes = {
    isProgress: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

import React from "react";
import PropTypes from 'prop-types';
import Input from '../BaseInput';


export default class InputDate extends React.PureComponent {
    render() {
        let { placeholder, value, name, icon, autocomplete, onChange, errors, isReadOnly } = this.props;

        return (
            <Input{...this.props} className={'datepicker'} errors={errors} type={'date'} value={value} name={name} placeholder={placeholder} icon={icon} onChange={onChange} attributes={{autoComplete: autocomplete ? autocomplete : '', readOnly: !!isReadOnly}}/>
        );
    }
}

InputDate.propTypes = {
    isReadOnly: PropTypes.bool,
    errors: PropTypes.array,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.node,
    autocomplete: PropTypes.string,
};

import { connect } from 'react-redux';
import ProtectedRoute from '../../components/routes/ProtectedRoute';
import {getUser, signIn, signOut, signInSuccess } from "../../actions/user.actions";

const mapStateToProps = state => ({
    isLogged: state.user.isLogged,
    isLoginPending: state.user.isLoginPending,
    isLoggedExpired: state.user.isLoggedExpired,
    isLoader: state.ui.isLoader,
    loaderPercent: state.ui.loaderPercent,
    loaderText: state.ui.loaderText,
    userProfile: state.user.userProfile,
});

const mapDispatchToProps = dispatch => ({
    signIn : (username, password) => dispatch(signIn({username, password})),
    getUser: () => dispatch(getUser()),
    signOut: () => dispatch(signOut()),
    signInSuccess: (data) => dispatch(signInSuccess(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);

import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { CREATE_LAB_PROFILE, CREATE_DENTIST_PROFILE } from "../../constants/url";
import { SUCCESS_STATUS, ERROR_STATUS } from "../../constants/response";
import { ROLE_DENTIST, ROLE_LAB, ROLE_DENTIST_LIMITED, ROLE_DENTIST_MOBILE, ROLE_LAB_MOBILE, ROLE_DENTIST_MOBILE_PREMIUM, ROLE_LAB_MOBILE_CASE_FREE } from "../../constants/user";
import { getUrlKeys } from "../../helpers/url";

class CreateProfile extends React.PureComponent {
    componentDidMount() {
        const { search } = this.props.location;
        const { email, key } = getUrlKeys(search);

        if (email && key) {
            this.props.getProfileForSubscription(key, email);
        }
    }
    componentDidUpdate(prevProps) {
        const { profileSubscription } = this.props;

        if (profileSubscription) {
            this.openTargetProfile(profileSubscription);
        }
    }
    openTargetProfile = data => {
        const { profile, status, errorMessage } = data;

        if (status === ERROR_STATUS) {
            this.props.showNotification({
                type: 'error',
                title: 'Failed to create a profile',
                message: errorMessage,
            });
        } else if (status === SUCCESS_STATUS) {
            if (profile) {
                switch (+profile.role_id) {
                    case ROLE_DENTIST:
                    case ROLE_DENTIST_LIMITED:
                    case ROLE_DENTIST_MOBILE:
                    case ROLE_DENTIST_MOBILE_PREMIUM:
                        this.props.history.push(CREATE_DENTIST_PROFILE);
                        break;
                    case ROLE_LAB:
                    case ROLE_LAB_MOBILE:
                    case ROLE_LAB_MOBILE_CASE_FREE:
                        this.props.history.push(CREATE_LAB_PROFILE);
                        break;
                    default:
                        break;
                }
            }
        }
    };
    render() {
        return '';
    }
}

CreateProfile.propTypes = {
    getProfileForSubscription: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    profileSubscription: PropTypes.object.isRequired,
};

export default withRouter(CreateProfile);
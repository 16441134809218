import { connect } from 'react-redux';
import CreateLabProfile from '../../components/profile/CreateLabProfile';
import {getCountries, getStates, setStates} from "../../actions/settings.action";
import { createLabProfile } from "../../actions/user.actions";
import { getCreateProfile } from "../../selectors/user.selector";

const mapStateToProps = state => ({
    userProfile: getCreateProfile(state),
    countries: state.settings.countries,
    states: state.settings.states,
    profileCreated: state.user.profileCreated,
});

const mapDispatchToProps = dispatch => ({
    getStates: countryId => dispatch(getStates(countryId)),
    getCountries: () => dispatch(getCountries()),
    createLabProfile: profile => dispatch(createLabProfile(profile)),
    setStates: states => dispatch(setStates(states)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLabProfile);

export const DASHBOARD = '/';
export const CASE = '/case/:caseId';
export const LOGIN = '/login';
export const LOOKUP = '/lookup';
export const CREATE_CASE = '/create-case/';
export const FORGOT_PASSWORD = '/forgot-password';
export const UPDATE_PASSWORD = '/update-password';
export const EDIT_LAB_PROFILE = '/edit-lab-profile';
export const CREATE_DENTIST_PROFILE = '/dentist/create';
export const LAB_ADD = '/dentist/lab/add';
export const DENTIST_ACCOUNT_CREATED = '/dentist/complete';
export const LAB_ACCOUNT_CREATED = '/lab/complete';
export const SPECIALIST_ADD = '/dentist/specialist/add';
export const EDIT_DENTIST_PROFILE = '/dentist/edit';
export const EDIT_SPECIALIST_PROFILE = '/edit-specialist-profile';
export const CREATE_LAB_PROFILE = '/create-lab-profile';
export const CREATE_PROFILE = '/create-profile';
export const HELP = 'https://shadewave.com/support-center/';
export const ASCEND = '/ascend/:key';
export const ASCEND_SELECT_CASE = '/ascend/selectcase';
export const ASCEND_SELECT_IMAGE = '/ascend/images';
export const ASCEND_FINISH = '/ascend/finish';
export const CREATE_TRIAL_PROFILE = '/create-trial'

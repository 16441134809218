import React from "react";
import PropTypes from 'prop-types';

export default class Loader extends React.PureComponent {
    render() {
        let { isLoading, percent, children, className } = this.props;

        if (!isLoading) {
            return false;
        }
        return (
            <div className={`loader ${className}`}>
                <div className={'inner'}>
                    <div className={'info'}>
                        <div className={`loader-circle progress-${percent}`}>
                            <div className="pie">
                                <div className="left-side half-circle"></div>
                                <div className="right-side half-circle"></div>
                            </div>

                            <div className={'percentage'}>{percent}%</div>
                        </div>

                        <div className={'text'}>
                            Please wait...<br/>
                            {children}
                        </div>

                        <div className={'dash'}></div>
                    </div>
                </div>

            </div>
        )
    }
}

export const LoaderPropTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    percent: PropTypes.oneOf([
        0, 25, 50, 75, 100
    ]).isRequired,
    children: PropTypes.node.isRequired, // text
};

Loader.propTypes = LoaderPropTypes;

import React from 'react';
import PropTypes from 'prop-types';
import Header from '../containers/Header';
import Form from './elements/Form';
import InputGroup from './elements/form/InputGroup';
import InputText from './elements/form/input/InputText';
import InputTel from './elements/form/input/InputTel';
import InputDate from './elements/form/input/InputDate';
import GreenButton from './elements/form/buttons/GreenButton';
import RadioGroup from './elements/form/RadioGroup';
import Textarea from './elements/form/Textarea';
import Select from './elements/form/Select';
import Checkbox from './elements/form/Checkbox';
import FormError from './elements/form/FormError';
import AlertDialog from './elements/AlertDialog';
import FormRow from './createCase/FormRow';
import FileUpload from './createCase/FileUpload';
import { dateToString, dateToSystemString } from '../helpers/date';
import ShadeTabSelect from './createCase/ShadeTabSelect';
import Required from './elements/icons/Required';
import PageWithSupportFooter from './elements/PageWithSupportFooter';


import { Chip } from '@material-ui/core';
import { allowedImageTypes, themeLight } from '../config/app';
import {
	ROLE_DENTIST,
	ROLE_DENTIST_LIMITED,
	ROLE_DENTIST_MOBILE,
	ROLE_DENTIST_MOBILE_PREMIUM,
} from '../constants/user';
import { Button } from '@material-ui/core';
import AddMagicTouchProductDialog from './createCase/AddMagicTouchProductDialog';

const shadeWaveTabId = '-2';

export default class CreateCase extends React.Component {
	state = {
		fileError: '',
		date: dateToString(new Date()),
		caseNumber: '',
		alternateId: '',
		doctors: this.props.userProfile.dentists,
		currentDoctorIndex:
			this.props.userProfile.dentists?.length > 0
				? this.props.userProfile.dentists[0].id + this.props.userProfile.dentists[0].idx
				: '0',
		patientFirst: '',
		patientLast: '',
		gender: 1,
		phone: this.props.userProfile.phone,
		email: this.props.userProfile.email,
		comment: '',
		isShadeCorrect: false,
		leftShadeGuide: this.props.userProfile.shade_guide_id.toString(),
		rightShadeGuide: '', //this.props.userProfile.right_shade_guide_id.toString(),
		leftShadeTab: this.props.userProfile.shade_tab_id.toString(),
		rightShadeTab: '', //this.props.userProfile.right_shade_tab_id.toString(),
		files: [],
		//caseId: this.props.createdCaseId,
		ascendkey: new URLSearchParams(this.props.location.search)?.get('key'),
		labId: 0,
		specialistId: 0,
		magictouch_enabled: this.props.userProfile?.mt_enabled,
		magictouch_token: this.props.userProfile?.mt_token,
		magictouch_default_labid: this.props.userProfile?.mt_default_labid,
		magictouch_customer_selected: null,
		magictouch_practice_doctor: null,
		magictouch_customers_loading: true,
		magictouch_practice_doctors_loading: false,
		magictouch_products_dialog_open: false,
        magictouch_products_selected: [],

		errors: {},
		formError: false,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		/*if (nextProps.createdCaseId !== prevState.caseId) {
            document.location.href = `/case/${nextProps.createdCaseId}`;
            return {...prevState, caseId: nextProps.createdCaseId};
        }*/
		if (nextProps.ascendkey === prevState.ascendkey) {
			if (
				nextProps.ascendSession &&
				(nextProps.ascendSession.patFirstName !== prevState.patientFirst ||
					nextProps.ascendSession.patLastName !== prevState.patientLast)
			) {
				return {
					...prevState,
					patientFirst: nextProps.ascendSession.patFirstName,
					patientLast: nextProps.ascendSession.patLastName,
				};
			}
		}

		return null;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.magictouch_customers !== this.props.magictouch_customers) {
			this.setState({
				magictouch_customers_loading: (this.props.magictouch_customers?.length ?? 0) === 0,
			});
		}
	}

	componentDidMount() {
		let {
			getShadeGuides,
			getNewCaseNum,
			getConnectedSpecialists,
			getLabs,
			getMagicTouchCustomersList,
			getMagicTouchProductsList,
		} = this.props;

		getLabs();
		getConnectedSpecialists();
		getShadeGuides();
		getNewCaseNum();
		
		if (this.state.magictouch_enabled && this.state.magictouch_token) {
			this.setState({
				magictouch_customers_loading: true,
			});
			getMagicTouchCustomersList(this.state.magictouch_token, this.state.magictouch_default_labid);
			getMagicTouchProductsList(this.state.magictouch_token, this.state.magictouch_default_labid);
		}
	}

	setError = (field, error) => {
		let { errors } = this.state;

		errors[field] = error;

		this.setState({
			errors: errors,
			formError: true,
		});
	};
	resetErrors = () => {
		this.setState({
			errors: {},
			formError: false,
		});
	};
	dateChangeHandler = (value) => {
		this.setState({ date: value });
	};
	caseNumberChangeHandler = (value) => {
		let { caseNum } = this.props;

		return caseNum;
	};
	alternateIdChangeHandler = (value) => {
		this.setState({ alternateId: value });
	};
	doctorFirstChangeHandler = (value) => {
		this.setState({ doctorFirst: value });
	};
	doctorLastChangeHandler = (value) => {
		this.setState({ doctorLast: value });
	};
	patientFirstChangeHandler = (value) => {
		this.setState({ patientFirst: value });
	};
	patientLastChangeHandler = (value) => {
		this.setState({ patientLast: value });
	};
	phoneChangeHandler = (value) => {
		this.setState({ phone: value });
	};
	emailChangeHandler = (value) => {
		this.setState({ email: value });
	};
	commentChangeHandler = (value) => {
		this.setState({ comment: value });
	};
	doctorChangeHandler = (value) => {
        let doctor = this.state.doctors.find((d) => (d.id + ' ' + d.idx) === value);
		this.setState(
            { 
                currentDoctorIndex: value,
                doctorFirst: doctor?.first_name,
                doctorLast: doctor?.last_name
            }
        );
	};
	submitHandler = () => {
		let { createCase, userProfile, ascendSession, isDentist } = this.props;
		let {
			//caseNumber,
			alternateId,
			doctors,
			currentDoctorIndex,
            doctorFirst,
            doctorLast,
			patientFirst,
			patientLast,
			phone,
			email,
			comment,
			isShadeCorrect,
			gender,
			leftShadeGuide,
			leftShadeTab,
			rightShadeGuide,
			rightShadeTab,
			files,
			labId,
			specialistId,
			magictouch_token,
			magictouch_default_labid,
			magictouch_customer_selected,
			magictouch_practice_doctor,
			magictouch_products_selected,
		} = this.state;

		this.resetErrors();

		if (!isShadeCorrect) {
			this.setError('isShadeCorrect', 'Are the ShadeGuide and ShadeTab correct?');
		}

		if (!leftShadeGuide /* || !rightShadeGuide*/) {
			this.setError('shadeGuide', 'Shade guide is required');
		}
		if (!leftShadeTab /* || !rightShadeTab*/) {
			this.setError('shadeTab', 'Shade Tab is required');
		}

		let { errors } = this.state;

        let doctor = doctors.find((d) => (d.id + ' ' + d.idx) === currentDoctorIndex);
        if (isDentist) {
		    if (!doctor) {
			    this.setError('doctor', '* Valid Doctor Name is required');
		    }
        }
        else {
            if ( doctor && (doctor.first_name !== doctorFirst || doctor.last_name !== doctorLast) ) {
                doctor = null;
            }
        }

		if (Object.keys(errors).length > 0) {
			return false;
		}

		createCase(
			{
				username: userProfile.username,

				date: dateToSystemString(new Date()),
				altId: alternateId,
				patientFirst: patientFirst,
				patientLast: patientLast,
				sex: gender,
				doctorId:
					userProfile.role_id === ROLE_DENTIST ||
					userProfile.role_id === ROLE_DENTIST_MOBILE ||
					userProfile.role_id === ROLE_DENTIST_MOBILE_PREMIUM ||
					userProfile.role_id === ROLE_DENTIST_LIMITED
						? doctor.id
						: (doctor?.dentist_id ?? 0),

				doctorName: `${doctorFirst} ${doctorLast}`,
				dentistFirstName: doctorFirst,
				dentistLastName: doctorLast,
				phone: phone,
				email: email,
				comment: comment,

				shadeguide: leftShadeGuide,
				shadetab: leftShadeTab,
				rightShadeGuide: rightShadeGuide,
				rightShadeTab: rightShadeTab,

				specId: parseInt(specialistId),
				labId: parseInt(labId),
				patId: ascendSession ? ascendSession.patId : null,

				magictouch_token: magictouch_token,
				magictouch_customer: magictouch_customer_selected,
				magictouch_labid: magictouch_default_labid,
				magictouch_practice_doctor: magictouch_practice_doctor,
				magictouch_products: magictouch_products_selected,
			},
			files
		);
	};
	showFormError = (field, error) => {};
	genderChangeHandler = (value) => {
		this.setState({
			gender: value,
		});
	};
	getGenderOptions = () => {
		let { gender } = this.state;
		return [
			{ name: 'gender', value: 'Male', text: 'Male', isCurrent: gender === 'Male' },
			{ name: 'gender', value: 'Female', text: 'Female', isCurrent: gender === 'Female' },
		];
	};
	correctChangeHandler = () => {
		let { isShadeCorrect } = this.state;

		this.setState({ isShadeCorrect: !isShadeCorrect });
	};
	requestShadeTabs = (guideId, storeKey) => {
		let { getShadeTabs } = this.props;

		getShadeTabs(guideId, storeKey);
	};
	leftShadeGuideChangeHandler = (value) => {
		this.setState({
			leftShadeGuide: value,
		});
		this.requestShadeTabs(value, 'left');
	};
	rightShadeGuideChangeHandler = (value) => {
		this.setState({
			rightShadeGuide: value,
		});
		this.requestShadeTabs(value, 'right');
	};
	leftShadeTabChangeHandler = (value) => {
		this.setState({ leftShadeTab: value });
	};
	rightShadeTabChangeHandler = (value) => {
		this.setState({ rightShadeTab: value });
	};
	assignLabChangeHandler = (value) => {
		this.setState({ labId: value }, () => {
			
		});
	};
	assignSpecialistChangeHandler = (value) => {
		this.setState({ specialistId: value });
	};
	getShadeTabOptions = (key) => {
		let { shadeTabsByKey } = this.props;

		let tabs = shadeTabsByKey[key];

		if (key === 'right' && !tabs) {
			tabs = {
				color: [
					{
						id: shadeWaveTabId,
						name: 'SHADE',
						rgb: '#fff',
					},
				],
			};
		}
		if (!tabs) {
			return [];
		}

		return tabs.color.map((color) => {
			return {
				value: color.id,
				name: color.name,
				color: color.rgb,
			};
		});
	};
	saveHandler = () => {};
	getShadeGuidesOptions = (key) => {
		let { shadeGuides } = this.props;

		let guides = Object.assign({}, shadeGuides);

		if (key === 'right') {
			if (guides.options.filter((option) => option.value === shadeWaveTabId).length === 0) {
				guides.options.push({
					name: 'ShadeWave Shade Tab',
					value: shadeWaveTabId,
				});
			}
		}

		return guides.options ? guides.options : [];
	};
	onFileUploadError = (file, error) => {
		this.setState({
			fileError: error,
		});
	};
	renderFileError = () => {
		let { fileError } = this.state;

		if (fileError === '') {
			return false;
		}

		return <AlertDialog isOpen={true} message={fileError} ok={() => this.setState({ fileError: '' })} />;
	};
	setFiles = (files) => {
		this.setState({
			files: files,
		});
	};
	renderFormError = () => {
		let { formError } = this.state;

		if (!formError) {
			return false;
		}
		return <FormError>Form contains invalid data</FormError>;
	};
	renderUploadPhotoError = () => {
		let { uploadPhotoError, hideUploadPhotoError } = this.props;

		if (uploadPhotoError === '') {
			return false;
		}

		return <AlertDialog isOpen={true} message={uploadPhotoError} ok={hideUploadPhotoError} />;
	};
	isShadeGuideSelectDisabled = (key) => {
		let { shadeTabsLoadingByKey } = this.props;

		if (typeof shadeTabsLoadingByKey[key] === 'undefined') {
			return false;
		}

		return shadeTabsLoadingByKey[key];
	};

	getOptions = (array) => {
		let options = [
			{
				name: '-- None --',
				value: '0',
			},
		];
		array.forEach((element) => {
			options.push({
				name: element.name,
				value: element.user_id,
			});
		});

		return options;
	};

	renderCaseCreateError = () => {
		let { notificationDialog } = this.props;

		if (!notificationDialog || notificationDialog.message === '') {
			return false;
		}

		return (
			<AlertDialog
				title={notificationDialog.title}
				className={`light ${notificationDialog.type}`}
				message={notificationDialog.message}
				ok={this.closeCreateCaseErrorMessage}
				isOpen={true}
			/>
		);
	};

	closeCreateCaseErrorMessage = () => {
		let { errorMessageShown } = this.props;

		errorMessageShown();
	};

	getDoctorOptions = (doctors) => {
		let list = doctors.map((d) => {
			return {
				value: d.id + ' ' + d.idx,
				name: d.first_name + ' ' + d.last_name,
			};
		});

		return list;
	};

	getMagicTouchCustomerOptions = (magictouch_customers) => {
		let list = magictouch_customers?.map((c) => {
			return {
				value: c.customerID,
				name: c.labName + ' - ' + c.firstName + ' ' + c.lastName,
			};
		});
		return list ?? [];
	};

	getMagicTouchPracticeDoctorOptions = (doctors) => {
		let list = doctors?.map((c) => {
			return {
				value: c.id,
				name: c.firstName + ' ' + c.lastName,
			};
		});
		return list ?? [];
	};

	magicTouchCustomerChangeHandler = (value) => {
		this.setState(
			{
				magictouch_customer_selected: value,
			},
			() => {
				this.props.getMagicTouchPracticeDoctorsList(
					this.state.magictouch_token,
					this.state.magictouch_customer_selected
				);
			}
		);
	};

	magicTouchPracticeDoctorChangeHandler = (value) => {
		this.setState({
			magictouch_practice_doctor: value,
		});
	};

    handleCloseMagicTouchProducts = (value) => {
        const { magictouch_products_selected } = this.state;
        if (value) {
            this.setState({
                magictouch_products_dialog_open: false,
                magictouch_products_selected: [...magictouch_products_selected, value],
            })
        }
        else {
            this.setState({
                magictouch_products_dialog_open: false,
                
            })
        }
    }

	render() {
		let {
			date,
			alternateId,
			doctorFirst,
			doctorLast,
			doctors,
			currentDoctorIndex,
			patientFirst,
			patientLast,
			phone,
			email,
			comment,
			isShadeCorrect,
			leftShadeGuide,
			rightShadeGuide,
			leftShadeTab,
			rightShadeTab,
			labId,
			specialistId,
			errors,
			ascendkey,
			magictouch_token,
			magictouch_enabled,
			magictouch_customer_selected,
			magictouch_practice_doctor,
			magictouch_customers_loading,
			magictouch_practice_doctors_loading,
			magictouch_products_dialog_open,
            magictouch_products_selected,
		} = this.state;
		let {
			shadeTabsByKey,
			isCreateCasePending,
			caseNum,
			specialists,
			labs,
			isDentist,
			magictouch_customers,
			magictouch_practice_doctors,
			magictouch_products,
		} = this.props;

		return (
			<div className={'light-page create-case'}>
				<Header theme={themeLight} />

				{this.renderFileError()}
				{this.renderUploadPhotoError()}
				{this.renderCaseCreateError()}
				<PageWithSupportFooter className={'create-case-inner'}>
					<div className={'page-title'}>Create a Case</div>
					<div className={'sub-title'}>Case Details</div>

					<Form onSubmit={this.submitHandler} isAjax={true}>
						<div className={'column-container'}>
							<div className={'column first'}>
								<InputDate
									isRequired={true}
									placeholder={'Date'}
									name={'date'}
									onChange={this.dateChangeHandler}
									value={date}
									isReadOnly={true}
								/>
								<InputText
									isRequired={true}
									isDisabled={true}
									placeholder={'Case #'}
									name={'case_number'}
									onChange={this.caseNumberChangeHandler}
									value={caseNum}
								/>
								<InputText
									placeholder={'Alternate ID'}
									name={'alternate_id'}
									onChange={this.alternateIdChangeHandler}
									value={alternateId}
								/>
								<InputGroup>
									<Select
										isRequired={true}
										placeholder={isDentist ? 'Doctor Name' : 'Select Doctor Name Or Enter Below'}
                                        placeholderLabel={'Doctor Name'}
										onChange={this.doctorChangeHandler}
										options={this.getDoctorOptions(doctors)}
										value={currentDoctorIndex}
                                        datatip={isDentist ? 'To add more Doctor Names go to Edit Profile page' : null}
									/>
									{errors && errors['doctor'] ? (
										<div className={'error'} key={'doctor-error'}>
											{errors['doctor']}
										</div>
									) : null}
                                    
								</InputGroup>
                                {   !isDentist && <InputText isRequired={true} placeholder={'Doctor First Name'} name={'doctor_first'} onChange={this.doctorFirstChangeHandler} value={doctorFirst}/> }
                                {   !isDentist && <InputText isRequired={true} placeholder={'Doctor Last Name'} name={'doctor_last'} onChange={this.doctorLastChangeHandler} value={doctorLast}/> }
                                    
								<InputText
									isRequired={true}
									placeholder={'Patient First Name'}
									name={'patient_first'}
									onChange={this.patientFirstChangeHandler}
									value={patientFirst}
									isDisabled={ascendkey && ascendkey.length > 0}
								/>
								<InputText
									isRequired={true}
									placeholder={'Patient Last Name'}
									name={'patient_last'}
									onChange={this.patientLastChangeHandler}
									value={patientLast}
									isDisabled={ascendkey && ascendkey.length > 0}
								/>
								<RadioGroup
									className={'gender'}
									onClick={this.genderChangeHandler}
									options={this.getGenderOptions()}
								>
									Gender
								</RadioGroup>

								<InputTel
									placeholder={'Phone Number'}
									name={'phone'}
									onChange={this.phoneChangeHandler}
									value={phone}
								/>
								<InputText
									placeholder={'Email Address'}
									name={'email'}
									onChange={this.emailChangeHandler}
									value={email}
								/>

								{isDentist ? (
									<InputGroup>
										<Select
											placeholder={'Assign Lab'}
											onChange={this.assignLabChangeHandler}
											options={this.getOptions(labs)}
											value={labId.toString()}
										/>
									</InputGroup>
								) : (
									false
								)}
								{isDentist ? (
									<InputGroup>
										<Select
											placeholder={'Assign Specialist'}
											onChange={this.assignSpecialistChangeHandler}
											options={this.getOptions(specialists)}
											value={specialistId.toString()}
										/>
									</InputGroup>
								) : (
									false
								)}
								{magictouch_enabled && magictouch_token && (
									<InputGroup>
										<Select
											placeholder={'MagicTouch Customer'}
											onChange={this.magicTouchCustomerChangeHandler}
											options={this.getMagicTouchCustomerOptions(magictouch_customers)}
											value={magictouch_customer_selected}
											isDisabled={magictouch_customers_loading}
											isLoading={magictouch_customers_loading}
										/>
									</InputGroup>
								)}
								{magictouch_enabled && magictouch_token && magictouch_customer_selected && (
									<InputGroup>
										<Select
											placeholder={'MagicTouch Practice Doctor'}
											onChange={this.magicTouchPracticeDoctorChangeHandler}
											options={this.getMagicTouchPracticeDoctorOptions(
												magictouch_practice_doctors
											)}
											value={magictouch_practice_doctor}
											isDisabled={magictouch_practice_doctors_loading}
											isLoading={magictouch_practice_doctors_loading}
										/>
									</InputGroup>
								)}
								{magictouch_enabled && magictouch_token && magictouch_customer_selected && (
									<InputGroup>
										<Button
											onClick={() => {
												this.setState({ magictouch_products_dialog_open: true });
											}}
										>
											Add MagicTouch Product
										</Button>
										{this.renderProductsDialog(magictouch_products_dialog_open, magictouch_products)}
									</InputGroup>
								)}
                                { magictouch_enabled && magictouch_token && magictouch_customer_selected && (
                                    <InputGroup>
                                        <div className={'products-chips'}>
                                            {
                                                magictouch_products_selected?.map(p => {
                                                    return <Chip
                                                    label={p.description}
                                                    
                                                    variant="outlined"
                                                />
                                                })
                                            }
                                            
                                        </div>
                                    </InputGroup>)
                                }
							</div>

							<div className={'column last'}>
								<Textarea
									placeholder={'Case comment'}
									onChange={this.commentChangeHandler}
									value={comment}
									name={'comment'}
								/>

								<FormRow
									className={'shade-guide'}
									title={
										<div className={'with-required'}>
											<Required /> Shade guide
										</div>
									}
									error={errors.shadeGuide}
								>
									<Select
										hideLabel={true}
										isTriggerChangeOnReady={parseInt(leftShadeGuide) > 0}
										placeholder={'Left'}
										onChange={this.leftShadeGuideChangeHandler}
										options={this.getShadeGuidesOptions('Left')}
										value={leftShadeGuide}
									/>
									<Select
										hideLabel={true}
										isTriggerChangeOnReady={parseInt(rightShadeGuide) > 0}
										placeholder={'- None -'}
										onChange={this.rightShadeGuideChangeHandler}
										options={this.getShadeGuidesOptions('right')}
										value={rightShadeGuide}
									/>
								</FormRow>

								<FormRow
									className={'shade-tab'}
									title={
										<div className={'with-required'}>
											<Required /> Inserted Shade Tab
										</div>
									}
									error={errors.shadeTab}
								>
									<ShadeTabSelect
										isDisabled={this.isShadeGuideSelectDisabled('left')}
										guideId={shadeTabsByKey['left'] ? shadeTabsByKey['left'].id : '0'}
										onChange={this.leftShadeTabChangeHandler}
										options={this.getShadeTabOptions('left')}
										selected={leftShadeTab}
										placeholder={'Left'}
									/>
									<ShadeTabSelect
										isDisabled={this.isShadeGuideSelectDisabled('right')}
										guideId={shadeTabsByKey['right'] ? shadeTabsByKey['right'].id : '0'}
										onChange={this.rightShadeTabChangeHandler}
										options={this.getShadeTabOptions('right')}
										selected={rightShadeTab}
										placeholder={'- None -'}
									/>
								</FormRow>

								<FormRow className={'shade-correct-checbox-row'}>
									<Checkbox
										error={errors.isShadeCorrect}
										onChange={this.correctChangeHandler}
										isChecked={isShadeCorrect}
										className={'is-shade-correct'}
									>
										<span className={'with-required'}>
											<Required /> The Shade Guide and Shade tab are correct
										</span>
									</Checkbox>
								</FormRow>

								<FileUpload
									allowedFileTypes={allowedImageTypes}
									onError={this.onFileUploadError}
									setFiles={this.setFiles}
								/>
							</div>
						</div>
						<div className={'bottom'}>
							{this.renderFormError()}
							<GreenButton
								className={'submit'}
								isSubmit={true}
								text={'SAVE AND CREATE CASE'}
								onClick={this.saveHandler}
								isLoading={isCreateCasePending}
							/>
						</div>
					</Form>
				</PageWithSupportFooter>
			</div>
		);
	}

    renderProductsDialog() {
        let { magictouch_products } = this.props;
        let { magictouch_products_dialog_open } = this.state;
        return <AddMagicTouchProductDialog isOpen={magictouch_products_dialog_open} magictouch_products={magictouch_products} onClose={this.handleCloseMagicTouchProducts} />
    }
}

CreateCase.propTypes = {
	isDentist: PropTypes.bool.isRequired,
	labs: PropTypes.array.isRequired,
	specialists: PropTypes.array.isRequired,
	getLabs: PropTypes.func.isRequired,
	getConnectedSpecialists: PropTypes.func.isRequired,
	hideUploadPhotoError: PropTypes.func.isRequired,
	uploadPhotoError: PropTypes.string.isRequired,
	shadeTabsByKey: PropTypes.object.isRequired,
	shadeTabsLoadingByKey: PropTypes.object.isRequired,
	shadeGuides: PropTypes.object.isRequired,
	getShadeGuides: PropTypes.func.isRequired,
	getShadeTabs: PropTypes.func.isRequired,
	createCase: PropTypes.func.isRequired,
	notificationDialog: PropTypes.object.isRequired,
	errorMessageShown: PropTypes.func.isRequired,
	ascendSession: PropTypes.object,
	ascendkey: PropTypes.string,
	getMagicTouchCustomersList: PropTypes.func.isRequired,
	magictouch_customers: PropTypes.array.isRequired,
	magictouch_practice_doctors: PropTypes.array.isRequired,
	getMagicTouchPracticeDoctorsList: PropTypes.func.isRequired,
	getMagicTouchProductsList: PropTypes.func.isRequired,
	magictouch_products: PropTypes.array.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import {ModalContent, ModalHeader} from "../../elements/Modal";
import Search from '../../elements/Search';
import ElementLoader from '../../elements/loader/ElementLoader';
import Table, { Header, Body, Cell, Row, RowLink } from '../../elements/Table';
import { CASES_LOADER_COMPONENT_NAME, CASES_LOAD } from '../../../constants/loaderText';
import { viewCaseShowLimit } from '../../../config/case';

let searchTid = null;

export default class ViewCases extends React.PureComponent {
    state = {
        contentHeight: 0,
        casesLoaded: viewCaseShowLimit,
        content: null,
        search: '',
    };

    componentDidMount() {
        let { loadCases } = this.props;

        loadCases(-1);
    }

    isContain = (string) => {
        let { search } = this.state;

        if (search === '') {
            return true;
        }

        return string.toLowerCase().indexOf(search.toLowerCase()) >= 0;
    };

    getCasesToShow = () => {
        let { cases } = this.props;
        let { casesLoaded } = this.state;

        let casesToShow = cases.filter(caseInfo => {
            return this.isContain(caseInfo.doctorFirst) || this.isContain(caseInfo.doctorLast) || this.isContain(caseInfo.patient);
        }).slice(0, casesLoaded);

        return casesToShow;
    };
    searchChangeHandler = (value) => {
        this.setState({search: value});

        if (searchTid) {
            clearTimeout(searchTid);
        }

        setTimeout(() => {

        }, 1);
    };

    scrollHandler = (e) => {
        let { casesLoaded, content } = this.state;

        const table = e.target;

        if (table.scrollTop + table.clientHeight + 50 > content.clientHeight) {
            this.setState({casesLoaded: casesLoaded + viewCaseShowLimit})
        }
    };

    renderContent = () => {
        let { loaderComponent, hideSubMenu } = this.props;
        let { contentHeight } = this.state;

        let isLoading = loaderComponent === CASES_LOADER_COMPONENT_NAME;

        if (isLoading) {
            return (
                <ElementLoader isLoading={true} percent={25}>
                    {CASES_LOAD}
                </ElementLoader>
            )
        }

        let casesToShow = this.getCasesToShow();
        /*const cases = [
            {date: '2020-03-12', doctorFirst: 'Evgeniy', doctorLast: 'Popov', patient: 'Milica Jerotijevic', status: 'new'},
            {date: '2020-04-07', doctorFirst: 'Joe', doctorLast: 'Ejes', patient: 'Haiz Velayathy', status: 'complete'},
            {date: '2020-08-28', doctorFirst: 'Andrey', doctorLast: 'Ivanov', patient: 'Natalia Mielniczek', status: 'new'},
        ];*/
        return (
            <Table className={'cases'} attributes={{
                onScroll: this.scrollHandler,
            }}>
                <Header>
                    <Row>
                    {['Date', 'Doctor Firstname', 'Doctor Lastname', 'Patient', 'Status'].map((title, index) => {
                        return <Cell key={index}>{title}</Cell>
                    })}
                    </Row>
                </Header>
                <Body attributes={{
                    ref: (e) => {
                        if (!e || contentHeight > 0) {
                            return false;
                        }


                        this.setState({
                            contentHeight: e.clientHeight,
                            content: e,
                        })
                    }
                }}>
                {casesToShow.map((caseDetails, index) => {
                    return (
                        <RowLink onClick={hideSubMenu} to={`/case/${caseDetails.id}`} key={index}>
                            <Cell>{caseDetails.date}</Cell>
                            <Cell>{caseDetails.doctorFirst}</Cell>
                            <Cell>{caseDetails.doctorLast}</Cell>
                            <Cell>{caseDetails.patient}</Cell>
                            <Cell><div className={'status ' + caseDetails.status}>{caseDetails.status}</div></Cell>
                        </RowLink>
                    )
                })}
                </Body>
            </Table>
        )
    };

    render() {
        let { hideSubMenu } = this.props;
        let { search } = this.state;
        return (
            <div className={'view-cases'}>
                <ModalHeader title={'View Cases'} onClose={hideSubMenu}>
                    <Search onChange={this.searchChangeHandler} value={search}/>
                </ModalHeader>

                <ModalContent>
                    {this.renderContent()}
                </ModalContent>
            </div>
        )
    }
}
/*<GreenButton className={'padded'} text={'Full screen'} onClick={() => {}}/>
                <GreenButton className={'padded'} text={'Restart'} onClick={() => {}}/>*/
ViewCases.propTypes = {
    hideSubMenu: PropTypes.func,
    loadCases: PropTypes.func.isRequired,
    loaderComponent: PropTypes.string.isRequired,
};

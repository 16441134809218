import { takeLatest, put, all, call } from 'redux-saga/effects';
import SettingsActionTypes from "../actions/settings.types";
import swApi from "../libraries/swApi";
import { setCountries, setStates } from "../actions/settings.action";

export function* doGetCountries() {
    try {
        const response = yield call(swApi.makeRequest, 'getCountries');

        yield call(swApi.checkResponse, response);
        yield put(setCountries(response.data))
    } catch (error) {
        console.log(error);
    }
}

export function* doGetStates({payload}) {
    try {
        const params = [payload];

        const response = yield call(swApi.makeRequest, 'getStates', params);
        yield call(swApi.checkResponse, response);
        yield put(setStates(response.data || []))
    } catch (error) {
        console.log(error);
    }
}

export function* onGetCountry() {
    yield takeLatest(SettingsActionTypes.GET_COUNTRIES, doGetCountries);
}

export function* onGetStates() {
    yield takeLatest(SettingsActionTypes.GET_STATES, doGetStates);
}

export function* settingSaga() {
    yield all([
        call(onGetCountry),
        call(onGetStates),
    ]);
}

import React from "react";
import PropTypes from 'prop-types';

export default class FormRow extends React.PureComponent {
    renderFieldError = (field) => {
        let { error } = this.props;

        if (!error) {
            return false;
        }

        return <div className={'field-error form-row-error'}>{error}</div>;
    };
    render() {
        let { title, children, className } = this.props;

        return (
            <div className={'form-row' + (className ? ` ${className}` : '')}>
                <div className={'form-row-inner'}>
                    {title ? <div className={'title'}>{title}</div> : ''}
                    <div className={'elements'}>
                        {children}
                    </div>
                </div>
                {this.renderFieldError()}
            </div>
        )
    }
}

FormRow.propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    children: PropTypes.node,
};

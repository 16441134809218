import CaseActionTypes from "../actions/case.types";

const initialState = {
    autoCompleteData: {
        patientFirstName: [],
        patientLastName: [],
        patientId: [],
        userName: [],
        userLastName: [],
        userPhone: [],
        caseNumber: [],
    },
    labErrorCaseIds: [],
    specialistErrorCaseIds: [],
    labByCaseUpdated: {},
    specialistByCaseUpdated: {},
};

function lookupReducer(state = initialState, action) {
    switch (action.type) {
        case CaseActionTypes.SET_CASE_SPECIALIST_SUCCESS:
            let specialistByCaseUpdated = Object.assign({}, state.specialistByCaseUpdated);
            specialistByCaseUpdated[action.caseId] = action.labId;
            return {...state, specialistByCaseUpdated};
        case CaseActionTypes.SET_CASE_LAB_SUCCESS:
            let labByCaseUpdated = Object.assign({}, state.labByCaseUpdated);
            labByCaseUpdated[action.caseId] = action.labId;
            return {...state, labByCaseUpdated};
        case CaseActionTypes.ERROR_MESSAGE_SHOWN:
            return {...state, labErrorCaseIds: [], specialistErrorCaseIds: []};
        case CaseActionTypes.SET_CASE_LAB_ERROR:
            let labErrorCaseIds = Object.assign([], state.labErrorCaseIds);
            labErrorCaseIds.push(action.caseId);
            return {...state, labErrorCaseIds: labErrorCaseIds};
        case CaseActionTypes.SET_CASE_SPECIALIST_ERROR:
            let specialistErrorCaseIds = Object.assign([], state.specialistErrorCaseIds);
            specialistErrorCaseIds.push(action.caseId);
            return {...state, specialistErrorCaseIds: specialistErrorCaseIds};
        case CaseActionTypes.AUTOCOMPLETE_RESULT:
            return {...state, autoCompleteData: action.results};
        default:
            return state;
    }
}

export default lookupReducer;

import LoaderActionTypes from './loader.types';

export const showLoader = (percent, text) => ({
    type: LoaderActionTypes.SHOW_LOADER,
    percent: percent,
    text: text,
});
export const hideLoader = () => ({
    type: LoaderActionTypes.HIDE_LOADER,
});

export const showComponentLoader = (component, percent, text) => ({
    type: LoaderActionTypes.SHOW_COMPONENT_LOADER,
    component,
    percent,
    text,
});
export const hideComponentLoader = (component) => ({
    type: LoaderActionTypes.HIDE_COMPONENT_LOADER,
    component
});

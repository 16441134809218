import { connect } from 'react-redux';
import LabAccountCreated from '../../components/profile/LabAccountCreated';
import { getCreateProfile } from "../../selectors/user.selector";
import { setProfileCreated, setProfileForSubscription} from "../../actions/user.actions";

const mapStateToProps = state => ({
    userProfile: getCreateProfile(state),
    profileCreated: state.user.profileCreated,
});

const mapDispatchToProps = dispatch => ({
    setProfileCreated: profileCreated => dispatch(setProfileCreated(profileCreated)),
    setProfileForSubscription: profile => dispatch(setProfileForSubscription(profile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabAccountCreated);

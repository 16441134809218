import axios from 'axios';

export default class swApi {
    //static url = 'https://app.shadewave.com/swapi/?contentType=application/json';
    static url = process.env.REACT_APP_API_URL + 'swapi/?contentType=application/json';
    static service = 'ShadeWaveService';
    static authError500 = 'Request failed with status code 500';
    static EMPTY_RESPONSE = 'empty response';

    static makeRequest = (method, params, serviceName = null) => {
        let options = {
            method: 'post',
            url: swApi.url,
            data: swApi.getRequestParam(method, params, serviceName),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            crossOrigin: true,
            withCredentials: true,
        };

        return axios(options);

    };

    static getRequestParam = (method, params, serviceName) => {
        return {
            "serviceName" : serviceName ?? swApi.service,
            "methodName" : method,
            "parameters" : params
        }
    };

    static checkResponse = (response) => {
        if (!response || (!response.data && response.data !== false && response.data !== 0))
        {
            let error = new Error();
            error.message = swApi.EMPTY_RESPONSE;
            throw error;
        }
        if (String(response.data).includes('User not authenticated'))
        {
            let error = new Error();
            error.message = 'User not authenticated';
            error.name = 2;
            throw error;
        }
        if (response.data.hasOwnProperty('_explicitType') && response.data._explicitType === 'com.shadeWave.shared.data.descriptors.remoting.vo.ErrorVO')
        {
            let error = new Error();
            error.message = response.data.errorDetails ?? response.data.info;
            error.name = response.data.code;
            throw error;
        }
        if (typeof response.data.errorDetails !== "undefined") {
            let error = new Error();
            error.message = response.data.errorDetails ?? response.data.info;
            error.name = response.data.code;
            throw error;
        }
    };

}

import React from "react";
import PropTypes from 'prop-types';
import Checkbox from '../elements/form/Checkbox';
import GreyButton from '../elements/form/buttons/GreyButton';
import GreenButton from '../elements/form/buttons/GreenButton';
import Button from "../elements/form/Button";
import SecureNotes from '../../containers/SecureNotes';
import { IMAGE_ORIGINAL } from "../../constants/imageTypes";
import ConfirmDialog from "../elements/ConfirmDialog";


export default class TopMenu extends React.PureComponent {

    state = {
        isCaseNotesComplete: false,
        showSecureNotesDialog: false,
        isShowCompleteConfirm: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isCaseCompleted !== prevState.isCaseCompleted) {
            
            return {...prevState, isCaseNotesComplete: nextProps.isCaseCompleted};
        }

        return null;
    }

    componentDidMount() {
        let { checkAllMessagesRead, caseId } = this.props;
        checkAllMessagesRead(caseId);
    }

    
    caseNotesCompleteChangeHandler = () => {
        if (!this.state.isCaseNotesComplete) {

            this.setState({
                isShowCompleteConfirm: true,
            });
        }
    };
    caseNotesHandler = () => {
        const { loadCaseMessages, loadAllCasePhoto, caseId } = this.props;
        loadCaseMessages(caseId);
        loadAllCasePhoto(caseId);
        this.toggleSecureNotesDialog(true);
    };
    closeSecureNotesNotes = () => {
        this.props.emptyCaseMessagePhotos();
        this.toggleSecureNotesDialog(false);
    };
    toggleSecureNotesDialog = toggle => {
        let { checkAllMessagesRead, caseId } = this.props;
        checkAllMessagesRead(caseId);
        this.setState({showSecureNotesDialog: toggle});
    };
    processPhotoHandler = () => {
        let { startProcessPhoto, showSubMenu } = this.props;
        startProcessPhoto();
        showSubMenu('process');
    };
    photoButtonHandler = (num) => {
        
    };
    shadeTabHandler = () => {
        
    };
    renderPhotoButtons = () => {
        let { currentPhotoProcessingStep, currentShadeReferenceAnalysis } = this.props;

        let buttonsArray = (currentShadeReferenceAnalysis === 'on') ? ['1', '2', '3', 'Tab'] : ['Tab'];
        return buttonsArray.map((numText, index) => {
            //let numText = num.toString();
            let className = 'photo-button';
            switch (index) {
                case 0:
                    className += ' photo-button-white';
                    break;
                case 1:
                    className += ' photo-button-black';
                    break;
                case 2:
                    className += ' photo-button-gray';
                    break;
                case 3:
                    className += ' photo-button-shade';
                    break;
                default:
                    break;

            }

            if (currentPhotoProcessingStep > index + 1)
            {
                className += ' photo-button-selected';
            }

            if (currentPhotoProcessingStep === index + 1)
            {
                className += ' photo-button-current';
            }
            if (currentPhotoProcessingStep < 1)
            {
                className += ' faded';
            }

            return <Button text={numText} className={className} key={index}/>
        });
    };
    menuOpenerHandler = () => {
        let { isWorkAreaMenuShow, showWorkAreaMenu, hideWorkAreaMenu } = this.props;

        if (isWorkAreaMenuShow) {
            return hideWorkAreaMenu();
        }

        return showWorkAreaMenu();
    };

    handleRestart = () => {
        let { restartOriginalImage, selectedPhotoType } = this.props;

        restartOriginalImage();
        selectedPhotoType(IMAGE_ORIGINAL);
    }

    completeCase = () => {
        let { markCaseComplete, caseId } = this.props;
        this.setState({
            isShowCompleteConfirm: false,
            isCaseNotesComplete: true
        });
        
        markCaseComplete(caseId);
    }

    /*renewCase = () => {
        let { markCaseNew, caseId } = this.props;
        this.setState({
            isShowCompleteConfirm: false,
            isCaseNotesComplete: true
        });
        
        markCaseNew(caseId);
    }*/

    onCloseConfirmation = () => {
        this.setState({
            isShowCompleteConfirm: false,
        })
    }

    render() {
        let { isCaseNotesComplete, showSecureNotesDialog, isShowCompleteConfirm } = this.state;
        let { isPhotoCorrectStartAllowed, isRestartAllowed, isQcMode, leftShadeTab, rightShadeTab, isAllMessagesRead, hasUnreadReceived, isAscendCase } =this.props;

        return (
            <div className={'top-menu'}>
                <div className={'menu-opener'} onClick={this.menuOpenerHandler}>
                    <img src={require('../../assets/images/burger.svg').default} alt=''/>

                    <div className={'text'}>Menu</div>
                </div>
                <div className={'case-notes'}>
                    { isAscendCase ? <img src={require('../../assets/images/icons/dentrix-trans.png')} alt="Dentrix Case" width={60} className={'case-notes-icon'}/> : null }
                    { isAllMessagesRead ?
                        <img src={require('../../assets/images/icons/check-icon.png')} alt="Check Icon" width={26} className={'case-notes-icon'} />
                    : ( hasUnreadReceived ? 
                        <img src={require('../../assets/images/icons/envelop.png')} alt="Envelop" width={26} className={'case-notes-icon'} />
                    : null) }
                    <GreyButton text={'Case Notes'} onClick={this.caseNotesHandler} disabled={isQcMode}/>

                    <Checkbox className={'case-checkbox'} text={isCaseNotesComplete? 'Case Completed' : 'Mark case as Complete'} onChange={this.caseNotesCompleteChangeHandler} isChecked={isCaseNotesComplete} disable={isCaseNotesComplete || isQcMode}/>
                </div>

                <div className={'photo-control'}>
                    <div className={'used-shade-tabs'}>
                        <div className={'shade-tab'}>{leftShadeTab}</div>
                        <div className={'shade-tab'}>{rightShadeTab}</div>
                    </div>
                    <div className={'button-horizontal-samesize'}>
                        <GreenButton text={'Restart'} className={'spacing'} onClick={this.handleRestart} disabled={!isRestartAllowed} />
                        <GreenButton text={'Process Photo'} className={'spacing'} onClick={this.processPhotoHandler} disabled={!isPhotoCorrectStartAllowed}/>
                    </div>
                    

                    <div className={'breaker'}></div>

                    {this.renderPhotoButtons()}
                </div>
                <SecureNotes
                    handleClose={this.closeSecureNotesNotes}
                    isOpen={showSecureNotesDialog} />
                <ConfirmDialog
                    isOpen={isShowCompleteConfirm}
                    title={'Case Completion'}
                    message={'Are you sure to mark this case as complete? Did you export or print all needed processed images?'}
                    no={this.onCloseConfirmation}
                    yes={this.completeCase} />
            </div>
        )
    }
}

TopMenu.propTypes = {
    isWorkAreaMenuShow: PropTypes.bool.isRequired,
    showWorkAreaMenu: PropTypes.func.isRequired,
    hideWorkAreaMenu: PropTypes.func.isRequired,
    currentPhotoProcessingStep: PropTypes.number.isRequired,
    currentShadeReferenceAnalysis: PropTypes.string.isRequired,
    startProcessPhoto: PropTypes.func.isRequired,
    showSubMenu: PropTypes.func.isRequired,
    loadCaseMessages: PropTypes.func.isRequired,
    loadAllCasePhoto: PropTypes.func.isRequired,
    caseId: PropTypes.string.isRequired,
    emptyCaseMessagePhotos: PropTypes.func.isRequired,
    isPhotoCorrectStartAllowed: PropTypes.bool,
    isRestartAllowed: PropTypes.bool,
    restartOriginalImage: PropTypes.func.isRequired,
    selectedPhotoType: PropTypes.func.isRequired,
    isCaseCompleted: PropTypes.bool,
    isQcMode: PropTypes.bool,
    leftShadeTab: PropTypes.string,
    rightShadeTab: PropTypes.string,
    isAllMessagesRead: PropTypes.bool.isRequired,
    hasUnreadReceived: PropTypes.bool.isRequired,
    isAscendCase: PropTypes.bool
};

import { connect } from "react-redux";
import { setQcMode, updateLeftQcValues, updateQcValues, updateRightQcValues } from "../../actions/case.actions";
import QcView from "../../components/case/QcView";
import { getLeftQcPhoto, getLeftShadeValues, getRightQcPhoto, getRightShadeValues } from "../../selectors/case.selector";
import { getQCPrintData } from "../../selectors/workArea.selector";


const mapStateToProps = state => ({
    leftqc: state.case.leftqc,
    rightqc: state.case.rightqc,
    isQcGrayScale: state.case.isQcGrayScale,
    isQcOriginal: state.case.isQcOriginal,
    isQcCrosshair: state.case.isQcCrosshair,
    leftPhoto: getLeftQcPhoto(state),
    rightPhoto: getRightQcPhoto(state),
    leftShadeValues: getLeftShadeValues(state),
    rightShadeValues: getRightShadeValues(state),
    printData: getQCPrintData(state),
});

const mapDispatchToProps = dispatch => ({
    updateLeftQcValues: (values) => dispatch(updateLeftQcValues(values)),
    updateRightQcValues: (values) => dispatch(updateRightQcValues(values)),
    updateQcValues: (values) => dispatch(updateQcValues(values)),
    setQcMode: () => dispatch(setQcMode()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QcView);

export const isStateExists = (state) => {
    return (state !== null) && (typeof state !== 'undefined');
};

export const replaceStateValues = (initialValues, state) => {
    let values = initialValues;

    Object.keys(initialValues).forEach(key => {
        values[key]= state[key];
    });

    return values;
};

export const formatStateButtons = (buttons, current) => {
    console.log(buttons, current);
    return buttons.map(button => {
        return {...button, isCurrent: current === button.name}
    });
};

export const formatShadeTabs = (shadeTabs, selectedTabs) => {
    return shadeTabs.map(tab => {
        tab.isSelected = selectedTabs.indexOf(tab.id) >= 0;
        return tab;
    });
};


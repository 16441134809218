import React from "react";
import PropTypes from "prop-types";
import Link from "../elements/Link";

export default class MenuLink extends React.PureComponent {
    handleClick = () => {
        const { handleClick, hideMenu } = this.props;

        if (typeof handleClick === 'function') {
            handleClick();
        }
        if (typeof hideMenu === 'function') {
            hideMenu();
        }
    };
    render() {
        const { icon, link, text, isExternal } = this.props;

        return (
            <Link onClick={this.handleClick} className={'link'} to={link} isExternal={isExternal}>
                <span className={'icon-container'}>
                    <img alt={text} src={icon}/>
                </span>
                <span className={'text'}>{text}</span>
            </Link>
        )
    }
}

MenuLink.propTypes = {
    icon: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    hideMenu: PropTypes.func,
    isExternal: PropTypes.bool,
};

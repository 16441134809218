import React from "react";
import { PureComponent } from "react";
import MatDialog, { MatDialogContent, MatDialogHeader } from "./DraggableDialog";
import InputDate from "./form/input/InputDate";
import { CircularProgress } from "@material-ui/core";
import GreenButton from "./form/buttons/GreenButton";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { dateToString, stringToSystemString } from "../../helpers/date";
import { generateBackup } from "../../actions/user.actions";

class BackupDialog extends PureComponent {
    state = {
        backupDateFrom: dateToString(new Date()),
        backupDateTo: dateToString(new Date()),
        isGeneratingBackup: false
    }

    componentDidUpdate = (prevProps) => {

        if (prevProps.isOpenBackup !== this.props.isOpenBackup) {
            if (this.props.isOpenBackup) {
                this.setState( {
                    isGeneratingBackup: false
                })
            }
        }
    }

    dateBackupFromChangeHandler = (v) => {
        this.setState({
            backupDateFrom: v
        })
    }

    dateBackupToChangeHandler = (v) => {
        this.setState({
            backupDateTo: v
        })
    }

    handleCloseBackupDialog = () => {
        
        this.setState( {
            //isOpenBackup: false,
            isGeneratingBackup: false
        })
        this.props.onClose();
    }

    render() {
        const {isOpenBackup, backupUrl, generateBackup} = this.props;
        const {backupDateFrom, backupDateTo, isGeneratingBackup} = this.state;

        return <MatDialog
        isOpen={isOpenBackup ?? false}
        onClose={this.handleCloseBackupDialog}
        classes={{
            container: 'overlay',
            paper: 'modal add-edit-lab-specialist-popup',
        }}>
        <MatDialogHeader onClose={this.handleCloseBackupDialog} title={'Download Backup'} isLightMode={true}>
            
        </MatDialogHeader>

        <MatDialogContent>
            <div className="light-page add-edit-lab-specialist-content">
                <div className="content-text">
                    Select cases date range
                </div>
                    <InputDate
                        isRequired={false}
                        placeholder={'From Date'}
                        name={'from_date'}
                        onChange={this.dateBackupFromChangeHandler}
                        value={backupDateFrom}
                        
					/>
                    <InputDate
                        isRequired={false}
                        placeholder={'To Date'}
                        name={'to_date'}
                        onChange={this.dateBackupToChangeHandler}
                        value={backupDateTo}
                        
					/>

                    { (isGeneratingBackup && backupUrl == null) &&
                    <div className="progress-info">
                        <div className="content-text">
                            Backup Zip is generating, please wait...
                        </div>
                        <CircularProgress />
                    </div>
                    }

                    { backupUrl != null &&
                    <div className="progress-info">
                        <div className="content-text">
                            Backup Zip is ready, click on "Download Backup" button below
                        </div>
                        
                    </div>
                    }
                    
                
            </div>
            <div className="footer">
                { backupUrl == null ?
                <GreenButton className="backup-button" text="Generate Backup" onClick={() => {
                    this.setState({
                        isGeneratingBackup: true
                    })
                    generateBackup(stringToSystemString( backupDateFrom), stringToSystemString(backupDateTo));
                }} disabled={isGeneratingBackup} />
                : <GreenButton className="backup-button" text="Download Backup" onClick={() => {
                    this.handleCloseBackupDialog()
                    window.open(backupUrl, '_blank', 'noreferrer')
                }} disabled={backupUrl == null} />
                }
            </div>
        </MatDialogContent>
    </MatDialog>
    }
}

BackupDialog.propTypes = {
    isOpenBackup: PropTypes.bool,
    generateBackup: PropTypes.func.isRequired,
    backupUrl: PropTypes.string
};

const mapStateToProps = state => ({
    // blabla: state.blabla,
    backupUrl: state.user.backupUrl
});

const mapDispatchToProps = dispatch => ({
    generateBackup: (fromDate, toDate) => dispatch(generateBackup({fromDate, toDate}))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BackupDialog)
import caseActionTypes from '../actions/case.types';
import photoIcon from '../assets/images/icons/photo.svg';
import processIcon from '../assets/images/icons/process.svg';
import editIcon from '../assets/images/icons/edit.svg';
import mapsIcon from '../assets/images/icons/maps.svg';
import preferencesIcon from '../assets/images/icons/preferences.svg';
import caseIcon from '../assets/images/icons/case.svg';
import viewIcon from '../assets/images/icons/view.svg';
import chatIcon from '../assets/images/icons/chat.svg';
import { HELP } from '../constants/url';

export const typeLink = 'link';
export const typeBlankLink = 'blankLink';
export const topButtons = [
    {action: caseActionTypes.PHOTOS, text: 'Photos', icon: photoIcon, menu: 'photos'},
    {action: caseActionTypes.PROCESS, text: 'Process', icon: processIcon, menu: 'process'},
    {action: caseActionTypes.EDIT, text: 'Edit', icon: editIcon, menu: 'edit'},
    {action: caseActionTypes.MAPS, text: 'Maps', icon: mapsIcon, menu: 'maps'},
];
export const bottomButtons = [
    {action: caseActionTypes.PREFERENCES, text: 'Preferences', icon: preferencesIcon, menu: 'preferences'},
    {action: caseActionTypes.CASE_DETAILS, text: 'Case Details', icon: caseIcon, menu: 'details'},
    {action: caseActionTypes.VIEW_CASES, text: 'View Cases', icon: viewIcon, menu: 'cases'},
    {action: HELP, text: 'Support', icon: chatIcon, menu: 'support', type: typeBlankLink},
];

import React from "react";
import PropTypes from 'prop-types';
import Button from '../Button';

export default class TransparentButton extends React.PureComponent {
    render() {
        let { text, className, onClick, children } = this.props;

        return (
            <Button onClick={onClick} className={'transparent' + (className ? ` ${className}` : '')} text={text}>{children}</Button>
        )
    }
}

TransparentButton.propTypes = {
    text: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

import React from "react";
import PropTypes from 'prop-types';

export default class FormError extends React.PureComponent {
    render() {
        let { children } = this.props;

        return (
            <div className={'form-error'}>
                {children}
            </div>
        )
    }
}

FormError.propTypes = {
    children: PropTypes.node.isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CREATE_CASE, LOGIN, LOOKUP } from '../constants/url';
import Header from '../containers/Header';
import { withRouter } from 'react-router-dom';
import Button from './elements/form/Button';
import PageWithSupportFooter from './elements/PageWithSupportFooter';
import { themeLight } from '../config/app';
import BackupDialog from './elements/BackupDialog';
import { ROLE_DENTIST, ROLE_DENTIST_LIMITED, ROLE_DENTIST_MOBILE, ROLE_DENTIST_MOBILE_PREMIUM, ROLE_LAB, ROLE_LAB_MOBILE, ROLE_LAB_MOBILE_CASE_FREE } from '../constants/user';

class Main extends PureComponent {
	state = {
		isLogged: null,
	};

	componentDidMount = () => {
		const { isLogged, isLoggedExpired } = this.props;

		this.setState({ isLogged: isLogged });
		if (!isLogged && !isLoggedExpired) {
			this.props.history.push(LOGIN);
		}
	};

	componentDidUpdate = () => {
		const { isLogged, isLoggedExpired } = this.props;
		if (isLogged === this.state.isLogged) return;
		this.setState({ isLogged: isLogged });
		if (!isLogged && !isLoggedExpired ) {
			this.props.history.push(LOGIN);
		}
	};

	onLookupCase = () => {
		this.props.history.push(LOOKUP);
	};

	onCreateCase = () => {
		this.props.history.push(CREATE_CASE);
	};

	onCloseBackupDialog = () => {
        this.setState({
            isOpenBackup: false
        })
        this.props.backupUrlGenerated(null)
    }

	gotoStore = () => {
		const { expiredUserRole } = this.props;
		let product_id = 0;
		switch ( expiredUserRole ) {
			case ROLE_DENTIST:
			case ROLE_DENTIST_LIMITED:
				product_id = 50;
				break;
			case ROLE_DENTIST_MOBILE:
				product_id = 60;
				break;
			case ROLE_DENTIST_MOBILE_PREMIUM:
				product_id = 70;
				break;
			case ROLE_LAB:
			case ROLE_LAB_MOBILE:
			case ROLE_LAB_MOBILE_CASE_FREE:
				product_id = 51;
				break;
		
			default:
				break;
		}
		if (product_id > 0) {
			window.open('https://store.shadewave.com/index.php?route=product/product&product_id='+product_id, '_blank', 'noreferrer')
		}
	}

	render() {
		const { isLogged, isLoggedExpired } = this.props;
		const { isOpenBackup } = this.state;
		
		if (isLogged)
			return (
				<div className={"light-page main-page"}>
					<Header theme={themeLight} className={'main-page-header'} />

					<PageWithSupportFooter className={'main-inner'}>
						<div className={"main-dot-container"}>
							<Button
								type={"button"}
								className={"mainpage"}
								text="Create a Case"
								onClick={this.onCreateCase}
							/>
							<Button
								type={"button"}
								className={"mainpage"}
								text="Look Up a Case"
								onClick={this.onLookupCase}
							/>
						</div>
					</PageWithSupportFooter>
				</div>
			);
		else if (isLoggedExpired)
				return (
					<div className={"light-page main-page"}>
						<Header theme={themeLight} className={'main-page-header'} />

						<PageWithSupportFooter className={'main-inner'}>
							<div className={"main-dot-container"}>
								<div className={'main-sub-container'}>
									<div className={'text-block'}>
										<p>Your subscription is expired. Please, purchase new subscription using our Store.</p>
										<p>Note: if you were not registered in the Store before, please, use the same email for registration as in the Shadewave application account. </p>
									</div>
									<Button
										type={"button"}
										className={"submainpage"}
										text="Go to Store"
										onClick={this.gotoStore}
									/>
									<div className={'text-block'}>
										<p>You can download backup of your cases with original images.</p>
	
									</div>
									<Button
										type={"button"}
										className={"submainpage"}
										text="Download Backup"
										onClick={() => {
											this.props.backupUrlGenerated(null);
											this.setState({
												isOpenBackup: true
											})
										}}
									/>
								</div>
								
							</div>
						</PageWithSupportFooter>

						<BackupDialog onClose={this.onCloseBackupDialog} isOpenBackup={isOpenBackup} />
					</div>
			);
		else return null;
	}
}

Main.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  isLoggedExpired: PropTypes.bool.isRequired,
  backupUrlGenerated: PropTypes.func.isRequired,
  expiredUserRole: PropTypes.number.isRequired
};

Main.defaultProps = {
  // bla: 'test',
};

export default withRouter(Main);

import React from "react";
import PropTypes from "prop-types";

export default class ControlRow extends React.PureComponent {
    render() {
        let { title, className, children, onClick, isDisabled, isRadio} = this.props;

        return (
            <div className={'row' + (className ? ` ${className}` : '')} onClick={() => {
                if (typeof onClick == 'function' && !isDisabled) {
                    onClick()
                }}}>

                {isRadio ? <div className={'radio-icon ' + (className ? ` ${className}` : '')}></div> : ''}
                <div className={'row-title' + (isDisabled?' disabled':'')}>{title}</div>
                <div className={'row-buttons'} >
                    {children}
                </div>
            </div>
        )
    }
}

ControlRow.propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
};

import React from "react";
import Loader, { LoaderPropTypes } from '../Loader';

export default class ScreenLoader extends React.PureComponent {
    render() {
        return (
            <Loader className={'screen-loader'} {...this.props}/>
        )
    }
}

ScreenLoader.propTypes = LoaderPropTypes;

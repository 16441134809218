import { connect } from "react-redux";
import Main from "../components/Main";
import { backupUrlGenerated } from "../actions/user.actions";
import { expiredUserRole } from "../selectors/user.selector";

const mapStateToProps = state => ({
    isLogged: state.user.isLogged,
    isLoggedExpired: state.user.isLoggedExpired,
    expiredUserRole: expiredUserRole(state)
  });
  
  const mapDispatchToProps = dispatch => ({
    backupUrlGenerated: (url) => dispatch(backupUrlGenerated(url))
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Main);
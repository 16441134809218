import React from "react";
import Loader, { LoaderPropTypes } from '../Loader';

export default class ElementLoader extends React.PureComponent {
    render() {
        return (
            <Loader className={'element-loader'} {...this.props}/>
        )
    }
}
ElementLoader.propTypes = LoaderPropTypes;

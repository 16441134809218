import { all, call } from 'redux-saga/effects';
import { userSagas } from './user.sagas';
import { caseSagas } from './case.sagas';
import { settingSaga } from './settings.saga';
import { dentistSagas } from './dentist.sagas';
import { ascendSaga } from './ascend.sagas';

export default function* rootSaga() {
  yield all([call(userSagas), call(caseSagas), call(settingSaga), call(dentistSagas), call(ascendSaga)]);
}

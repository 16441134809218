import React from "react";
import PropTypes from 'prop-types';
import Header from '../../containers/Header';
import PhotoPage from '../elements/PhotoPage';
import FormSteps from "./elements/FormSteps";
import { NavLink } from "react-router-dom";
import { LOGIN } from "../../constants/url";
import { themeLight } from "../../config/app";

export default class DentistAccountCreated extends React.PureComponent {
    componentDidMount() {
        const { userProfile, profileCreated } = this.props;

        if (!profileCreated || !userProfile || (userProfile && !Object.keys(userProfile).length)) {
            this.props.history.push(LOGIN);
        }
    }

    componentWillUnmount() {
        const { setProfileForSubscription, setProfileCreated } = this.props;
        setProfileForSubscription({});
        setProfileCreated(false);
    }
    render() {
        return (
            <div className={'light-page create-dentist-profile account-created'}>
                <PhotoPage>
                    <Header theme={themeLight} isUserMenu={false}/>

                    <FormSteps currentStep={4} stepGroup={'dentist'}/>

                    <div className={'message'}>
                        <img className={'telegram'} src={require('../../assets/images/telegram.png')} alt={'Account Created'}/>

                        <div className={'title'}>Account Created!</div>

                        <div className={'login-info'}>
                            Your account is successfully created. <NavLink to={LOGIN}>Login here</NavLink>
                        </div>
                    </div>
                </PhotoPage>
            </div>
        );
    }
}

DentistAccountCreated.propTypes = {
    userProfile: PropTypes.object.isRequired,
    profileCreated: PropTypes.bool.isRequired,
    setProfileCreated: PropTypes.func.isRequired,
    setProfileForSubscription: PropTypes.func.isRequired,
};

export const SET_CASE_LAB_ERROR = 'The selected lab cannot process this case because of the expired license.';
export const SET_CASE_LAB_ERROR_NOCREDIT = "The selected lab cannot process this case because doesn't have available use credits.";
export const SET_CASE_SPECIALIST_ERROR = 'The selected specialist cannot process this case because of the expired license.';

export const UNSET_CASE_LAB_SUCCESS = 'Lab has been unassigned.';
export const SET_CASE_LAB_SUCCESS = 'The Lab has been set.';
export const SET_CASE_LAB_NEW_EMAIL_SENT = 'A notification e-mail was sent to the new Lab.';
export const SET_CASE_SPECIALIST_NEW_EMAIL_SENT = 'A notification e-mail was sent to the new Specialist.';

export const SET_CASE_LAB_OLD_EMAIL_SENT = 'A notification e-mail was sent to the old Lab';
export const SET_CASE_SPECIALIST_OLD_EMAIL_SENT = 'A notification e-mail was sent to the old Specialist';
export const SET_CASE_SPECIALIST_SUCCESS = 'The Specialist has been set.';
export const UNSET_CASE_SPECIALIST_SUCCESS = 'Specialist has been unassigned';

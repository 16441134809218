import React from "react";
import ControlRow from "../subMenus/elements/ControlRow";

export class QcEdit extends React.PureComponent {
    

    render() {
        let { onZoom, onRotate, disabled } = this.props;
        return (
            <div className={'qc-controls-column'}>
                <ControlRow title={'Zoom'} className={'zoom'} isDisabled={disabled} >
                    <img onClick={() => {
                        onZoom(-0.1);
                    }} src={require('../../../assets/images/icons/minus.png')} alt={'zoom out'} width={26} disabled={disabled} />
                    <img onClick={() => {
                        onZoom(0.1);
                    }} src={require('../../../assets/images/icons/plus.png')} alt={'zoom in'} width={26} disabled={disabled}/>
                </ControlRow>
                <ControlRow title={'Rotate'} className={'rotate'} isDisabled={disabled}>
                    <img onClick={() => {
                        onRotate(-1);
                    }} src={require('../../../assets/images/icons/minus.png')} alt={'rotate left'} width={26} disabled={disabled}/>
                    <img onClick={() => {
                        onRotate(1);
                    }} src={require('../../../assets/images/icons/plus.png')} alt={'rotate right'} width={26} disabled={disabled}/>
                </ControlRow>
            </div>
        );
    }
}

import { connect } from 'react-redux';
import LabAdd from '../../components/dentist/LabAdd';
import { addLab, getLabById, setLab, searchLabs } from "../../actions/user.actions";
import { getCountries, getStates, setStates } from "../../actions/settings.action";
import {getCreateProfile, getUserIdForAdd} from "../../selectors/user.selector";

const mapStateToProps = state => ({
    activeLab: state.user.activeLab,
    labs: state.user.labs,
    countries: state.settings.countries,
    states: state.settings.states,
    dentistToAdd: state.user.dentistToAdd,
    userId: getUserIdForAdd(state),
    userProfile: getCreateProfile(state),
});

const mapDispatchToProps = dispatch => ({
    searchLabs: (lab, userId) => dispatch(searchLabs({lab, userId})),
    addLab: (lab, getUsers, userId) => dispatch(addLab({lab, getUsers, userId})),
    setLab: lab => dispatch(setLab(lab)),
    getLabById: id => dispatch(getLabById(id)),
    getStates: countryId => dispatch(getStates(countryId)),
    getCountries: () => dispatch(getCountries()),
    setStates: states => dispatch(setStates(states)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabAdd);

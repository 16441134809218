import { connect } from 'react-redux';
import store from '../store';
import Lookup from '../components/Lookup';
import {
    loadCases,
    deleteCase,
    getConnectedSpecialists,
    getLabs,
    setCaseLab,
    setCaseSpecialist,
    autoCompleteSend,
    errorMessageShown,
    updateAlternativeId,
    updateCaseDetails,
    markCaseComplete,
    markCaseNew,
} from '../actions/case.actions';
import { getCasesList, getLabs as getLabsSelector, getConnectedSpecialists as getConnectedSpecialistsSelector, hasAscendCases } from "../selectors/case.selector";
import { isDentist, isLab } from "../selectors/user.selector";
import { getAutoCompleteData, getLabErrorCaseIds, getSpecialistErrorCaseIds, getLabByCaseUpdated, getSpecialistByCaseUpdated } from "../selectors/lookup.selector";
import lookupReducer from '../reducers/lookup.reducer';
import { backupUrlGenerated } from '../actions/user.actions';

store.reducerManager.add("lookup", lookupReducer);

const mapStateToProps = state => ({
    isLogged: state.user.isLogged,
    role: state.user.userProfile.role_id,
    isDentist: isDentist(state),
    isLab: isLab(state),
    cases: getCasesList(state),
    hasAscendCase: hasAscendCases(state),
    loaderComponent: state.ui.loaderComponent,
    labs: getLabsSelector(state),
    specialists: getConnectedSpecialistsSelector(state),
    autoCompleteData: getAutoCompleteData(state),
    messageType: state.ui.notificationDialog.type,
    message: state.ui.notificationDialog.message,
    messageTitle: state.ui.notificationDialog.title,
    labErrorCaseIds: getLabErrorCaseIds(state),
    specialistErrorCaseIds: getSpecialistErrorCaseIds(state),
    labByCaseUpdated: getLabByCaseUpdated(state),
    specialistByCaseUpdated: getSpecialistByCaseUpdated(state),
    
});

const mapDispatchToProps = dispatch => ({
    loadCases: (limit, terms, lastCaseId, lastDate) => dispatch(loadCases(limit, terms, lastCaseId, lastDate)),
    deleteCase: (caseId) => dispatch(deleteCase(caseId)),
    errorMessageShown: () => dispatch(errorMessageShown()),
    getConnectedSpecialists: () => dispatch(getConnectedSpecialists()),
    getLabs: () => dispatch(getLabs()),
    setCaseLab: (caseId, labId) => dispatch(setCaseLab(caseId, labId)),
    setCaseSpecialist: (caseId, specialistId) => dispatch(setCaseSpecialist(caseId, specialistId)),
    autoCompleteSend: (username, field) => dispatch(autoCompleteSend(username, field)),
    updateAlternativeId: (caseId, altId) => dispatch(updateAlternativeId({caseId, altId})),
    backupUrlGenerated: (url) => dispatch(backupUrlGenerated(url)),
    updateCaseDetails: (caseId, editData) => dispatch(updateCaseDetails({caseId, editData})),
    markCaseComplete: (caseId) => dispatch(markCaseComplete(caseId)),
    markCaseNew: (caseId) => dispatch(markCaseNew(caseId))
    
});

export default connect(mapStateToProps, mapDispatchToProps)(Lookup);

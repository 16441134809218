import React from "react";
import PropTypes from "prop-types";
import ControlRow from './elements/ControlRow';
import ShadeTabsBlock from './elements/ShadeTabsBlock';
import SwitchButtons from '../../elements/form/SwitchButtons';
import MatDialog, {MatDialogHeader, MatDialogContent} from '../../elements/DraggableDialog';

export default class Preferences extends React.Component {
    state = {
        isShadeTabsPopup: false,
        selectedTabs: [],
        arrowButtonsClasses: {
            moveRight: ' disabled',
            moveLeft: ' disabled',
            moveAllToRight: ' disabled',
            moveAllToLeft: ' disabled',
        },
    };
    /*shouldComponentUpdate(nextProps, nextState) {
        return true;
    };*/
    componentDidUpdate(prevProps) {
        let isSelectedChanged = false;

        if (prevProps.shadeTabFilter?.length !== this.props.shadeTabFilter?.length) {
            isSelectedChanged = true;
        } else {
            const sortedPrevSelectedShades = [...prevProps.shadeTabFilter].sort((a, b) => a - b).join();
            const sortedNextSelectedShades = [...this.props.shadeTabFilter].sort((a, b) => a - b).join();

            isSelectedChanged = sortedPrevSelectedShades !== sortedNextSelectedShades;
        }

        if (isSelectedChanged) {
            this.updateArrowButtons();
        }
    }
    componentDidMount() {
        this.updateArrowButtons();
    }
    updateArrowButtons() {
        const { shadeTabs, shadeTabFilter } = this.props;
        const { selectedTabs } = this.state;
        const availableShadeTabs = shadeTabs.filter(tab => !shadeTabFilter.includes(tab.id));
        const hasSelectedAvailableShadeTabs = availableShadeTabs.filter(availableShadeTab => selectedTabs.includes(availableShadeTab.id));

        const selectedShadeTabs = shadeTabs.filter(shadeTab => shadeTabFilter.includes(shadeTab.id));
        const hasSelectedShadeTabs = selectedShadeTabs.filter(selectedItem => selectedTabs.includes(selectedItem.id));

        const arrowButtonsClasses = {
            moveLeft: hasSelectedShadeTabs.length ? '' : ' disabled',
            moveAllToLeft: selectedShadeTabs.length ? '' : ' disabled',
            moveRight: hasSelectedAvailableShadeTabs.length ? '' : ' disabled',
            moveAllToRight: availableShadeTabs.length ? '' : ' disabled',
        };
        this.setState({arrowButtonsClasses});
    }
    themeClickHandler = (name) => {
        let { setTheme } = this.props;

        setTheme(name);
    };
    processPhotoWalkthroughClickHandler = (name) => {
        let { setProcessPhotoWalkthrough, changeWalkthroughSettings } = this.props;

        setProcessPhotoWalkthrough(name);
        changeWalkthroughSettings(name);
    };
    shadeReferenceAnalysisButtonsClickHandler = (name) => {
        let { setShadeReferenceAnalysis } = this.props;

        setShadeReferenceAnalysis(name);
    };
    defineShadeTabsClickHandler = () => {
        this.setState({isShadeTabsPopup: true});
    };
    defineShadeTabsClose = () => {
        this.setState({isShadeTabsPopup: false});
    };
    renderButtons = (buttons, clickHandler) => {
        return <SwitchButtons className={'background'} buttons={buttons} onClick={clickHandler}/>
    };
    selectShadeTab = (id) => {
        let { selectedTabs } = this.state;

        let index = selectedTabs.indexOf(id);


        if (index >= 0) {
            selectedTabs.splice(index, 1);
        } else {
            selectedTabs.push(id);
        }
        this.updateArrowButtons();
        this.setState({selectedTabs})
    };
    renderShadeTabs = (isSelected) => {
        const { shadeTabs, shadeTabFilter } = this.props;
        const { selectedTabs } = this.state;

        if (isSelected) {
            return shadeTabFilter.map(preSelectedTab => {
                const index = shadeTabs.findIndex(shadeTab => shadeTab.id === preSelectedTab);
                const tab = shadeTabs[index];
                if (!tab) {
                    return null;
                }
                const isSelected = selectedTabs.indexOf(tab.id) >= 0;

                return (
                    <div onClick={() => { this.selectShadeTab(tab.id)}} key={index} className={'shade-tab' + (isSelected ? ' selected' : '')}>{tab.name}</div>
                )
            });
        } else {
            return shadeTabs.filter(tab => !shadeTabFilter.includes(tab.id)).map((tab, index) => {
                const isSelected = selectedTabs.indexOf(tab.id) >= 0;

                return (
                    <div onClick={() => { this.selectShadeTab(tab.id)}} key={index} className={'shade-tab' + (isSelected ? ' selected' : '')}>{tab.name}</div>
                )
            });
        }
    };
    resetSelectedShadeTabs = () => {
        this.setState({selectedTabs: []});
    };
    moveLeftSelected = () => {
        const { shadeTabFilter, shadeTabs } = this.props;
        const { selectedTabs } = this.state;
        const selectedShadeGuideIds = shadeTabFilter ? shadeTabFilter.filter(id => !selectedTabs.includes(id)) : [];
        const shadeGuides = shadeTabs.filter(shadeTab => selectedShadeGuideIds.includes(shadeTab.id));
        this.setSelectedShadeTabs(shadeGuides, JSON.stringify(selectedShadeGuideIds));
    };
    moveRightSelected = () => {
        const { shadeTabFilter = [], shadeTabs } = this.props;
        const { selectedTabs = [] } = this.state;
        const idsToSelect = selectedTabs.filter(id => !shadeTabFilter.includes(id));
        const selectedShadeGuideIds = [...idsToSelect, ...shadeTabFilter];
        const shadeGuides = shadeTabs.filter(shadeTab => selectedShadeGuideIds.includes(shadeTab.id));
        this.setSelectedShadeTabs(shadeGuides, JSON.stringify(selectedShadeGuideIds));
    };
    moveLeftAll = () => {
        this.setSelectedShadeTabs([], '[]');
    };
    moveRightAll = () => {
        const { shadeTabs } = this.props;
        const selectedShadeGuideIds = shadeTabs.map(it => it.id);
        this.setSelectedShadeTabs(shadeTabs, JSON.stringify(selectedShadeGuideIds));
    };
    setSelectedShadeTabs(shadeGuides, selectedShadeGuidIds) {
        const { updateShadeGuides, setShadeTabs } = this.props;

        setShadeTabs(selectedShadeGuidIds);
        updateShadeGuides(shadeGuides);
        this.resetSelectedShadeTabs();
    }
    render() {
        const { processPhotoWalkthroughButtons, shadeReferenceAnalysisButtons } = this.props;
        const { isShadeTabsPopup, arrowButtonsClasses } = this.state;
        return (
            <div className={'preferences'}>

                <ControlRow title={'Define Shade Tab List'} className={'padded shade-tab-list action'} onClick={this.defineShadeTabsClickHandler}/>

                <ControlRow title={'Process Photo Walkthrough'} className={'switch-buttons-container column'}>
                    {this.renderButtons(processPhotoWalkthroughButtons, this.processPhotoWalkthroughClickHandler)}
                </ControlRow>
                <ControlRow title={'Shade Reference Analysis'} className={'switch-buttons-container column'}>
                    {this.renderButtons(shadeReferenceAnalysisButtons, this.shadeReferenceAnalysisButtonsClickHandler)}
                </ControlRow>

                <MatDialog
                    isOpen={isShadeTabsPopup ?? false}
                    onClose={this.defineShadeTabsClose}
                    classes={{
                        container: 'overlay shade-tabs-overlay',
                        paper: 'modal shade-tabs-popup',
                    }}>
                    <MatDialogHeader onClose={this.defineShadeTabsClose} title={'Define Shade Tab List'} />

                    <MatDialogContent>
                        <ShadeTabsBlock title={'Available'} className={'left'}>
                            {this.renderShadeTabs(false)}
                        </ShadeTabsBlock>
                        <div className={'control'}>
                            <div className={`move-right${arrowButtonsClasses.moveRight}`} onClick={this.moveRightSelected}></div>
                            <div className={`move-left${arrowButtonsClasses.moveLeft}`} onClick={this.moveLeftSelected}></div>
                            <div className={`move-right-all${arrowButtonsClasses.moveAllToRight}`} onClick={this.moveRightAll}></div>
                            <div className={`move-left-all${arrowButtonsClasses.moveAllToLeft}`} onClick={this.moveLeftAll}></div>
                        </div>
                        <ShadeTabsBlock title={'Selected'} className={'right'}>
                            {this.renderShadeTabs(true)}
                        </ShadeTabsBlock>
                    </MatDialogContent>
                </MatDialog>
            </div>
        )
    }
}

Preferences.propTypes = {
    shadeTabs: PropTypes.array.isRequired,
    themeButtons: PropTypes.array.isRequired,
    processPhotoWalkthroughButtons: PropTypes.array.isRequired,
    shadeReferenceAnalysisButtons: PropTypes.array.isRequired,
    shadeTabFilter: PropTypes.array.isRequired,

    setTheme: PropTypes.func.isRequired,
    setProcessPhotoWalkthrough: PropTypes.func.isRequired,
    setShadeReferenceAnalysis: PropTypes.func.isRequired,
    setShadeTabs: PropTypes.func.isRequired,
    updateShadeGuides: PropTypes.func.isRequired,
    changeWalkthroughSettings: PropTypes.func.isRequired,
};

import React from "react";
import PropTypes from 'prop-types';

export default class Button extends React.PureComponent {
    state = {
        buttonRef: null,
    };

    componentDidUpdate(prevProps) {
        const { isDisabled } = this.props;
        const { isDisabled: prevDisabled } = prevProps;
        const { buttonRef } = this.state;

        if (typeof isDisabled !== 'undefined' && typeof prevDisabled !== 'undefined') {
            buttonRef.disabled = isDisabled;
        }
    }
    render() {
        const { text, className, onClick, children, type, disabled } = this.props;
        const { buttonRef } = this.state;
        return (
            <button ref={(e) => {
                if (!e || buttonRef) {
                    return false;
                }

                this.setState({buttonRef: e});
            }} type={type ? type : 'button'} onClick={(e) => {if (!disabled && onClick) onClick();}} onTouchEnd={(e) => { if (!disabled && onClick) {e.preventDefault(); onClick();}}} className={'button' + (className ? ` ${className}` : '')} disabled={disabled}>
                {text}{children}
            </button>
        )
    }
}

Button.propTypes = {
    type: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    disabled: PropTypes.bool,
};

import React from "react";
import PropTypes from 'prop-types';
import SliderComponent from '@material-ui/core/Slider';

const changeStep = 5;
export default class Slider extends React.PureComponent {
    handleChange = (e, value) => {
        let { onChange } = this.props;

        onChange(value);
    };
    incrementHandler = () => {
        let { current, max, onChange, buttonStep } = this.props;
        
        let value = current + (buttonStep ?? changeStep);
        
        if (value > max) {
            value = max;
        }
        
        onChange(value);
    };
    decrementHandler = () => {
        let { current, min, onChange, buttonStep } = this.props;

        let value = current - (buttonStep ?? changeStep);

        if (value < min) {
            value = min;
        }

        onChange(value);
    };
    modifyValue = (value) => {
        return value;
    };
    getDefaultValue = () => {
        let { defaultValue } = this.props;

        if (typeof defaultValue === 'undefined') {
            return 0;
        }

        return defaultValue;
    };
    reset = () => {
        this.handleChange(false, this.getDefaultValue());
    };
    render() {
        let { current, min, max, isResetButton, allChanges, buttonStep, isDisabled } = this.props;
        //console.log('isDisabled', isDisabled)
        return (
            <div className={'slider'}>
                <SliderComponent
                    min={min}
                    max={max}
                    value={this.modifyValue(current)}
                    onChange={allChanges && this.handleChange}
                    onChangeCommitted={this.handleChange}
                    aria-labelledby={'continuous-slider'}
                    valueLabelDisplay={'on'}
                    disabled={isDisabled}
                    marks={[
                        {
                            value: min,
                            label: min.toString(),
                        },
                        {
                            value: max,
                            label: max.toString(),
                        },
                    ]}
                />
                {isResetButton && <div onClick={() => {if (!isDisabled) this.decrementHandler()}} className={'control decrement'} disabled={isDisabled}>- {buttonStep ?? changeStep}</div> }
                {isResetButton && <div onClick={() => {if (!isDisabled) this.incrementHandler()}} className={'control increment'} disabled={isDisabled}>+ {buttonStep ?? changeStep}</div> }
                {isResetButton && current !== this.getDefaultValue() ? <div onClick={() => {if (!isDisabled) this.reset()}} className={'reset control'} disabled={isDisabled}>reset</div> : false}
            </div>
        )
    }
}

Slider.propTypes = {
    isResetButton: PropTypes.bool,
    defaultValue: PropTypes.number,
    current: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    allChanges: PropTypes.bool
};

import React from "react";
import PropTypes from "prop-types";
import { caseShape, photoShape } from "../../helpers/case";
import { Stage, Layer, Rect, Transformer, Group, Line } from "react-konva";
//import Image from '../../containers/case/photo/Image';
import Image from "./photo/Image";
import Xmark from "./photo/Xmark";
import { getImageDimensions, getCurrentViewImage, getCurrentMask } from "../../helpers/photo";
import { isFullScreen, openFullscreen } from "../../helpers/ui";
import { withResizeDetector } from "react-resize-detector";
import { zoomScaleBy } from "../../config/photo";
import TransparentButton from "../elements/form/buttons/TransparentButton";
import GreenButton from "../elements/form/buttons/GreenButton";
import Cropper from "../elements/Cropper";
import {
    IMAGE_SHADE_MAP,
    IMAGE_CORRECTED,
    IMAGE_ORIGINAL,
    IMAGE_MODIFIER,
    IMAGE_TRIPLE_VIEW,
    IMAGE_DOUBLE_VIEW,
} from "../../constants/imageTypes";
import ShadeImageLabel from "./photo/ShadeImageLabel";
import Konva from "konva";
import ShadeColorSelectorModal from "./photo/ShadeColorSelectorModal";
import { IconButton, Menu, MenuItem, Popover, Slider } from "@material-ui/core";
import AlertDialog from "../elements/AlertDialog";
import ReactToPrint from "react-to-print";
import photoUtils from "../../libraries/photoUtils";
import html2canvas from "html2canvas";
import ConfirmDialog from "../elements/ConfirmDialog";
import TextAnnotationLabel from "./photo/TextAnnotationLabel";
import { ColorButton } from "material-ui-color";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import DoneIcon from "@material-ui/icons/Done";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { v4 as uuidv4 } from "uuid";
import { ArrowRightAltOutlined, RadioButtonUncheckedOutlined, ThreeSixtyOutlined } from "@material-ui/icons";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import { GRAPHIC_ARROW, GRAPHIC_CIRCLE, GRAPHIC_LINE, NO_DRAW } from "../../constants/graphicTypes";
import GraphAnnotation from "./photo/GraphAnnotation";
import { updateShadeLabelsMetadata, updateShadeMapLabels } from "../../actions/case.actions";

const containerVerticalOffset = 34; // vertical padding * 2 of the .photo-container
const containerHorizontalOffset = 38; //

let fullScreenTid = null;

const scaleDefaults = {
    stageScale: 1,
    stageX: 0,
    stageY: 0,
};
const cropDefaults = {
    cropX: 0,
    cropY: 0,
    cropWidth: 0,
    cropHeight: 0,
};

const BORDER = 5;
class PhotoContainer extends React.PureComponent {
    state = {
        container: null,
        containerWidth: 0,
        containerHeight: 0,
        currentPhotoUrl: this.props.currentPhoto ? this.props.currentPhoto.url : "",
        imageWidth: 0,
        imageHeight: 0,
        imageX: 0,
        imageY: 0,
        ratio: -1,
        ratioX: 1,
        ratioY: 1,
        ratioXmarks: 1,
        ratioShadeTrans: -1,
        shadeTransX: -1,
        shadeTransY: -1,
        image: null,
        imageType: null,
        photoId: null,
        mapSettings: null,
        shadeLabels: [],
        shadeTransLabelsShow: true,
        viewShadeSelector: false,
        selectedColor: null,
        selectedCooredinate: null,
        selectedRefPoints: [],
        isShowContextMenu: false,
        contextMenuX: 0,
        contextMenuY: 0,
        regionInitialized: false,
        isShowAfterPrintConfirm: false,
        anchorEl: null,
        anchorAddTextEl: null,
        crossHairSettingMode: false,
        xCH: null,
        yCH: null,
        drawMode: -1,
        //graphicObjects: [],
        selectedGraphicObject: null,
        drawingActive: false,
        image_loaded: true,
        showShadeTransTransform: true,
        ...scaleDefaults,
        ...cropDefaults,
    };

    clientX = 0;
    clientY = 0;

    circles = {};
    textAnnotEl = [];
    shadeTabsEl = [];

    componentDidUpdate(prevProps) {
        let { width, height, isRegionActive, correctedImageType, currentPhoto, textAnnotations, shadeTabCoords } = this.props;
        let { containerWidth, containerHeight, showShadeTransTransform } = this.state;

        if (!isFullScreen()) {
            if (width && height && (Math.abs(containerWidth - width) > 1 || Math.abs(containerHeight - height) > 1)) {
                this.handleImageLoad({ width, height });
            }
        }
        else {
            if (width && height && (Math.abs(containerWidth - window.innerWidth) > 1 || Math.abs(containerHeight - window.innerHeight) > 1)) {
                this.handleImageLoad({ width: window.innerWidth, height: window.innerHeight });
            }
        }
        
        if (prevProps.correctedImageType !== correctedImageType) {
            let image = getCurrentViewImage(
                currentPhoto,
                containerWidth,
                containerHeight,
                correctedImageType === "norm"
            );
            this.setState({
                image: image,
                image_loaded: false
            }, () => {
                setTimeout(
                    () => {
                        this.setState({
                            image_loaded: true
                        })
                    }, 100
                )
            })
            //this.setState({ image: image, image_loaded: false }, () => {setTimeout(() => {this.setState({ image_loaded: true})}, 500)});
        }

        if (isRegionActive && !this.state.regionInitialized) {
            if (this.transformer.nodes.length === 0) this.transformer.attachTo(this.rectangle);
            this.transformer.getLayer().batchDraw();
            if (this.transformerSecond.nodes.length === 0) this.transformerSecond.attachTo(this.rectangleSecond);
            this.transformerSecond.getLayer().batchDraw();
            this.setState({ regionInitialized: true });
        } else if (!isRegionActive && this.state.regionInitialized) {
            this.transformer.detach();
            this.transformerSecond.detach();
            this.setState({ regionInitialized: false });
        }
        let imageDoubleToShow = (currentPhoto!=null) && (currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW) && (this.imageDouble != null)
        console.log('check showShadeTransTransform', showShadeTransTransform)
        if (imageDoubleToShow && !this.state.shadeTransformerInitialized && !this.state.printing && showShadeTransTransform)
        {
            if (this.shadeTransformer.nodes.length === 0) this.shadeTransformer.attachTo(this.imageDouble.imageRef);
            this.shadeTransformer.flipEnabled(false);
            this.shadeTransformer.getLayer().batchDraw();
            this.setState({ shadeTransformerInitialized: true, ratioShadeTrans: this.state.ratio });
        }
        else if ((!imageDoubleToShow) && this.state.shadeTransformerInitialized) {
            this.shadeTransformer.detach()
            this.setState({ shadeTransformerInitialized: false });
        }
        /*if (prevProps.textAnnotations != textAnnotations) {
            let isUpdated = false
            if (this.textAnnotEl != null && this.state.ratio != -1 ) {
                const newTextAnnotations = textAnnotations.map((item) => {
                    if ('width' in item && item.width == this.textAnnotEl[item.uid].label.getWidth() / this.state.ratio &&
                    'ratio' in item &&
                    item.ratio == this.state.ratio) {
                        return item;
                    } else {
                        isUpdated = true
                        let w = this.textAnnotEl[item.uid].label.getWidth()
                        let h = this.textAnnotEl[item.uid].label.getHeight()
                        let x = item.x;
                        let y = item.y;
                        if ('ratio' in item && item.ratio != this.state.ratio) {
                            x = x - ((w / this.state.ratio) - (w / item.ratio))/2.0
                            y = y - ((h / this.state.ratio) - (h / item.ratio))/2.0
                        }
                        console.log(w, h, x, y)
                        //let h = this.textAnnotEl[item.uid].getHeight()
                        return {...item, x:x, y:y, width: w / this.state.ratio, height: h / this.state.ratio, ratio: this.state.ratio} 
                    }
                })
                if (isUpdated)
                    this.props.updateTextAnnotations(currentPhoto.id, newTextAnnotations)
            }
            
        }*/

        if (prevProps.currentPhoto != currentPhoto || prevProps.currentPhoto?.showShadeTabs != currentPhoto?.showShadeTabs) {
            let isSTUpdated = false
            let shadeCoords = {}
            if (this.shadeTabsEl != null && this.shadeTabsEl.left != null && this.state.ratio != -1 ) {
                const itemLeft = shadeTabCoords.left
                console.log(itemLeft, shadeTabCoords)
                if ( ! ('ver' in itemLeft && itemLeft.ver == '1.1') ) {
                    isSTUpdated = true
                    let w = this.shadeTabsEl['left'].label.getWidth()
                    let h = this.shadeTabsEl['left'].label.getHeight()
                    let x = itemLeft.x + (w / (this.state.ratio * 2));
                    let y = itemLeft.y + (h / (this.state.ratio * 2));
                    console.log(w, h, x, y)
                    //let h = this.textAnnotEl[item.uid].getHeight()
                    shadeCoords = {...shadeCoords, left: {...itemLeft, x:x, y:y, ver: '1.1'}} 
                }
                const itemRight = shadeTabCoords.right
                console.log(itemRight, shadeCoords)
                if ( ! ('ver' in itemRight && itemRight.ver == '1.1')) {
                    isSTUpdated = true
                    let w = this.shadeTabsEl['right'].label.getWidth()
                    let h = this.shadeTabsEl['right'].label.getHeight()
                    let x = itemRight.x + w / (this.state.ratio * 2);
                    let y = itemRight.y + h / (this.state.ratio * 2);
                    console.log(w, h, x, y)
                    //let h = this.textAnnotEl[item.uid].getHeight()
                    shadeCoords = {...shadeCoords, right: {...itemRight, x:x, y:y, ver: '1.1'}} 
                }
                
                if (isSTUpdated)
                    this.props.updateShadeTabCoords(currentPhoto.id, {
                        ...shadeTabCoords,
                        ...shadeCoords,
                    })
            }
        }
    }

    componentDidMount() {
        let { currentPhoto } = this.props;

        if (!currentPhoto) {
            return false;
        }

        ["fullscreenchange", "webkitfullscreenchange", "mozfullscreenchange", "msfullscreenchange"].forEach(
            (eventType) => document.addEventListener(eventType, this.fullScreenhandler, false)
        );

        document.addEventListener("keydown", this.documentKeydownHandler, false);
    }

    documentKeydownHandler = (event) => {
        // Ctrl + 0
        if (event.ctrlKey && event.keyCode === 48) {
            this.resetZoom();
        }
    };
    resetZoom = () => {
        this.setState(scaleDefaults, () => {
            this.updateShadeTransRelativePosition()
        });
    };
    fullScreenhandler = (e) => {
        let containerWidth, containerHeight;

        if (fullScreenTid) {
            clearTimeout(fullScreenTid);
        }

        if (isFullScreen()) {
            containerWidth = window.innerWidth;
            containerHeight = window.innerHeight;
        } else {
            let { container } = this.state;

            containerWidth = this.getElementWidth(container);
            containerHeight = this.getElementHeight(container);
        }

        this.setState({
            containerWidth: containerWidth,
            containerHeight: containerHeight,
        });
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.currentPhoto &&
            nextProps.currentPhoto.imgOriginal &&
            (nextProps.currentPhoto.currentPhotoType !== prevState.imageType ||
                nextProps.currentPhoto.id !== prevState.photoId ||
                nextProps.currentPhoto.currentMapSettings !== prevState.mapSettings ||
                nextProps.currentPhoto.url !== prevState.currentPhotoUrl)
        ) {
            return {
                containerHeight: 0,
                containerWidth: 0,
                image: null,
                imageType: nextProps.currentPhoto.currentPhotoType,
                photoId: nextProps.currentPhoto.id,
                mapSettings: nextProps.currentPhoto.currentMapSettings,
                currentPhotoUrl: nextProps.currentPhoto.url,
                shadeLabels: [],
            };
        }
        return null;
    }

    handleImageLoad = (params) => {
        let { containerWidth, containerHeight } = this.state;
        let { currentPhoto, correctedImageType } = this.props;
        let { image } = this.state;

        if (!currentPhoto) {
            return false;
        }

        if (params) {
            let { width, height } = params;

            // replacing state values from params if passed after the component updated (on resize f.e.)
            if (width) {
                containerWidth = width;
            }
            if (height) {
                containerHeight = height;
            }
        }

        if (!image || currentPhoto.currentPhotoType === IMAGE_TRIPLE_VIEW) {
            image = getCurrentViewImage(currentPhoto, containerWidth, containerHeight, correctedImageType === "norm");
        }
        if (!image) {
            console.log("not image");
            return;
        }
        let dimensions = getImageDimensions(image, containerWidth, containerHeight);
        this.setState({...dimensions, image_loaded: false, shadeTransLabelsShow: false}, this.onDimensionChanged);
    };

    onDimensionChanged = () => {
        const { crossHairPos, shadeTabCoords, currentPhoto, updateShadeTransPosition, shadeTransPos } = this.props;
        const { ratio, imageX, imageY } = this.state;
        
        let xCH = !isNaN(crossHairPos?.x) && crossHairPos?.x != 0 ? crossHairPos?.x : null;
        let yCH = !isNaN(crossHairPos?.y) && crossHairPos?.y != 0 ? crossHairPos?.y : null;
        
        if (xCH != null && yCH != null) {
            this.restoreCrossHairPosition(xCH, yCH, ratio);
        } else {
            this.setState({
                xCH: null,
                yCH: null,
            });
        }

        setTimeout(
                    () => {
                        this.setState({
                            image_loaded: true
                        })
                    }, 300
                )

        if (!shadeTransPos )        
        {
            updateShadeTransPosition({
                shadeTransX: 0.5,
                shadeTransY: 0.5,
                ratioShadeTrans: 1.0
            })
        }
        
        let isUpdated = false
        if (this.textAnnotEl != null && this.state.ratio != -1 ) {
            const newTextAnnotations = this.props.textAnnotations.map((item) => {
                if ('ver' in item && 
                item.ver == '1.1') {
                    return item;
                } else {
                    isUpdated = true
                    let w = this.textAnnotEl[item.uid].label.getWidth()
                    let h = this.textAnnotEl[item.uid].label.getHeight()
                    let x = item.x + w / (this.state.ratio * 2);
                    let y = item.y + h / (this.state.ratio * 2);
                    console.log(w, h, x, y)
                    //let h = this.textAnnotEl[item.uid].getHeight()
                    return {...item, x:x, y:y, ver: '1.1'} 
                }
            })
            if (isUpdated)
                this.props.updateTextAnnotations(this.props.currentPhoto.id, newTextAnnotations)
        }

        let isSTUpdated = false
        let shadeCoords = {}
        console.log('dimension changed', this.shadeTabsEl, this.state.ratio, shadeTabCoords);
        if (this.shadeTabsEl != null && this.shadeTabsEl.left != null && this.state.ratio != -1 ) {
            const itemLeft = shadeTabCoords.left
            if ( ! ('ver' in itemLeft && itemLeft.ver == '1.1')) {
                isSTUpdated = true
                
                let w = this.shadeTabsEl['left'].label.getWidth()
                let h = this.shadeTabsEl['left'].label.getHeight()
                let x = itemLeft.x + w / (this.state.ratio * 2);
                let y = itemLeft.y + h / (this.state.ratio * 2);
                console.log(w, h, x, y )
                //let h = this.textAnnotEl[item.uid].getHeight()
                shadeCoords = {...shadeCoords, left: {...itemLeft, x:x, y:y, ver: '1.1'}} 
            }
            const itemRight = shadeTabCoords.right
            if ( ! ('ver' in itemRight && itemRight.ver == '1.1')) {
                isSTUpdated = true
                let w = this.shadeTabsEl['right'].label.getWidth()
                let h = this.shadeTabsEl['right'].label.getHeight()
                let x = itemRight.x  + w / (this.state.ratio * 2);
                let y = itemRight.y + h / (this.state.ratio * 2);
                console.log(w, h, x, y)
                //let h = this.textAnnotEl[item.uid].getHeight()
                shadeCoords = {...shadeCoords, right: {...itemRight, x:x, y:y, ver: '1.1'}} 
            }
            
            if (isSTUpdated)
                this.props.updateShadeTabCoords(currentPhoto.id, {
                    ...shadeTabCoords,
                    ...shadeCoords,
                })
        }

        this.setState({shadeTransLabelsShow: true})


    };

    renderPhoto = () => {
        let { currentPhoto, brightness, contrast, isGrayScale } = this.props;
        let { imageWidth, imageHeight, imageX, imageY, image } = this.state;

        if (!currentPhoto) {
            return false;
        }

        if (imageWidth < 1) {
            return false;
        }

        //if (!((containerWidth === width && containerHeight === height) || (containerWidth === window.innerWidth && containerHeight === window.innerHeight)))
        //{
        //    console.log('do not render image ', containerWidth, width, window.innerWidth, containerHeight, height, window.innerHeight);
        //    return false;
        //}

        const filters = {
            brightness,
            contrast,
            isGrayScale,
        };

        return (
            <Image
                ref={(el) => {this.mainImage = el}}
                image={image}
                x={imageX}
                y={imageY}
                width={imageWidth}
                height={imageHeight}
                imageType={currentPhoto.currentPhotoType}
                {...filters}
            />
        );
    };

    renderMarks = () => {
        let { currentPhoto, currentPhotoProcessingStep } = this.props;

        let { ratio, imageX, imageY, ratioXmarks, imageHeight, imageWidth } = this.state;

        if (currentPhoto.currentPhotoType !== IMAGE_CORRECTED && currentPhoto.currentPhotoType !== IMAGE_ORIGINAL)
            return false;

        if (imageWidth < 1 || imageHeight < 1) return false;

        if (currentPhoto.xmarks.length === 0 || !ratio) {
            return false;
        }

        if (
            currentPhoto.hideXmarks &&
            !(currentPhoto.currentPhotoType === IMAGE_ORIGINAL && currentPhotoProcessingStep > 0)
        )
            return false;

        return currentPhoto.xmarks.map((coordinates, index) => {
            let cx, cy;
            if (coordinates.x < 1 && coordinates.y < 1) {
                cx = coordinates.x * imageWidth + imageX - coordinates.width / 2;
                cy = coordinates.y * imageHeight + imageY - coordinates.height / 2;
            } else {
                cx = coordinates.x * ratio * ratioXmarks + imageX;
                cy = coordinates.y * ratio * ratioXmarks + imageY;
            }
            return <Xmark key={index} x={cx} y={cy} width={coordinates.width} height={coordinates.height} />;
        });
    };

    handleClearShadeLabels = () => {
        let { currentPhoto, updateShadeMapLabels, updateShadeLabelsMetadata } = this.props;
        
        if (currentPhoto.currentPhotoType === IMAGE_SHADE_MAP) {
            updateShadeMapLabels([], null);
        } else if (currentPhoto.currentPhotoType === IMAGE_MODIFIER) {
            updateShadeMapLabels(null, []);
        } else if (currentPhoto.currentPhotoType === IMAGE_TRIPLE_VIEW || currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW) {
            updateShadeMapLabels([], []);
        }
        updateShadeLabelsMetadata();
        this.setState({ isShowContextMenu: false });
    };

    renderTextAnnotationLabels = () => {
        let { currentPhoto, textAnnotations } = this.props;
        let { ratio, imageX, imageY, drawMode } = this.state;

        if (currentPhoto.currentPhotoType != IMAGE_ORIGINAL && currentPhoto.currentPhotoType != IMAGE_CORRECTED && currentPhoto.currentPhotoType != IMAGE_DOUBLE_VIEW)
            return false;
        
        return (
            currentPhoto.showTextAnnotations &&
            textAnnotations.map((item) => {
                return (
                    <TextAnnotationLabel
                        ref={(el) => {this.textAnnotEl[item.uid] = el}}
                        key={item.uid}
                        text={item.text}
                        x={item.x * ratio + imageX}
                        y={item.y * ratio + imageY}
                        index={item.uid}
                        onDragEnd={this.onShadeTabDragEnd}
                        onClick={(e) => {
                            e.evt.preventDefault();
                            this.setState({
                                anchorEl: e.target,
                                contextMenuX: e.evt.clientX,
                                contextMenuY: e.evt.clientY,
                                currentAnnotationText: item.text ?? "",
                            });
                        }}
                        draggable={drawMode == NO_DRAW}
                        fontSize={item.fontSize}
                        color={item.color}
                    />
                );
            })
        );
    };

    renderShadeTabLabels = () => {
        let { currentPhoto, leftShadeTab, rightShadeTab, shadeTabCoords, shadeTabSettings } = this.props;
        let { ratio, imageX, imageY, drawMode } = this.state;

        if (currentPhoto.currentPhotoType != IMAGE_ORIGINAL && currentPhoto.currentPhotoType != IMAGE_CORRECTED && currentPhoto.currentPhotoType != IMAGE_DOUBLE_VIEW)
            return false;

        let leftShadeTabCoords = shadeTabCoords.left;
        let rightShadeTabCoords = shadeTabCoords.right;
        let leftShadeTabSettings = shadeTabSettings.left;
        let rightShadeTabSettings = shadeTabSettings.right;

        return (
            currentPhoto.showShadeTabs && (
                <>
                    <TextAnnotationLabel
                        ref={(el) => {this.shadeTabsEl['left'] = el}}
                        text={leftShadeTab}
                        x={leftShadeTabCoords.x * ratio + imageX}
                        y={leftShadeTabCoords.y * ratio + imageY}
                        index={"st01"}
                        onDragEnd={this.onShadeTabDragEnd}
                        onClick={(e) => {
                            e.evt.preventDefault();
                            this.setState({
                                anchorEl: e.target,
                                contextMenuX: e.evt.clientX,
                                contextMenuY: e.evt.clientY,
                            });
                        }}
                        draggable={drawMode == NO_DRAW}
                        fontSize={leftShadeTabSettings.fontSize}
                        color={leftShadeTabSettings.color}
                    />
                    <TextAnnotationLabel
                        ref={(el) => {this.shadeTabsEl['right'] = el}}
                        text={rightShadeTab}
                        x={rightShadeTabCoords.x * ratio + imageX}
                        y={rightShadeTabCoords.y * ratio + imageY}
                        index={"st02"}
                        onDragEnd={this.onShadeTabDragEnd}
                        fontSize={rightShadeTabSettings.fontSize}
                        color={rightShadeTabSettings.color}
                        onClick={(e) => {
                            e.evt.preventDefault();
                            this.setState({
                                anchorEl: e.target,
                                contextMenuX: e.evt.clientX,
                                contextMenuY: e.evt.clientY,
                            });
                        }}
                        draggable={drawMode == NO_DRAW}
                    />
                </>
            )
        );
    };

    onShadeTabDragEnd = (e) => {
        let { imageX, imageY, ratio } = this.state;
        const { updateShadeTabCoords, shadeTabCoords, textAnnotations, updateTextAnnotations, currentPhoto } =
            this.props;
        
        let nx = e.target.attrs.x - imageX;
        let ny = e.target.attrs.y - imageY;
        console.log(e, nx, ny, e.target.attrs, imageX, imageY)
        if (e.target.attrs.index === "st01") {
            updateShadeTabCoords(currentPhoto.id, { ...shadeTabCoords, ...{ left: { x: nx / ratio, y: ny / ratio, ver: '1.1' } } });
        } else if (e.target.attrs.index === "st02") {
            updateShadeTabCoords(currentPhoto.id, {
                ...shadeTabCoords,
                ...{ right: { x: nx / ratio, y: ny / ratio, ver: '1.1' } },
            });
        } else {
            updateTextAnnotations(
                currentPhoto.id,
                textAnnotations.map((item) => {
                    if (item.uid === e.target.attrs.index) {
                        //let w = this.textAnnotEl[item.uid].label.getWidth()
                        //let h = this.textAnnotEl[item.uid].label.getHeight()
                        return { ...item, x: nx / ratio, y: ny / ratio, ver: '1.1' /*, width: w / ratio, height: h / ratio*/ };
                    } else {
                        return item;
                    }
                })
            );
        }
    };

    renderShadeTransLabels = () => {
        let { currentPhoto, shadeMapLabels, modifierLabels, shadeTransPos } = this.props;
        let { ratio, shadeTransLabelsShow } = this.state;

        if (currentPhoto.currentPhotoType !== IMAGE_DOUBLE_VIEW || !this.imageDouble || !this.imageDouble.imageRef)
            return false;

        if (!shadeTransLabelsShow || !ratio)
        return false;

        let shLabels = shadeMapLabels.map((shadeLabel, index) => {
            let pos = this.convertShadeTransToLayerPosition({x: shadeLabel.x, y: shadeLabel.y})
            return shadeLabel && shadeLabel.text && shadeLabel.text.length > 0 ? (
                <ShadeImageLabel
                    key={"sm" + index}
                    index={"sm" + index}
                    x={pos.x }
                    y={pos.y }
                    text={shadeLabel.text}
                    ratio={shadeTransPos.ratioShadeTrans}
                    fontSizeBase={16}
                />
            ) : null;
        });
        let mrLabels = modifierLabels.map((shadeLabel, index) => {
            let dx = 20 + (currentPhoto.imgDouble.width - 20) / 2
            let pos = this.convertShadeTransToLayerPosition({x: shadeLabel.x + dx, y: shadeLabel.y})
            return shadeLabel && shadeLabel.text && shadeLabel.text.length > 0 ? (
                <ShadeImageLabel
                    key={"mr" + index}
                    index={"mr" + index}
                    x={pos.x}
                    y={pos.y}
                    text={shadeLabel.text}
                    ratio={shadeTransPos.ratioShadeTrans}
                    fontSizeBase={16}
                />
            ) : null;
        });
        return shLabels.concat(mrLabels);
    }

    renderShadeLabels = () => {
        let { currentPhoto, shadeMapLabels, modifierLabels } = this.props;
        let { ratio, imageX, imageY, image } = this.state;

        if (currentPhoto.currentPhotoType === IMAGE_ORIGINAL || currentPhoto.currentPhotoType === IMAGE_CORRECTED)
            return false;

        if (
            ((!shadeMapLabels || shadeMapLabels.length === 0) && (!modifierLabels || modifierLabels.length === 0)) ||
            !ratio
        ) {
            return false;
        }

        if (currentPhoto.currentPhotoType === IMAGE_SHADE_MAP) {
            return shadeMapLabels.map((shadeLabel, index) => {
                return shadeLabel && shadeLabel.text && shadeLabel.text.length > 0 ? (
                    <ShadeImageLabel
                        key={"sm" + index}
                        index={"sm" + index}
                        x={shadeLabel.x * ratio + imageX}
                        y={shadeLabel.y * ratio + imageY}
                        text={shadeLabel.text}
                    />
                ) : null;
            });
        } else if (currentPhoto.currentPhotoType === IMAGE_MODIFIER) {
            return modifierLabels.map((shadeLabel, index) => {
                return shadeLabel && shadeLabel.text && shadeLabel.text.length > 0 ? (
                    <ShadeImageLabel
                        key={"mr" + index}
                        index={"mr" + index}
                        x={shadeLabel.x * ratio + imageX}
                        y={shadeLabel.y * ratio + imageY}
                        text={shadeLabel.text}
                    />
                ) : null;
            });
        } else if (currentPhoto.currentPhotoType === IMAGE_TRIPLE_VIEW) {
            if (!image) return null;
            let dy = image.height === currentPhoto.imgTriple1.height ? 0 : 10 + image.height / 2;
            let dx =
                image.height === currentPhoto.imgTriple1.height
                    ? 40 + ((image.width - 40) * 2) / 3
                    : 20 + (image.width - 20) / 2;
            let shLabels = shadeMapLabels.map((shadeLabel, index) => {
                return shadeLabel && shadeLabel.text && shadeLabel.text.length > 0 ? (
                    <ShadeImageLabel
                        key={"sm" + index}
                        index={"sm" + index}
                        x={shadeLabel.x * ratio + imageX}
                        y={(shadeLabel.y + dy) * ratio + imageY}
                        text={shadeLabel.text}
                    />
                ) : null;
            });
            let mrLabels = modifierLabels.map((shadeLabel, index) => {
                return shadeLabel && shadeLabel.text && shadeLabel.text.length > 0 ? (
                    <ShadeImageLabel
                        key={"mr" + index}
                        index={"mr" + index}
                        x={(shadeLabel.x + dx) * ratio + imageX}
                        y={(shadeLabel.y + dy) * ratio + imageY}
                        text={shadeLabel.text}
                    />
                ) : null;
            });
            return shLabels.concat(mrLabels);
        }
    };

    getElementWidth = (el) => {
        return el.clientWidth - containerHorizontalOffset;
    };
    getElementHeight = (el) => {
        return el.clientHeight - containerVerticalOffset;
    };
    containerOnLoad = (el) => {
        let { setPhotoContainerRef } = this.props;
        let { containerWidth } = this.state;

        if (!el || containerWidth > 0) {
            return false;
        }

        this.containerRef = el;
        setPhotoContainerRef(el);

        this.setState({
            container: el,
            containerWidth: this.getElementWidth(el),
            containerHeight: this.getElementHeight(el),
        });
    };

    renderZoomInfo = () => {
        let { stageScale } = this.state;

        if (stageScale === 1) {
            return false;
        }

        const percent = Math.round(stageScale * 100);

        return (
            <div className={"zoom-info"}>
                Image scale: <span className={"scale"}>{percent}%</span>
                <TransparentButton onClick={this.resetZoom} text={"Reset"} />
            </div>
        );
    };
    handleWheel = (e) => {
        e.evt.preventDefault();

        const stage = e.target.getStage().getLayers()[0];

        const oldX = stage.x();
        const oldY = stage.y();

        const oldScale = stage.scaleX();

        const newScale = e.evt.deltaY > 0 ? oldScale * zoomScaleBy : oldScale / zoomScaleBy;

        //stage.setX(oldX - ((newScale - oldScale) * stage.width()) / 2);
        //stage.setY(oldY - ((newScale - oldScale) * stage.height()) / 2);
        this.setState({
            stageScale: newScale,
            //stageX: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
            //stageY: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale
        }, () => {
            this.updateShadeTransRelativePosition()
        });
        console.log('handleWheel')
        this.updateCrossHairPosition(this.state.xCH, this.state.yCH, this.state.ratio);
        
    };

    processShadeTranslucencyMapClick = (pos) => {
        const {currentPhoto, shadeGuide, currentModifier, addModifierLabel, addShadeMapLabel} = this.props;

        console.log('call processShadeTranslucencyMapClick', pos)
            
        let img = currentPhoto.imgDouble;
        let mask = currentPhoto.imgDoubleMask;

        if (pos.x >= 0 && pos.y >= 0 && pos.x < img.width && pos.y < img.height) {
            let pixelPos = (pos.y * img.width + pos.x) * 3;
            let colorCode = mask[pixelPos];

            let dx = 20 + (img.width - 20)/2
            console.log(pixelPos, colorCode)
            if (colorCode > 0) {
                let element =
                            pos.x > img.width / 2
                                ? currentModifier.values.length >= colorCode
                                    ? currentModifier.values[colorCode - 1].name
                                    : ""
                                : shadeGuide.color.length >= colorCode
                                ? shadeGuide.color[colorCode - 1].name
                                : "";
                if (pos.x > img.width / 2) {
                    addModifierLabel({ x: pos.x - dx, y: pos.y, text: element });
                } else {
                    addShadeMapLabel({ x: pos.x, y: pos.y, text: element });
                }
                updateShadeLabelsMetadata()
            }
            this.setState({
                showShadeTransTransform: true
            })
        }
        else {
            this.hideShadeTransTransformer()
        }
    }

    processShadeMapClick = (pos) => {
        let {
            currentPhoto,
            shadeGuide,
            currentModifier,
            addModifierLabel,
            addShadeMapLabel,
            updateShadeLabelsMetadata,
        } = this.props;
        let { containerHeight, containerWidth } = this.state;
        let img = getCurrentViewImage(currentPhoto, containerWidth, containerHeight, false);
        let mask = getCurrentMask(currentPhoto, containerWidth, containerHeight);

        let pixelPos = (pos.y * img.width + pos.x) * 3;
        let colorCode = mask[pixelPos];

        if (colorCode > 0) {
            let element = "";
            switch (currentPhoto.currentPhotoType) {
                case IMAGE_MODIFIER:
                    element =
                        currentModifier.values.length >= colorCode ? currentModifier.values[colorCode - 1].name : "";
                    addModifierLabel({ x: pos.x, y: pos.y, text: element });
                    break;
                case IMAGE_SHADE_MAP:
                    element = shadeGuide.color.length >= colorCode ? shadeGuide.color[colorCode - 1].name : "";
                    addShadeMapLabel({ x: pos.x, y: pos.y, text: element });
                    break;
                case IMAGE_TRIPLE_VIEW:
                    element =
                        pos.x > img.width / 2
                            ? currentModifier.values.length >= colorCode
                                ? currentModifier.values[colorCode - 1].name
                                : ""
                            : shadeGuide.color.length >= colorCode
                            ? shadeGuide.color[colorCode - 1].name
                            : "";
                    let dy = img.height === currentPhoto.imgTriple1.height ? 0 : 10 + img.height / 2;
                    let dx =
                        img.height === currentPhoto.imgTriple1.height
                            ? 40 + ((img.width - 40) * 2) / 3
                            : 20 + (img.width - 20) / 2;
                    if (pos.x > img.width / 2) {
                        addModifierLabel({ x: pos.x - dx, y: pos.y - dy, text: element });
                    } else {
                        addShadeMapLabel({ x: pos.x, y: pos.y - dy, text: element });
                    }
                    break;
                default:
                    element = "";
                    break;
            }
            updateShadeLabelsMetadata();
        }
        
    };

    handleStageClick = (e) => {
        let {
            currentPhoto,
            currentPhotoProcessingStep,
            currentShadeReferenceAnalysis,
            addShadeSelected,
            shadeGuide,
            removeShadeLabel,
        } = this.props;
        let { ratio, image, isShowContextMenu, ratioShadeTrans } = this.state;

        if (e.evt.button === 0 || e.type === "tap") {
            if (e.type === "tap" && isShowContextMenu) return;
            const stage = e.target.getStage();
            const images = stage.getLayers()[0].getChildren(function (node) {
                return node.getClassName() === "Image";
            });

            const shadeImages = stage.getLayers()[1].getChildren(function (node) {
                return node.getClassName() === "Image";
            });

            console.log('shadeImages', shadeImages)

            if (shadeImages && shadeImages.length > 0) {
                const imgElement = shadeImages[0];
                const transform = imgElement.getAbsoluteTransform().copy();

                // to detect relative position we need to invert transform
                transform.invert();

                console.log(transform)

                let pos = stage.getPointerPosition();
                pos = transform.point(pos);

                console.log('shadeImages pos', pos)

                if (pos.x < 0 || pos.y < 0) {
                    this.hideShadeTransTransformer()
                    return;
                }

                if (
                    currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW 
                ) {
                    const shape = e.target;

                    if (shape instanceof Konva.Text) {
                        const k = shape.attrs.index;
                        if (k)
                            removeShadeLabel(k);
                    } else {
                        this.processShadeTranslucencyMapClick({ x: Math.round(pos.x) , y: Math.round(pos.y)  });
                    }
                    return
                }
            }
            
            if (images && images.length > 0) {
                const imgElement = images[0];
                const transform = imgElement.getAbsoluteTransform().copy();

                // to detect relative position we need to invert transform
                transform.invert();

                let pos = stage.getPointerPosition();
                pos = transform.point(pos);

                console.log('pos', pos)
                if (pos.x < 0 || pos.y < 0) {
                    return;
                }

                if (
                    currentPhoto.currentPhotoType === IMAGE_SHADE_MAP ||
                    currentPhoto.currentPhotoType === IMAGE_MODIFIER ||
                    currentPhoto.currentPhotoType === IMAGE_TRIPLE_VIEW
                ) {
                    const shape = e.target;

                    if (shape instanceof Konva.Text) {
                        const k = shape.attrs.index;
                        if (k)
                            removeShadeLabel(k);
                    } else {
                        this.processShadeMapClick({ x: Math.round(pos.x / ratio), y: Math.round(pos.y / ratio) });
                    }
                } else if (currentPhoto.currentPhotoType === IMAGE_ORIGINAL && currentPhotoProcessingStep > 0) {
                    const context = currentPhoto.imgOriginal.getContext("2d");
                    const data = photoUtils.getAverageColor(
                        context.getImageData(Math.round(pos.x / ratio) - 10, Math.round(pos.y / ratio) - 10, 20, 20)
                            .data
                    );
                    let coordinate = {
                        x: pos.x / ratio / image.width,
                        y: pos.y / ratio / image.height,
                        width: 20,
                        height: 20,
                    };
                    if (currentShadeReferenceAnalysis === "on" && currentPhotoProcessingStep < 4) {
                        const uColor = ((data[0] << 16) + (data[1] << 8) + data[2] + (data[3] << 24)) >>> 0;
                        let uRefColor = 0;
                        const uStudioColor = 0;
                        switch (currentPhotoProcessingStep) {
                            case 1:
                                uRefColor = parseInt("0xff" + shadeGuide.plateColors.plateNormWhite) >>> 0;
                                break;
                            case 2:
                                uRefColor = parseInt("0xff" + shadeGuide.plateColors.plateNormBlack) >>> 0;
                                break;
                            case 3:
                                uRefColor = parseInt("0xff" + shadeGuide.plateColors.plateNormGrey) >>> 0;
                                break;
                            case 4:
                                uRefColor = parseInt("0xff" + shadeGuide.plateColors.plateNormShade) >>> 0;
                                break;
                            default:
                                uRefColor = 0;
                        }
                        addShadeSelected(uColor, uRefColor, uStudioColor, coordinate);
                    } else {
                        this.setState({
                            viewShadeSelector: true,
                            selectedColor: data,
                            selectedCoordinate: coordinate,
                        });
                    }
                }
            }
            
        }
    };

    hideShadeSelector = () => {
        this.setState({ viewShadeSelector: false });
    };

    onShadeSelected = (color, refColor, studioColor, coordinate, shadeName) => {
        let {
            addShadeSelected,
            currentPhotoProcessingStep,
            currentShadeReferenceAnalysis,
            shadeGuide,
            setLeftRightShade,
        } = this.props;
        const uColor = ((color[0] << 16) + (color[1] << 8) + color[2] + (color[3] << 24)) >>> 0;
        const uRefColor = parseInt("0xff" + refColor.substring(1)) >>> 0;
        let uStudioColor = parseInt("0xff" + studioColor.substring(1)) >>> 0;

        const isLeftUsedShadeTab =
            (currentShadeReferenceAnalysis === "off" && currentPhotoProcessingStep === 1) ||
            (currentShadeReferenceAnalysis === "on" && currentPhotoProcessingStep === 4);
        const isRightUsedShadeTab = currentPhotoProcessingStep === 5;

        if (currentShadeReferenceAnalysis === "off" && currentPhotoProcessingStep === 1) {
            addShadeSelected(
                0,
                parseInt("0xff" + shadeGuide.plateColors.plateNormWhite) >>> 0,
                parseInt(shadeGuide.lifelinePlate.white) >>> 0,
                null
            );
            addShadeSelected(
                0,
                parseInt("0xff" + shadeGuide.plateColors.plateNormBlack) >>> 0,
                parseInt(shadeGuide.lifelinePlate.black) >>> 0,
                null
            );
            addShadeSelected(
                0,
                parseInt("0xff" + shadeGuide.plateColors.plateNormGrey) >>> 0,
                parseInt(shadeGuide.lifelinePlate.gray) >>> 0,
                null
            );
        }
        if (currentShadeReferenceAnalysis === "on") {
            uStudioColor = 0;
        }
        
        if (isLeftUsedShadeTab) {
            setLeftRightShade(true, shadeName);
        }
        if (isRightUsedShadeTab) {
            setLeftRightShade(false, shadeName);
        }
        addShadeSelected(uColor, uRefColor, uStudioColor, coordinate);
    };

    renderShadeSelector = () => {
        let { viewShadeSelector, selectedColor, selectedCoordinate } = this.state;
        let { shadeGuide, systemShadeColors } = this.props;

        let currentRefColors = systemShadeColors.concat(shadeGuide.color);

        return viewShadeSelector ? (
            <ShadeColorSelectorModal
                refColors={currentRefColors}
                color={selectedColor}
                coordinate={selectedCoordinate}
                hideWindow={this.hideShadeSelector}
                onShadeSelected={this.onShadeSelected}
            />
        ) : null;
    };

    cropHandler = () => {
        let { cropImage, isWorkAreaProgress, caseId } = this.props;
        let { cropX, cropY, cropWidth, cropHeight, photoId, imageWidth, imageHeight, cropRotate, cropScale } =
            this.state;

        if (isWorkAreaProgress) {
            return false;
        }

        cropImage(photoId, caseId, {
            x: cropX,
            y: cropY,
            width: cropWidth,
            height: cropHeight,
            imageWidth,
            imageHeight,
            rotate: cropRotate,
            scale: cropScale,
        });
    };
    renderCropControl = () => {
        let { isCrop } = this.props;

        if (!isCrop) {
            return false;
        }

        return (
            <div className={"cropper-control"}>
                <div className={"cropper-control-inner"}>
                    <GreenButton text={"Crop Area"} onClick={this.cropHandler} />
                </div>
            </div>
        );
    };

    onCrop = (event) => {
        const details = event.detail;

        this.setState({
            cropX: details.x,
            cropY: details.y,
            cropWidth: details.width,
            cropHeight: details.height,
            cropRotate: details.rotate,
            cropScale: details.scaleX,
            ...scaleDefaults,
        });
    };

    renderCropper = () => {
        let { currentPhoto, isCrop, rotateDegrees } = this.props;

        if (!isCrop) {
            return false;
        }

        let { containerHeight, containerWidth, imageWidth, imageHeight } = this.state;
        let photoUrl = currentPhoto.url.includes(process.env.REACT_APP_API_URL)
            ? currentPhoto.url
            : process.env.REACT_APP_API_URL + currentPhoto.url;
        return (
            <Cropper
                onCrop={this.onCrop}
                onCropAction={this.cropHandler}
                rotateDegrees={rotateDegrees}
                containerWidth={containerWidth}
                containerHeight={containerHeight}
                height={imageHeight}
                width={imageWidth}
                src={photoUrl}
            />
        );
    };

    onDragTransformerStart = (e) => {
        let fx = this.rectangle.x();
        let fy = this.rectangle.y();
        let sx = this.rectangleSecond.x();
        let sy = this.rectangleSecond.y();
        this.clientX = sx - fx;
        this.clientY = sy - fy;
    };

    moveSecondTransformerInsideFirst = (e) => {
        if (this.rectangle.rotation() !== 0) {
            this.rectangle.rotation(0);
        }

        let fx = this.rectangle.x();
        let fy = this.rectangle.y();
        let fw = this.rectangle.width() * this.rectangle.scaleX();
        let fh = this.rectangle.height() * this.rectangle.scaleY();

        let sx = this.rectangleSecond.x();
        let sy = this.rectangleSecond.y();
        let sw = this.rectangleSecond.width() * this.rectangleSecond.scaleX();
        let sh = this.rectangleSecond.height() * this.rectangleSecond.scaleY();

        if (e.type === "dragmove") {
            sx = this.clientX + fx;
            sy = this.clientY + fy;
            this.rectangleSecond.x(sx);
            this.rectangleSecond.y(sy);
            
            return;
        }

        if (e.type === "dragend") {
            sx = this.clientX + fx;
            sy = this.clientY + fy;
            this.rectangleSecond.x(sx);
            this.rectangleSecond.y(sy);
           
            return;
        }

        if (fw < 100) {
            let dx = 100 - fw;
            fw = 100;
            if (e.evt.movementX < 0) {
                fx -= dx;
                this.rectangle.x(fx);
            }
            this.rectangle.scaleX(fw / this.rectangle.width());
            
        }

        if (fh < 100) {
            let dy = 100 - fh;
            fh = 100;
            if (e.evt.movementY < 0) {
                fy -= dy;
                this.rectangle.y(fy);
            }
            this.rectangle.scaleY(fh / this.rectangle.height());
            
        }

        if (sx + sw + BORDER > fx + fw) sx = fx + fw - BORDER - sw;
        if (fx + BORDER > sx) sx = fx + BORDER;
        if (sy + sh + BORDER > fy + fh) sy = fy + fh - sh - BORDER;
        if (fy + BORDER > sy) sy = fy + BORDER;
        if (sx + sw + BORDER > fx + fw) sw = fx + fw - sx - BORDER;
        
        if (sw < 40) {
            sw = 40;
            sx = fx + fw - sw;
        }
        
        if (sy + sh + BORDER > fy + fh) sh = fy + fh - sy - BORDER;
        
        if (sh < 40) {
            sh = 40;
            sy = fy + fh - sh;
        }

        this.rectangleSecond.x(sx);
        this.rectangleSecond.y(sy);
        this.rectangleSecond.scaleX(sw / this.rectangleSecond.width());
        this.rectangleSecond.scaleY(sh / this.rectangleSecond.height());
    };

    onTransformRegion = (e) => {
        this.moveSecondTransformerInsideFirst(e);
    };

    onTransformSecondRegion = (e) => {
        let sw = this.rectangleSecond.width() * this.rectangleSecond.scaleX();
        let sh = this.rectangleSecond.height() * this.rectangleSecond.scaleY();

        if (sw < 40) {
            sw = 40;
            this.rectangleSecond.scaleX(sw / this.rectangleSecond.width());
        }

        if (sh < 40) {
            sh = 40;
            this.rectangleSecond.scaleY(sh / this.rectangleSecond.height());
        }

        let bound = this.rectangle.absolutePosition();
        let bound2 = this.rectangleSecond.absolutePosition();
        let w = this.rectangle.width() * this.rectangle.scaleX();
        let h = this.rectangle.height() * this.rectangle.scaleY();
        let w2 = this.rectangleSecond.width() * this.rectangleSecond.scaleX();
        let h2 = this.rectangleSecond.height() * this.rectangleSecond.scaleY();

        if (bound.x + BORDER > bound2.x) {
            this.rectangleSecond.x(this.rectangleSecond.x() + bound.x + BORDER - bound2.x);
        }
        if (bound.y + BORDER > bound2.y) {
            this.rectangleSecond.y(this.rectangleSecond.y() + bound.y + BORDER - bound2.y);
        }
        if (bound.x + w - BORDER < bound2.x + w2) {
            this.rectangleSecond.x(this.rectangleSecond.x() + bound.x + w - bound2.x - w2 - BORDER);
        }
        if (bound.y + h - BORDER < bound2.y + h2) {
            this.rectangleSecond.y(this.rectangleSecond.y() + bound.y + h - bound2.y - h2 - BORDER);
        }
    };

    onValueRegionClick = (e) => {
        e.cancelBubble = true;

        this.updateRegionValue();
    };

    renderShadeTranslucency = () => {
        let { imageWidth, imageHeight, imageX, imageY, drawMode, ratio } = this.state;
        const { currentPhoto, shadeTransPos } = this.props;
        const filters = {
            brightness : 0,
            contrast : 0,
            isGrayScale : false,
        };
        if (currentPhoto.currentPhotoType !== IMAGE_DOUBLE_VIEW)
            return null;

        if (!shadeTransPos  ) 
            return null;

        if (!this.mainImage || !this.mainImage.imageRef)
        return null;

        let shadeMapPos = shadeTransPos
        console.log(shadeMapPos)
        /*let shadeTransX = shadeMapPos.shadeTransX * this.mainImage.imageRef.width() + this.mainImage.imageRef.x() - currentPhoto.imgDouble.width / 2
        let shadeTransY = shadeMapPos.shadeTransY * this.mainImage.imageRef.height() + this.mainImage.imageRef.y() - currentPhoto.imgDouble.height / 2
        let ratioShadeTrans = shadeMapPos.ratioShadeTrans * ratio
        console.log(shadeTransX, shadeTransY, ratioShadeTrans)
        console.log(this.mainImage.imageRef.x(), this.mainImage.imageRef.width(), this.mainImage.imageRef.scaleX(), this.mainImage.imageRef.offsetX())
        console.log(this.mainImage.x, this.mainImage.width)*/

        let ratioShadeTrans = shadeMapPos.ratioShadeTrans * ratio
        let calculatedPos = this.getShadeTransCoordinates(shadeMapPos)
        let shadeTransX = calculatedPos.shadeTransX - (currentPhoto.imgDouble.width / 2) 
        let shadeTransY = calculatedPos.shadeTransY - (currentPhoto.imgDouble.height / 2) 
        console.log(shadeTransX, shadeTransY, ratioShadeTrans, currentPhoto.currentPhotoType)
        
        return currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW ?
         <Image 
            ref={(node) => {
                this.imageDouble = node;
                }
            }
            image={currentPhoto.imgDouble}
            x={shadeTransX}
            y={shadeTransY}
            height={currentPhoto.imgDouble.height}
            width={currentPhoto.imgDouble.width}
            imageType={IMAGE_DOUBLE_VIEW}
            draggable={true}
            scaleX={ratioShadeTrans}
            scaleY={ratioShadeTrans}
            rotation={0}
            //visible={currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW}
            {...filters}
            dragBoundFunc={this.dragBoundShadeTransFunc}
            onTransform={this.onTransformShadeTranslucency}
            onDragMove={this.onTransformShadeTranslucency}
            onDragEnd={this.onTransformShadeTranslucency}
            onTransformEnd={this.onTransformShadeTranslucency}
        /> : null;
    }

    renderShadeTranslucencyTransformer = () => {
        const {currentPhoto} = this.props;
        
        return (
            <Transformer
                ref={(node) => {
                    this.shadeTransformer = node;
                }}
                rotateEnabled={false}
                resizeEnabled={true}
                keepRatio={true}
                centeredScaling={true}
                flipEnabled={false}
                enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
            />
        );
    }

    renderTransformerRect = () => {
        let { imageWidth, imageHeight, imageX, imageY, drawMode } = this.state;
        let { isRegionActive } = this.props;

        return (
            <Rect
                ref={(node) => {
                    this.rectangle = node;
                }}
                name={"rectangle-region"}
                x={imageX + imageWidth / 2 - 60}
                y={imageY + imageHeight / 2 - 60}
                height={120}
                width={120}
                stroke={"red"}
                strokeEnabled={false}
                fillEnabled={true}
                fill={"lightblue"}
                opacity={0.5}
                draggable={drawMode == NO_DRAW}
                onTransform={this.onTransformRegion}
                visible={isRegionActive}
                onDragMove={this.onTransformRegion}
                onDragEnd={this.onTransformRegion}
                onDragStart={this.onDragTransformerStart}
                onTouchStart={this.onDragTransformerStart}

                //rotation={-rotateDegrees}
                //offset={{x : imageWidth/2, y : imageHeight/2}}
            />
        );
    };

    renderSecondTransformerRect = () => {
        let { imageWidth, imageHeight, imageX, imageY, drawMode } = this.state;
        let { isRegionActive } = this.props;

        return (
            <Rect
                ref={(node) => {
                    this.rectangleSecond = node;
                }}
                name={"rectangle-second-region"}
                x={imageX + imageWidth / 2 - 25}
                y={imageY + imageHeight / 2 - 25}
                height={50}
                width={50}
                stroke={"red"}
                strokeEnabled={false}
                fillEnabled={true}
                fill={"lime"}
                opacity={0.5}
                draggable={drawMode == NO_DRAW}
                onTransform={this.onTransformSecondRegion}
                visible={isRegionActive}
                dragBoundFunc={this.dragBoundSecondTransformer}
                onDragEnd={this.onTransformSecondRegion}
                onDragMove={this.onTransformSecondRegion}
                //onTouchMove={this.onTransformSecondRegion}
                //onTouchEnd={this.onTransformSecondRegion}
                onClick={this.onValueRegionClick}
                onTap={this.onValueRegionClick}
                //rotation={-rotateDegrees}
                //offset={{x : imageWidth/2, y : imageHeight/2}}
            />
        );
    };

    renderTransformer = () => {
        return (
            <Transformer
                ref={(node) => {
                    this.transformer = node;
                }}
                rotateEnabled={false}
                resizeEnabled={true}
                keepRatio={false}
            />
        );
    };

    renderSecondTransformer = () => {
        return (
            <Transformer
                ref={(node) => {
                    this.transformerSecond = node;
                }}
                rotateEnabled={false}
                resizeEnabled={true}
                keepRatio={false}
                boundBoxFunc={this.boundBoxSecondTransformer}
            />
        );
    };

    boundBoxSecondTransformer = (oldBox, newBox) => {
        let bound = this.rectangle.absolutePosition();

        let boundX = bound.x + BORDER;
        let boundY = bound.y + BORDER;
        let boundRight = bound.x + this.rectangle.width() * this.rectangle.scaleX() - BORDER;
        let boundBottom = bound.y + this.rectangle.height() * this.rectangle.scaleY() - BORDER;
        if (boundX > newBox.x) newBox.x = boundX;
        if (boundY > newBox.y) newBox.y = boundY;
        if (newBox.x + newBox.width > boundRight) newBox.width = boundRight - newBox.x;
        if (newBox.y + newBox.height > boundBottom) newBox.height = boundBottom - newBox.y;

        if (newBox.width < 1) {
            newBox.width = 1;
        }
        if (newBox.height < 1) {
            newBox.height = 1;
        }

        return newBox;
    };

    dragBoundSecondTransformer = (pos) => {
        const bound = this.rectangle.absolutePosition();
        const boundX = bound.x + BORDER;
        const boundY = bound.y + BORDER;
        const boundRight = bound.x + this.rectangle.width() * this.rectangle.scaleX() - BORDER;
        const boundBottom = bound.y + this.rectangle.height() * this.rectangle.scaleY() - BORDER;
        let pos2 = { x: pos.x, y: pos.y };
        if (boundX > pos2.x) pos2.x = boundX;
        if (boundY > pos2.y) pos2.y = boundY;
        const rectWidth = this.rectangleSecond.width() * this.rectangleSecond.scaleX();
        const rectHeight = this.rectangleSecond.height() * this.rectangleSecond.scaleY();
        if (pos2.x + rectWidth > boundRight) pos2.x = boundRight - rectWidth;
        if (pos2.y + rectHeight > boundBottom) pos2.y = boundBottom - rectHeight;

        return { x: pos2.x, y: pos2.y };
    };

    onTransformShadeTranslucency = (e) => {
        const { updateShadeTransPosition } = this.props;
        const { ratio } = this.state;

        let sw = this.imageDouble.imageRef.width() * this.imageDouble.imageRef.scaleX();
        let sh = this.imageDouble.imageRef.height() * this.imageDouble.imageRef.scaleY();

        if (sw < 100) {
            sw = 100;
            this.imageDouble.imageRef.scaleX(sw / this.imageDouble.imageRef.width());
            this.imageDouble.imageRef.scaleY(this.imageDouble.imageRef.scaleX())
        }

        if (sh < 100) {
            sh = 100;
            this.imageDouble.imageRef.scaleY(sh / this.imageDouble.imageRef.height());
            this.imageDouble.imageRef.scaleX(this.imageDouble.imageRef.scaleY())
        }
        /*console.log(e)
        let boundMainPos = this.mainImage.imageRef.absolutePosition();
        let imageOffsetX = this.mainImage.imageRef.offsetX() * this.mainImage.imageRef.scaleX();
        let imageOffsetY = this.mainImage.imageRef.offsetY() * this.mainImage.imageRef.scaleY();
        let imageX = boundMainPos.x - imageOffsetX;
        let imageY = boundMainPos.y - imageOffsetY;
        let imageWidth = this.mainImage.imageRef.width() * this.mainImage.imageRef.scaleX()
        let imageHeight = this.mainImage.imageRef.height() * this.mainImage.imageRef.scaleY()
        
        let thisImage = this.imageDouble.imageRef
        let thisPos = thisImage.absolutePosition();
        let thisWidth = thisImage.width() * thisImage.scaleX()
        let thisHeight = thisImage.height() * thisImage.scaleY()
        let thisOffsetX = thisImage.offsetX() * thisImage.scaleX()
        let thisOffsetY = thisImage.offsetY() * thisImage.scaleY()
        let thisX = thisPos.x - thisOffsetX;
        let thisY = thisPos.y - thisOffsetY;

        let changed = true
        let counter = 0

        while (changed && counter < 2) {
            changed = false
            counter++
            if (thisX < imageX) {            
                thisImage.x( imageX - thisX + thisImage.x() )
        //        thisImage.scaleX(thisImage.scaleX() * ((thisWidth - imageX + thisX)/thisWidth))
        //        thisImage.scaleY(thisImage.scaleX())
                
                //thisX = thisImage.absolutePosition().x - thisOffsetX
                changed = true
            }
            if (thisY < imageY) {
                thisImage.y( imageY - thisY + thisImage.y())
                //thisImage.scaleY(thisImage.scaleY() * (thisHeight / (thisHeight + imageY - thisY)))
                //thisImage.scaleX(thisImage.scaleY())
                //thisY = thisImage.absolutePosition().y - thisOffsetY
                changed = true
            }
            if ((thisX + thisWidth) > (imageX + imageWidth)) {
                thisImage.x(thisImage.x() + (imageX + imageWidth) - (thisX + thisWidth) )
                //thisImage.scaleX(thisImage.scaleX() * (thisWidth / (thisWidth + (thisX + thisWidth) - (imageX + imageWidth))))
                //thisImage.scaleY(thisImage.scaleX())
                //thisX = thisImage.absolutePosition().x - thisOffsetX
                changed = true
            } 
            if ((thisY + thisHeight) > (imageY + imageHeight)) {
                thisImage.y(thisImage.y() + (imageY + imageHeight) - (thisY + thisHeight) )
                //thisImage.scaleY(thisImage.scaleY() * (thisHeight / (thisHeight + (thisY + thisHeight) - (imageY + imageHeight))))
                //thisImage.scaleX(thisImage.scaleY())
                //thisY = thisImage.absolutePosition().x - thisOffsetX
                changed = true
            }

            if (thisImage.width() * thisImage.scaleX() > imageWidth ) {
                thisImage.scaleX(imageWidth / thisImage.width())
                thisImage.scaleY(thisImage.scaleX())
                changed = true
            }

            if (thisImage.height() * thisImage.scaleY() > imageHeight ) {
                thisImage.scaleY(imageHeight / thisImage.height())
                thisImage.scaleX(thisImage.scaleY())
                changed = true
            }
            thisX = thisImage.absolutePosition().x - thisOffsetX
            thisY = thisImage.absolutePosition().y - thisOffsetY

            thisWidth = thisImage.width() * thisImage.scaleX()
            thisHeight = thisImage.height() * thisImage.scaleY()
            thisOffsetX = thisImage.offsetX() * thisImage.scaleX()
            thisOffsetY = thisImage.offsetY() * thisImage.scaleY()

        }*/

        if (e.type == "dragend" || e.type == "transformend") {
            /*updateShadeTransPosition({
                ratioShadeTrans: thisImage.scaleX() / ratio,
                shadeTransX: (thisImage.x() - imageX) / imageWidth,
                shadeTransY: (thisImage.y() - imageY) / imageHeight
            })*/
            this.updateShadeTransToImageCoordinates()
            this.setState({
                shadeTransLabelsShow: true
            })
        }
        else {
            this.setState({
                shadeTransLabelsShow: false
            })
        }
        
    }

    dragBoundShadeTransFunc = (pos) => {
        return pos;
        let boundMainPos = this.mainImage.imageRef.absolutePosition();
        let imageX = boundMainPos.x - this.mainImage.imageRef.offsetX() * this.mainImage.imageRef.scaleX();
        let imageY = boundMainPos.y - this.mainImage.imageRef.offsetY() * this.mainImage.imageRef.scaleY();
        let imageWidth = this.mainImage.imageRef.width() * this.mainImage.imageRef.scaleX()
        let imageHeight = this.mainImage.imageRef.height() * this.mainImage.imageRef.scaleY()
        let thisImage = this.imageDouble.imageRef
        let thisWidth = thisImage.width() * thisImage.scaleX()
        let thisHeight = thisImage.height() * thisImage.scaleY()
        let thisOffsetX = thisImage.offsetX() * thisImage.scaleX()
        let thisOffsetY = thisImage.offsetY() * thisImage.scaleY()
        var pos2 = {
            x:pos.x - thisOffsetX, 
            y:pos.y - thisOffsetY
        };
        if (pos2.x < imageX) pos2.x = imageX
        if (pos2.y < imageY) pos2.y = imageY
        if (pos2.x + thisWidth > imageX + imageWidth) {
            pos2.x = imageX + imageWidth - thisWidth;
        }
        if (pos2.y + thisHeight > imageY + imageHeight) {
            pos2.y = imageY + imageHeight - thisHeight;
        }
        
        pos2 = {x: pos2.x + thisOffsetX, y: pos2.y + thisOffsetY}
        
        return pos2;
    }

    onContextMenu = (e) => {
        e.evt.preventDefault();
        console.log(e)
        if (e.target === this.stage) {
            // if we are on empty place of the stage we will do nothing\
            console.log("if we are on empty place of the stage we will do nothing");
            return;
        }

        if (e.target?.attrs?.id?.startsWith('ga')) {
            this.onOpenGraphEdit(e, e.target?.attrs?.id)
            return
        }

        let coordBase = e.evt;
        if (e.type === "touchstart") {
            if (e.evt.targetTouches.length === 1) {
                coordBase = e.evt.targetTouches.item(0);
            }
        }
        this.setState({
            isShowContextMenu: true,
            contextMenuX: coordBase.clientX,
            contextMenuY: coordBase.clientY,
        });
    };

    handleContextClose = () => {
        this.setState({
            isShowContextMenu: false,
        });
    };

    handleColorCorrect = () => {
        let { runColorCorrect } = this.props;
        this.setState({
            isShowContextMenu: false,
        });
        runColorCorrect();
    };

    handleExport = () => {
        let { ratio, containerHeight, containerWidth } = this.state;

        if (this.shadeTransformer && this.state.shadeTransformerInitialized) {
            this.shadeTransformer.detach();
        }
        this.setState({
            isShowContextMenu: false,
            printing: true, 
            shadeTransformerInitialized: false
        }, () => {
            const canvas = this.stage.toCanvas({
                x: 0, y: 0, width: containerWidth, height: containerHeight
            });
            const bounds = photoUtils.getBorders(canvas);
            const url = this.stage.toDataURL({
                pixelRatio: 1 / ratio,
                x: bounds.xMin,
                y: bounds.yMin,
                width: bounds.xMax - bounds.xMin,
                height: bounds.yMax - bounds.yMin,
                mimeType: "image/jpeg",
            });
    
            let link = document.createElement("a");
            link.download = "export.jpg";
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        this.setState({
            printing: false
        })

        });

        
    };

    handleCropRegion = () => {
        const {currentPhoto, processRegion} = this.props;
        const { imageX, imageY, ratio } = this.state;
        this.setState({
            isShowContextMenu: false,
        });

        let x = this.rectangle.x() - imageX;
        let y = this.rectangle.y() - imageY;
        let w = this.rectangle.width() * this.rectangle.scaleX();
        let h = this.rectangle.height() * this.rectangle.scaleY();

        let x2 = this.rectangleSecond.x() - imageX;
        let y2 = this.rectangleSecond.y() - imageY;
        let w2 = this.rectangleSecond.width() * this.rectangleSecond.scaleX();
        let h2 = this.rectangleSecond.height() * this.rectangleSecond.scaleY();

        processRegion(
            {
                x: Math.round(x / ratio),
                y: Math.round(y / ratio),
                width: Math.round(w / ratio),
                height: Math.round(h / ratio),
            },
            {
                x: Math.round(x2 / ratio),
                y: Math.round(y2 / ratio),
                width: Math.round(w2 / ratio),
                height: Math.round(h2 / ratio),
            },
            currentPhoto?.metadata?.isTeethSegment ?? false,
            currentPhoto?.metadata?.teethMaskThreshold ?? 0.5
        );
    };

    handleSaveServer = () => {
        let { ratio, photoId, containerHeight, containerWidth } = this.state;
        let { saveImageToServer, currentPhoto } = this.props;

        let canvas = this.stage.toCanvas({
            x: 0, y: 0, width: containerWidth, height: containerHeight
        });
        let bounds = photoUtils.getBorders(canvas);
        let url = this.stage.toDataURL({
            pixelRatio: 1 / ratio,
            x: bounds.xMin,
            y: bounds.yMin,
            width: bounds.xMax - bounds.xMin,
            height: bounds.yMax - bounds.yMin,
        });
        this.setState({ dataUrl: url, isShowContextMenu: false }, () => {
            console.log("imageWidth", containerWidth, "imageHeight", containerHeight);
            html2canvas(this.printRef, {
                windowWidth: containerWidth + 40,
                windowHeight: containerHeight + 200,
                width: containerWidth + 40,
                height: containerHeight + 200,
            }).then((canvas) => {
                let cnv = document.createElement("canvas");
                let context = cnv.getContext("2d");

                cnv.width = canvas.width + 40;
                cnv.height = canvas.height + 40;
                context.fillStyle = "white";
                context.fillRect(0, 0, cnv.width, cnv.height);
                context.drawImage(canvas, 20, 20);
                saveImageToServer(
                    cnv,
                    photoId,
                    currentPhoto.currentPhotoType === IMAGE_CORRECTED ? "corrected" : "map"
                );
            });
        });
    };

    handleBeforePrint = () => {
        let { ratio, containerHeight, containerWidth } = this.state;
        console.log(containerWidth, containerHeight)
        
        return new Promise((resolve, reject) => {
            if (this.shadeTransformer && this.state.shadeTransformerInitialized) {
                this.shadeTransformer.detach()
            }
            let canvas = this.stage.toCanvas({
                x: 0, y: 0, width: containerWidth, height: containerHeight
            });
            let bounds = photoUtils.getBorders(canvas);
            this.setState({printing: true, shadeTransformerInitialized: false}, () => {
                let url = this.stage.toDataURL({
                    pixelRatio: 1 / ratio,
                    x: bounds.xMin,
                    y: bounds.yMin,
                    width: bounds.xMax - bounds.xMin,
                    height: bounds.yMax - bounds.yMin,
                });
                this.setState({ dataUrl: url, isShowContextMenu: false }, () => resolve());
            })
            
        });
    };

    handlePrintAfter = () => {this.setState({printing: false})};

    onCloseConfirmation = () => {
        this.setState({
            isShowAfterPrintConfirm: false,
        });
    };

    sendFinalImage = () => {
        let { imageHeight, imageWidth, photoId } = this.state;
        let { saveImageToServer, currentPhoto } = this.props;
        this.setState({
            isShowAfterPrintConfirm: false,
        });

        html2canvas(this.printRef, {
            windowWidth: imageWidth + 40,
            windowHeight: imageHeight + 160,
            width: imageWidth + 40,
            height: imageHeight + 160,
        }).then((canvas) => {
            let cnv = document.createElement("canvas");
            let context = cnv.getContext("2d");

            cnv.width = canvas.width + 40;
            cnv.height = canvas.height + 40;
            context.fillStyle = "white";
            context.fillRect(0, 0, cnv.width, cnv.height);
            context.drawImage(canvas, 20, 20);
            saveImageToServer(cnv, photoId, currentPhoto.currentPhotoType === IMAGE_CORRECTED ? "corrected" : "map");
        });
    };

    handleShowHideXmarks = () => {
        let { setHideXMarks, currentPhoto } = this.props;

        setHideXMarks(!currentPhoto.hideXmarks);
        this.setState({ isShowContextMenu: false });
    };

    handleShowHideShadeTabs = () => {
        let { setShowShadeTabs, currentPhoto, shadeTabCoords, updateShadeTabCoords } = this.props;

        let pos = this.convertImageToLayerPosition({x: 0, y: 0 })
        if (!currentPhoto.showShadeTabs && shadeTabCoords.left.x == -1 && shadeTabCoords.right.y == -1 ) {
            let pos1 = { x : 50  + ((pos.x > 0) ? pos.x : 0), y: 50 + ((pos.y > 0) ? pos.y : 0) };
            pos1 = this.convertGlobalToImagePosition(pos1);
            let pos2 = { x : 150  + ((pos.x > 0) ? pos.x : 0), y: 50 + ((pos.y > 0) ? pos.y : 0) };
            pos2 = this.convertGlobalToImagePosition(pos2);
            updateShadeTabCoords(currentPhoto.id, {
                left: {x : pos1.x, y: pos1.y, ver: '1.1'},
                right: {x : pos2.x, y: pos2.y, ver: '1.1'}
            })
        }
        

        setShowShadeTabs(!currentPhoto.showShadeTabs);
        this.setState({ isShowContextMenu: false });
    };

    handleShowHideTextAnnotations = () => {
        let { setShowTextAnnotations, currentPhoto } = this.props;

        setShowTextAnnotations(!currentPhoto.showTextAnnotations);
        this.setState({ isShowContextMenu: false });
    };

    handleShowHideGraphicsAnnotations = () => {
        let { setShowGraphicsAnnotations, currentPhoto } = this.props;

        setShowGraphicsAnnotations(!currentPhoto.showGraphicsAnnotations);
        this.setState({ isShowContextMenu: false });
    };

    handleDeleteAllAnnotations = () => {
        const {updateTextAnnotations, currentPhoto, updateGraphicAnnotations} = this.props;
        const {graphicObjects} = this.state;
        updateTextAnnotations(
            currentPhoto.id,
            []
        );
        updateGraphicAnnotations(
            currentPhoto.id,
            []
        );
        this.setState({ isShowContextMenu: false });
    };

    handleCrosshairOnOff = () => {
        let { setShowCrossHair, currentPhoto } = this.props;

        let showCrossHair = !currentPhoto.showCrossHair; 
        setShowCrossHair(showCrossHair);
        if (!showCrossHair) {
            this.setState({
                isShowContextMenu: false,
                crossHairSettingMode: false
            })
        }
        else
            this.setState({ isShowContextMenu: false });
    };

    handleUndo = () => {
        let { undoSavedPhoto, currentPhoto } = this.props;

        undoSavedPhoto(currentPhoto.id);
    }

    handleRedo = () => {
        let { redoSavedPhoto, currentPhoto } = this.props;

        redoSavedPhoto(currentPhoto.id);
    }

    renderContextMenu = () => {
        let { isShowContextMenu, contextMenuX, contextMenuY, ratio, crossHairSettingMode } = this.state;
        let { isColorCorrectionAllowed, isRegionActive, currentPhoto, shadeMapLabels, modifierLabels, currentCase, textAnnotations, graphicObjects, undoAvailable, redoAvailable } =
            this.props;

        let xmarksAvailable =
            (currentPhoto.currentPhotoType === IMAGE_CORRECTED || currentPhoto.currentPhotoType === IMAGE_ORIGINAL) &&
            currentPhoto.xmarks.length > 0 &&
            ratio !== null;
        let shadeLabelsExists =
            (currentPhoto.currentPhotoType === IMAGE_SHADE_MAP && shadeMapLabels?.length > 0) ||
            (currentPhoto.currentPhotoType === IMAGE_MODIFIER && modifierLabels?.length > 0) ||
            ((currentPhoto.currentPhotoType === IMAGE_TRIPLE_VIEW || currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW) &&
                (shadeMapLabels?.length > 0 || modifierLabels?.length > 0));
        let shadeTabLabelsAvailable =
            currentPhoto.currentPhotoType === IMAGE_CORRECTED || currentPhoto.currentPhotoType === IMAGE_ORIGINAL ;
        
        let annotationsExists = (shadeTabLabelsAvailable || currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW) && (textAnnotations.length > 0 || graphicObjects.length > 0)

        return (
            <Menu
                keepMounted
                open={isShowContextMenu}
                onClose={this.handleContextClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenuY !== null && contextMenuX !== null
                        ? { top: contextMenuY, left: contextMenuX }
                        : undefined
                }
                classes={{ paper: "context-menu", list: "context-menu" }}
            >
                {(undoAvailable != '') && <MenuItem onClick={this.handleUndo}>Undo {undoAvailable}</MenuItem>}
                {(redoAvailable != '') && <MenuItem onClick={this.handleRedo}>Redo {redoAvailable}</MenuItem>}
                {isColorCorrectionAllowed && <MenuItem onClick={this.handleColorCorrect}>Color Correct</MenuItem>}
                {isRegionActive && <MenuItem onClick={this.handleCropRegion}>Crop Region</MenuItem>}
                <MenuItem onClick={this.handleExport}>Export</MenuItem>
                {currentCase.magictouch_connected && currentPhoto.currentPhotoType !== IMAGE_ORIGINAL && (
                    <MenuItem onClick={this.handleSaveServer}>Save to MagicTouch</MenuItem>
                )}
                <ReactToPrint
                    content={() => this.printRef}
                    trigger={() => {
                        return <MenuItem>Print</MenuItem>;
                    }}
                    copyStyles={true}
                    onBeforeGetContent={this.handleBeforePrint}
                    onAfterPrint={this.handlePrintAfter}
                    removeAfterPrint={true}
                />

                {xmarksAvailable && (
                    <MenuItem onClick={this.handleShowHideXmarks}>
                        {currentPhoto.hideXmarks ? "Show " : "Hide "} reference points
                    </MenuItem>
                )}

                {shadeTabLabelsAvailable && (
                    <MenuItem onClick={this.handleShowHideShadeTabs}>
                        {currentPhoto.showShadeTabs ? "Hide " : "Show "} shade tabs labels
                    </MenuItem>
                )}

                {(shadeTabLabelsAvailable || currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW) && (
                    <MenuItem onClick={this.handleShowHideTextAnnotations}>
                        {currentPhoto.showTextAnnotations ? "Hide " : "Show "} text annotations
                    </MenuItem>
                )}

                {(shadeTabLabelsAvailable || currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW) && (
                    <MenuItem
                        onClick={(e) => {
                            this.setState({
                                anchorAddTextEl: e.target,
                                contextMenuX: e.clientX,
                                contextMenuY: e.clientY,
                                isShowContextMenu: false,
                                currentAnnotationText: "",
                            });
                        }}
                    >
                        Add Text Annotation
                    </MenuItem>
                )}

                {(shadeTabLabelsAvailable || currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW) && (
                    <MenuItem onClick={this.handleShowHideGraphicsAnnotations}>
                        {currentPhoto.showGraphicsAnnotations ? "Hide " : "Show "} graphics annotations
                    </MenuItem>
                )}

                {(shadeTabLabelsAvailable || currentPhoto.currentPhotoType === IMAGE_DOUBLE_VIEW) && (
                    <MenuItem
                        onClick={(e) => {
                            this.setState({
                                anchorAddGraphicEl: e.target,
                                contextMenuX: e.clientX,
                                contextMenuY: e.clientY,
                                isShowContextMenu: false,
                            });
                        }}
                    >
                        Add Graphic Annotation
                    </MenuItem>
                )}

                {annotationsExists && (
                    <MenuItem onClick={this.handleDeleteAllAnnotations}>
                        Delete All annotations
                    </MenuItem>
                )}

                {shadeLabelsExists && <MenuItem onClick={this.handleClearShadeLabels}>Clear Labels</MenuItem>}
                {shadeTabLabelsAvailable &&
                    <MenuItem onClick={this.handleCrosshairOnOff}>
                        {currentPhoto.showCrossHair ? "Hide" : "Show"} Crosshair
                    </MenuItem>
                }
                {!crossHairSettingMode && currentPhoto.showCrossHair && (
                    <MenuItem onClick={this.handleCrossHairMoveStart}>Move Crosshair</MenuItem>
                )}
            </Menu>
        );
    };

    handlePopoverClose = (elementIndex) => {
        this.setState({
            anchorEl: null,
            currentAnnotationText: "",
        });
    };

    handlePopoverTextDone = (elementIndex) => {
        this.props.updateTextAnnotations(
            this.props.currentPhoto.id,
            this.props.textAnnotations.map((item) => {
                if (item.uid == elementIndex) {
                    //let w = this.textAnnotEl[item.uid].label.getWidth()
                    //let h = this.textAnnotEl[item.uid].label.getHeight()
                    return { ...item, text: this.state.currentAnnotationText /*, width: w / this.state.ratio, height: h / this.state.ratio*/  };
                } else {
                    return item;
                }
            })
        );
    };

    handlePopoverTextDelete = (elementIndex) => {
        this.props.updateTextAnnotations(
            this.props.currentPhoto.id,
            this.props.textAnnotations.filter((item) => {
                return item.uid !== elementIndex;
            })
        );
        this.setState({
            anchorEl: null,
            currentAnnotationText: "",
        });
    };

    handleTextAnnotationColor = (color, elementIndex) => {
        const { currentPhoto } = this.props;
        if (elementIndex == "st01" || elementIndex == "st02") {
            let settings =
                elementIndex == "st01"
                    ? {
                          ...this.props.shadeTabSettings,
                          ...{ left: { ...this.props.shadeTabSettings.left, color: color } },
                      }
                    : {
                          ...this.props.shadeTabSettings,
                          ...{ right: { ...this.props.shadeTabSettings.right, color: color } },
                      };
            this.props.updateShadeTabSettings(currentPhoto.id, settings);
        } else {
            this.props.updateTextAnnotations(
                this.props.currentPhoto.id,
                this.props.textAnnotations.map((item) => {
                    if (item.uid == elementIndex) {
                        return { ...item, color: color };
                    } else {
                        return item;
                    }
                })
            );
        }
    };

    handleOnTextAnnotationFontSliderChange = (value, elementIndex) => {
        const { currentPhoto } = this.props;
        if (elementIndex == "st01" || elementIndex == "st02") {
            let settings =
                elementIndex == "st01"
                    ? {
                          ...this.props.shadeTabSettings,
                          ...{ left: { ...this.props.shadeTabSettings.left, fontSize: value } },
                      }
                    : {
                          ...this.props.shadeTabSettings,
                          ...{ right: { ...this.props.shadeTabSettings.right, fontSize: value } },
                      };
            this.props.updateShadeTabSettings(currentPhoto.id, settings);
        } else {
            this.props.updateTextAnnotations(
                this.props.currentPhoto.id,
                this.props.textAnnotations.map((item) => {
                    if (item.uid == elementIndex) {
                        //let w = this.textAnnotEl[item.uid].label.getWidth()
                        //let h = this.textAnnotEl[item.uid].label.getHeight()
                        return { ...item, fontSize: value /*, width: w / this.state.ratio, height: h / this.state.ratio*/ };
                    } else {
                        return item;
                    }
                })
            );
        }
    };

    addTextAnnotation = () => {
        let { textAnnotations, currentPhoto } = this.props;
        const { imageX, imageY } = this.state;

        if (this.state.currentAnnotationText == null || this.state.currentAnnotationText.length == 0) {
            return;
        }

        let uidValue = uuidv4();
        //console.log({x: (stageX + 50), y: (stageY + 50)})
        let pos = this.convertImageToLayerPosition({x: 0, y: 0 })
        pos = { x : 50  + ((pos.x > 0) ? pos.x : 0), y: 50 + ((pos.y > 0) ? pos.y : 0) };
        pos = this.convertGlobalToImagePosition(pos);
        
        let newTextAnnotations = [
            ...textAnnotations,
            { uid: uidValue, fontSize: 40, color: "#000000", text: this.state.currentAnnotationText, x: pos.x, y: pos.y, ver: '1.1' },
        ];
        this.props.updateTextAnnotations(currentPhoto.id, newTextAnnotations);
        this.setState({ anchorAddTextEl: null, currentAnnotationText: "" });
    };

    onAnnotationTextChange = (e) => {
        this.setState({
            currentAnnotationText: e.target.value,
        });
    };

    handleAddTextClose = (e) => {
        this.setState({ anchorAddTextEl: null, currentAnnotationText: "" });
    };

    onKeyDown = (e) => {
        const {updateGraphicAnnotations, currentPhoto, graphicObjects} = this.props
        const {selectedGraphicObject, anchorEl} = this.state;
        
        if (e.key === 'Delete') {
            if (selectedGraphicObject != null) {
                const uid = selectedGraphicObject;
                updateGraphicAnnotations(
                    currentPhoto.id,
                    graphicObjects.filter((g) => {
                        return (g.uid !== uid); 
                    })
                );
            }
            else if (anchorEl != null) {
                let elementIndex = anchorEl?.parent?.attrs?.index;
                this.handlePopoverTextDelete(elementIndex)
            }
        }
    }

    renderAddTextAnnotationDialog = () => {
        const { anchorAddTextEl, contextMenuX, contextMenuY } = this.state;

        return (
            <Popover
                id={"popover_add_text_annotation"}
                open={anchorAddTextEl != null}
                anchorReference="anchorPosition"
                anchorPosition={{ top: contextMenuY + 50, left: contextMenuX }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={this.handleAddTextClose}
                onContextMenu={(e) => {
                    e.preventDefault();
                }}
                classes={{ paper: "context-menu" }}
            >
                <div className="context-menu column color-menu">
                    <div className="row-center">
                        <TextField
                            id="add-text"
                            label="Add Text"
                            className={"color-row-item"}
                            autoFocus={true}
                            onChange={this.onAnnotationTextChange}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    this.addTextAnnotation();
                                }
                            }}
                        />
                        <IconButton className={"color-row-item-last"} size="small" onClick={this.addTextAnnotation}>
                            <DoneIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                            className={"color-row-item-last"}
                            size="small"
                            onClick={(e) => {
                                this.setState({ anchorAddTextEl: null });
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
            </Popover>
        );
    };

    handleAddGraphicClose = (e) => {
        this.setState({ anchorAddGraphicEl: null });
    };

    addGraphAnnotation = (e, graphic_type) => {
        this.stage.container().style.cursor = "crosshair";
        this.setState({ anchorAddGraphicEl: null, drawMode: graphic_type });
    };

    onOpenGraphEdit = (e, uid) => {
        this.setState({
            currentGraphicUid: uid,
            contextMenuX: e.evt.clientX,
            contextMenuY: e.evt.clientY
        });
    };

    handleGraphSettingsClose = () => {
        this.setState({
            currentGraphicUid: null,
        });
    };

    handleGraphicAnnotationDelete = (uid) => {
        const {updateGraphicAnnotations, currentPhoto, graphicObjects} = this.props
        this.setState({
            currentGraphicUid: null,
        });
        updateGraphicAnnotations(
            currentPhoto.id,
            graphicObjects.filter((g) => {
                return (g.uid !== uid); 
            })
        );
        
    }

    handleGraphAnnotationColor = (color, uid) => {
        const {updateGraphicAnnotations, currentPhoto, graphicObjects} = this.props
        updateGraphicAnnotations(
            currentPhoto.id,
            graphicObjects.map((g) => {
                if (g.uid == uid) {
                    return { ...g, color: color };
                } else return g;
            })
        );
    };

    handleOnGraphAnnotationSizeSliderChange = (size, uid) => {
        const {updateGraphicAnnotations, currentPhoto, graphicObjects} = this.props
        updateGraphicAnnotations(
            currentPhoto.id,
            graphicObjects.map((g) => {
                if (g.uid == uid) {
                    return { ...g, size: size };
                } else return g;
            }),
        );
    };

    handleSetDashedLine = (uid) => {
        const {updateGraphicAnnotations, currentPhoto, graphicObjects} = this.props
        updateGraphicAnnotations(
            currentPhoto.id,
            graphicObjects.map((g) => {
                if (g.uid == uid) {
                    return { ...g, dashed: true };
                } else return g;
            }),
        );
    }

    handleSetDirectLine = (uid) => {
        const {updateGraphicAnnotations, currentPhoto, graphicObjects} = this.props
        updateGraphicAnnotations(
            currentPhoto.id,
            graphicObjects.map((g) => {
                if (g.uid == uid) {
                    return { ...g, dashed: false };
                } else return g;
            }),
        );
    }

    renderGraphicSettings = () => {
        //const {  } = this.props;
        const { currentGraphicUid, contextMenuX, contextMenuY,  } = this.state;
        const { graphicObjects } = this.props;
        //let elementIndex = anchorEl?.parent?.attrs?.index
        let graphObj = graphicObjects.find((g) => g.uid == currentGraphicUid);
        if (graphObj == null) return false;
        return (
            <Popover
                id={"popover_settings_shadetab"}
                open={currentGraphicUid != null}
                anchorReference="anchorPosition"
                anchorPosition={{ top: contextMenuY + 10, left: contextMenuX }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={() => {
                    this.handleGraphSettingsClose(currentGraphicUid);
                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                }}
                classes={{ paper: "context-menu" }}
            >
                <div className="context-menu column color-menu">
                    <div className="row-center">
                        <ColorButton
                            color={"#000000"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleGraphAnnotationColor("#000000", currentGraphicUid)}
                        />
                        <ColorButton
                            color={"#FFFFFF"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleGraphAnnotationColor("#FFFFFF", currentGraphicUid)}
                        />
                        <ColorButton
                            color={"#FF0000"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleGraphAnnotationColor("#FF0000", currentGraphicUid)}
                        />
                        <ColorButton
                            color={"#0000FF"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleGraphAnnotationColor("#0000FF", currentGraphicUid)}
                        />
                        <ColorButton
                            color={"#00FF00"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleGraphAnnotationColor("#00FF00", currentGraphicUid)}
                        />
                        <ColorButton
                            color={"#FFFF00"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleGraphAnnotationColor("#FFFF00", currentGraphicUid)}
                        />
                        <ColorButton
                            color={"#00FFFF"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleGraphAnnotationColor("#00FFFF", currentGraphicUid)}
                        />
                        <ColorButton
                            color={"#FF00FF"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleGraphAnnotationColor("#FF00FF", currentGraphicUid)}
                        />
                        <IconButton
                                className={"color-row-item-last"}
                                size="small"
                                onClick={() => {
                                    this.handleGraphicAnnotationDelete(currentGraphicUid);
                                }}
                            >
                                <DeleteForeverIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                            className={"color-row-item-last"}
                            size="small"
                            onClick={() => {
                                this.handleGraphSettingsClose(currentGraphicUid);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                    <div className="context-menu row">
                        <label id="discrete-slider" className="slider-menu-label">
                            Size
                        </label>
                        <Slider
                            key={`slider-${graphObj?.size ?? 7}`}
                            className="slider-color-menu"
                            defaultValue={graphObj?.size ?? 7}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={2}
                            marks
                            min={1}
                            max={20}
                            onChangeCommitted={(e, val) =>
                                this.handleOnGraphAnnotationSizeSliderChange(val, currentGraphicUid)
                            }
                        />
                        <IconButton
                            className={''}
                            size="small"
                            onClick={() => {
                                if (!(graphObj.dashed ?? false)) 
                                this.handleSetDashedLine(currentGraphicUid);
                                else
                                this.handleSetDirectLine(currentGraphicUid);
                            }}
                            
                        >
                            { (graphObj.dashed ?? false) ? <RemoveOutlinedIcon fontSize="small" /> : <MoreHorizIcon fontSize="small" />}
                        </IconButton>
                        
                    </div>
                </div>
            </Popover>
        );
    };

    renderGraphicAnnotations = () => {
        const { ratio, drawMode, selectedGraphicObject, image_loaded } = this.state;
        const { graphicObjects, currentPhoto } = this.props;

        if (currentPhoto.currentPhotoType != IMAGE_ORIGINAL && currentPhoto.currentPhotoType != IMAGE_CORRECTED && currentPhoto.currentPhotoType != IMAGE_DOUBLE_VIEW)
            return false;

        if (!image_loaded ) return false;

        if (!currentPhoto.showGraphicsAnnotations) return false;
    
        return graphicObjects.map((graphObj, i) => {
            return (
                <GraphAnnotation
                    key={'g' + graphObj.uid}
                    graphObj={graphObj}
                    ratio={ratio}
                    drawMode={drawMode}
                    selected={graphObj.uid === selectedGraphicObject}
                    convertImageToLayerPositionsArray={(p) => this.convertImageToLayerPositionsArray(p)}
                    onLineDragEnd={(e, uid) => this.onLineDragEnd(e, uid)}
                    setSelectedGraphObject={(uid) => this.setSelectedGraphObject(uid)}
                    onLineEndsDragMove={(e, uid, index) => this.onLineEndsDragMove(e, uid, index)}
                    onOpenGraphEdit={(e, uid) => this.onOpenGraphEdit(e, uid)}
                />
            );
        });
    };

    renderAddGraphicsAnnotationDialog = () => {
        const { anchorAddGraphicEl, contextMenuX, contextMenuY } = this.state;

        return (
            <Popover
                id={"popover_add_graphic_annotation"}
                open={anchorAddGraphicEl != null}
                anchorReference="anchorPosition"
                anchorPosition={{ top: contextMenuY - 50, left: contextMenuX }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={this.handleAddGraphicClose}
                onContextMenu={(e) => {
                    e.preventDefault();
                }}
                classes={{ paper: "context-menu" }}
            >
                <div className="context-menu column color-menu">
                    <div className="row-center">
                        <IconButton
                            className={""}
                            size="medium"
                            onClick={(e) => {
                                this.addGraphAnnotation(e, 0);
                            }}
                        >
                            <RemoveOutlinedIcon fontSize="large" />
                        </IconButton>
                        <IconButton
                            className={""}
                            size="medium"
                            onClick={(e) => {
                                this.addGraphAnnotation(e, 1);
                            }}
                        >
                            <ArrowRightAltOutlined fontSize="large" />
                        </IconButton>
                        <IconButton
                            className={""}
                            size="medium"
                            onClick={(e) => {
                                this.addGraphAnnotation(e, 2);
                            }}
                        >
                            <RadioButtonUncheckedOutlined fontSize="large" />
                        </IconButton>
                        <IconButton
                            className={"color-row-item-last"}
                            size="medium"
                            onClick={(e) => {
                                this.setState({ anchorAddGraphicEl: null });
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
            </Popover>
        );
    };

    renderCrosshairs = () => {
        let { currentPhoto, currentPhotoProcessingStep } = this.props;
        let { containerWidth, containerHeight, printing }  = this.state;

        if (currentPhoto.currentPhotoType != IMAGE_ORIGINAL && currentPhoto.currentPhotoType != IMAGE_CORRECTED || printing)
            return false;

        return (
            currentPhoto?.showCrossHair && (
                <Group>
                    <Line
                        //name={"rectangle-horz"}
                        dashEnabled={true}
                        dash={[10, 5]}
                        points={[0, containerHeight / 2, 45, containerHeight / 2]}
                        stroke={"white"}
                        strokeEnabled={true}
                        fillEnabled={true}
                        fill={"lime"}
                        opacity={1}
                        draggable={false}
                        visible={true}
                    />
                    <Line
                        //name={"rectangle-horz"}
                        dashEnabled={true}
                        dash={[10, 5]}
                        points={[
                            (Math.floor((containerWidth - 45) / 15) + 1) * 15,
                            containerHeight / 2,
                            containerWidth,
                            containerHeight / 2,
                        ]}
                        stroke={"white"}
                        strokeEnabled={true}
                        fillEnabled={true}
                        fill={"lime"}
                        opacity={1}
                        draggable={false}
                        visible={true}
                    />
                    <Line
                        //name={"rectangle-horz"}
                        dashEnabled={true}
                        dash={[10, 5]}
                        points={[containerWidth / 2, 0, containerWidth / 2, 45]}
                        stroke={"white"}
                        strokeEnabled={true}
                        fillEnabled={true}
                        fill={"lime"}
                        opacity={1}
                        draggable={false}
                        visible={true}
                    />
                    <Line
                        //name={"rectangle-horz"}
                        dashEnabled={true}
                        dash={[10, 5]}
                        points={[
                            containerWidth / 2,
                            (Math.floor((containerHeight - 45) / 15) + 1) * 15,
                            containerWidth / 2,
                            containerHeight,
                        ]}
                        stroke={"white"}
                        strokeEnabled={true}
                        fillEnabled={true}
                        fill={"lime"}
                        opacity={1}
                        draggable={false}
                        visible={true}
                    />
                    <Line
                        ref={(node) => {
                            this.vertLine = node;
                        }}
                        //name={"rectangle-horz"}
                        dashEnabled={true}
                        dash={[10, 5]}
                        points={[
                            this.state.xCH ?? containerWidth / 2,
                            0,
                            this.state.xCH ?? containerWidth / 2,
                            this.state.yCH ?? containerHeight / 2,
                            this.state.xCH ?? containerWidth / 2,
                            containerHeight,
                        ]}
                        stroke={"gray"}
                        strokeEnabled={true}
                        hitStrokeWidth={16}
                        fillEnabled={true}
                        fill={"lime"}
                        opacity={1}
                        draggable={false}
                        visible={true}
                        onMouseEnter={() => {
                            if (currentPhotoProcessingStep < 1) {
                                this.stage.container().style.cursor = "move";
                            }
                        }}
                        onMouseLeave={() => {
                            if (currentPhotoProcessingStep < 1) {
                                this.stage.container().style.cursor = "default";
                            }
                        }}
                        onMouseDown={this.handleCrossLineClick}
                        onTouchStart={this.handleCrossLineClick}
                        onMouseUp={(e) => {
                            if (this.state.crossHairSettingMode) {
                                this.finishCrossHair(e);
                            }
                        }}
                        onTouchEnd={(e) => {
                            if (this.state.crossHairSettingMode) {
                                this.finishCrossHair(e);
                            }
                        }}
                        index={"cross_hair_vertical"}
                    />
                    <Line
                        ref={(node) => {
                            this.horizLine = node;
                        }}
                        //name={"rectangle-horz"}
                        dashEnabled={true}
                        dash={[10, 5]}
                        points={[
                            0,
                            this.state.yCH ?? containerHeight / 2,
                            this.state.xCH ?? containerWidth / 2,
                            this.state.yCH ?? containerHeight / 2,
                            containerWidth,
                            this.state.yCH ?? containerHeight / 2,
                        ]}
                        stroke={"gray"}
                        hitStrokeWidth={16}
                        strokeEnabled={true}
                        fillEnabled={true}
                        fill={"lime"}
                        opacity={1}
                        draggable={false}
                        visible={true}
                        onMouseEnter={() => {
                            if (currentPhotoProcessingStep < 1) {
                                this.stage.container().style.cursor = "move";
                            }
                        }}
                        onMouseLeave={() => {
                            if (currentPhotoProcessingStep < 1) {
                                this.stage.container().style.cursor = "default";
                            }
                        }}
                        onMouseDown={this.handleCrossLineClick}
                        onTouchStart={this.handleCrossLineClick}
                        onMouseUp={(e) => {
                            if (this.state.crossHairSettingMode) {
                                this.finishCrossHair(e);
                            }
                        }}
                        onTouchEnd={(e) => {
                            if (this.state.crossHairSettingMode) {
                                this.finishCrossHair(e);
                            }
                        }}
                        index={"cross_hair_horizontal"}
                    />
                </Group>
            )
        );
    };

    handleCrossLineClick = (e) => {
        this.setState({
            crossHairSettingMode: true,
        });    
    };

    handleCrossHairMoveStart = (e) => {
        this.setState({
            isShowContextMenu: false,
            crossHairSettingMode: true,
        });
    };

    renderShadeTabSettings = () => {
        const { shadeTabSettings } = this.props;
        const { anchorEl, contextMenuX, contextMenuY } = this.state;
        
        let elementIndex = anchorEl?.parent?.attrs?.index;
        return (
            <Popover
                id={"popover_settings_shadetab"}
                open={anchorEl != null}
                anchorReference="anchorPosition"
                anchorPosition={{ top: contextMenuY + 50, left: contextMenuX }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={() => {
                    this.handlePopoverClose(elementIndex);
                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                }}
                classes={{ paper: "context-menu" }}
            >
                <div className="context-menu column color-menu">
                    {elementIndex != "st01" && elementIndex != "st02" && (
                        <div className="row-center">
                            <TextField
                                id="edit-text"
                                label="Edit Text"
                                autoFocus={true}
                                value={this.state.currentAnnotationText}
                                className={"color-row-item"}
                                onChange={this.onAnnotationTextChange}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        this.handlePopoverTextDone(elementIndex);
                                        this.handlePopoverClose(elementIndex);
                                    }
                                }}
                            />
                            <IconButton
                                className={"color-row-item-last"}
                                size="small"
                                onClick={() => {
                                    this.handlePopoverTextDone(elementIndex);
                                }}
                            >
                                <DoneIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                className={"color-row-item-last"}
                                size="small"
                                onClick={() => {
                                    this.handlePopoverTextDelete(elementIndex);
                                }}
                            >
                                <DeleteForeverIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                className={"color-row-item-last"}
                                size="small"
                                onClick={() => {
                                    this.handlePopoverClose(elementIndex);
                                }}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </div>
                    )}
                    <div className="row-center">
                        <ColorButton
                            color={"#000000"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleTextAnnotationColor("#000000", elementIndex)}
                        />
                        <ColorButton
                            color={"#FFFFFF"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleTextAnnotationColor("#FFFFFF", elementIndex)}
                        />
                        <ColorButton
                            color={"#FF0000"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleTextAnnotationColor("#FF0000", elementIndex)}
                        />
                        <ColorButton
                            color={"#0000FF"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleTextAnnotationColor("#0000FF", elementIndex)}
                        />
                        <ColorButton
                            color={"#00FF00"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleTextAnnotationColor("#00FF00", elementIndex)}
                        />
                        <ColorButton
                            color={"#FFFF00"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleTextAnnotationColor("#FFFF00", elementIndex)}
                        />
                        <ColorButton
                            color={"#00FFFF"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleTextAnnotationColor("#00FFFF", elementIndex)}
                        />
                        <ColorButton
                            color={"#FF00FF"}
                            borderWidth={1}
                            borderColor={"#000000"}
                            className={"color-row-item"}
                            size={20}
                            onClick={() => this.handleTextAnnotationColor("#FF00FF", elementIndex)}
                        />
                        {(elementIndex == "st01" || elementIndex == "st02") && (
                            <IconButton
                                className={"color-row-item-last"}
                                size="small"
                                onClick={() => {
                                    this.handlePopoverClose(elementIndex);
                                }}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        )}
                    </div>
                    <div className="context-menu row">
                        <label id="discrete-slider" className="slider-menu-label">
                            Size
                        </label>
                        <Slider
                            className="slider-color-menu"
                            defaultValue={
                                elementIndex == "st01"
                                    ? shadeTabSettings?.left?.fontSize
                                    : shadeTabSettings?.right?.fontSize
                            }
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={4}
                            marks
                            min={20}
                            max={60}
                            onChangeCommitted={(e, val) =>
                                this.handleOnTextAnnotationFontSliderChange(val, elementIndex)
                            }
                        />
                    </div>
                </div>
            </Popover>
        );
    };

    renderPhotoProcessingError = () => {
        let { photoProcessingError, hidePhotoProcessingError } = this.props;

        if (photoProcessingError === "") {
            return false;
        }

        return (
            <AlertDialog
                isLightMode={false}
                isOpen={true}
                message={photoProcessingError}
                ok={hidePhotoProcessingError}
            />
        );
    };

    renderPrintTextHeader = () => {
        let { printData } = this.props;
        return (
            <div className={"container-text-annotation"}>
                <div className={"block-text-annotation"}>
                    <label className={"text-label"} style={{ color: "black" }}>
                        Shade Value : {printData.shadeValue}
                    </label>
                    <label className={"text-label"}>3D Master : {printData.master3dValue}</label>
                    <label className={"text-label"}>Brightness : {printData.brightnessValue}</label>
                    <label className={"text-label"}>Shade Guide Used: {printData.shadeGuide}</label>
                    <label className={"text-label"}>Shade Tabs Used: {printData.shadeTabs}</label>
                </div>
                <div className={"block-text-annotation"}>
                    <label className={"text-label"}>Doctor First Name: {printData.doctorFirstName}</label>
                    <label className={"text-label"}>Doctor Last Name : {printData.doctorLastName}</label>
                    <label className={"text-label"}>Name of Lab : {printData.labName}</label>
                    <label className={"text-label"}>Name of Patient : {printData.patientName}</label>
                </div>
            </div>
        );
    };

    renderPrintImage = () => {
        let { dataUrl } = this.state;

        return (
            // eslint-disable-next-line jsx-a11y/alt-text
            <div className={"img-print-content"}>
                <img src={dataUrl} className={"img-print"} alt={'Print'} />
            </div>
        );
    };

    onMouseEnter = (e) => {
        let { currentPhoto, currentPhotoProcessingStep } = this.props;
        const { drawMode } = this.state;
        if (currentPhoto.currentPhotoType === IMAGE_ORIGINAL && currentPhotoProcessingStep > 0) {
            let cursorImg = require("../../assets/images/icons/red-rect.png");
            this.stage.container().style.cursor = "url(" + cursorImg + ") 12 12, crosshair"; // 'crosshair';
        }
        else {
            if (drawMode > -1) {
                this.stage.container().style.cursor = "crosshair";
            } else {
                this.stage.container().style.cursor = "default";
            }
        }
    };

    hideShadeTransTransformer() {
        if (this.shadeTransformer && this.state.shadeTransformerInitialized) {
            this.shadeTransformer.detach();
        }
        this.setState({
            showShadeTransTransform: false,
            shadeTransformerInitialized: false
        }, () => {console.log('showShadeTransTransform to false')});
    }

    restoreCrossHairPosition(xCH, yCH, ratio) {
        
        let pos = { x: xCH, y: yCH };
        pos = this.convertImageToLayerPosition(pos);
        console.log('restoreCrossHairPos, pos = ', pos);
        console.log('this.stage', this.stage);
        console.log('this.stage.getLayers', this.stage?.getLayers());
        const layer = this.stage?.getLayers()[2];
        if (layer) {
            const layerTransform = layer.getAbsoluteTransform().copy();
            console.log('layerTransform', layerTransform);
            pos = layerTransform.point(pos);
            if (pos != null) {
                this.setState({
                    xCH: pos.x,
                    yCH: pos.y,
                });
            }
        }
    }

    convertGlobalToImagePosition(pos) {
        const { ratio } = this.state;

        const images = this.stage?.getLayers()[0].getChildren(function (node) {
            return node.getClassName() === "Image";
        });
        if (images && images.length > 0) {
            const imgElement = images[0];
            const transform = imgElement.getAbsoluteTransform().copy();
            transform.invert();
            pos = transform.point(pos);
            pos = { x: pos.x / ratio, y: pos.y / ratio };

            return pos;
        } else {
            return null;
        }
    }

    convertImageToLayerPosition(pos) {
        const { ratio } = this.state;

        const images = this.stage?.getLayers()[0].getChildren(function (node) {
            return node.getClassName() === "Image";
        });
        if (images && images.length > 0) {
            const imgElement = images[0];
            const transform = imgElement.getAbsoluteTransform().copy();
            pos = { x: pos.x * ratio, y: pos.y * ratio };
            pos = transform.point(pos);
            return pos;
        } else {
            return null;
        }
    }

    convertShadeTransToLayerPosition(pos) {
        //var images = this.stage?.getLayers()[1].getChildren(function (node) {
        //    return node.getClassName() === "Image";
        //});
        //if (images && images.length > 0) {
            const imgElement = this.imageDouble.imageRef;// images[0];
            const transform = imgElement.getAbsoluteTransform().copy();
            pos = { x: pos.x , y: pos.y  };
            pos = transform.point(pos);
            return pos;
        //} else {
        //    return null;
        //}
    }

    updateShadeTransToImageCoordinates() {

        let initShadeX = this.imageDouble.imageRef.offsetX()// * this.imageDouble.imageRef.scaleX()
        let initShadeY = this.imageDouble.imageRef.offsetY()//* this.imageDouble.imageRef.scaleY()
        let initPos = {x : initShadeX, y: initShadeY};
        console.log('initPos', initPos)
        let shadeTransform = this.imageDouble.imageRef.getAbsoluteTransform().copy();
        console.log('shadeTransform', shadeTransform.copy())
        //shadeTransform = shadeTransform.invert();
        console.log('shadeTransform', shadeTransform)
        let initGlobalPos = shadeTransform.point(initPos);
        console.log('initGlobalPos', initGlobalPos)
        let mainImageTransform = this.mainImage.imageRef.getAbsoluteTransform().copy();
        console.log('mainImageTransform', mainImageTransform.copy())
        mainImageTransform.invert()
        console.log('mainImageTransform', mainImageTransform)
        let destImagePos = mainImageTransform.point(initGlobalPos);
        console.log('destImagePos', destImagePos)

        this.props.updateShadeTransPosition({
            shadeTransX: destImagePos.x / this.mainImage.imageRef.width(),
            shadeTransY: destImagePos.y / this.mainImage.imageRef.height(),
            ratioShadeTrans:  this.imageDouble.imageRef.scaleX() / ( this.state.ratio)
        });


    }

    getShadeTransCoordinates = (shadeMapPos) => {
        //const { currentPhoto } = this.props;
        const mainImageTransform = this.mainImage.imageRef.getAbsoluteTransform().copy();
        //mainImageTransform.invert();
        console.log('mainImageTransform', mainImageTransform, this.mainImage.imageRef.width(), this.mainImage.imageRef.scaleX(), this.mainImage.imageRef.offsetX())
        const destImagePos = mainImageTransform.point({x: shadeMapPos.shadeTransX * this.mainImage.imageRef.width(), y: shadeMapPos.shadeTransY * this.mainImage.imageRef.height()});
        const layer = this.stage?.getLayers()[1];
        const layerTransform = layer.getAbsoluteTransform().copy();
        console.log('layerTransform', layerTransform)
        const destPos = layerTransform.point(destImagePos);
        return {
            shadeTransX : destPos.x,
            shadeTransY : destPos.y
        }
    }

    convertImageToLayerPositionsArray(positions) {
        const { ratio } = this.state;
        if (this.stage == null || this.stage.getLayers() == null) return null;
        const layers = this.stage.getLayers();
        if (layers.length < 1) return null;
        const images = layers[0].getChildren(function (node) {
            return node.getClassName() === "Image";
        });
        if (images && images.length > 0) {
            const imgElement = images[0];
            const transform = imgElement.getTransform().copy();
            
            let result = [];
            const len = Object.keys(positions).length;
            for (let i = 0; i < len; i += 2) {
                let pos = { x: positions[i] * ratio, y: positions[i + 1] * ratio };
                pos = transform.point(pos);
                result.push(pos.x);
                result.push(pos.y);
            }
            return result;
        } else {
            console.log("could not get image layer");
            this.setState({
                image_loaded: false
            }, () => {
                setTimeout(
                    () => {
                        this.setState({
                            image_loaded: true
                        })
                    }, 300
                )
            })
            return null;
        }
    }

    updateRegionValue() {
        let { getRegionValues } = this.props;
        let { ratio, imageX, imageY } = this.state;

        let x = this.rectangleSecond.x() - imageX;
        let y = this.rectangleSecond.y() - imageY;
        let w = this.rectangleSecond.width() * this.rectangleSecond.scaleX();
        let h = this.rectangleSecond.height() * this.rectangleSecond.scaleY();

        getRegionValues({
            x: Math.round(x / ratio),
            y: Math.round(y / ratio),
            width: Math.round(w / ratio),
            height: Math.round(h / ratio),
        });
    }

    onDoubleClickInner = (e) => {
        let { isCrop } = this.props;

        if (isCrop && e.target && e.target.classList && e.target.classList.contains("cropper-face")) this.cropHandler();
    };

    onTouchStart = (e) => {
        if (e.evt.targetTouches.length === 1) {
            //console.log('onTouchStart')
            this.onMouseDown(e)
            if (!(this.state.drawMode > -1 || this.state.crossHairSettingMode)) { 
                if (!this.timer) {
                    this.timer = setTimeout(this.onContextMenu.bind(this, e), 500);
                    this.imageTouchX = e.evt.targetTouches.item(0).clientX;
                    this.imageTouchY = e.evt.targetTouches.item(0).clientY;
                }
            }
        }
    };

    onTouchEnd = (e) => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        if (this.state.drawMode > -1 || this.state.crossHairSettingMode) {
            this.onMouseUp(e)
        }
        //console.log('onTouchEnd', this.imageTouchX, this.imageTouchY)
        this.imageTouchX = 0;
        this.imageTouchY = 0;
    };

    moveCrossHair = (e) => {
        if (this.state.crossHairSettingMode) {
            this.stage.container().style.cursor = "crosshair";
            //console.log(e)
            const stage = e.target.getStage();
            const layer = stage.getLayers()[2];
            const layerPos = layer.getRelativePointerPosition();
            
            let layerX = layerPos.x; //(e.evt.type == 'touchmove') ? stage.getPointerPosition().x : .x;
            //e.evt.layerX;
            let layerY = layerPos.y; //(e.evt.type == 'touchmove') ? stage.getPointerPosition().y : stage.getLayers()[2].getRelativePointerPosition().y;//e.evt.layerY;

            this.horizLine.points([]);
            this.horizLine.points([
                0,
                layerY,
                layerX,
                layerY,
                this.state.containerWidth,
                layerY,
            ]);
            this.vertLine.points([
                layerX,
                0,
                layerX,
                layerY,
                layerX,
                this.state.containerHeight,
            ]);

            let xCH = layerX;
            let yCH = layerY;
            this.setState({
                xCH: xCH,
                yCH: yCH,
            });

            this.horizLine.getStage().draw();
        }
    };

    finishCrossHair = (e) => {
        const { ratio } = this.state;

        const stage = e.target.getStage();
        const layer = stage.getLayers()[2];
        const layerPos = layer.getRelativePointerPosition();
        const stagePos = stage.getPointerPosition();
        let layerX = layerPos.x; // (e.evt.type == 'touchend') ? stage.getPointerPosition().x : e.evt.layerX;
        let layerY = layerPos.y; // (e.evt.type == 'touchend') ? stage.getPointerPosition().y : e.evt.layerY;

        this.horizLine.points([0, layerY, layerX, layerY, this.state.containerWidth, layerY]);
        this.vertLine.points([layerX, 0, layerX, layerY, layerX, this.state.containerHeight]);

        let xCH = layerX;
        let yCH = layerY;
        this.setState({
            xCH: xCH,
            yCH: yCH,
        });

        this.horizLine.getStage().draw();
console.log('finishCrossHair')
        this.updateCrossHairPosition(stagePos.x, stagePos.y, ratio);

        this.stage.container().style.cursor = "default";

        this.setState({
            crossHairSettingMode: false,
        });
    };

    getImagePosition = (e, addTransform = null, position = null) => {
        const { ratio } = this.state;
        const stage = e.target.getStage();
        const images = stage.getLayers()[0].getChildren(function (node) {
            return node.getClassName() === "Image";
        });
        if (images && images.length > 0) {
            const imgElement = images[0];
            const transform = imgElement.getAbsoluteTransform().copy();

            // to detect relative position we need to invert transform
            transform.invert();
           
            let pos = position ?? stage.getPointerPosition();
            if (addTransform) {
                addTransform.invert();
                pos = addTransform.point(pos);
            }
            pos = transform.point(pos);
            return { x: pos.x / ratio, y: pos.y / ratio };
        } else {
            return null;
        }
    };

    setSelectedGraphObject = (uid) => {
        this.setState({
            selectedGraphicObject: uid
        })
        
    };

    onLineDragEnd = (e, uid) => {
        const { ratio } = this.state;
        const {updateGraphicAnnotations, currentPhoto, graphicObjects} = this.props
        
        if (e.target?.attrs?.id && e.target?.attrs?.id.endsWith("-g")) {
            let newUid = "ga" + uuidv4()
            updateGraphicAnnotations(
                    currentPhoto.id,
                    graphicObjects.map((line) => {
                        if (line.uid == uid) {
                            let pos = e.target.getTransform().getTranslation();

                            if (e.target?.attrs?.id?.endsWith("c-g")) {
                                return {
                                    ...line,
                                    uid: newUid,
                                    x: line.x + pos.x / ratio,
                                    y: line.y + pos.y / ratio,
                                };
                            } else {
                                //var pos = {x: e.target.getAbsoluteTransform().getTranslation().x / ratio, y: e.target.getAbsoluteTransform().getTranslation().y / ratio}
                                let newPoints = [
                                    line.points[0] + pos.x / ratio,
                                    line.points[1] + pos.y / ratio,
                                    line.points[2] + pos.x / ratio,
                                    line.points[3] + pos.y / ratio,
                                ];
                                return { ...line, uid: newUid, points: newPoints };
                            }
                        } else {
                            return line;
                        }
                    }),
            );
            if (this.state.selectedGraphicObject == uid) {
                this.setState({
                    selectedGraphicObject: newUid
                })
            }
        }
    };

    onLineEndsDragMove = (e, uid, index) => {
        const {updateGraphicAnnotations, currentPhoto, graphicObjects} = this.props
        const point = this.getImagePosition(e, e.target.getParent().getTransform().copy());
        
        updateGraphicAnnotations(
            currentPhoto.id,
            graphicObjects.map((line) => {
                if (line.uid == uid) {
                    let points;
                    if (index == 0) {
                        points = { ...line.points };

                        points[0] = point.x;
                        points[1] = point.y;
                        return { ...line, points: points };
                    } else if (index == 1) {
                        points = { ...line.points };

                        points[2] = point.x;
                        points[3] = point.y;
                        return { ...line, points: points };
                    } else if (index == 2) {
                        let radius = Math.sqrt(Math.pow(line.x - point.x, 2) + Math.pow(line.y - point.y, 2));

                        //e.target.setAbsolutePosition({x: (line.x + radius)/ratio, y: (line.y)/ratio})
                        return { ...line, radius: radius };
                    }
                } else {
                    return line;
                }
            })
        );
    };

    onMouseMove = (e) => {
        const { drawMode, drawingActive } = this.state;
        const {updateGraphicAnnotations, currentPhoto, graphicObjects} = this.props

        this.moveCrossHair(e);
        if (drawMode > -1 && drawingActive) {
            const point = this.getImagePosition(e);

            if (drawMode == GRAPHIC_LINE || drawMode == GRAPHIC_ARROW) {
                let lines = graphicObjects;
                let lastLine = lines[lines.length - 1];
                if (lastLine.points.length > 2) {
                    // replace last point
                    lastLine.points.splice(lastLine.points.length - 2, 2, point.x, point.y);
                } else {
                    lastLine.points = [...lastLine.points, point.x, point.y];
                }
                // replace last graph
                lines.splice(lines.length - 1, 1, lastLine);

                updateGraphicAnnotations(
                    currentPhoto.id,
                    lines.concat(),
                );
            } else if (drawMode == GRAPHIC_CIRCLE) {
                let graphs = graphicObjects;
                let lastObj = graphs[graphs.length - 1];
                
                let radius = Math.sqrt(Math.pow(lastObj.x - point.x, 2) + Math.pow(lastObj.y - point.y, 2));
                lastObj = { ...lastObj, radius: radius };
                graphs.splice(graphs.length - 1, 1, lastObj);

                updateGraphicAnnotations(
                    currentPhoto.id,
                    graphs.concat()
                );
            }
        }
    };

    onMouseDown = (e) => {
        const { drawMode } = this.state;
        const {updateGraphicAnnotations, currentPhoto, graphicObjects} = this.props
        if (drawMode > -1) {
            const pos = this.getImagePosition(e);
            let newUid = "ga" + uuidv4();
            if (drawMode == GRAPHIC_LINE || drawMode == GRAPHIC_ARROW) {
                this.setState({
                    drawingActive: true
                });
                updateGraphicAnnotations(
                    currentPhoto.id,
                    [
                        ...graphicObjects,
                        { uid: newUid, graph_type: drawMode, points: [pos.x, pos.y], color: "#0000ff", size: 9 },
                    ]
                );
            } else if (drawMode == GRAPHIC_CIRCLE) {
                this.setState({
                    drawingActive: true
                });
                updateGraphicAnnotations(
                    currentPhoto.id,
                    [
                        ...graphicObjects,
                        { uid: newUid, graph_type: drawMode, x: pos.x, y: pos.y, color: "#0000ff", size: 9 },
                    ]
                );
            }
        } else if (!e.target?.attrs?.id?.startsWith("ga")) {
            this.setState({
                selectedGraphicObject: null
            }
            );
        }
    };

    onMouseUp = (e) => {
        if (this.state.drawMode > -1) {
            this.setState({
                drawMode: -1,
                drawingActive: false,
            });
            this.stage.container().style.cursor = "default";
        }
        if (this.state.crossHairSettingMode) {
            this.finishCrossHair(e)
        }
    };

    onTouchMove = (e) => {
        if (e.evt.targetTouches.length === 1) {
            let x = e.evt.targetTouches.item(0).clientX;
            let y = e.evt.targetTouches.item(0).clientY;
            //console.log('onTouchMove', x, y)
            if (this.state.drawMode > -1 || this.state.crossHairSettingMode) {
                this.onMouseMove(e)
            }
            else
            if (Math.abs(x - this.imageTouchX) > 3 || Math.abs(y - this.imageTouchY) > 3) {
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
            }
        }
    };

    updateCrossHairPosition(xCH, yCH, ratio) {
        if (xCH != null && yCH != null) {
            const images = this.stage?.getLayers()[0].getChildren(function (node) {
                return node.getClassName() === "Image";
            });
            if (images && images.length > 0) {
                const imgElement = images[0];
                const transform = imgElement.getAbsoluteTransform().copy();

                // to detect relative position we need to invert transform
                transform.invert();

                let pos = { x: xCH, y: yCH };
                pos = transform.point(pos);
                this.props.saveCrossHairPosition({
                    photoId: this.props.currentPhoto.id,
                    x: pos.x / ratio,
                    y: pos.y / ratio,
                });
            }
        }
    }

    updateShadeTransRelativePosition = () => {
        const {currentPhoto} = this.props;
        if (currentPhoto.currentPhotoType !== IMAGE_DOUBLE_VIEW) {
            return
        }
        if (this.imageDouble && this.imageDouble.imageRef) {
            this.updateShadeTransToImageCoordinates()
        }
    }

    onImageLayerDragEnd = (e) => {
        console.log('onImageLayerDragEnd', e)
        if (e.target && e.target.getClassName() == 'Layer') {
            this.updateCrossHairPosition(this.state.xCH, this.state.yCH, this.state.ratio);
            this.updateShadeTransRelativePosition();
        }
    };

    render() {
        let { currentPhoto, targetRef, rotateDegrees, isWorkAreaProgress, isCrop, isRegionActive } = this.props;
        let {
            container,
            stageScale,
            containerWidth,
            containerHeight,
            stageX,
            stageY,
            isShowAfterPrintConfirm,
            drawMode,
            crossHairSettingMode
        } = this.state;

        if (!currentPhoto) {
            return false;
        }

        return (
            <div ref={targetRef} className={"photo-container"} >
                {this.renderCropControl()}
                {this.renderPhotoProcessingError()}
                {this.renderZoomInfo()}
                {isShowAfterPrintConfirm && (
                    <ConfirmDialog
                        isOpen={isShowAfterPrintConfirm}
                        title={"Save Final Image"}
                        message={'Would you like to save printed image as "Final" image of the case?'}
                        no={this.onCloseConfirmation}
                        yes={this.sendFinalImage}
                    />
                )}
                <div className={"inner"} onDoubleClick={this.onDoubleClickInner} ref={this.containerOnLoad} onKeyDown={this.onKeyDown}>
                    {this.renderCropper()}
                    {!isCrop && (
                        <Stage
                            ref={(node) => {
                                this.stage = node;
                            }}
                            width={containerWidth}
                            height={containerHeight}
                            onWheel={this.handleWheel}
                            onClick={this.handleStageClick}
                            onTap={this.handleStageClick}
                            onTouchStart={this.onTouchStart}
                            onTouchEnd={this.onTouchEnd}
                            onTouchMove={this.onTouchMove}
                            onMouseMove={this.onMouseMove}

                            onContextMenu={this.onContextMenu}
                            onMouseEnter={this.onMouseEnter}
                            onDblClick={(e) => {
                                if (isWorkAreaProgress || isCrop || isRegionActive) {
                                    return false;
                                }
                                openFullscreen(container);
                            }}
                            onMouseDown={this.onMouseDown}
                            onMouseUp={this.onMouseUp}
                            tabIndex={1}

                        >
                            <Layer
                                ref={(node) => {
                                    this.mainLayer = node;
                                }}
                                x={stageX + containerWidth / 2}
                                y={stageY + containerHeight / 2}
                                draggable={drawMode == NO_DRAW && !crossHairSettingMode}
                                scaleX={stageScale}
                                backgroundColor="red"
                                scaleY={stageScale}
                                rotation={rotateDegrees ? rotateDegrees : 0}
                                offset={{ x: containerWidth / 2, y: containerHeight / 2 }}
                                onDragEnd={this.onImageLayerDragEnd}
                            >
                                {this.renderPhoto()}
                                {this.renderMarks()}
                                {this.renderShadeLabels()}
                                {this.renderShadeTabLabels()}
                                {this.renderTextAnnotationLabels()}
                                {this.renderGraphicAnnotations()}
                                {this.renderTransformerRect()}
                                {this.renderSecondTransformerRect()}
                                {this.renderTransformer()}
                                {this.renderSecondTransformer()}
                            </Layer>
                            <Layer>
                                {this.renderShadeTranslucency()}
                                {this.renderShadeTranslucencyTransformer()}
                                {this.renderShadeTransLabels()}
                            </Layer>
                            <Layer>{this.renderCrosshairs()}</Layer>
                        </Stage>
                    )}
                    {this.renderShadeSelector()}
                    {this.renderContextMenu()}
                    {this.renderShadeTabSettings()}
                    {this.renderGraphicSettings()}
                    {this.renderAddTextAnnotationDialog()}
                    {this.renderAddGraphicsAnnotationDialog()}
                </div>

                <div style={{ overflow: "hidden", height: 0, position: "absolute" }}>
                    <div className={"print-area"} ref={(el) => (this.printRef = el)}>
                        {this.renderPrintTextHeader()}
                        {this.renderPrintImage()}
                    </div>
                </div>
            </div>
        );
    }
}

/**/
PhotoContainer.propTypes = {
    currentPhoto: photoShape,
    currentCase: caseShape,
    hidePhotoProcessingError: PropTypes.func.isRequired,
    photoProcessingError: PropTypes.string.isRequired,
    caseId: PropTypes.number.isRequired,
    brightness: PropTypes.number.isRequired,
    rotateDegrees: PropTypes.number,
    contrast: PropTypes.number.isRequired,
    isGrayScale: PropTypes.bool.isRequired,
    isCrop: PropTypes.bool.isRequired,
    colorMapSensitivity: PropTypes.number,
    modifierSensitivity: PropTypes.number,

    setPhotoContainerRef: PropTypes.func.isRequired,
    cropImage: PropTypes.func.isRequired,
    isWorkAreaMenuShow: PropTypes.bool.isRequired,
    isWorkAreaProgress: PropTypes.bool.isRequired,

    shadeGuide: PropTypes.object,
    currentPhotoProcessingStep: PropTypes.number.isRequired,
    addShadeSelected: PropTypes.func.isRequired,
    currentShadeReferenceAnalysis: PropTypes.string.isRequired,
    isRegionActive: PropTypes.bool.isRequired,
    runColorCorrect: PropTypes.func.isRequired,
    isColorCorrectionAllowed: PropTypes.bool,
    currentModifier: PropTypes.object,
    processRegion: PropTypes.func,
    getRegionValues: PropTypes.func,
    printData: PropTypes.object,
    shadeMapLabels: PropTypes.array,
    modifierLabels: PropTypes.array,
    addShadeMapLabel: PropTypes.func,
    addModifierLabel: PropTypes.func,
    removeShadeLabel: PropTypes.func,
    updateShadeLabelsMetadata: PropTypes.func,
    setHideXMarks: PropTypes.func,
    setShowShadeTabs: PropTypes.func,
    setShowTextAnnotations: PropTypes.func,
    setShowGraphicsAnnotations: PropTypes.func,
    setShowCrossHair: PropTypes.func,
    saveImageToServer: PropTypes.func,
    userProfile: PropTypes.object,
    setLeftRightShade: PropTypes.func,
    correctedImageType: PropTypes.string.isRequired,
    shadeTabCoords: PropTypes.object,
    updateShadeTabCoords: PropTypes.func,
    textAnnotations: PropTypes.array,
    graphicObjects: PropTypes.array,
    crossHairPos: PropTypes.object,
    updateGraphicAnnotations: PropTypes.func,
    updateTextAnnotations: PropTypes.func,
    addShadeTransLabel: PropTypes.func,
    updateShadeTransPosition: PropTypes.func
};

export default withResizeDetector(PhotoContainer, {
    refreshMode: "debounce",
    refreshRate: 100,
    refreshOptions: { leading: false, trailing: true },
    handleHeight: true,
    handleWidth: true,
});

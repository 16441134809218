import { connect } from 'react-redux';
import store from '../store';
import CreateCase from '../components/CreateCase';
import caseReducer from '../reducers/case.reducer';
import { getShadeGuides as getShadeGuidesSelector } from '../selectors/workArea.selector';
import { getShadeGuides, createCase, getShadeTabs, getNewCaseNum, hideUploadPhotoError, getConnectedSpecialists, getLabs, errorMessageShown, getMagicTouchCustomersList, getMagicTouchPracticeDoctorsList, getMagicTouchProductsList } from '../actions/case.actions';
import { getShadeTabsByKey, shadeTabsLoadingByKey, getCaseNum, getLabs as getLabsSelector, getConnectedSpecialists as getConnectedSpecialistsSelector } from '../selectors/case.selector';
import {isDentist} from "../selectors/user.selector";

store.reducerManager.add("case", caseReducer);

const mapStateToProps = state => ({
    isLogged: state.user.isLogged,
    userProfile: state.user.userProfile,
    shadeGuides: getShadeGuidesSelector(state),
    shadeTabsByKey: getShadeTabsByKey(state),
    shadeTabsLoadingByKey: shadeTabsLoadingByKey(state),
    isCreateCasePending: state.ui.isCreateCasePending,
    //createdCaseId: getCreatedCaseId(state),
    caseNum: getCaseNum(state),
    uploadPhotoError: state.ui.uploadPhotoError,
    labs: getLabsSelector(state),
    specialists: getConnectedSpecialistsSelector(state),
    isDentist: isDentist(state),
    notificationDialog: state.ui.notificationDialog,
    ascendSession: state.ascend.session,
    ascendkey: state.ascend.ascendkey,
    magictouch_customers: state.case.magictouch_customers,
    magictouch_practice_doctors: state.case.magictouch_practice_doctors,
    magictouch_products: state.case.magictouch_products,
});

const mapDispatchToProps = dispatch => ({
    getLabs: () => dispatch(getLabs()),
    getConnectedSpecialists: () => dispatch(getConnectedSpecialists()),
    hideUploadPhotoError: () => dispatch(hideUploadPhotoError()),
    getShadeGuides: () => dispatch(getShadeGuides()),
    getShadeTabs: (guideId, storeKey) => dispatch(getShadeTabs(guideId, storeKey)),
    createCase: (data, files) => dispatch(createCase(data, files)),
    getNewCaseNum: () => dispatch(getNewCaseNum()),
    errorMessageShown: () => dispatch(errorMessageShown()),
    getMagicTouchCustomersList: (token, labid) => dispatch(getMagicTouchCustomersList(token, labid)),
    getMagicTouchPracticeDoctorsList: (token, customerid) => dispatch(getMagicTouchPracticeDoctorsList(token, customerid)),
    getMagicTouchProductsList: (token, labid) => dispatch(getMagicTouchProductsList(token, labid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCase);

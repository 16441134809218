import React from "react";
import PropTypes from "prop-types";
import Button from "../elements/form/Button";
import Form from '../elements/Form';
import InputEmail from "../elements/form/input/InputEmail";

export default class ForgotPassword extends React.PureComponent {
    state = {
        email: '',
        emailError: null,
    };
    reset = action => {
        const { email } = this.state;

        if (!email) {
            this.setState({emailError: ['Email should be filled!']});
            return;
        }
        this.setState({emailError: null});
        this.props.resendUsernameOrPassword(email, action);
    };
    emailChangeHandler = email => {
        this.setState({
            email,
            emailError: email ? null : ['Email should be filled!']
        });
    };
    render() {
        const { emailError } = this.state;
        return (
            <div className="forgot-password-form">
                <p className="auth-page-text">Forgot Username/ Password</p>
                <p className="auth-info">Please enter your email below</p>

                <div className="form-group">
                    <Form onSubmit={() => {}} isAjax={true}>
                        <InputEmail errors={emailError} name={'email'} onChange={this.emailChangeHandler} value={this.state.email} placeholder={'Email address'} isRequired={true} />
                        <Button text="RESEND USERNAME" onClick={() => this.reset('username')} className="resend-username" />
                        <Button text="RESET PASSWORD" onClick={() => this.reset('password')} className="reset-password" />
                    </Form>
                </div>
            </div>
        )
    }
}

ForgotPassword.propTypes = {
    resendUsernameOrPassword: PropTypes.func.isRequired,
};
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from '../elements/form/Button';
import { getCases, getSession, selectAscendCase } from '../../actions/ascend.actions';
//import { Test } from './AscendSelectCase.styles';

class AscendSelectCase extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    componentDidMount = () => {
    
        let { getCases, getSession, ascendkey } = this.props;
        getSession(ascendkey);
        getCases(ascendkey);
        
    }


    static getDerivedStateFromError(error) {
        // getDerivedStateFromError -> Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
    }

    getSnapshotBeforeUpdate = (prevProps, prevState) => {
        
    }

    componentDidUpdate = () => {
        
        //let { ascendkey, setAscendCase } = this.props;
        //if (this.props.match.params.key !== ascendkey) {
        //
        //} 

    }

    componentWillUnmount = () => {
        
    }

    onCreateCase = () => {
        this.props.history.push("/create-case/?key=" + this.props.ascendkey);
    }

    onSelectCase = (caseId) => {
        this.props.selectAscendCase(caseId);
        this.props.history.push("/ascend/images");
    }

    render() {
        let { casesList } = this.props;
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }
        return (
            <div className="light-page ascend-selectcase-container">
                <div className="ascend-selectcase-form">
                    <div className="ascend-row">
                        <h2>Select case with images to acquire or create new case</h2>
                    </div>
                    <div className="ascend-row">
                        <Button text="CREATE CASE" onClick={this.onCreateCase} />
                    </div>
                    {
                        (casesList && casesList.length > 0) ? 
                        casesList?.sort((first, second) => {
                            return (second.newImages*1000 + second.totalImages) - (first.newImages*1000 + first.totalImages );
                        }).map((item, index) => {
                            return (
                                <div className="ascend-row">
                                    <Button text={`${item.your_case_id} - ${item.newImages} new, ${item.totalImages} total` } key={item.id} onClick={(e) => {this.onSelectCase(item.id)}} />
                                </div>
                            )
                        }) 
                        :
                        <div className="ascend-row">
                            <h3>There are no cases for selected user and patient, please, create new one.</h3>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

AscendSelectCase.propTypes = {
    // bla: PropTypes.string,
};

AscendSelectCase.defaultProps = {
    // bla: 'test',
};

const mapStateToProps = state => ({
    // blabla: state.blabla,
    ascendkey: state.ascend.ascendkey,
    casesList: state.ascend.cases,
});

const mapDispatchToProps = dispatch => ({
    // fnBlaBla: () => dispatch(action.name()),
    selectAscendCase: (caseId) => dispatch(selectAscendCase({caseId})),
    getCases: (ascendkey) => dispatch(getCases({ascendkey})),
    getSession: (ascendkey) => dispatch(getSession({ascendkey})),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AscendSelectCase);

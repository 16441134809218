import { connect } from 'react-redux';
import EditLabProfile from '../../components/profile/EditLabProfile';
import { getCountries, getStates, setStates } from "../../actions/settings.action";
import { updateLabProfile, deleteDentist, getMagicTouchLabsList, generateExportDentists } from "../../actions/user.actions";

const mapStateToProps = state => ({
    userProfile: state.user.userProfile,
    countries: state.settings.countries,
    states: state.settings.states,
    magictouch_labs: state.user.magictouch_labs,
});

const mapDispatchToProps = dispatch => ({
    getStates: countryId => dispatch(getStates(countryId)),
    getCountries: () => dispatch(getCountries()),
    setStates: states => dispatch(setStates(states)),
    updateLabProfile: profile => dispatch(updateLabProfile(profile)),
    deleteDentist: id => dispatch(deleteDentist(id)),
    getMagicTouchLabsList: token => dispatch(getMagicTouchLabsList({token})),
    generateExportDentists: (fromDate, toDate) => dispatch(generateExportDentists({fromDate, toDate}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLabProfile);

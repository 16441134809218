const lab = [
    {num: 1, text: 'Lab Profile'},
    {num: 2, text: 'Completed'},
];
const dentist = [
    {num: 1, text: 'Dentist Profile'},
    {num: 2, text: 'Dental Lab'},
    {num: 3, text: 'Dental Specialist'},
    {num: 4, text: 'Completed'},
];
export const stepsGroup = {
    lab,
    dentist,
};
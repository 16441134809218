import { takeLatest, put, all, call, select, delay, takeEvery } from "redux-saga/effects";

import CaseActionTypes from "../actions/case.types";
import WorkAreaActionTypes from "../actions/workArea.types";
import { getLabReassignMessage, getSpecialistReassignMessage } from '../helpers/case';
import { SET_CASE_LAB_ERROR, SET_CASE_LAB_ERROR_NOCREDIT, SET_CASE_SPECIALIST_ERROR } from '../constants/texts';

import {
	caseLoaded,
	caseLoadFailure,
	casesLoaded,
	additionalCasesLoaded,
	casesFailed,
	availableShadeSystemsSuccess,
	lastUsedSettingsLoaded,
	shadeGuideLoaded,
	standardModifiersLoaded,
	shadeGuideByKeyLoaded,
	createCasePending,
	createCaseError,
	createCaseSuccess,
	getNewCaseNumSuccess,
	photoOriginalLoaded,
	photoCorrectedLoaded,
	setShadeTabsSuccess,
	shadeGuideFailed,
	caseDeleted,
	shadeMapGenerated,
	selectedPhotoType,
	getConnectedSpecialistsSuccess,
	getLabsSuccess,
	setCaseSpecialistError, setCaseSpecialistSuccess,
	setCaseLabError, setCaseLabSuccess,
	loadCaseMessagesSuccess,
	loadAllCasePhotoSuccess,
	setActiveCaseId,
	setRoles,
	loadCaseMessages,
	loadCaseMessagePhoto,
	caseMessagePhotoLoaded,
	caseMessagesSaveFinished,
	caseMessagePhotoSaveFinished,
	setPhotosToSaveCount,
	deletePhotoSuccess,
	autoCompleteResult,
	uploadPhotoSuccess,
	uploadPhotoError,
	tripleImageGenerated,
	regionCorrectedGenerated,
    standardViewColorsLoaded,
	setActivePhoto,
	shadeTabsIsLoading,
    photoMetadataUpdated,
    photoMetadataLoaded,
    updateShadeLabelsMetadata,
    updateShadeMapLabels,
    changeCurrentModifier,
    caseCompleted,
    messagesReadAll,
    magicTouchCustomersListLoaded,
    getMagicTouchPracticeDoctorsList,
    magicTouchPracticeDoctorsListLoaded,
    magicTouchProductsListLoaded,
	updateTextAnnotations,
	updateGraphicAnnotations,
	updateShadeTabCoords,
	undoCompleted,
	redoCompleted,
	doubleImageGenerated,
	caseRenewed,
	teethMaskGenerated,
} from "../actions/case.actions";
import { cropImageSuccess, photoProcessingError, choseRegion, regionColorValuesReceived, grayscaleImageSuccess, setTeethSegment, setTeethMaskThreshold } from "../actions/workArea.actions";
import { togglePhotoUploadProgress } from "../actions/ui.actions";
import { showLoader, hideLoader, showComponentLoader, hideComponentLoader } from "../actions/loader.actions";
import * as loaderText from "../constants/loaderText";
import swApi from "../libraries/swApi";
import photoUtils from "../libraries/photoUtils";
import { creditsCharged, noCreditsAvailable, signOut, userNotAuthenticated } from "../actions/user.actions";
import { IMAGE_CORRECTED, IMAGE_DOUBLE_VIEW, IMAGE_MODIFIER, IMAGE_SHADE_MAP, IMAGE_TRIPLE_VIEW } from "../constants/imageTypes";
import { getCurrentShadeGuide, getMapsValues } from "../selectors/workArea.selector";
import { getCurrentPhoto, getCurrentModifier, getCurrentModifiersList, getCurrentCase, getPhotos, getTextAnnotations, getGraphicAnnotations, getShadeTabCoords } from "../selectors/case.selector";
import { ROLE_LAB, ROLE_LAB_MOBILE_CASE_FREE } from "../constants/user";
import { LOOKUP } from "../constants/url";
import history from '../history';
import { onGetMagicTouchLabsList } from "./user.sagas";
import { OPERATION_FLIP, OPERATION_ROTATE } from "../constants/photoOperations";
import { GRAPHIC_ARROW, GRAPHIC_CIRCLE, GRAPHIC_LINE } from "../constants/graphicTypes";

const getCase = (state) => state.case;
const getUserProfile = (state) => state.user.userProfile;

export function* checkUserNotAuthenticated(error) {
	//yield console.log(checkUserNotAuthenticated, error.name, error.message);
	if (error.name == 2 || error.message === swApi.authError500 ) {
		yield put(userNotAuthenticated());
		//yield delay(500);
		//yield history.push('/login');
	}
}

export function* doLoadCase({ caseId }) {
	try {
        let userProfile = yield select(getUserProfile);

        if (userProfile.role_id === ROLE_LAB || userProfile.role_id === ROLE_LAB_MOBILE_CASE_FREE /*&& ( parseInt(caseData.currentCase.is_charged) < 1)*/ ) {
            let useCreditsResult = yield call(swApi.makeRequest, 'useCreditsForProcessCase', [caseId, userProfile.id]);
            if (useCreditsResult.data.status === 'ok')
            {
                if (useCreditsResult.data.error_message === '')
                    yield put(creditsCharged());
            }
            else {
                yield history.push(LOOKUP);
                yield put(noCreditsAvailable());
                const url = 'https://store.shadewave.com/index.php?route=product/product&product_id=63';
                yield call (window.open, url, '_blank');
                return;
            }
        }

        yield put(showLoader(25, loaderText.CASE_LOAD));

		let params = [caseId];
		let [caseResponse, shadesResponse, lastSettingsResponse, rolesResponse, viewColorsResponse] = yield all([
			call(swApi.makeRequest, "getCase", params),
			call(swApi.makeRequest, "getUserShadeStandards", []),
			call(swApi.makeRequest, "getLastUsedSettings", []),
            call(swApi.makeRequest, "getAllRoles", []),
            call(swApi.makeRequest, "getViewStandardColors", []),
		]);

		yield put(showLoader(50, loaderText.CASE_LOAD));
		yield put(setActiveCaseId(caseId));

		yield call(swApi.checkResponse, caseResponse);
		//yield console.log("caseResponse", caseResponse.data);
		yield put(caseLoaded(caseResponse.data));

		yield call(swApi.checkResponse, shadesResponse);
		//yield console.log("shadesResponse", shadesResponse.data);
		yield put(availableShadeSystemsSuccess(shadesResponse.data));

		yield call(swApi.checkResponse, lastSettingsResponse);
		//yield console.log("lastSettingsResponse", lastSettingsResponse.data);
        if (lastSettingsResponse.data.limits == '3')
        {
            lastSettingsResponse.data.limits = '4';
        }
		yield put(lastUsedSettingsLoaded(lastSettingsResponse.data));

		yield call(swApi.checkResponse, rolesResponse);
		//yield console.log("rolesResponse", rolesResponse.data);
        yield put(setRoles(rolesResponse.data));

        yield call(swApi.checkResponse, viewColorsResponse);
		//yield console.log("viewColorsResponse", viewColorsResponse.data);
		yield put(standardViewColorsLoaded(viewColorsResponse.data));

		let shadeId =
			lastSettingsResponse.data.shadeGuideId != null && lastSettingsResponse.data.shadeGuideId !== ""
				? lastSettingsResponse.data.shadeGuideId
				: shadesResponse.data != null && shadesResponse.data.length > 0
				? shadesResponse.data[0].id
				: 0;
		//yield console.log("shadeId", shadeId);
		if (!shadesResponse.data.some((v) => v.id === shadeId)) {
			shadeId = shadesResponse.data[0].id;
		}
		//yield console.log("shadeId updated", shadeId);

		yield put(showLoader(75, loaderText.CASE_LOAD));
		let [shadeGuideResponse, modifiersResponse] = yield all([
			call(swApi.makeRequest, "getShadeGuide", [shadeId]),
			call(swApi.makeRequest, "getStandardModifiers", []),
			call(doLoadPhotos, { photos: caseResponse.data.photos }),
			//call(photoUtils.getShadeMatchModule),
		]);

		yield call(swApi.checkResponse, shadeGuideResponse);
		//yield console.log("shadeGuideResponse", shadeGuideResponse.data);
		yield put(shadeGuideLoaded(shadeGuideResponse.data));

		yield call(swApi.checkResponse, modifiersResponse);
		//yield console.log("modifiersResponse", modifiersResponse.data);
		yield put(standardModifiersLoaded(modifiersResponse.data));

		yield put(showLoader(100, loaderText.CASE_LOAD));
		yield put(hideLoader());
	} catch (error) {
		yield console.log(error);
		yield put(caseLoadFailure(error));
		yield checkUserNotAuthenticated(error);
	}
}

export function* doGetShadeGuide({ shadeGuideId }) {
	try {
		let shadeGuideResponse = yield call(swApi.makeRequest, "getShadeGuide", [shadeGuideId]);
		yield call(swApi.checkResponse, shadeGuideResponse);
        yield put(shadeGuideLoaded(shadeGuideResponse.data));

        yield call(swApi.makeRequest, "setDefaultProperty", [
			"shadeGuideId",
			shadeGuideResponse.data.id
		]);

        let photo = yield select(getCurrentPhoto);

		if (photo && photo.corrected && (photo.imgRegionShadeMap || photo.imgShadeMap)) {
            yield doGenerateShadeMap({ newProps: { isShadeMaps: true, isModifier: true }});
        }
	} catch (error) {
		yield put(shadeGuideFailed(error));
		if (error.message === swApi.EMPTY_RESPONSE) {
			yield put(signOut());
		}
		yield checkUserNotAuthenticated(error);
	}
}

export function* doLoadPhoto({ photo }) {
	const img = yield call(photoUtils.load, photo.url);
	yield put(photoOriginalLoaded({ image: img.canvas, id: photo.id, imageSrc: img.canvasThumb.toDataURL(), imageSize: img.imageSize }));
}

export function* doLoadPhotos({ photos }) {
	yield all(photos.map((photo) => call(doLoadPhoto, { photo })));
}

export function* doUploadPhoto({ file, fileContent, caseId }) {
	try {
		yield put(togglePhotoUploadProgress(true));

		const photoData = {
			case_id: caseId,
			filenameOrig: file.name,
			content_type: "base64",
			metadata: {mcWhite:0,manualCorrected:false,autoCorrected:false,mcBlack:0,mcShade:0},
		};
		const response = yield call(swApi.makeRequest, "uploadPhoto", [
			{
				filedataOrig: {
					data: fileContent,
				},
				...photoData,
			}, false
		]);

		if (typeof response.data === "undefined" ) {
			yield put(uploadPhotoError("Error on upload photo"));
			return;
		}

		// TODO check errors
		yield call(swApi.checkResponse, response);
        if (response.data.error) {
            yield put(uploadPhotoError('Error on upload photo: ' + response.data.error));
            return;
        }
        if (typeof response.data.id === "undefined" ) {
			yield put(uploadPhotoError("Error on upload photo"));
			return;
        }

		let photo = { id: response.data.id, url: response.data.url, ...photoData };

		const caseResponse = yield call(swApi.makeRequest, "getCase", [caseId]);
		yield call(swApi.checkResponse, caseResponse);

		yield put(caseLoaded(caseResponse.data));

		yield doLoadPhoto({ photo });

		yield put(togglePhotoUploadProgress(false));
        yield put(uploadPhotoSuccess());
        yield put(setActivePhoto(photo.id));
	} catch (error) {
		yield put(caseLoadFailure(error));
		yield checkUserNotAuthenticated(error);
	}
}

export function* doNotifyLabAboutPhotos({caseId}) {
    try {
        const response = yield call(swApi.makeRequest, "notifyLabAboutPhotos", [caseId]);
	    //yield call(swApi.checkResponse, response);
    }
    catch (error) {
        //yield checkUserNotAuthenticated(error);
    }
}

export function* doLoadShadeTabs({ guideId, storeKey }) {
	try {
		yield put(shadeTabsIsLoading(true, storeKey));

		const shadesResponse = yield call(swApi.makeRequest, "getShadeGuide", [guideId]);
		yield call(swApi.checkResponse, shadesResponse);

		yield put(shadeGuideByKeyLoaded(shadesResponse.data, storeKey));
		yield put(shadeTabsIsLoading(false, storeKey));
	} catch (e) {
		console.error(e);

		yield put(shadeGuideByKeyLoaded(null, storeKey));
	}
}
export function* doLoadShadeGuides() {
	try {
		const shadesResponse = yield call(swApi.makeRequest, "getUserShadeStandards", []);

		yield call(swApi.checkResponse, shadesResponse);
		yield put(availableShadeSystemsSuccess(shadesResponse.data));
	} catch (e) {
		console.error(e);
	}
}

export function* doLoadCases(payload) {
	try {
		let { limit, casesFilter, lastCaseId, lastDate } = payload;
		yield put(showComponentLoader(loaderText.CASES_LOADER_COMPONENT_NAME, 25, loaderText.CASE_LOAD));

		let params = [limit, casesFilter];

		if (lastCaseId && lastDate) {
			params.push(lastCaseId);
			params.push(lastDate);
		}

		const response = yield call(swApi.makeRequest, "getCasesForMobile", params);

		yield put(showComponentLoader(loaderText.CASES_LOADER_COMPONENT_NAME, 75, loaderText.CASE_LOAD));

		yield call(swApi.checkResponse, response);

		if (lastCaseId) {
			yield put(additionalCasesLoaded(response.data));
		} else {
			yield put(casesLoaded(response.data));
		}


		yield put(hideComponentLoader(loaderText.CASES_LOADER_COMPONENT_NAME));
	} catch (error) {
		yield put(casesFailed(error));
		yield checkUserNotAuthenticated(error);
	}
}

export function* doSetShadeTabs(payload) {
	try {
		let { selectedTabsIds } = payload;
		const setShadeTabsResponse = yield call(swApi.makeRequest, "setDefaultProperty", [
			"shadeTabFilter",
			selectedTabsIds,
		]);
		yield call(swApi.checkResponse, setShadeTabsResponse);
		yield put(setShadeTabsSuccess(selectedTabsIds));
	} catch (error) {
		yield console.log(error);
		yield checkUserNotAuthenticated(error);
	}
}

export function* doGetRegionValue({ rect }) {
    let photo = yield select(getCurrentPhoto);

    if (photo && photo.corrected) {
        try {
            let mapData = yield select(getMapsValues);
            let colors = [];
            let colorNames = [];
            let master3d = [];
            let brightness = [];
            let masterNames = [];
            let brightnessNames =[];
            yield mapData.shadeGuide.color.forEach(function (item) {
                colors.push(parseInt(item.rgb, 16) + 0xff000000);
                colorNames.push(item.name);
            });
            yield mapData.shadeGuide.master3d.forEach(function (item) {
                master3d.push(parseInt(item.rgb, 16) + 0xff000000);
                masterNames.push(item.name);
            });
            yield mapData.shadeGuide.brightness.forEach(function (item) {
                brightness.push(parseInt(item.rgb, 16) + 0xff000000);
                brightnessNames.push(item.name);
            });
            let resultCodes = yield call(photoUtils.getRegionColorValues, photo.imgCorrected, rect, colors, master3d, brightness);
            let result = {
                colorName: colorNames[resultCodes.colorCode],
                master3dName: masterNames[resultCodes.masterCode],
                brightnessName: brightnessNames[resultCodes.brightnessCode],
            };
            yield put(regionColorValuesReceived(result));
            let meta = {
                ...photo.metadata,
                valueBarShade: result.colorName,
                valueBarMaster: result.master3dName,
                valueBarBrightness: result.brightnessName,
                valueRect: rect,
            };
            let r = yield call(swApi.makeRequest, 'updatePhotoMetaData', [photo.id, meta]);
            yield call(swApi.checkResponse, r);
            yield put(photoMetadataUpdated(photo.id, meta));
        }
        catch(error) {
            yield console.log(error);
            yield checkUserNotAuthenticated(error);
        }
    }
}

export function* doGenerateTeethMask( props ) {
	try {
		console.log('doGenerateTeethMask', props);
		let photo = yield select(getCurrentPhoto);

		if (photo && photo.corrected) {
			if (!photo.imgTeethMask || props.threshold != photo.teethMaskThreshold) {
				
				const originalImage = photo.imgOriginal;
				const toothMaskRes = yield call(
					photoUtils.getToothMask,
					originalImage, props.threshold
				)
				const toothMask = toothMaskRes.mask;
				console.log('toothMask', toothMask);
				
				yield put(
					teethMaskGenerated(
						photo.id,
						props.threshold,
						toothMask
					)
				);

				return toothMask;
			}
		}
	} catch (error) {
        yield console.log(error);
        yield put(hideLoader());
        yield checkUserNotAuthenticated(error);
        yield put(photoProcessingError('Image processing error: ' + error + '\nPlease refresh browser to resolve memory issue.'));
	}
}

export function* doGenerateShadeMap({ newProps }) {
	try {
		let photo = yield select(getCurrentPhoto);

		if (photo && photo.corrected) {
            let mapDataSelect = yield select(getMapsValues);
            let modifier = yield select(getCurrentModifier);
            if (newProps.modifier)
            {
                let modifierList = yield select(getCurrentModifiersList);
                modifier = yield modifierList.find(item => item.id === newProps.modifier);
            }
			yield put(showLoader(25, loaderText.PHOTO_MAP_GENERATING));
			yield delay(10);
			yield call(photoUtils.getShadeMatchModule);
			yield put(showLoader(50, loaderText.PHOTO_MAP_GENERATING));
			yield delay(10);

			let mapData = { ...mapDataSelect, ...newProps };
			let colors = [];
			let fillColors = [];
			let bgColor =
				mapData.currentBackground === "black"
					? 0xff000000
					: mapData.currentBackground === "gray"
					? 0xff666666
					: 0xffffffff;
            let drawContours = mapData.currentMapType === "outline" ? true : false;
            let correctedImage = photo.imgRegionCorrected ? photo.imgRegionCorrected : photo.imgCorrected;
			let teethMask = photo.imgTeethMask;
            let isRegion = photo.imgRegionCorrected != null;
            let response, responseMod;
            let meta = photo.metadata;
			let teethMaskRect = {
				x: 0, y: 0, width: photo.imgOriginal.width, height: photo.imgOriginal.height
			};
			if (meta.regionRect) {
				teethMaskRect = meta.regionRect;
			}

			const isTeethSegment = newProps.isTeethSegment ?? (meta.isTeethSegment ?? false)
			const teethMaskThreshold = newProps.teethMaskThreshold ?? (meta.teethMaskThreshold ?? 0.5)
			console.log(newProps, meta, isTeethSegment, teethMaskThreshold);
			if (!isTeethSegment) {
				teethMask = null;
			}
			else {
				if (!photo.imgTeethMask || teethMaskThreshold != photo.teethMaskThreshold) {
					console.log('GENERATE NEW TEETH MASK');
					teethMask = yield call(doGenerateTeethMask, { threshold: teethMaskThreshold } );
				}
				else {
					console.log('USE SAME TEETH MASK');
				}
			}
			//console.log('toothMask', toothMask)

			if (mapData.isShadeMaps) {
				yield mapData.shadeGuide.color.forEach(function (item) {
					colors.push(parseInt(item.rgb, 16) + 0xff000000);
					fillColors.push(parseInt(item.gc_map, 16) + 0xff000000);
                });

                let imageType = IMAGE_SHADE_MAP;

                let params =
                    colors.length +
                    bgColor.toString(16) +
                    (mapData.isShadeMaps ? mapData.colorMapSensitivity.toString() :  mapData.modifierSensitivity.toString())+
                    mapData.shadeLimits.toString() +
                    drawContours +
                    fillColors.length +
                    imageType;

				

                response = yield call(
                    photoUtils.segmentImage,
                    correctedImage,
                    colors,
                    bgColor,
                    (mapData.isShadeMaps ? mapData.colorMapSensitivity : mapData.modifierSensitivity),
                    mapData.shadeLimits,
                    drawContours,
                    fillColors,
                    (mapData.isShadeMaps ? mapData.sensitivityLimits.shadeMap : mapData.sensitivityLimits.modifier),
					teethMask?.slice() ?? null,
					teethMaskRect,
					{
						x: 0, y: 0, width: photo.imgOriginal.width, height: photo.imgOriginal.height
					}
                );

                yield put(
                    shadeMapGenerated(
                        response.resultImg,
                        response.mask,
                        photo.id,
                        params,
                        imageType,
                        isRegion
                    )
                );



            };
            if (mapData.isModifier) {
                colors = [];
                fillColors = [];
				yield modifier.values.forEach(function (item) {
					colors.push(parseInt(item.rgb, 16) + 0xff000000);
					fillColors.push(parseInt(item.gc_map, 16) + 0xff000000);
                });

                let imageTypeMod = IMAGE_MODIFIER;

                let paramsMod =
                    colors.length +
                    bgColor.toString(16) +
                    (mapData.isShadeMaps ? mapData.colorMapSensitivity.toString() :  mapData.modifierSensitivity.toString())+
                    mapData.shadeLimits.toString() +
                    drawContours +
                    fillColors.length +
                    imageTypeMod;

                responseMod = yield call(
                    photoUtils.segmentImage,
                    correctedImage,
                    colors,
                    bgColor,
                    (mapData.isShadeMaps ? mapData.colorMapSensitivity : mapData.modifierSensitivity),
                    mapData.shadeLimits,
                    drawContours,
                    fillColors,
                    (mapData.isShadeMaps ? mapData.sensitivityLimits.shadeMap : mapData.sensitivityLimits.modifier),
					teethMask?.slice() ?? null,
					teethMaskRect,
					{
						x: 0, y: 0, width: photo.imgOriginal.width, height: photo.imgOriginal.height
					}
                );

                yield put(
                    shadeMapGenerated(
                        responseMod.resultImg,
                        responseMod.mask,
                        photo.id,
                        paramsMod,
                        imageTypeMod,
                        isRegion
                    )
                );

            }

            meta = {...meta,
                shadeMapSensitivity: mapData.colorMapSensitivity,
                modifierSensitivity: mapData.modifierSensitivity,
                shadeLimits: mapData.shadeLimits,
                background: mapData.currentBackground,
                shadeGuideId: mapData.shadeGuide.id,
                modifierId: modifier.id,
				isTeethSegment: isTeethSegment ?? false,
				teethMaskThreshold: teethMaskThreshold ?? 0.5
            };
            //console.log('doGenerateShadeMap update meta', meta)
            let r = yield call(swApi.makeRequest, 'updatePhotoMetaData', [photo.id, meta]);
            yield call(swApi.checkResponse, r);
            yield put(photoMetadataUpdated( photo.id, meta));

            yield doRecalculateShadeLabels();

			yield put(showLoader(100, loaderText.PHOTO_MAP_GENERATING));
			yield delay(10);

			if (/*mapDataSelect.currentPhotoType === IMAGE_TRIPLE_VIEW*/isRegion) {
				yield doGenerateTripleImage(
					photo.id,
					mapData,
					drawContours,
					photo.imgRegionViewCorrected,
					mapData.isShadeMaps ? response.resultImg : photo.imgRegionShadeMap,
					mapData.isModifier ? responseMod.resultImg : photo.imgRegionModifier,
					mapData.isShadeMaps ? response.mask : photo.imgRegionShadeMapMask,
					mapData.isModifier ? responseMod.mask : photo.imgRegionModifierMask
				);
				yield doGenerateDoubleImage(
					photo.id,
					mapData,
					drawContours,
					mapData.isShadeMaps ? response.resultImg : photo.imgRegionShadeMap,
					mapData.isModifier ? responseMod.resultImg : photo.imgRegionModifier,
					mapData.isShadeMaps ? response.mask : photo.imgRegionShadeMapMask,
					mapData.isModifier ? responseMod.mask : photo.imgRegionModifierMask
				)
			}

			yield put(hideLoader());
		}
	} catch (error) {
        yield console.log(error);
        yield put(hideLoader());
        yield checkUserNotAuthenticated(error);
        yield put(photoProcessingError('Image processing error: ' + error + '\nPlease refresh browser to resolve memory issue.'));
	}
}

export function* doRecalculateShadeLabels() {
    let photo = yield select(getCurrentPhoto);
    let shadeGuide = yield select(getCurrentShadeGuide);
    let currentModifier = yield select(getCurrentModifier);

    if (photo && photo.corrected && (photo.shadeMapLabels || photo.modifierLabels)) {
        if (photo.shadeMapLabels) {
            let mask = photo.imgRegionShadeMapMask ?? photo.imgShadeMapMask;
            let width = photo.imgRegionShadeMap ? photo.imgRegionShadeMap.width : (photo.imgShadeMap ? photo.imgShadeMap.width : 0);
            if (mask && (width > 0)) {
                let newLabels = photo.shadeMapLabels.map((item,index) => {
                    let x = item.x;
                    let y = item.y;
                    let pixelPos = (y * width + x) * 3;
                    let element = '';
                    let colorCode = mask[ pixelPos ];
                    if (colorCode > 0) {
                        element = (shadeGuide.color.length >= colorCode) ? shadeGuide.color[colorCode - 1].name : '';
                    }
                    return {...item, text: element};
                });
                yield put(updateShadeMapLabels(newLabels, null));
            }
        }

        if (photo.modifierLabels) {
            let mask = photo.imgRegionModifierMask ?? photo.imgModifierMask;
            let width = photo.imgRegionModifier ? photo.imgRegionModifier.width : (photo.imgModifier ? photo.imgModifier.width : 0);
            if (mask && (width > 0)) {
                let newLabels = photo.modifierLabels.map((item,index) => {
                    let x = item.x;
                    let y = item.y;
                    let pixelPos = (y * width + x) * 3;
                    let element = '';
                    let colorCode = mask[ pixelPos ];
                    if (colorCode > 0) {
                        element = (currentModifier.values.length >= colorCode) ?  currentModifier.values[colorCode - 1].name : '';
                    }
                    return {...item, text: element};
                });
                yield put(updateShadeMapLabels(null, newLabels));
            }
        }

        yield put(updateShadeLabelsMetadata());
    }
	
}

export function* doLoadQcPhoto({photoId}) {

}

export function* doProcessActivePhoto({ photoId }) {
	try {
		let caseData = yield select(getCase);
		let photo = caseData.photos.find(item => item.id === photoId);
		if (photo) {

			if (!photo.corrected) {
				let meta = photo.metadata;

                if (meta && meta.shadeGuideId) {
                    let shadeGuideResponse = yield call(swApi.makeRequest, "getShadeGuide", [meta.shadeGuideId]);
		            yield call(swApi.checkResponse, shadeGuideResponse);
                    yield put(shadeGuideLoaded(shadeGuideResponse.data));
                }

                if (meta && meta.modifierId) {
                    yield put(changeCurrentModifier(meta.modifierId));
                }

				if (meta && meta.manualCorrected) {
					yield put(showLoader(25, loaderText.PHOTO_CORRECTING));
                    yield delay(10);

					let colors = meta.colors;
					let refColors = meta.refColors;
					let studioColors = meta.studioColors;
					yield call(photoUtils.getShadeMatchModule);
					yield put(showLoader(50, loaderText.PHOTO_CORRECTING));
					yield delay(10);
					const response = yield call(
						photoUtils.correctImage,
						photo.imgOriginal,
						colors,
						refColors,
                        studioColors,
                        false
					);
					yield put(showLoader(75, loaderText.PHOTO_CORRECTING));
                    yield delay(10);

                    let refViewColors = yield call(photoUtils.generateViewRefColors, refColors, caseData.standardViewColors);

                    const responseView = yield call(photoUtils.correctImage, photo.imgOriginal, colors, refViewColors, studioColors, true);

                    yield put(photoCorrectedLoaded(response, responseView, photoId));

                    yield put(selectedPhotoType(IMAGE_CORRECTED));

                    if (photo.allmapdata) {
                        meta = {...meta,
                            shadeMapSensitivity: photo.allmapdata.cmSensitivity ,
                            modifierSensitivity: photo.allmapdata.trSensitivity ,
                            background: ( (photo.allmapdata.backgroundColor === 4294967295)
                                            ? 'none'
                                            : ( (photo.allmapdata.backgroundColor === 4278190080) ? 'black' : 'gray') ),
                        };
                    }

                    if (meta.shadeLimits == 3)
                    {
                        meta.shadeLimits = 4;
                    }

                    yield put(photoMetadataLoaded(meta));

                    yield put(showLoader(100, loaderText.PHOTO_CORRECTING));
                    yield put(hideLoader());


                    let valueBoxReceived = false;
                    if (meta.regionRect) {
                        yield doProcessRegion({rect : meta.regionRect, notShow: true, isTeethSegment: meta.isTeethSegment ?? false, teethMaskThreshold: meta.teethMaskThreshold ?? 0.5});
                        if (meta.valueRect) {
                            yield doGetRegionValue({rect: meta.valueRect});
                            valueBoxReceived = true;
                        }
                        else if (meta.valueBarShade || meta.valueBarMaster || meta.valueBarBrightness)
                        {
                            let result = {
                                colorName: meta.valueBarShade ?? '',
                                master3dName: meta.valueBarMaster ?? '',
                                brightnessName: meta.valueBarBrightness ?? '',
                            };
                            yield put(regionColorValuesReceived(result));
                            valueBoxReceived = true;
                        }
                    }
                    else if (photo.allmapdata) {
                        if (photo.allmapdata.regionApplied &&  photo.allmapdata.regionRect) {
                            yield doProcessRegion({rect : photo.allmapdata.regionRect, notShow: true, isTeethSegment: meta.isTeethSegment ?? false, teethMaskThreshold: meta.teethMaskThreshold ?? 0.5});
                            if (meta.valueBarShade || meta.valueBarMaster || meta.valueBarBrightness)
                            {
                                let result = {
                                    colorName: meta.valueBarShade ?? '',
                                    master3dName: meta.valueBarMaster ?? '',
                                    brightnessName: meta.valueBarBrightness ?? '',
                                };
                                yield put(regionColorValuesReceived(result));
                                valueBoxReceived = true;
                            }

                        }

                    }

                    if (!valueBoxReceived) {
                        let result = {
                            colorName: '',
                            master3dName: '',
                            brightnessName: '',
                        };
                        yield put(regionColorValuesReceived(result));
                    }

                    yield put(selectedPhotoType(IMAGE_CORRECTED));

				} else {
					yield console.log("photo original resend");
					yield put(selectedPhotoType(photo.currentPhotoType));
				}
			} else {
				yield put(selectedPhotoType(photo.currentPhotoType));
			}
		}
	} catch (error) {
        yield console.log(error);
        yield put(hideLoader());
        yield put(photoProcessingError('Image processing error: ' + error + '\nPlease refresh browser to resolve memory issue.'));

	}
}

export function* doPhotoCorrection() {
	try {
		let caseData = yield select(getCase);
        let photo = yield select(getCurrentPhoto);
        let userProfile = yield select(getUserProfile);

		if (photo) {
            let meta = photo.metadata;
            //yield console.log('meta initial', meta, photo.metadata);
			yield put(showLoader(25, loaderText.PHOTO_CORRECTING));
			yield delay(10);
			let colors = meta?.colors ? meta.colors : [];
			let refColors = meta?.refColors ? meta.refColors : [];
			let studioColors = meta?.studioColors ? meta.studioColors : [];
			yield call(photoUtils.getShadeMatchModule);
			yield put(showLoader(50, loaderText.PHOTO_CORRECTING));
			yield delay(10);
			const response = yield call(photoUtils.correctImage, photo.imgOriginal, colors, refColors, studioColors, false);
			yield put(showLoader(75, loaderText.PHOTO_CORRECTING));
			yield delay(10);

            let refViewColors = yield call(photoUtils.generateViewRefColors, refColors, caseData.standardViewColors);
            const responseView = yield call(photoUtils.correctImage, photo.imgOriginal, colors, refViewColors, studioColors, true);
            yield put(photoCorrectedLoaded(response, responseView, photo.id));

			yield put(selectedPhotoType(IMAGE_CORRECTED));
			yield delay(20);
            yield put(choseRegion("on"));
            //yield console.log('meta before', meta);
            meta = {...meta, manualCorrected: true};
            //yield console.log('meta', meta);
            let r = yield call(swApi.makeRequest, 'updatePhotoMetaData', [photo.id, meta]);
            yield call(swApi.checkResponse, r);
            yield put(photoMetadataUpdated( photo.id, meta));
            if ((userProfile.role_id === ROLE_LAB || userProfile.role_id === ROLE_LAB_MOBILE_CASE_FREE) && ( parseInt(caseData.currentCase.is_charged) < 1) ) {
                yield call(swApi.makeRequest, 'useCreditsForProcessCase', [caseData.currentCase.caseId, userProfile.id]);
                yield put(creditsCharged());
            }
            yield put(showLoader(100, loaderText.PHOTO_CORRECTING));
			yield put(hideLoader());
		}
	} catch (error) {
        yield console.log(error);
        yield put(hideLoader());
        yield checkUserNotAuthenticated(error);
        yield put(photoProcessingError('Image processing error: ' + error + '\nPlease refresh browser to resolve memory issue.'));
	}
}

export function* doGenerateTripleImage(
	activePhotoId,
	mapData,
	drawContours,
	correctedImage,
	imgSM,
	imgM,
	maskSM,
	maskM
) {
	let imageType = IMAGE_TRIPLE_VIEW;
	let paramsTV =
		mapData.colorMapSensitivity.toString() +
		mapData.modifierSensitivity.toString() +
		mapData.shadeLimits.toString() +
		drawContours +
		imageType;
	let tripleResult = yield call(photoUtils.generateTripleImages, correctedImage, imgSM, imgM, maskSM, maskM);
	yield put(tripleImageGenerated(tripleResult, activePhotoId, paramsTV, imageType));
}

export function* doGenerateDoubleImage(
	activePhotoId,
	mapData,
	drawContours,
	imgSM,
	imgM,
	maskSM,
	maskM
) {
	let imageType = IMAGE_DOUBLE_VIEW;
	let paramsTV =
		mapData.colorMapSensitivity.toString() +
		mapData.modifierSensitivity.toString() +
		mapData.shadeLimits.toString() +
		drawContours +
		imageType;
	let doubleResult = yield call(photoUtils.generateDoubleImage, imgSM, imgM, maskSM, maskM);
	yield put(doubleImageGenerated(doubleResult, activePhotoId, paramsTV, imageType));
}

export function* doProcessRegion({ rect, notShow, subrect, isTeethSegment, teethMaskThreshold }) {
	try {
		let modifier = yield select(getCurrentModifier);
		let photo = yield select(getCurrentPhoto);

		if (photo) {
			yield put(showLoader(0, loaderText.PHOTO_MAP_GENERATING));
            yield delay(10);
            let mapData = yield select(getMapsValues);
            let meta = photo.metadata ?? {};
			let cropCorrectedResult = null;
			let cropCorrectedViewResult = null;
			let cropOriginalResult = null;
			const teethMaskRect = rect;
			if (!photo.imgRegionCorrected || !photo.imgRegionViewCorrected || !photo.imgRegionOriginal ||!photoUtils.isRectsEqual(meta.regionRect, rect)) {
				let r = yield call(photoUtils.cropRegion, photo.imgCorrected, rect);
                cropCorrectedResult = r.resultImg;
                let vr = yield call(photoUtils.cropRegion, photo.imgViewCorrected, rect);
                cropCorrectedViewResult = vr.resultImg;
				let or = yield call(photoUtils.cropRegion, photo.imgOriginal, rect);
                cropOriginalResult = or.resultImg;
                yield put(regionCorrectedGenerated(cropCorrectedResult, cropCorrectedViewResult, cropOriginalResult, photo.id));

                if (photo.shadeMapLabels && photo.imgShadeMap) {
                    let newLabels = photo.shadeMapLabels.map((item,index) => {
                        let nx = item.x - rect.x;
                        let ny = item.y - rect.y;
                        return {...item, x: nx, y: ny};
                    });
                    yield put(updateShadeMapLabels(newLabels, null));
                }
                if (photo.modifierLabels && photo.imgModifier) {
                    let newLabels = photo.modifierLabels.map((item,index) => {
                        let nx = item.x - rect.x;
                        let ny = item.y - rect.y;
                        return {...item, x: nx, y: ny};
                    });
                    yield put(updateShadeMapLabels(null, newLabels));
                }
                if (photo.shadeMapLabels || photo.modifierLabels) {
                    yield doUpdateShadeLabelsMetadata();
                }

			} else {
				cropCorrectedResult = photo.imgRegionCorrected;
				cropCorrectedViewResult = photo.imgRegionViewCorrected;
				cropOriginalResult = photo.imgRegionOriginal;
			}

			yield put(showLoader(25, loaderText.PHOTO_MAP_GENERATING));
			yield delay(10);

			let colorsSM = [];
			let fillColorsSM = [];
			let colorsM = [];
			let fillColorsM = [];

			let bgColor =
				mapData.currentBackground === "black"
					? 0xff000000
					: mapData.currentBackground === "gray"
					? 0xff666666
					: 0xffffffff;
			let drawContours = mapData.currentMapType === "outline" ? true : false;

			yield mapData.shadeGuide.color.forEach(function (item) {
				colorsSM.push(parseInt(item.rgb, 16) + 0xff000000);
				fillColorsSM.push(parseInt(item.gc_map, 16) + 0xff000000);
			});

			yield modifier.values.forEach(function (item) {
				colorsM.push(parseInt(item.rgb, 16) + 0xff000000);
				fillColorsM.push(parseInt(item.gc_map, 16) + 0xff000000);
			});

			const isTeethSegmentVal = isTeethSegment ?? (meta.isTeethSegment ?? false)
			const teethMaskThresholdVal = teethMaskThreshold ?? (meta.teethMaskThreshold ?? 0.5)
			console.log(meta, isTeethSegment, teethMaskThreshold, isTeethSegmentVal, teethMaskThresholdVal);
			let teethMask = photo.imgTeethMask;
			if (!isTeethSegmentVal) {
				teethMask = null;
			}
			else {
				if (!photo.imgTeethMask || teethMaskThresholdVal != photo.teethMaskThreshold) {
					console.log('GENERATE NEW TEETH MASK');
					teethMask = yield call(doGenerateTeethMask, { threshold: teethMaskThresholdVal } );
				}
				else {
					console.log('USE SAME TEETH MASK');
				}
			}

			let correctedImage = cropCorrectedResult;
			const responseSM = yield call(
				photoUtils.segmentImage,
				correctedImage,
				colorsSM,
				bgColor,
				mapData.colorMapSensitivity,
				mapData.shadeLimits,
				drawContours,
                fillColorsSM,
                mapData.sensitivityLimits.shadeMap,
				teethMask?.slice() ?? null,
				teethMaskRect,
				{
					x: 0, y: 0, width: photo.imgOriginal.width, height: photo.imgOriginal.height
				}
			);
			yield put(showLoader(50, loaderText.PHOTO_MAP_GENERATING));
			yield delay(10);
			let paramsSM =
				colorsSM.length +
				bgColor.toString(16) +
				mapData.colorMapSensitivity.toString() +
				mapData.shadeLimits.toString() +
				drawContours +
				fillColorsSM.length;
			let imageType = IMAGE_SHADE_MAP;

			yield put(
				shadeMapGenerated(
					responseSM.resultImg,
					responseSM.mask,
					photo.id,
					paramsSM,
					imageType,
					true
				)
			);

			correctedImage = cropCorrectedResult;
			const responseM = yield call(
				photoUtils.segmentImage,
				correctedImage,
				colorsM,
				bgColor,
				mapData.modifierSensitivity,
				mapData.shadeLimits,
				drawContours,
                fillColorsM,
                mapData.sensitivityLimits.modifier,
				teethMask?.slice() ?? null,
				teethMaskRect,
				{
					x: 0, y: 0, width: photo.imgOriginal.width, height: photo.imgOriginal.height
				}
			);
			yield put(showLoader(75, loaderText.PHOTO_MAP_GENERATING));
			yield delay(10);
			let paramsM =
				colorsM.length +
				bgColor.toString(16) +
				mapData.modifierSensitivity.toString() +
				mapData.shadeLimits.toString() +
				drawContours +
				fillColorsM.length;
			imageType = IMAGE_MODIFIER;
			yield put(
				shadeMapGenerated(responseM.resultImg, responseM.mask, photo.id, paramsM, imageType, true)
            );

			yield put(setTeethSegment(isTeethSegmentVal ? 'on' : 'off'));
			yield put(setTeethMaskThreshold((1.0-teethMaskThresholdVal)*100.0));

            meta = {...meta,
                shadeMapSensitivity: mapData.colorMapSensitivity,
                modifierSensitivity: mapData.modifierSensitivity,
                shadeLimits: mapData.shadeLimits,
                background: mapData.currentBackground,
                regionRect: { x: rect.x, y: rect.y, width: rect.width, height: rect.height},
                shadeGuideId: mapData.shadeGuide.id,
                modifierId: modifier.id,
				isTeethSegment: isTeethSegmentVal,
				teethMaskThreshold: teethMaskThresholdVal
            };

            let r = yield call(swApi.makeRequest, 'updatePhotoMetaData', [photo.id, meta]);
            yield call(swApi.checkResponse, r);
            yield put(photoMetadataUpdated( photo.id, meta));

            yield doRecalculateShadeLabels();

            if (subrect)
                yield doGetRegionValue({rect: subrect});

			yield doGenerateTripleImage(
				photo.id,
				mapData,
				drawContours,
				cropCorrectedViewResult,
				responseSM.resultImg,
				responseM.resultImg,
				responseSM.mask,
				responseM.mask
            );
			yield doGenerateDoubleImage(
				photo.id,
				mapData,
				drawContours,
				responseSM.resultImg,
				responseM.resultImg,
				responseSM.mask,
				responseM.mask
            );
            if (!notShow)
			    yield put(selectedPhotoType(IMAGE_TRIPLE_VIEW));
			yield put(showLoader(100, loaderText.PHOTO_MAP_GENERATING));
			yield put(hideLoader());
		}
	} catch (error) {
        yield console.log(error);
        yield put(hideLoader());
        yield checkUserNotAuthenticated(error);
        yield put(photoProcessingError('Image processing error: ' + error + '\nPlease refresh browser to resolve memory issue.'));
	}
}

export function* doRestartOriginalImage() {
    try {
        let photo = yield select(getCurrentPhoto);
        let metadata = {...photo.metadata, colors: [], refColors: [], studioColors: [], coordinates: [], manualCorrected: false};
        let r = yield call(swApi.makeRequest, 'updatePhotoMetaData', [photo.id, metadata]);
        yield call(swApi.checkResponse, r);

    }
    catch (error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }
}

export function* doUpdateShadeLabelsMetadata() {
    try {
        let photo = yield select(getCurrentPhoto);
        let metadata = {...photo.metadata, shadeLabels: photo.shadeMapLabels, otherLabels: photo.modifierLabels } ;
        //console.log('doUpdateShadeLabelsMetadata', metadata)
        let r = yield call(swApi.makeRequest, 'updatePhotoMetaData', [photo.id, metadata]);
        yield call(swApi.checkResponse, r);
        yield put(photoMetadataUpdated(photo.id, metadata));
    }
    catch(error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }
}

export function* doUpdateShadeTabCoordsMetadata({photoId, coords}) {
    try {
        yield delay(1000);
        let photos = yield select(getPhotos);
        let photo = photos.find(item => item.id == photoId);
        let metadata = {...photo.metadata, shadeTabCoords: {...photo.shadeTabCoords, ...coords} } ;
        //console.log('doUpdateShadeTabCoordsMetadata', metadata)
        let r = yield call(swApi.makeRequest, 'updatePhotoMetaData', [photoId, metadata]);
        yield call(swApi.checkResponse, r);
        yield put(photoMetadataUpdated(photo.id, metadata));
    }
    catch(error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }
}

export function* doUpdateCrossHairCoordsMetadata({payload}) {
    try {
		//yield console.log(payload)
        yield delay(1000);
        let photos = yield select(getPhotos);
        let photo = photos.find(item => item.id == payload.photoId);
        let metadata = {...photo.metadata, crossHairPos: { x: payload.x, y: payload.y } } ;
        let r = yield call(swApi.makeRequest, 'updatePhotoMetaData', [payload.photoId, metadata]);
        yield call(swApi.checkResponse, r);
        yield put(photoMetadataUpdated(photo.id, metadata));
    }
    catch(error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }
}

export function* doUpdateShadeTransPosition({payload}) {
	try {
		yield delay(1000);
		//let photos = yield select(getPhotos);
        let photo = yield select(getCurrentPhoto);
        let metadata = {...photo.metadata, shadeTransPos: payload } ;
        let r = yield call(swApi.makeRequest, 'updatePhotoMetaData', [photo.id, metadata]);
        yield call(swApi.checkResponse, r);
        yield put(photoMetadataUpdated(photo.id, metadata));
	}
	catch(error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }
}

export function* doUpdateTextAnnotations({photoId, payload}) {
    try {
        yield delay(1000);
        
        //console.log('doUpdateTextAnnotations', payload)
        let r = yield call(swApi.makeRequest, 'updatePhotoTextAnnotations', [photoId, payload]);
        yield call(swApi.checkResponse, r);
        //yield put(photoMetadataUpdated(photo.id, metadata));
    }
    catch(error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }
}

export function* doUpdateGraphicAnnotations({photoId, payload}) {
    try {
        yield delay(1000);
        
        //console.log('doUpdateGraphicAnnotations', payload)
        let r = yield call(swApi.makeRequest, 'updatePhotoGraphicAnnotations', [photoId, payload]);
        yield call(swApi.checkResponse, r);
        //yield put(photoMetadataUpdated(photo.id, metadata));
    }
    catch(error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }
}

export function* doUpdateShadeTabSettingsMetadata({photoId, settings}) {
    try {
        yield delay(1000);
        let photos = yield select(getPhotos);
        let photo = photos.find(item => item.id == photoId);
        let metadata = {...photo.metadata, shadeTabSettings: settings } ;
        //console.log('doUpdateShadeTabSettingsMetadata', metadata)
        let r = yield call(swApi.makeRequest, 'updatePhotoMetaData', [photoId, metadata]);
        yield call(swApi.checkResponse, r);
        yield put(photoMetadataUpdated(photo.id, metadata));
    }
    catch(error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }
}

export function* doDeleteCase({ caseId }) {
	try {
		yield put(showComponentLoader(loaderText.CASES_LOADER_COMPONENT_NAME, 50, loaderText.CASE_LOAD));
		const setShadeTabsResponse = yield call(swApi.makeRequest, "deleteCase", [caseId]);
		yield call(swApi.checkResponse, setShadeTabsResponse);
		yield put(hideComponentLoader(loaderText.CASES_LOADER_COMPONENT_NAME));
		yield put(caseDeleted(caseId));
	} catch (error) {
		yield console.log(error);
		yield checkUserNotAuthenticated(error);
	}
}
export function* doGetNewCaseNum() {
	try {
		const response = yield call(swApi.makeRequest, "getCaseNewCaseNum", []);
		yield call(swApi.checkResponse, response);
		yield put(getNewCaseNumSuccess(response.data));
	} catch (error) {
		yield console.log(error);
		yield checkUserNotAuthenticated(error);
	}
}

export function* doCreateCase({ data, files }) {
	try {

		yield put(createCasePending());
		let response = yield call(swApi.makeRequest, "createCase", [
			{ doctorId: "", username: "", storage: "", useCredit: false, ...data },
		]);
		yield call(swApi.checkResponse, response);

		if (!/^[0-9]+$/.test(response.data)) {
			yield put(createCaseError(response.data));
		} else {
			const caseId = parseInt(response.data);

            if (caseId === 0) {
                yield put(createCaseError('To create the case it is necessary to use credits, but there are no available Case Uses. Please, purchase new Case Uses from store.shadewave.com'));
                const url = 'https://store.shadewave.com/index.php?route=product/product&product_id=63';
                yield call (window.open, url, '_blank');
                return;
            }

			if (files && files.length > 0) {
				yield all(
					files.map((file) => {
						return call(swApi.makeRequest, "uploadPhoto", [
							{
								case_id: caseId,
								filedataOrig: {
									data: file.result,
								},
								filenameOrig: file.data.name,
								content_type: "base64",
							},
						]);
					})
				);

                yield call(swApi.makeRequest, "notifyLabAboutPhotos", [caseId]);
			}

            if (data.patId) {
                response = yield call(swApi.makeRequest, "setCaseInformation", [caseId, data.patId, data.patientFirst, data.patientLast], 'SWDentrix');
                yield call(swApi.checkResponse, response);
            }

			yield put(createCaseSuccess(caseId));
            
            let userProfile = yield select(getUserProfile);
            if (userProfile.role_id === ROLE_LAB || userProfile.role_id === ROLE_LAB_MOBILE_CASE_FREE) {
                yield put(creditsCharged());
            }
            
            if (data.patId) {
                const url = window.location.origin + '/case/' + caseId;
                yield call (window.open, url, '_blank');
                yield call (history.push, '/ascend/selectcase');
            }
            else {
                yield call (history.push, '/case/' + caseId);
            }

		}
	} catch (error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
        yield put(createCaseError(error.message.replace('. ', '.<br/>')));
	}
}

export function* doUndoRedoAnnotations({currentPhoto, width, height, angle, flipOrientation, last_operation}) {
	let textAnnotations = yield select(getTextAnnotations);
    	let graphicObjects = yield select(getGraphicAnnotations);
    	let shadeTabCoords = yield select(getShadeTabCoords);
console.log(width, height, angle, flipOrientation, last_operation)
		if ((last_operation == OPERATION_ROTATE)) {
			let newWidth = 2* (Math.abs(width/2*Math.cos(angle)) + Math.abs(height/2*Math.sin(angle)) )
			let newHeight = 2* (Math.abs(height/2*Math.cos(angle)) + Math.abs(width/2*Math.sin(angle)) )
			//console.log(angle, width, height, currentPhoto)

			if ((textAnnotations?.length ?? 0) > 0) {
				yield put(updateTextAnnotations(
					currentPhoto.id,
					textAnnotations.map((item) => {
						let x = item.x - width/2;
						let y = item.y - height/2;
						let x1 =  x*Math.cos(angle) - y*Math.sin(angle) + newWidth/2 
						let y1 = x*Math.sin(angle) + y*Math.cos(angle) + newHeight/2
						return { ...item, x: x1, y: y1 };
					})
				));
			}
			if ((graphicObjects?.length ?? 0) > 0) {
				yield put(updateGraphicAnnotations(
					currentPhoto.id,
					graphicObjects.map((g) => {
						let result = g;
						if (g.graph_type == GRAPHIC_LINE || g.graph_type == GRAPHIC_ARROW) {
							let x1 = g.points[0] - width/2;
							let y1 = g.points[1] - height/2;
							let x1n =  x1*Math.cos(angle) - y1*Math.sin(angle) + newWidth/2 
							let y1n = x1*Math.sin(angle) + y1*Math.cos(angle) + newHeight/2
							let x2 = g.points[2] - width/2;
							let y2 = g.points[3] - height/2;
							let x2n =  x2*Math.cos(angle) - y2*Math.sin(angle) + newWidth/2 
							let y2n = x2*Math.sin(angle) + y2*Math.cos(angle) + newHeight/2
							result = { ...g, points: [x1n, y1n, x2n, y2n] };
						}
						else if (g.graph_type == GRAPHIC_CIRCLE) {
							let x = g.x - width/2;
							let y = g.y - height/2;
							let x1 =  x*Math.cos(angle) - y*Math.sin(angle) + newWidth/2 
							let y1 = x*Math.sin(angle) + y*Math.cos(angle) + newHeight/2
							result = { ...g, x: x1, y : y1 };
						}
							
						return result;
					}),
				));
			}
			if (shadeTabCoords ) {
				let shadeCoords = {}
				if (shadeTabCoords.left) {
					let x = shadeTabCoords.left.x - width/2;
					let y = shadeTabCoords.left.y - height/2;
					let x1 =  x*Math.cos(angle) - y*Math.sin(angle) + newWidth/2 
					let y1 = x*Math.sin(angle) + y*Math.cos(angle) + newHeight/2
					shadeCoords = {...shadeCoords, ...{left : {x : x1, y: y1, ver: '1.1'}}}
				}
				if (shadeTabCoords.right) {
					let x = shadeTabCoords.right.x - width/2;
					let y = shadeTabCoords.right.y - height/2;
					let x1 =  x*Math.cos(angle) - y*Math.sin(angle) + newWidth/2 
					let y1 = x*Math.sin(angle) + y*Math.cos(angle) + newHeight/2
					shadeCoords = {...shadeCoords, ...{right : {x : x1, y: y1, ver: '1.1'}}}
				}
				yield put(updateShadeTabCoords(currentPhoto.id, {
					...shadeTabCoords,
					...shadeCoords,
				}));
			}
		}
		else if ((last_operation == OPERATION_FLIP)) {
			
			if ((textAnnotations?.length ?? 0) > 0) {
				yield put(updateTextAnnotations(
					currentPhoto.id,
					textAnnotations.map((item) => {
						return { ...item, x: ((flipOrientation === 1) ? item.x : (width -  item.x)), y: ((flipOrientation === 1) ? (height - item.y) : item.y) };
					})
				));
			}
			if ((graphicObjects?.length ?? 0) > 0) {
				yield put(updateGraphicAnnotations(
					currentPhoto.id,
					graphicObjects.map((g) => {
						let result = g;
						if (g.graph_type == GRAPHIC_LINE || g.graph_type == GRAPHIC_ARROW) {
							result = { ...g, points: [ ((flipOrientation == 1) ? g.points[0] : (width - g.points[0])),
													 ((flipOrientation == 1) ? (height - g.points[1]) : g.points[1]),
													 ((flipOrientation == 1) ? g.points[2] : (width - g.points[2])), 
													 ((flipOrientation == 1) ? (height - g.points[3]) : g.points[3]) 
													] };
						}
						else if (g.graph_type == GRAPHIC_CIRCLE) {
							result = { ...g, x: ((flipOrientation == 1) ? g.x : (width - g.x) ) , y : ((flipOrientation == 0) ? g.y : (height - g.y) ) };
						}
							
						return result;
						
					}),
				));
			}
			if (shadeTabCoords ) {
				let shadeCoords = {}
				if (shadeTabCoords.left) {
					shadeCoords = {...shadeCoords, ...{
						left : {
							x : ((flipOrientation == 1) ? shadeTabCoords.left.x : (width - shadeTabCoords.left.x) ), 
							y: ((flipOrientation == 0) ? shadeTabCoords.left.y : (height - shadeTabCoords.left.y) ),
							ver: '1.1'
						}}}
				}
				if (shadeTabCoords.right) {
					shadeCoords = {...shadeCoords, ...{
						right : {
							x : ((flipOrientation == 1) ? shadeTabCoords.right.x : (width - shadeTabCoords.right.x) ), 
							y: ((flipOrientation == 0) ? shadeTabCoords.right.y : (height - shadeTabCoords.right.y) ),
							ver: '1.1'
						}}}
				}
				yield put(updateShadeTabCoords(currentPhoto.id, {
					...shadeTabCoords,
					...shadeCoords,
				}));
			}
		}
}

export function* doUndoSavedPhoto({photoId}) {
    try {
        yield put(showLoader(25, loaderText.UNDO_IMAGE));
		let currentPhoto = yield select(getCurrentPhoto);
		let rotateDegrees = (currentPhoto.last_undo_operation == OPERATION_ROTATE) ? currentPhoto.last_undo_value : 0;
		console.log(rotateDegrees, currentPhoto.last_undo_operation, currentPhoto.last_undo_value)
		let angle = -rotateDegrees * Math.PI / 180;
        let width = currentPhoto.imgOriginal?.width;
        let height = currentPhoto.imgOriginal?.height;
		let flipOrientation = (currentPhoto.last_undo_operation == OPERATION_FLIP) ? currentPhoto.last_undo_value : -1;

        const response = yield call(swApi.makeRequest, "undoChangedPhoto", [ photoId ]);
        yield call(swApi.checkResponse, response);
        
        yield put(showLoader(50, loaderText.UNDO_IMAGE));

        yield call(doLoadPhotos, { photos: [response.data] });
		yield put(showLoader(75, loaderText.UNDO_IMAGE));

		yield call(doUndoRedoAnnotations, {currentPhoto, width, height, angle, flipOrientation, last_operation: currentPhoto.last_undo_operation})
		
		yield put(undoCompleted())
        yield put(showLoader(100, loaderText.UNDO_IMAGE));
        yield put(delay(10));
        yield put(hideLoader());
    }
    catch(error) {
        yield put(hideLoader());
        yield checkUserNotAuthenticated(error);
        yield put(photoProcessingError(error.message));
    }
}

export function* doRedoSavedPhoto({photoId}) {
    try {
        yield put(showLoader(25, loaderText.REDO_IMAGE));
		let currentPhoto = yield select(getCurrentPhoto);
		let rotateDegrees = (currentPhoto.last_redo_operation == OPERATION_ROTATE) ? currentPhoto.last_redo_value : 0;
		console.log(rotateDegrees, currentPhoto.last_redo_operation, currentPhoto.last_redo_value)
		let angle = rotateDegrees * Math.PI / 180;
        let width = currentPhoto.imgOriginal?.width;
        let height = currentPhoto.imgOriginal?.height;
		let flipOrientation = (currentPhoto.last_redo_operation == OPERATION_FLIP) ? currentPhoto.last_redo_value : -1;

        const response = yield call(swApi.makeRequest, "redoChangedPhoto", [ photoId ]);
        yield call(swApi.checkResponse, response);
        
        yield put(showLoader(50, loaderText.REDO_IMAGE));

        yield call(doLoadPhotos, { photos: [response.data] });
		yield put(showLoader(75, loaderText.REDO_IMAGE));
		
		yield call(doUndoRedoAnnotations, {currentPhoto, width, height, angle, flipOrientation, last_operation: currentPhoto.last_redo_operation});
		yield put(redoCompleted());
        yield put(showLoader(100, loaderText.REDO_IMAGE));
        yield put(delay(10));
        yield put(hideLoader());
    }
    catch(error) {
        yield put(hideLoader());
        yield checkUserNotAuthenticated(error);
        yield put(photoProcessingError(error.message));
    }
}



export function* doSaveRotatedPhoto({photoId, rotation}) {
    try {
        yield put(showLoader(25, loaderText.SAVING_IMAGE_TO_SERVER));
        const response = yield call(swApi.makeRequest, "saveRotatedPhoto", [ photoId, rotation]);
        yield call(swApi.checkResponse, response);
        
        yield put(showLoader(50, loaderText.SAVING_IMAGE_TO_SERVER));

        yield call(doLoadPhotos, { photos: [response.data] });
        yield put(showLoader(100, loaderText.SAVING_IMAGE_TO_SERVER));
        yield put(delay(10));
        yield put(hideLoader());
    }
    catch(error) {
        yield put(hideLoader());
        yield checkUserNotAuthenticated(error);
        yield put(photoProcessingError(error.message));
    }
}

export function* doSaveFlippedPhoto({photoId, flipOrientation}) {
    try {
        yield put(showLoader(25, loaderText.SAVING_IMAGE_TO_SERVER));
        const response = yield call(swApi.makeRequest, "saveFlippedPhoto", [ photoId, flipOrientation]);
        yield call(swApi.checkResponse, response);
        
        yield put(showLoader(50, loaderText.SAVING_IMAGE_TO_SERVER));

        yield call(doLoadPhotos, { photos: [response.data] });
        yield put(showLoader(100, loaderText.SAVING_IMAGE_TO_SERVER));
        yield put(delay(10));
        yield put(hideLoader());
    }
    catch(error) {
        yield put(hideLoader());
        yield checkUserNotAuthenticated(error);
        yield put(photoProcessingError(error.message));
    }
}



export function* onCropImage() {
	yield takeLatest(WorkAreaActionTypes.CROP_IMAGE, function* ({ photoId, caseId, details }) {
		try {
            yield put(showLoader(25, loaderText.CREATE_CROPPED_PHOTO));
			const response = yield call(swApi.makeRequest, "cropPhoto", [{ photoId: photoId, caseId: caseId, ...details }]);

			if (typeof response.data.errorDetails !== "undefined") {
                yield put(hideLoader());
				return yield put(photoProcessingError(response.data.info));
			}
            yield put(showLoader(50, loaderText.CREATE_CROPPED_PHOTO));
			yield put(cropImageSuccess(response.data));
            yield call(doLoadPhotos, { photos: [response.data] });
            yield put(showLoader(75, loaderText.CREATE_CROPPED_PHOTO));
            yield put(setActivePhoto(response.data.id));
            yield put(showLoader(100, loaderText.CREATE_CROPPED_PHOTO));
            yield put(delay(10));
            yield put(hideLoader());
		} catch (error) {
			console.error(error);
		}
	});
}

export function* onGrayScaleImage() {
	yield takeLatest(WorkAreaActionTypes.GRAYSCALE_IMAGE, function* ({ photoId }) {
		try {
            yield put(showLoader(25, loaderText.CREATE_GRAYSCALE_PHOTO));
			const response = yield call(swApi.makeRequest, "grayScalePhoto", [photoId]);

			if (typeof response.data.errorDetails !== "undefined") {
                yield put(hideLoader());
				return yield put(photoProcessingError(response.data.info));
			}
            yield put(showLoader(50, loaderText.CREATE_GRAYSCALE_PHOTO));
			yield put(grayscaleImageSuccess(response.data));
            yield call(doLoadPhotos, { photos: [response.data] });
            yield put(showLoader(75, loaderText.CREATE_GRAYSCALE_PHOTO));
            yield put(setActivePhoto(response.data.id));
            yield put(showLoader(100, loaderText.CREATE_GRAYSCALE_PHOTO));
            yield put(delay(10));
            yield put(hideLoader());
		} catch (error) {
			console.error(error);
		}
	});
}

export function* onLoadCase() {
	yield takeLatest(CaseActionTypes.LOAD_CASE, doLoadCase);
}
export function* onDeletePhoto() {
	yield takeLatest(CaseActionTypes.DELETE_PHOTO, function* ({ photoId }) {
		yield call(swApi.makeRequest, "deletePhoto", [photoId]);

		yield put(deletePhotoSuccess(photoId));
	});
}
export function* onPhotoUpload() {
	yield takeEvery(CaseActionTypes.UPLOAD_PHOTO, doUploadPhoto);
}

export function* onLoadCases() {
	yield takeLatest(CaseActionTypes.LOAD_CASES, doLoadCases);
}

export function* onLoadShadeTabs() {
	yield takeLatest(CaseActionTypes.GET_SHADE_TABS, doLoadShadeTabs);
}
export function* onLoadShadeGuides() {
	yield takeLatest(CaseActionTypes.GET_SHADE_GUIDES, doLoadShadeGuides);
}
export function* onSetShadeTabs() {
	yield takeLatest(CaseActionTypes.SET_SHADE_TABS, doSetShadeTabs);
}

export function* onSetActivePhoto() {
	yield takeLatest(CaseActionTypes.SET_ACTIVE_PHOTO, doProcessActivePhoto);
}

export function* onGetShadeGuide() {
	yield takeLatest(CaseActionTypes.GET_SHADE_GUIDE, doGetShadeGuide);
}

export function* onDeleteCase() {
	yield takeLatest(CaseActionTypes.DELETE_CASE, doDeleteCase);
}
export function* onCreateCase() {
	yield takeLatest(CaseActionTypes.CREATE_CASE, doCreateCase);
}
export function* onGetNewCaseNum() {
	yield takeLatest(CaseActionTypes.GET_NEW_CASE_NUM, doGetNewCaseNum);
}
export function* onGenerateShadeMap() {
	yield takeLatest(CaseActionTypes.GENERATE_SHADE_MAP, doGenerateShadeMap);
}
export function* onGetConnectedSpecialists() {
	yield takeLatest(CaseActionTypes.GET_CONNECTED_SPECIALISTS, function* () {
		try {
			const response = yield call(swApi.makeRequest, "getConnectedSpecialists", []);
			yield call(swApi.checkResponse, response);

			yield put(getConnectedSpecialistsSuccess(response.data));
		} catch (e) {
			yield console.error(e);
			yield checkUserNotAuthenticated(e);
		}
	});
}
export function* onGetLabs() {
	yield takeLatest(CaseActionTypes.GET_LABS, function* () {
		try {
			const response = yield call(swApi.makeRequest, "getConnectedLabs", []);
			yield call(swApi.checkResponse, response);

			yield put(getLabsSuccess(response.data));
		} catch (e) {
			yield console.error(e);
			yield checkUserNotAuthenticated(e);
		}
	});
}
export function* onSetCaseLab() {
	yield takeLatest(CaseActionTypes.SET_CASE_LAB, function* ({ caseId, labId }) {
		try {
			const response = yield call(swApi.makeRequest, "updateCaseLab", [caseId, labId]);

			if (typeof response.data.error !== "undefined") {
				if (response.data.error == 'expired')
					yield put(setCaseLabError(SET_CASE_LAB_ERROR, caseId, response.data.labId));
				else if (response.data.error == 'nocredit')
					yield put(setCaseLabError(SET_CASE_LAB_ERROR_NOCREDIT, caseId, response.data.labId));
			} else {
				yield put(setCaseLabSuccess(getLabReassignMessage(response.data), caseId, response.data.labId));
			}
		} catch (e) {
			yield console.error(e);
			yield checkUserNotAuthenticated(e);
		}
	});
}
export function* onSetCaseSpecialist() {
	yield takeLatest(CaseActionTypes.SET_CASE_SPECIALIST, function* ({ caseId, specialistId }) {
		try {
			const response = yield call(swApi.makeRequest, "updateCaseSpecialist", [caseId, specialistId]);

			if (typeof response.data.error !== "undefined") {
				yield put(setCaseSpecialistError(SET_CASE_SPECIALIST_ERROR, caseId, response.data.specId));
			} else {
				yield put(setCaseSpecialistSuccess(getSpecialistReassignMessage(response.data), caseId, response.data.specId));
			}
		} catch (e) {
			yield console.error(e);
			yield checkUserNotAuthenticated(e);
		}
	});
}

export function* onColorCorrect() {
	yield takeLatest(CaseActionTypes.RUN_COLOR_CORRECT, doPhotoCorrection);
}

export function* doLoadCaseMessages({ payload }) {
	try {
		const response = yield call(swApi.makeRequest, "getAllCaseMessages", [payload]);

		yield call(swApi.checkResponse, response);
		yield put(loadCaseMessagesSuccess(response.data));
	} catch (e) {
		yield put(loadCaseMessagesSuccess([]));
	}
}

export function* doLoadAllCasePhoto({ payload }) {
	try {
		const response = yield call(swApi.makeRequest, "getAllCasePhotos", [payload]);

		yield call(swApi.checkResponse, response);
		const data = response.data;
		yield put(loadAllCasePhotoSuccess(data.length));
		yield put(loadCaseMessagePhoto(data));
	} catch (e) {
		yield put(loadAllCasePhotoSuccess(0));
	}
}
export function* doSaveCaseMessage({ payload: { caseId, message, roleList } }) {
	try {
		const response = yield call(swApi.makeRequest, "setMessage", [caseId, message, roleList]);

		yield call(swApi.checkResponse, response);
		yield put(caseMessagesSaveFinished());
		yield put(loadCaseMessages(caseId));
	} catch (e) {
		yield put(caseMessagesSaveFinished());
	}
}

export function* doSaveCaseAttachment({ payload: { caseId, files } }) {
	let name = "";
	try {
		yield put(setPhotosToSaveCount(files.length));
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const data = file.data;
			name = file.name;
			const response = yield call(swApi.makeRequest, "saveAsPhotoMessage", [caseId, { data }, name, true]);
			yield call(swApi.checkResponse, response);
			yield put(loadCaseMessagePhoto([{ photo_id: response.data }]));
			yield put(caseMessagePhotoSaveFinished({ saved: true }));
		}
	} catch (e) {
		yield put(caseMessagePhotoSaveFinished({ saved: false, name }));
	}
}

export function* doLoadCaseMessagePhoto({ payload }) {
	let targetPhoto = null;
	try {
		const photos = payload;
		for (let i = 0; i < photos.length; i++) {
			targetPhoto = photos[i];
			const date = new Date();
			const salt = date.getTime().toString();
			const photoId = targetPhoto.photo_id;
			const response = yield call(swApi.makeRequest, "getMessagePhoto", [photoId, salt, false]);
			yield call(swApi.checkResponse, response);
			const data = response.data;
			const canParse = isJson(data);
			let pl = {};

			if (canParse) {
				pl = { data: JSON.parse(data), loaded: true };
			} else {
				pl = { data: targetPhoto, loaded: false };
			}
			yield put(caseMessagePhotoLoaded(pl));
		}
	} catch (e) {
		yield put(caseMessagePhotoLoaded({ loaded: false, data: targetPhoto }));
	}
}

export function* doDownloadPhotos({caseId, photosList}) {
    const response = yield call(swApi.makeRequest, "downloadPhotos", [caseId, photosList]);
    yield call(swApi.checkResponse, response);
    const id = response.data;
    const url = process.env.REACT_APP_API_URL + 'tool/flex-helper.php?action=getZip&cID=' + id;
    yield call (window.open, url, '_blank');
}

export function* doCompleteCase({caseId}) {

    const response = yield call(swApi.makeRequest, "sendCaseCompleteEmail", [caseId]);
    yield call(swApi.checkResponse, response);
    yield put(caseCompleted(caseId));
}

export function* doRenewCase({caseId}) {

    const response = yield call(swApi.makeRequest, "renewCase", [caseId]);
    yield call(swApi.checkResponse, response);
    yield put(caseRenewed(caseId));
}

export function* doSaveImageToServer({canvas, photoId, photo_type}) {
    let caseData = yield select(getCurrentCase);
    yield put(showLoader(25, loaderText.SAVING_IMAGE_TO_SERVER));
    const ctx = canvas.getContext('2d');
    ctx.webkitImageSmoothingEnabled = true;
    ctx.mozImageSmoothingEnabled = true;
    ctx.imageSmoothingEnabled = true;
    let canvasBlob = yield new Promise((resolve, reject) => {
        canvas.toBlob(function(blob) {
            resolve(blob);
        },
        'image/jpeg', 1.0);
    });
    yield put(showLoader(50, loaderText.SAVING_IMAGE_TO_SERVER));

    const reader = new FileReader();

    let byteArray = yield new Promise((resolve, reject) => {
        reader.onload = function (event) {
            //console.log('loadend', event.target.result);
            resolve(event.target.result);

        };
        reader.readAsDataURL(canvasBlob);
    });
    yield put(showLoader(75, loaderText.SAVING_IMAGE_TO_SERVER));
    let response = yield call(swApi.makeRequest,"uploadMapPhoto", [caseData.caseId, photoId, photo_type, {data: byteArray}]);

    //yield console.log(response);
    yield call(swApi.checkResponse, response);
    yield put(showLoader(100, loaderText.SAVING_IMAGE_TO_SERVER));
    yield put(hideLoader());


    console.log('finished');

}

function* doUpdateShowWalkthrough( { value } ) {
    try {
		//yield console.log('doUpdateShowWalkthrough', value);
		const response = yield call(swApi.makeRequest, "setDefaultProperty", [
			"showWalkthrough",
			value,
		]);
		yield call(swApi.checkResponse, response);
	} catch (error) {
		yield console.log(error);
		yield checkUserNotAuthenticated(error);
	}
}

function* doUpdateLastMap( { photoType } ) {
    try {
		if (Array.of(IMAGE_SHADE_MAP, IMAGE_DOUBLE_VIEW, IMAGE_MODIFIER, IMAGE_TRIPLE_VIEW).includes(photoType)) {
			const response = yield call(swApi.makeRequest, "setDefaultProperty", [
				"lastMap",
				photoType,
			]);
			yield call(swApi.checkResponse, response);
		}
	} catch (error) {
		yield console.log(error);
		yield checkUserNotAuthenticated(error);
	}
}

function* doSaveShadeLimits( {value} ) {
    try {
		//yield console.log('doUpdateShowWalkthrough', value);
		const response = yield call(swApi.makeRequest, "setDefaultProperty", [
			"limits",
			value,
		]);
		yield call(swApi.checkResponse, response);
	} catch (error) {
		yield console.log(error);
		yield checkUserNotAuthenticated(error);
	}
}

function* doCheckAllMessagesRead({ caseId }) {
    try {
		const response = yield call(swApi.makeRequest, "checkAllMessagesRead", [
			caseId,
		]);
		yield call(swApi.checkResponse, response);
        yield put(messagesReadAll(response.data?.allSentMessagesRead, response.data?.hasUnreadReceived));
	} catch (error) {
		yield console.log(error);
		yield checkUserNotAuthenticated(error);
	}
}

function isJson(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export function* doGetMagicTouchCustomersList({token, labid}) {
    try {
		const response = yield call(swApi.makeRequest, "getMagicTouchCustomersList", [
			token, labid
		]);
		yield call(swApi.checkResponse, response);
        yield put(magicTouchCustomersListLoaded(response.data));
        
	} catch (error) {
		yield console.log(error);
		yield checkUserNotAuthenticated(error);
	}
}

export function* doGetMagicTouchPracticeDoctorsList({token, customerid}) {
    try {
		const response = yield call(swApi.makeRequest, "getMagicTouchPracticeDoctorsList", [
			token, customerid
		]);
		yield call(swApi.checkResponse, response);
        yield put(magicTouchPracticeDoctorsListLoaded(response.data));
	} catch (error) {
		yield console.log(error);
		yield checkUserNotAuthenticated(error);
	}
}

export function* doGetMagicTouchProductsList({token, labid}) {
    try {
		const response = yield call(swApi.makeRequest, "getMagicTouchProductsList", [
			token, labid
		]);
		yield call(swApi.checkResponse, response);
        yield put(magicTouchProductsListLoaded(response.data));
	} catch (error) {
		yield console.log(error);
		yield checkUserNotAuthenticated(error);
	}
}

export function* doUpdateAlternativeId({payload: {caseId, altId}}) {
    try {
        const response = yield call(swApi.makeRequest, 'updateAlternativeId', [caseId, altId]);
        yield call(swApi.checkResponse, response);
    }
    catch (error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }
}

export function* doUpdateCaseDetails({payload: {caseId, editData}}) {
    try {
        const response = yield call(swApi.makeRequest, 'updateCaseDetails', [caseId, editData]);
        yield call(swApi.checkResponse, response);
    }
    catch (error) {
        yield console.log(error);
        yield checkUserNotAuthenticated(error);
    }
}

export function* onLoadCaseMessages() {
	yield takeLatest(CaseActionTypes.LOAD_CASE_MESSAGES, doLoadCaseMessages);
}
export function* onLoadAllCasePhoto() {
	yield takeLatest(CaseActionTypes.LOAD_ALL_CASE_PHOTO, doLoadAllCasePhoto);
}
export function* onSaveCaseMessage() {
	yield takeLatest(CaseActionTypes.SAVE_CASE_MESSAGE, doSaveCaseMessage);
}
export function* onSaveCaseAttachment() {
	yield takeLatest(CaseActionTypes.SAVE_MESSAGE_ATTACHMENT, doSaveCaseAttachment);
}
export function* onLoadCaseMessagePhoto() {
	yield takeLatest(CaseActionTypes.LOAD_CASE_MESSAGE_PHOTO, doLoadCaseMessagePhoto);
}
export function* onAutoCompleteSend() {
	yield takeLatest(CaseActionTypes.AUTOCOMPLETE_SEND, function* ({ username, field }) {
		try {
			const response = yield call(swApi.makeRequest, "autoComplete", [username, field]);
			yield call(swApi.checkResponse, response);

			yield put(autoCompleteResult(response.data));
		} catch (e) {
			console.error(e);
			yield checkUserNotAuthenticated(e);
		}
	});
}



export function* onProcessRegion() {
	yield takeLatest(CaseActionTypes.PROCESS_REGION, doProcessRegion);
}

export function* onRestartOriginalImage() {
    yield takeLatest(CaseActionTypes.RESTART_ORIGINAL_IMAGE, doRestartOriginalImage);
}

export function* onGetRegionValue( ) {
    yield takeLatest(CaseActionTypes.GET_REGION_VALUE, doGetRegionValue);
}

export function* onUpdateShadeLabelsMetadata() {
    yield takeLatest(CaseActionTypes.UPDATE_SHADE_LABELS_METADATA, doUpdateShadeLabelsMetadata);
}

export function* onDownloadPhotos() {
    yield takeEvery(CaseActionTypes.DOWNLOAD_PHOTOS, doDownloadPhotos);
}

export function* onCompleteCase() {
    yield takeLatest(CaseActionTypes.MARK_CASE_COMPLETE, doCompleteCase);
}

export function* onRenewCase() {
    yield takeLatest(CaseActionTypes.MARK_CASE_NEW, doRenewCase);
}

export function* onsaveImageToServer() {
    yield takeLatest(CaseActionTypes.SAVE_IMAGE_TO_SERVER, doSaveImageToServer);
}

export function* onLoadQcPhoto() {
	yield takeLatest(CaseActionTypes.LOAD_QC_PHOTO, doLoadQcPhoto);
}

export function* onChangeShowWalkthrough() {
    yield takeLatest(CaseActionTypes.UPDATE_SHOW_WALKTHROUGH, doUpdateShowWalkthrough);
}

export function* onSaveShadeLimits() {
    yield takeLatest(WorkAreaActionTypes.SET_SHADE_LIMITS, doSaveShadeLimits);
}

export function* onSaveRotatedPhoto() {
    yield takeLatest(CaseActionTypes.SAVE_ROTATED_PHOTO, doSaveRotatedPhoto);
}

export function* onCheckAllMessagesRead() {
    yield takeLatest(CaseActionTypes.CHECK_ALL_MESSAGES_READ, doCheckAllMessagesRead);
}

export function* onNotifyLabAboutPhotos() {
    yield takeEvery(CaseActionTypes.NOTIFY_LAB_ABOUT_PHOTOS, doNotifyLabAboutPhotos);
}

export function* onGetMagicTouchCustomersList() {
    yield takeLatest(CaseActionTypes.GET_MAGICTOUCH_CUSTOMERS_LIST, doGetMagicTouchCustomersList);
}

export function* onGetMagicTouchPracticeDoctorsList() {
    yield takeLatest(CaseActionTypes.GET_MAGICTOUCH_PRACTICE_DOCTORS_LIST, doGetMagicTouchPracticeDoctorsList);
}

export function* onGetMagicTouchProductsList() {
    yield takeLatest(CaseActionTypes.MAGICTOUCH_GET_PRODUCTS_LIST, doGetMagicTouchProductsList);
}

export function* onPhotoShadeTabCoordsMetadataUpdated() {
    yield takeLatest(CaseActionTypes.UPDATE_SHADE_TAB_COORDS, doUpdateShadeTabCoordsMetadata);
}

export function* onPhotoShadeTabSettingsMetadataUpdated() {
    yield takeLatest(CaseActionTypes.UPDATE_SHADE_TAB_SETTINGS, doUpdateShadeTabSettingsMetadata);
}

export function* onPhotoUpdateTextAnnotations() {
    yield takeLatest(CaseActionTypes.UPDATE_TEXT_ANNOTATIONS, doUpdateTextAnnotations);
}

export function* onPhotoUpdateGraphicAnnotations() {
    yield takeLatest(CaseActionTypes.UPDATE_GRAPHIC_ANNOTATIONS, doUpdateGraphicAnnotations);
}

export function* onUpdateCrossHairPosition() {
	yield takeLatest(CaseActionTypes.SAVE_CROSSHAIR_POSITION, doUpdateCrossHairCoordsMetadata);
}

export function* onSaveFlippedPhoto() {
	yield takeEvery(CaseActionTypes.SAVE_FLIPPED_PHOTO, doSaveFlippedPhoto);
}

export function* onUndoSavedPhoto() {
	yield takeLatest(CaseActionTypes.UNDO_SAVED_PHOTO, doUndoSavedPhoto);
}

export function* onRedoSavedPhoto() {
	yield takeLatest(CaseActionTypes.REDO_SAVED_PHOTO, doRedoSavedPhoto);
}

export function* onUpdateAlternativeId() {
    yield takeEvery(CaseActionTypes.UPDATE_ALTERNATIVE_ID, doUpdateAlternativeId);
}

export function* onUpdateShadeTransPosition() {
	yield takeLatest(CaseActionTypes.UPDATE_SHADE_TRANS_POSITION, doUpdateShadeTransPosition);
}

export function* onUpdateLastMap() {
	yield takeLatest(CaseActionTypes.SELECTED_PHOTO_TYPE, doUpdateLastMap);
}

export function* onUpdateCaseDetails() {
	yield takeLatest(CaseActionTypes.UPDATE_CASE_DETAILS, doUpdateCaseDetails);
}

export function* caseSagas() {
	yield all([
		call(onLoadCase),
		call(onPhotoUpload),
		call(onLoadCases),
		call(onLoadShadeTabs),
		call(onLoadShadeGuides),
		call(onSetShadeTabs),
		call(onSetActivePhoto),
		call(onGetShadeGuide),
		call(onCreateCase),
		call(onGetNewCaseNum),
		call(onGenerateShadeMap),
		call(onDeleteCase),
		call(onGetConnectedSpecialists),
		call(onGetLabs),
		call(onSetCaseLab),
		call(onSetCaseSpecialist),
		call(onDeletePhoto),
		call(onColorCorrect),
		call(onCropImage),
		call(onLoadCaseMessages),
		call(onLoadAllCasePhoto),
		call(onSaveCaseMessage),
		call(onSaveCaseAttachment),
		call(onLoadCaseMessagePhoto),
		call(onAutoCompleteSend),
        call(onProcessRegion),
        call(onRestartOriginalImage),
        call(onGetRegionValue),
        call(onUpdateShadeLabelsMetadata),
        call(onDownloadPhotos),
        call(onCompleteCase),
        call(onsaveImageToServer),
        call(onChangeShowWalkthrough),
        call(onSaveShadeLimits),
        call(onSaveRotatedPhoto),
        call(onCheckAllMessagesRead),
        call(onNotifyLabAboutPhotos),
        call(onGetMagicTouchCustomersList),
        call(onGetMagicTouchPracticeDoctorsList),
        call(onGetMagicTouchProductsList),
        call(onPhotoShadeTabCoordsMetadataUpdated),
        call(onPhotoShadeTabSettingsMetadataUpdated),
        call(onPhotoUpdateTextAnnotations),
		call(onSaveFlippedPhoto),
		call(onUpdateCrossHairPosition),
		call(onPhotoUpdateGraphicAnnotations),
		call(onUndoSavedPhoto),
		call(onRedoSavedPhoto),
		call(onUpdateAlternativeId),
		call(onUpdateShadeTransPosition),
		call(onUpdateLastMap),
		call(onUpdateCaseDetails),
		call(onRenewCase),
		call(onGrayScaleImage)
	]);
}

import UIActionTypes from '../actions/ui.types';
import LoaderActionTypes from '../actions/loader.types';
import CaseActionTypes from '../actions/case.types';
import DentistActionTypes from '../actions/dentist.types';
import UserActionTypes from '../actions/user.types';

const initialNotification = {
    hideDuration: 0,
    type: 'success',
    message: '',
    title: ''
};

const initialNotificationDialog = {
    type: 'success',
    message: '',
    title: ''
};

const initialState = {
    isUserMenuShow: false,
    isProfileShow: false,
    isWorkAreaMenuShow: true,


    isLoader: false,
    loaderPercent: 0,
    loaderText: '',
    loaderComponent: '',
    isPhotoUploadProgress: false,

    isCreateCasePending: false,

    notification: initialNotification,
    notificationDialog: initialNotificationDialog,
    isLightHeader: false,
    setCaseLabError: '',
    setCaseSpecialistError: '',
    isSearchLabsProcess: false,
    isGetSpecialistsProcess: false,
    selectedColors: [],
    uploadPhotoError: '',
};

function uiReducer(state = initialState, action) {
    switch (action.type) {
        case CaseActionTypes.LOAD_CASE:
        case UserActionTypes.SIGN_OUT_SUCCESS:
            return initialState;
        case CaseActionTypes.GET_CONNECTED_SPECIALISTS_SUCCESS:
            return {...state, isGetSpecialistsProcess: false};
        case CaseActionTypes.GET_CONNECTED_SPECIALISTS:
            return {...state, isGetSpecialistsProcess: true};
        case DentistActionTypes.SEARCH_LABS_SUCCESS:
            return {...state, isSearchLabsProcess: false};
        case DentistActionTypes.SEARCH_LABS:
            return {...state, isSearchLabsProcess: true};
        case CaseActionTypes.SET_CASE_SPECIALIST_ERROR:
            return {...state, notificationDialog: {...initialNotification, type: 'error', message: action.error, title: 'Set case specialist error'}};
        case CaseActionTypes.SET_CASE_LAB_ERROR:
            return {...state, notificationDialog: {...initialNotification, type: 'error', message: action.error, title: 'Set case lab error'}};
        case CaseActionTypes.SET_CASE_SPECIALIST_SUCCESS:
            return {...state, notificationDialog: {...initialNotification, type: 'success', message: action.message, title: action.specId === 0 ? 'Specialist has been unassigned' : 'Set case specialist success'}};
        case CaseActionTypes.SET_CASE_LAB_SUCCESS:
            return {...state, notificationDialog: {...initialNotification, type: 'success', message: action.message, title: action.labId === 0 ? 'Lab has been unassigned' : 'Set case lab success'}};
        case CaseActionTypes.ERROR_MESSAGE_SHOWN:
            return {...state, notificationDialog: initialNotification};
        case UserActionTypes.NO_CREDITS_AVAILABLE:
            return {...state, notificationDialog: {...initialNotification, type: 'error', message: 'To work with the case it is necessary to use credits, but there are no available Case Uses. Please, purchase new Case Uses from store.shadewave.com', title: 'No credits available'}};

        case CaseActionTypes.CREATE_CASE_SUCCESS:
            return {...state, isCreateCasePending: false};
        case CaseActionTypes.CREATE_CASE_ERROR:
            return {...state, isCreateCasePending: false, notificationDialog: {...initialNotification, type: 'error', message: action.error, title: 'Create case error'}};
        case CaseActionTypes.CREATE_CASE_PENDING:
            return {...state, isCreateCasePending: true};
        case UIActionTypes.HIDE_PROFILE:
            return {...state, isProfileShow: false};
        case UIActionTypes.SHOW_PROFILE:
            return {...state, isProfileShow: true};

        case LoaderActionTypes.HIDE_COMPONENT_LOADER:
            return {...state,
                loaderComponent: initialState.loaderComponent,
                loaderPercent: initialState.loaderPercent,
                loaderText: initialState.loaderText};
        case LoaderActionTypes.SHOW_COMPONENT_LOADER:
            return {...state,
                loaderComponent: action.component,
                loaderPercent: action.percent,
                loaderText: action.text};
        case LoaderActionTypes.HIDE_LOADER:
            return {...state,
                isLoader: initialState.isLoader,
                loaderPercent: initialState.loaderPercent,
                loaderText: initialState.loaderText};
        case LoaderActionTypes.SHOW_LOADER:
            return {...state,
                isLoader: true,
                loaderPercent: action.percent,
                loaderText: action.text};
        case CaseActionTypes.HIDE_UPLOAD_PHOTO_ERROR:
            return {...state, uploadPhotoError: initialState.uploadPhotoError};
        case CaseActionTypes.UPLOAD_PHOTO_ERROR:
            return {...state, uploadPhotoError: action.message, isPhotoUploadProgress: false};
        case CaseActionTypes.UPLOAD_PHOTO_SUCCESS:
            return {...state, isPhotoUploadProgress: false};
        case UIActionTypes.TOGGLE_PHOTO_UPLOAD_PROGRESS:
            return {...state, isPhotoUploadProgress: action.value};
        case UIActionTypes.SHOW_WORK_AREA_MENU:
            return {...state, isWorkAreaMenuShow: true};
        case UIActionTypes.HIDE_WORK_AREA_MENU:
            return {...state, isWorkAreaMenuShow: false};
        case UIActionTypes.SHOW_USER_MENU:
            return {...state, isUserMenuShow: true};
        case UIActionTypes.HIDE_USER_MENU:
            return {...state, isUserMenuShow: false};
        case UIActionTypes.SHOW_NOTIFICATION:
            return {...state, notification: {...action.notification, timestamp: new Date().getTime()}};
        case UserActionTypes.USER_NOT_AUTHENTICATED:
            return initialState;
        case UIActionTypes.SHOW_LIGHT_HEADER:
            return {...state, isLightHeader: true};
        case UIActionTypes.HIDE_LIGHT_HEADER:
            return {...state, isLightHeader: false};

        default:
            return state;
    }
}

export default uiReducer;

import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import Route from '../components/routes/Route';
import { Router, Switch, withRouter } from 'react-router-dom';
import routes from '../config/routes';
import history from '../history';
import Notification from "./Notification";
import 'react-notifications/lib/notifications.css';
import '../styles'
import Main from '../containers/Main';

const Root = ({ store }) => (
    <Provider store={store}>
        <Router history={history}>
            
            <Switch>
            <Route exact path={'/fromAdmin'} isProtected={true} component={Main} />
            {routes.map((route, index) => {
                return <Route exact path={route.path} key={index} isProtected={route.isProtected} component={route.component}/>
            })}
            <Route path={'/'} isProtected={true} component={Main} />
            </Switch>
            <Notification />
        </Router>
    </Provider>
);

Root.propTypes = {
    store: PropTypes.object.isRequired,
};

export default withRouter(Root);


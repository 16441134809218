import React from "react";
import PropTypes from "prop-types";
import { topButtons, bottomButtons } from "../../config/leftMenu";
import LeftMenuButton from './LeftMenuButton';
import { IMAGE_DOUBLE_VIEW, IMAGE_MODIFIER, IMAGE_SHADE_MAP, IMAGE_TRIPLE_VIEW } from "../../constants/imageTypes";

export default class LeftMenu extends React.PureComponent {
    renderButton = (buttons) => {
        let { showSubMenu, hideSubMenu, subMenu, disabledButtons, lastMap, selectedPhotoType, isTripleAllowed, isDoubleAllowed, isShadeMapAvailable, generateShadeMap, isModifierAvailable } = this.props;

        const buttonsLength = buttons.length - 1;
        return buttons.map((button, index) => {
            let onClick = () => {
                if (subMenu === button.menu) {
                    hideSubMenu();
                } else {
                    showSubMenu(button.menu);
                    console.log('button.menu', button.menu, lastMap)
                    if (button.menu === 'maps') {
                        if (lastMap === IMAGE_TRIPLE_VIEW && !isTripleAllowed) 
                            lastMap = IMAGE_SHADE_MAP
                        if (lastMap === IMAGE_DOUBLE_VIEW && !isDoubleAllowed)
                            lastMap = IMAGE_SHADE_MAP
                        if (lastMap === IMAGE_SHADE_MAP && !isShadeMapAvailable )
                            generateShadeMap({ isShadeMaps: true, isModifier: false})
                        if (lastMap === IMAGE_MODIFIER && !isModifierAvailable)
                            generateShadeMap({ isShadeMaps: false, isModifier: true})
                        selectedPhotoType(lastMap)
                    }
                }
            };
            return <LeftMenuButton
                type={button.type}
                isLast={buttonsLength === index}
                action={button.action}
                onClick={onClick}
                text={button.text}
                icon={button.icon}
                key={index}
                isActive={subMenu === button.menu}
                isDisabled={disabledButtons.some(v => v === button.menu)}/>
        });
    };
    render() {
        let { isWorkAreaMenuShow } = this.props;

        let className = isWorkAreaMenuShow ? 'show' : 'hide';
        return (
            <div className={`left-menu ${className}`}>
                <div className={'top'}>{this.renderButton(topButtons)}</div>
                <div className={'bottom'}>{this.renderButton(bottomButtons)}</div>
            </div>
        )
    }
}

LeftMenu.propTypes = {
    subMenu: PropTypes.string.isRequired,
    leftMenuAction: PropTypes.func.isRequired,
    isWorkAreaMenuShow: PropTypes.bool.isRequired,
    showSubMenu: PropTypes.func.isRequired,
    hideSubMenu: PropTypes.func.isRequired,
    disabledButtons: PropTypes.array.isRequired,
    lastMap: PropTypes.string.isRequired, 
    selectedPhotoType: PropTypes.func.isRequired
};

import React from "react";
import PropTypes from 'prop-types';
import ProtectedRoute from '../../containers/routes/ProtectedRoute';
import PublicRoute from "../../containers/routes/PublicRoute";


export default class Route extends React.PureComponent {
    render() {
        let { path, component, attributes, isProtected } = this.props;

        if (isProtected) {
            
            return <ProtectedRoute exact path={path} component={component} attributes={attributes}/>
        }

        return (
            <PublicRoute exact path={path} component={component} attributes={attributes}/>
        )
    }
}

Route.propTypes = {
    path: PropTypes.string.isRequired,
    component: PropTypes.object,
    attributes: PropTypes.object,
    isProtected: PropTypes.bool,
};

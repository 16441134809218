import React from "react";
import PropTypes from "prop-types";
import ControlRow from './elements/ControlRow';
import SwitchButtons from '../../elements/form/SwitchButtons';
import RadioGroup from '../../elements/form/RadioGroup';
import Slider from '../../elements/Slider';
import Select from "../../elements/form/Select";
import { IMAGE_DOUBLE_VIEW, IMAGE_MODIFIER, IMAGE_SHADE_MAP, IMAGE_TRIPLE_VIEW } from "../../../constants/imageTypes";
import { defaultModifierSensitivity, defaultColorMapSensitivity, defaultTeethSegmentationSensitivity } from '../../../constants/workAreaDefauls';

export default class Maps extends React.PureComponent {

    getActiveClass = (statement) => {
        return statement ? ' active' : '';
    };

    backgroundClickHandler = (name) => {
        let { setBackground, currentBackground, isCurrentImageMap, isTeethSegmentName, teethSegmentSensitivity } = this.props;

        if (currentBackground !== name)
        {
            setBackground(name);
            if (isCurrentImageMap)
                this.makeShadeMap({currentBackground : name, isShadeMaps: true, isModifier: true, isTeethSegment: isTeethSegmentName == 'on', teethMaskThreshold: (100.0 - teethSegmentSensitivity)/100.0});
        }
    };

    renderBackgroundButtons = (buttons) => {
        return <SwitchButtons className={'background'} buttons={buttons} onClick={this.backgroundClickHandler}/>
    };

    teethSegmentClickHandler = (name) => {
        let { setTeethSegment, isCurrentImageMap, isTeethSegmentName, teethSegmentSensitivity } = this.props;

        if (isTeethSegmentName !== name)
        {
            setTeethSegment(name);
            if (isCurrentImageMap)
                this.makeShadeMap({isShadeMaps: true, isModifier: true, isTeethSegment: name == 'on', teethMaskThreshold: (100.0 - teethSegmentSensitivity)/100.0});
        }
    };

    renderTeethSegmentButtons = (buttons) => {
        return <SwitchButtons className={'background'} buttons={buttons} onClick={this.teethSegmentClickHandler}/>
    };

    mapTypeClickHandler = (name) => {
        let { setMapType, currentMapType, isCurrentImageMap, isTeethSegmentName, teethSegmentSensitivity } = this.props;

        if (currentMapType !== name)
        {
            setMapType(name);
            if (isCurrentImageMap)
                this.makeShadeMap({currentMapType : name, isShadeMaps: true, isModifier: true, isTeethSegment: isTeethSegmentName == 'on', teethMaskThreshold: (100.0 - teethSegmentSensitivity)/100.0});
        }
     };

    renderMapTypesButtons = (buttons) => {
        return <SwitchButtons className={'background'} buttons={buttons} onClick={this.mapTypeClickHandler}/>
    };

    makeShadeMap = (newState) => {
        let { generateShadeMap } = this.props;

        generateShadeMap(newState);
    };

    shadeMapsClickHandler = () => {
        let { selectedPhotoType, isTeethSegmentName, teethSegmentSensitivity } = this.props;

        this.makeShadeMap({ isShadeMaps: true, isModifier: false, isTeethSegment: isTeethSegmentName == 'on', teethMaskThreshold: (100.0 - teethSegmentSensitivity)/100.0});
        selectedPhotoType(IMAGE_SHADE_MAP);
    };

    translucencyClickHandler = () => {
        let { selectedPhotoType, isTeethSegmentName, teethSegmentSensitivity } = this.props;

        this.makeShadeMap({ isShadeMaps: false, isModifier: true, isTeethSegment: isTeethSegmentName == 'on', teethMaskThreshold: (100.0 - teethSegmentSensitivity)/100.0});
        selectedPhotoType(IMAGE_MODIFIER);
    };

    allMapsClickHandler = () => {
        let { selectedPhotoType } = this.props;
        selectedPhotoType(IMAGE_TRIPLE_VIEW);
    };

    doubleMapClickHandler = () => {
        let { selectedPhotoType } = this.props;
        selectedPhotoType(IMAGE_DOUBLE_VIEW);
    }

    getShadeLimitsOptions = () => {
        
        let { shadeLimits } = this.props;

        const name = 'shade-limits';
        return [
            //{name: name, text: '3',   value: 3, isCurrent: shadeLimits === 3},
            {name: name, text: '4',   value: 4, isCurrent: shadeLimits === 4},
            {name: name, text: 'All', value: 255, isCurrent: shadeLimits === 255},
        ]
    };

    shadeLimitsClickHandler = (value) => {
        let { setShadeLimits, shadeLimits, isCurrentImageMap, isTeethSegmentName, teethSegmentSensitivity  } = this.props;

        if (value !== shadeLimits)
        {
            setShadeLimits(value);
            if (isCurrentImageMap)
                this.makeShadeMap({shadeLimits: value, isShadeMaps: true, isModifier: true, isTeethSegment: isTeethSegmentName == 'on', teethMaskThreshold: (100.0 - teethSegmentSensitivity)/100.0});
        }

    };

    teethThresholdChangeHandler = (value) => {
        const { isCurrentImageMap, isTeethSegmentName, teethSegmentSensitivity, setTeethMaskThreshold } = this.props;

        if (value !== teethSegmentSensitivity)
        {
            setTeethMaskThreshold(value);
            if (isCurrentImageMap && isTeethSegmentName == 'on')
                this.makeShadeMap({isShadeMaps: true, isModifier: true, isTeethSegment: isTeethSegmentName == 'on', teethMaskThreshold: (100.0 - value)/100.0});
        }
    }

    colorMapSensitivityChangeHandler = (value) => {
        let { setColorMapSensitivity, colorMapSensitivity, isCurrentImageMap, isTeethSegmentName, teethSegmentSensitivity } = this.props;

        if (value !== colorMapSensitivity)
        {
            setColorMapSensitivity(value);
            if (isCurrentImageMap)
                this.makeShadeMap({colorMapSensitivity: value, isShadeMaps: true, isModifier: false, isTeethSegment: isTeethSegmentName == 'on', teethMaskThreshold: (100.0 - teethSegmentSensitivity)/100.0});
        }

    };
    modifierSensitivityChangeHandler = (value) => {
        let { setModifierSensitivity, modifierSensitivity, isCurrentImageMap, isTeethSegmentName, teethSegmentSensitivity } = this.props;

        if (value !== modifierSensitivity)
        {
            setModifierSensitivity(value);
            if (isCurrentImageMap)
                this.makeShadeMap({modifierSensitivity: value, isShadeMaps: false, isModifier: true, isTeethSegment: isTeethSegmentName == 'on', teethMaskThreshold: (100.0 - teethSegmentSensitivity)/100.0});
        }
    };

    modifierChanged = (value) => {
        let { changeCurrentModifier, currentModifier, isCurrentImageMap, isTeethSegmentName, teethSegmentSensitivity } = this.props;

        if (currentModifier.id !== value)
        {
            changeCurrentModifier(value);
            if (isCurrentImageMap)
                this.makeShadeMap({modifier: value, isShadeMaps: false, isModifier: true, isTeethSegment: isTeethSegmentName == 'on', teethMaskThreshold: (100.0 - teethSegmentSensitivity)/100.0});
        }


    }

    render() {
        let { isShadeMapsActive, isModifierActive, isAllMapsActive, isAllMapsAllowed, isDoubleMapsActive, isDoubleMapAllowed, backgroundButtons, teethSegmentButtons, teethSegmentSensitivity, mapTypesButtons, colorMapSensitivity, modifierSensitivity, modifiersList, currentModifier } = this.props;

        return (
            <div className={'maps'} disabled={'disabled'}>
                <ControlRow title={'Shade Limits:'} className={'shade-limits-row big'}>
                    <RadioGroup onClick={this.shadeLimitsClickHandler} className={'shade-limits'} options={this.getShadeLimitsOptions()}/>
                </ControlRow>

                <ControlRow title={'Shade Map'} className={'action' + this.getActiveClass(isShadeMapsActive)}  onClick={this.shadeMapsClickHandler} isRadio={true}></ControlRow>
                <ControlRow title={'All Maps & Original'} className={'action' + this.getActiveClass(isAllMapsActive)} isDisabled={!isAllMapsAllowed} onClick={this.allMapsClickHandler} isRadio={true}></ControlRow>
                <ControlRow title={'Shade/Translucency'} className={'action' + this.getActiveClass(isDoubleMapsActive)} isDisabled={!isDoubleMapAllowed} onClick={this.doubleMapClickHandler} isRadio={true}></ControlRow>
                <ControlRow title={'Modifiers/Translucency'} className={'action' + this.getActiveClass(isModifierActive)} onClick={this.translucencyClickHandler} isRadio={true}></ControlRow>
                <ControlRow title={'Current Modifier:'} className={'switch-buttons-container column'} >
                    <div className={'select-items'}>
                        <Select options={modifiersList} value={currentModifier ? currentModifier.id : ''} onChange={this.modifierChanged}/>
                    </div>
                </ControlRow>



                <ControlRow title={'Background:'} className={'switch-buttons-container column'}>
                    {this.renderBackgroundButtons(backgroundButtons)}
                </ControlRow>

                <ControlRow title={'Teeth Segmentation:'} className={'switch-buttons-container column'}>
                    {this.renderTeethSegmentButtons(teethSegmentButtons)}
                    <Slider defaultValue={defaultTeethSegmentationSensitivity} isResetButton={false} current={teethSegmentSensitivity} min={10} max={90} onChange={this.teethThresholdChangeHandler}/>
                </ControlRow>



                <ControlRow title={'Map Type:'} className={'switch-buttons-container column'} >
                    {this.renderMapTypesButtons(mapTypesButtons)}
                </ControlRow>

                <ControlRow title={'Color Map Sensitivity'} className={'column'}>
                    <Slider defaultValue={defaultColorMapSensitivity} isResetButton={true} current={colorMapSensitivity} min={0} max={100} onChange={this.colorMapSensitivityChangeHandler}/>
                </ControlRow>

                <ControlRow title={'Modifier Sensitivity'} className={'column'}>
                    <Slider defaultValue={defaultModifierSensitivity} isResetButton={true} current={modifierSensitivity} min={0} max={100} onChange={this.modifierSensitivityChangeHandler}/>
                </ControlRow>
            </div>
        )
    }
}
/*<GreenButton className={'padded'} text={'Full screen'} onClick={() => {}}/>
                <GreenButton className={'padded'} text={'Restart'} onClick={() => {}}/>*/
Maps.propTypes = {
    isShadeMapsActive: PropTypes.bool.isRequired,
    isModifierActive: PropTypes.bool.isRequired,
    isAllMapsActive: PropTypes.bool.isRequired,
    isDoubleMapsActive: PropTypes.bool.isRequired,
    backgroundButtons: PropTypes.array.isRequired,
    colorMapSensitivity: PropTypes.number.isRequired,
    modifierSensitivity: PropTypes.number.isRequired,
    shadeLimits: PropTypes.number.isRequired,
    currentPhotoType: PropTypes.string,
    modifiersList: PropTypes.array,
    currentModifier: PropTypes.object,
    isAllMapsAllowed: PropTypes.bool,
    isCurrentImageMap: PropTypes.bool,

    setShadeMaps: PropTypes.func.isRequired,
    setTranslucency: PropTypes.func.isRequired,
    setBlackAndWhite: PropTypes.func.isRequired,
    setBackground: PropTypes.func.isRequired,
    setMapType: PropTypes.func.isRequired,
    setAllMaps: PropTypes.func.isRequired,
    generateShadeMap: PropTypes.func.isRequired,
    setShadeLimits: PropTypes.func.isRequired,
    setColorMapSensitivity: PropTypes.func.isRequired,
    setModifierSensitivity: PropTypes.func.isRequired,
    changeCurrentModifier: PropTypes.func,
    selectedPhotoType: PropTypes.func,
};

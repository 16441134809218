import { connect } from 'react-redux';
import store from '../store';
import caseReducer from '../reducers/case.reducer';
import workArea from '../reducers/workArea.reducer';
import Case from '../components/Case';
import { changeWalkthroughSettings, generateShadeMap, leftMenuAction, selectedPhotoType } from "../actions/case.actions";
import { showWorkAreaMenu, hideWorkAreaMenu, startProcessPhoto } from "../actions/ui.actions";
import { showSubMenu, hideSubMenu, toggleWalkthroughExpand, closeWalkthrough } from "../actions/workArea.actions";
import { loadCase } from "../actions/case.actions";
import { getDisabledButtons, getIsAllMapsAllowed, getIsDoubleMapAllowed, getIsModifierAvailable, getIsShadeMapAvailable, getLastShadeMap, getSubMenu, getWalkthroughInfo } from "../selectors/workArea.selector";
import { getCurrentCase, getPhotos } from "../selectors/case.selector";

store.reducerManager.add("case", caseReducer);
store.reducerManager.add("workArea", workArea);

const mapStateToProps = state => ({
    isLogged: state.user.isLogged,
    isWorkAreaMenuShow: state.ui.isWorkAreaMenuShow,
    subMenu: getSubMenu(state),
    disabledButtons: getDisabledButtons(state),
    currentPhotoProcessingStep: state.workArea.currentPhotoProcessingStep,
    currentShadeReferenceAnalysis: state.workArea.currentShadeReferenceAnalysis,
    //currentPhoto: getCurrentPhoto(state),

    currentCase: getCurrentCase(state),
    photos: getPhotos(state),
    walkthrough: getWalkthroughInfo(state),

    isLoader: state.ui.isLoader,
    isQcMode: state.case.isQcMode,
    loaderPercent: state.ui.loaderPercent,
    loaderText: state.ui.loaderText,
    lastMap: getLastShadeMap(state),
    isTripleAllowed: getIsAllMapsAllowed(state),
    isDoubleAllowed: getIsDoubleMapAllowed(state),
    isShadeMapAvailable: getIsShadeMapAvailable(state),
    isModifierAvailable: getIsModifierAvailable(state)
});

const mapDispatchToProps = dispatch => ({
    leftMenuAction: (actionType) => dispatch(leftMenuAction(actionType)),
    showSubMenu: (menu) => dispatch(showSubMenu(menu)),
    hideSubMenu: () => dispatch(hideSubMenu()),
    showWorkAreaMenu : () => dispatch(showWorkAreaMenu()),
    hideWorkAreaMenu : () => dispatch(hideWorkAreaMenu()),
    loadCase : (caseId) => dispatch(loadCase(caseId)),
    toggleWalkthroughExpand : (isExpanded) => dispatch(toggleWalkthroughExpand(isExpanded)),
    closeWalkthrough : (notShow) => dispatch(closeWalkthrough(notShow)),
    startProcessPhoto: () => dispatch(startProcessPhoto()),
    changeWalkthroughSettings: (value) => dispatch(changeWalkthroughSettings(value)),
    selectedPhotoType: (value) => dispatch(selectedPhotoType(value)),
    generateShadeMap: (newProps) => {dispatch(generateShadeMap(newProps))}
    //loadCases : (terms) => dispatch(loadCases(terms)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Case);

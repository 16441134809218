import { connect } from 'react-redux';
import SpecialistAdd from '../../components/dentist/SpecialistAdd';
import {
    addSpecialist,
    getSpecialistById,
    setSpecialist,
    searchSpecialists,
    createDentistProfile
} from "../../actions/user.actions";
import { getCountries, getStates, setStates } from "../../actions/settings.action";
import {getCreateProfile, getUserIdForAdd} from "../../selectors/user.selector";

const mapStateToProps = state => ({
    activeSpecialist: state.user.activeSpecialist,
    specialists: state.user.specialists,
    countries: state.settings.countries,
    states: state.settings.states,
    dentistToAdd: state.user.dentistToAdd,
    profileCreated: state.user.profileCreated,
    userId: getUserIdForAdd(state),
    userProfile: getCreateProfile(state),
});

const mapDispatchToProps = dispatch => ({
    searchSpecialists: (specialist, userId) => dispatch(searchSpecialists({specialist, userId})),
    addSpecialist: (specialist, getUsers, userId) => dispatch(addSpecialist({specialist, getUsers, userId})),
    setSpecialist: specialist => dispatch(setSpecialist(specialist)),
    getSpecialistById: id => dispatch(getSpecialistById(id)),
    getStates: countryId => dispatch(getStates(countryId)),
    getCountries: () => dispatch(getCountries()),
    setStates: states => dispatch(setStates(states)),
    createDentistProfile: (profile, dentist) => dispatch(createDentistProfile({profile, dentist})),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialistAdd);

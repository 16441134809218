import React from "react";
import PropTypes from 'prop-types';
import MenuLink from './MenuLink';
import helpIcon from '../../assets/images/icons/help.svg';
import logoutIcon from '../../assets/images/icons/logout.svg';
import editIcon from '../../assets/images/icons/edit-profile.svg';
import homeIcon from '../../assets/images/icons/home-blue.png';
import { LOGIN, EDIT_LAB_PROFILE, EDIT_DENTIST_PROFILE, EDIT_SPECIALIST_PROFILE, HELP } from '../../constants/url';

export default class ExpandedUserMenu extends React.PureComponent {
    showProfile = () => {

    };
    getProfileLink = () => {
        const { roleId } = this.props;

        switch (roleId) {
            case 0: // admin
                return '';
            case 1: // dentist
            case 4:
            case 6:
            case 8:
                return EDIT_DENTIST_PROFILE;
            case 2: // lab
            case 7:
            case 9:
                return EDIT_LAB_PROFILE;
            case 5: // specialist
                return EDIT_SPECIALIST_PROFILE;
            default:
                return '';
        }
    };
    render() {
        let { isUserMenuShow, id, name, showProfile, hideUserMenu, signOut } = this.props;

        if (!isUserMenuShow) {
            return false;
        }
        const path = this.getProfileLink();
        return (
                <div className={'expanded-user-menu'} onClick={this.showProfile}>
                        <div className={'arrow-up'}></div>

                        <div className={'user'} onClick={showProfile}>
                            <div className={'name'}>{name}</div>
                            <div className={'id'}>{id}</div>
                        </div>

                        <div className={'control'}>
                            <MenuLink icon={homeIcon} link={'/'} text={'Home'} hideMenu={hideUserMenu} />
                            <MenuLink icon={helpIcon} link={HELP} isExternal={true} text={'Help'} hideMenu={hideUserMenu} />
                            {<MenuLink icon={editIcon} link={path} text={'Edit Profile'} hideMenu={hideUserMenu} />}
                            <MenuLink icon={logoutIcon} link={LOGIN} text={'Logout'} handleClick={signOut} hideMenu={hideUserMenu} />
                        </div>
                </div>

        )
    }
}

ExpandedUserMenu.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,

    isUserMenuShow: PropTypes.bool.isRequired,
    showProfile: PropTypes.func.isRequired,
    hideUserMenu: PropTypes.func.isRequired,
    roleId: PropTypes.number.isRequired,
};

import SettingsActionTypes from "../actions/settings.types";

const initialState = {
    countries: [],
    states: [],
};

function settingsReducer(state = initialState, action) {
    switch (action.type) {
        case SettingsActionTypes.SET_COUNTRIES:
            return {...state, countries: action.payload};
        case SettingsActionTypes.SET_STATES:
            return {...state, states: action.payload};
        default:
            return state;
    }
}

export default settingsReducer;

import React from "react";
import PropTypes from "prop-types";
import MatDialog, { MatDialogHeader, MatDialogContent } from "./DraggableDialog";
import TransparentButton from "./form/buttons/TransparentButton";
import GreenButton from "./form/buttons/GreenButton";

export default class ConfirmDialog extends React.PureComponent {
    render() {
        const { isOpen, yes, no, title, message, isLightMode, className } = this.props;

        return (
            <MatDialog
                isOpen={isOpen ?? false}
                onClose={no}
                classes={{
                    container: 'overlay',
                    paper: `modal shadewave-confirm-dialog ${className ? className : ''} ${isLightMode ? 'light' : 'dark'}`,
                }}>
                <MatDialogHeader onClose={no} title={title} isLightMode={isLightMode}>
                </MatDialogHeader>
                <MatDialogContent>
                    <div className="message-container">
                        <label className="message">
                            {message}
                        </label>
                    </div>
                    <div className="footer">
                        <TransparentButton className="close-button" text="No" onClick={no} />
                        <GreenButton className="confirm-button" text="Yes" onClick={yes} />
                    </div>
                </MatDialogContent>
            </MatDialog>
        )
    }
}

ConfirmDialog.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    yes: PropTypes.func.isRequired,
    no: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isLightMode: PropTypes.bool,
    className: PropTypes.string,
};
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import initialReducers from '../reducers';
import cookieMiddleware from '../middlewares/cookieMiddleware';

import createReducerManager from './createReducerManager';

import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/root-saga';
import { composeWithDevTools } from '@redux-devtools/extension';

const configureStore = (isProduction) => {
    const reducerManager = createReducerManager(initialReducers);

    let middlewares;

    const sagaMiddleware = createSagaMiddleware();

    const initialMiddlewares = [
        cookieMiddleware,
        sagaMiddleware,
    ];

    if (isProduction) {
        middlewares = applyMiddleware(...initialMiddlewares);
    } else {
        const logger = createLogger({collapsed: true});

        middlewares = composeWithDevTools( applyMiddleware(...initialMiddlewares, ...[
            logger,
        ]));
    }

    const store = createStore(
        reducerManager.reduce,
        middlewares
    );

    store.reducerManager = reducerManager;

    sagaMiddleware.run(rootSaga);

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            store.replaceReducer(reducerManager.reduce)
        })
    }

    return store
};

export default configureStore;

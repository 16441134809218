import SettingsActionTypes from "./settings.types";

export const getCountries = () => ({
    type: SettingsActionTypes.GET_COUNTRIES,
});

export const setCountries = countries => ({
    type: SettingsActionTypes.SET_COUNTRIES,
    payload: countries,
});

export const getStates = countryId => ({
    type: SettingsActionTypes.GET_STATES,
    payload: countryId
});

export const setStates = states => ({
    type: SettingsActionTypes.SET_STATES,
    payload: states,
});

import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "../elements/form/Button";
import { FORGOT_PASSWORD } from "../../constants/url";
import Form from '../elements/Form';
import InputText from '../elements/form/input/InputText';
import InputPassword from '../elements/form/input/InputPassword';

export default class Login extends React.PureComponent {
    state = {
        username: process.env.REACT_APP_STAGE === 'local' ? process.env.REACT_APP_TEST_USER : '',
        password: process.env.REACT_APP_STAGE === 'local' ? process.env.REACT_APP_TEST_PASSWORD : '',
    };
    login = () => {
        const { username, password } = this.state;
        this.props.signIn(username, password);
    };
    usernameChangeHandler = username => {
        this.setState({username});
    };
    passwordChangeHandler = password => {
        this.setState({password});
    };
    render() {
        const { username, password } = this.state;

        return (
            <div className="login-form">
                <p className="auth-page-text">Customer Login</p>
                <p className="auth-info">Login to your account</p>
                <Form onSubmit={this.login} isAjax={true}>
                    <InputText name={'username'} onChange={this.usernameChangeHandler} value={username} placeholder={'Email address / Username'} isRequired={true} />
                    <InputPassword name={'password'} autocomplete={'password'} onChange={this.passwordChangeHandler} value={password} placeholder={'Password'} isRequired={true}/>
                    <Link className="forgot-password-text" to={FORGOT_PASSWORD}> Forgot your password/ username? </Link>
                    <Button type={'submit'} text="LOGIN" className="login-button" />
                </Form>
            </div>
        )
    }
}

Login.propTypes = {
    signIn: PropTypes.func.isRequired,
};

import UIActionTypes from "./ui.types";

export const showUserMenu = () => ({
    type: UIActionTypes.SHOW_USER_MENU,
});
export const hideUserMenu = () => ({
    type: UIActionTypes.HIDE_USER_MENU,
});
export const showWorkAreaMenu = () => ({
    type: UIActionTypes.SHOW_WORK_AREA_MENU,
});
export const hideWorkAreaMenu = () => ({
    type: UIActionTypes.HIDE_WORK_AREA_MENU,
});

export const showProfile = () => ({
    type: UIActionTypes.SHOW_PROFILE,
});
export const hideProfile = () => ({
    type: UIActionTypes.HIDE_PROFILE,
});

export const togglePhotoUploadProgress = (value) => ({
    type: UIActionTypes.TOGGLE_PHOTO_UPLOAD_PROGRESS,
    value
});

export const showNotification = (notification) => ({
    type: UIActionTypes.SHOW_NOTIFICATION,
    notification
});

export const showNotificationDialog = (notification) => ({
    type: UIActionTypes.SHOW_NOTIFICATION_DIALOG,
    notification
});

export const showLightHeader = () => ({
    type: UIActionTypes.SHOW_LIGHT_HEADER
});

export const hideLightHeader = () => ({
    type: UIActionTypes.HIDE_LIGHT_HEADER
});

export const startProcessPhoto = () => ({
    type: UIActionTypes.START_PROCESS_PHOTO
});



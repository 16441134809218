import { connect } from 'react-redux';
import Details from '../../../components/case/subMenus/Details';
import { getCurrentCase } from "../../../selectors/case.selector";

const mapStateToProps = state => ({
    currentCase: getCurrentCase(state),
});

const mapDispatchToProps = dispatch => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(Details);

import React from "react";
import PropTypes from "prop-types";
import Table, { Header, Body, Cell, Row } from '.././elements/Table';

const DEFAULT_MAX_ROWS_COUNT = 4;

export default class LabSpecialistList extends React.PureComponent {
    renderTable() {
        const { rows, handleClick } = this.props;

        if (rows && rows.length) {
            return rows.map((it, index) => {
                return (
                    <Row key={index}>
                        <Cell handleClick={() => handleClick(it.id)} tooltipText={it.customerId} className="first-cell">{it.customerId ? it.customerId : ''}</Cell>
                        <Cell tooltipText={it.name}>{it.name ? it.name : ''}</Cell>
                        <Cell tooltipText={it.email} className="last-cell">{it.email ? it.email : ''}</Cell>
                    </Row>
                )
            });
        } else {
            return <Row><Cell className="full-width no-result">No result found</Cell></Row>
        }
    }
    render() {
        const { className, rows, maxRowsCount, columns } = this.props;
        const maxRowsCtn = maxRowsCount || DEFAULT_MAX_ROWS_COUNT;
        const headerClassName = rows.length > maxRowsCtn ? 'scroll-container' : '';

        return (
            <Table  className={className}>
                <Header className={headerClassName}>
                    <Row>
                        {columns.map((title, index) => {
                            return <Cell className={`${index === 0 ? 'first-cell' : ''} ${index === 2 ? 'last-cell' : ''}`} key={index}>{title}</Cell>
                        })}
                    </Row>
                </Header>
                <Body className="default-scroll">
                {this.renderTable()}
                </Body>
            </Table>
        )
    }
}

LabSpecialistList.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    maxRowsCount: PropTypes.number,
    className: PropTypes.string,
};
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from './Draggable';

export class PaperComponent extends React.PureComponent {
    render() {
        return (
            <Draggable handle="#draggable-dialog-title" bounds={false} cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper {...this.props} />
            </Draggable>
        )
    }
}

export class MatDialogHeader extends React.PureComponent {
    render() {
        let { children, title, onClose, isLightMode } = this.props;

        return (
            <div className={'modal-header'} id="draggable-dialog-title">
                {title && <div className={'title'}>{title}</div>}
                {children}
                <div className={isLightMode ? 'closer dark' : 'closer'} onClick={onClose} onTouchEnd={onClose} />
            </div>
        )
    }
}

MatDialogHeader.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    onClose: PropTypes.func,
    isLightMode: PropTypes.bool,
};

export class MatDialogContent extends React.PureComponent {
    render() {
        let { children, } = this.props;

        return (
            <div className={'content'}>
                {children}
            </div>
        )
    }
}

MatDialogContent.propTypes = {
    children: PropTypes.node.isRequired,
};

export default class MatDialog extends React.PureComponent {

    render() {
        const { isOpen, classes, children, closeOnOutsideClick, onClose } = this.props;
        return (
            <Dialog
                open={isOpen}
                classes={{
                    container : 'overlay ' + (classes ? `${classes.container}` : ''),
                    paper: 'modal ' +  (classes ? `${classes.paper}` : ''),
                }}
                onClose={(event, reason) => {
                    if (closeOnOutsideClick || (reason !== 'backdropClick')) {
                        onClose(event, reason)
                    } 
                }}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                //disableBackdropClick={!closeOnOutsideClick}
            >
                {children}
            </Dialog>
        );
    }
}

MatDialog.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
    classes: PropTypes.object,
    closeOnOutsideClick: PropTypes.bool,
};

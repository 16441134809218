const types = {
    SHOW_SUB_MENU: 'SHOW_SUB_MENU',
    HIDE_SUB_MENU: 'HIDE_SUB_MENU',

    SELECT_SHADE_GUIDE: 'SELECT_SHADE_GUIDE',
    EXPAND_SHADE_GUIDE: 'EXPAND_SHADE_GUIDE',
    UNEXPAND_SHADE_GUIDE: 'UNEXPAND_SHADE_GUIDE',
    UPDATE_SHADE_GUIDE: 'UPDATE_SHADE_GUIDE',
    CHANGE_IMAGE_TYPE: 'CHANGE_IMAGE_TYPE',
    CHOSE_REGION: 'CHOSE_REGION',
    CHANGE_CORRECTED_IMAGE_TYPE: 'CHANGE_CORRECTED_IMAGE_TYPE',

    ROTATE_IMAGE: 'ROTATE_IMAGE',
    SET_GRAY_SCALE: 'SET_GRAY_SCALE',
    SET_SHADE_LIMITS: 'SET_SHADE_LIMITS',
    SET_BRIGHTNESS: 'SET_BRIGHTNESS',
    SET_CONTRAST: 'SET_CONTRAST',
    SET_COLOR_MAP_SENSITIVITY: 'SET_COLOR_MAP_SENSITIVITY',
    SET_MODIFIER_SENSITIVITY: 'SET_MODIFIER_SENSITIVITY',
    SET_TEETH_MASK_THRESHOLD: 'SET_TEETH_MASK_THRESHOLD',

    SET_SHADE_MAPS: 'SET_SHADE_MAPS',
    SET_TRANSLUENCY: 'SET_TRANSLUENCY',
    SET_BLACK_AND_WHITE: 'SET_BLACK_AND_WHITE',
    SET_BACKGROUND: 'SET_BACKGROUND',
    SET_TEETH_SEGMENT: 'SET_TEETH_SEGMENT',
    SET_MAP_TYPE: 'SET_MAP_TYPE',
    SET_GRAY_SCALE_MAP: 'SET_GRAY_SCALE_MAP',
    SET_ALL_MAPS: 'SET_ALL_MAPS',

    SET_THEME: 'SET_THEME',
    SET_PROCESS_PHOTO_WALKTHROUGH: 'SET_PROCESS_PHOTO_WALKTHROUGH',
    CLOSE_WALKTHROUGH: 'CLOSE_WALKTHROUGH',
    SET_SHADE_REFERENCE_ANALYSIS: 'SET_SHADE_REFERENCE_ANALYSIS',

    TOGGLE_WALKTHROUGH_EXPAND: 'TOGGLE_WALKTHROUGH_EXPAND',
    SET_PHOTO_CONTAINER_REF: 'SET_PHOTO_CONTAINER_REF',

    TOGGLE_CROP: 'TOGGLE_CROP',
    CROP_IMAGE: 'CROP_IMAGE',
    CROP_IMAGE_SUCCESS: 'CROP_IMAGE_SUCCESS',
    GRAYSCALE_IMAGE: 'GRAYSCALE_IMAGE',
    GRAYSCALE_SUCCESS: 'GRAYSCALE_SUCCESS',
    PHOTO_PROCESSING_ERROR: 'PHOTO_PROCESSING_ERROR',
    HIDE_PHOTO_PROCESSING_ERROR: 'HIDE_PHOTO_PROCESSING_ERROR',
    REGION_COLOR_VALUES_RECEIVED: 'REGION_COLOR_VALUES_RECEIVED',
    
};

export default types;

import { connect } from 'react-redux';
import ProfileEdit from '../../components/dentist/ProfileEdit';
import { showProfile, hideProfile } from '../../actions/ui.actions';

const mapStateToProps = state => ({
    isProfileShow: state.ui.isProfileShow,
    userProfile: state.user.userProfile,
});

const mapDispatchToProps = dispatch => ({
    showProfile: () => dispatch(showProfile()),
    hideProfile: () => dispatch(hideProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);

import { connect } from "react-redux";
import CreateProfile from "../../components/profile/CreateProfile";
import { getProfileForSubscription } from "../../actions/user.actions";
import { showNotification } from "../../actions/ui.actions";

const mapStateToProps = state => ({
    profileSubscription: state.user.profileSubscription,
});

const mapDispatchToProps = dispatch => ({
    getProfileForSubscription: (key, email) => dispatch(getProfileForSubscription({key, email})),
    showNotification: notificationData => dispatch(showNotification(notificationData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfile);

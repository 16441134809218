import React from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { themeLight } from "../../config/app";
import { LOGIN, CREATE_DENTIST_PROFILE, CREATE_LAB_PROFILE } from "../../constants/url";

class Logo extends React.PureComponent {
    showIcons = (isBackButton = false) => {
        const { history } = this.props;
        const ignoredPaths = [LOGIN, '/'];

        if (isBackButton) {
            ignoredPaths.push(CREATE_DENTIST_PROFILE);
            ignoredPaths.push(CREATE_LAB_PROFILE);
        }
        return ignoredPaths.includes(history.location.pathname);
    };
    renderHomeIcon = () => {
        let { theme } = this.props;

        if (this.showIcons()) {
            return false;
        }

        return <img className={'home'} alt={'Home'} src={theme === themeLight ? require('../../assets/images/icons/home-purple.png') : require('../../assets/images/icons/home-white.png')}/>;
    };
    renderBackButton = () => {
        let { history, theme } = this.props;

        if (this.showIcons(true)) {
            return false;
        }

        return (
            <div className={`back-button ${theme}`} onClick={() => history.goBack()}>
                <img className={'img'} src={require('../../assets/images/icons/back.png')} alt={'Go back'}/>
                <span className={'text'}>
                    back
                </span>
            </div>
        )
    };
    render() {
        let { theme, onClick } = this.props;

        return (
            <div onClick={onClick} onTouchEnd={onClick} className={'logo-container'}>
                {this.renderHomeIcon()}
                <img className={'logo'} alt={'Shadewave'} src={theme === themeLight ? require('../../assets/images/logo-light.png') : require('../../assets/images/logo.png')}/>
                {this.renderBackButton()}
            </div>
        )
    }
}

Logo.propTypes = {
    theme: PropTypes.string,
    onClick: PropTypes.func,
};

export default withRouter(Logo);

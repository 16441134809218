import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Button from '../elements/form/Button';
import deleteIcon from '../../assets/images/icons/delete.svg';

export default class Uploader extends React.PureComponent {
    state = {
        files: []
    };
    onDrop = files => {
        this.setState({files: [...this.state.files, ...files]});
    };
    handleRemove = index => {
        const { files } = this.state;
        files.splice(index, 1);
        this.setState({files: [...files]});
    };
    renderUploadedFiles = () => {
        const files = this.state.files.map((file, i) => (
            <div className="uploaded-files" key={`${file.name}-${i}`}>
                <p className="uploaded-file">{file.name}</p>
                <img onClick={() => this.handleRemove(i)} src={deleteIcon} alt="delete" className={"remove-icon"} />
            </div>
        ));
        return (
            <div className="uploaded-files-container">
                {files}
            </div>
        )
    };
    getFiles = () => (this.state.files);
    reset = () => {
        this.setState({files: []});
    };
    render() {
        const { text, acceptedFormats } = this.props;

        return (
            <Dropzone accept={acceptedFormats} onDrop={this.onDrop}>
                {({getRootProps, getInputProps}) => (
                    <div className="dropzone-container">
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <Button className="attach-button">
                                <img src={require('../../assets/images/attach.png')} alt="attach"/>

                                <span>{text}</span>
                            </Button>
                        </div>
                        {this.renderUploadedFiles()}
                    </div>
                )}
            </Dropzone>
        );
    }
}

Uploader.propTypes = {
    text: PropTypes.string.isRequired,
    acceptedFormats: PropTypes.string.isRequired,
};

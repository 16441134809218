import { connect } from 'react-redux';
import ViewCases from '../../../components/case/subMenus/ViewCases';
import { loadCases } from '../../../actions/case.actions';
import { getCasesList} from '../../../selectors/case.selector';

const mapStateToProps = state => ({
    loaderComponent: state.ui.loaderComponent,
    cases: getCasesList(state),
});

const mapDispatchToProps = dispatch => ({
    loadCases: (limit, terms) => dispatch(loadCases(limit, terms))
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewCases);

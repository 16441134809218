import { connect } from 'react-redux';
import Preferences from '../../../components/case/subMenus/Preferences';
import { setTheme, setProcessPhotoWalkthrough, setShadeReferenceAnalysis, updateShadeGuides } from '../../../actions/workArea.actions';
import { changeWalkthroughSettings, setShadeTabs } from '../../../actions/case.actions';
import { getPreferencesValues } from '../../../selectors/workArea.selector';

const mapStateToProps = state => ({
    ...getPreferencesValues(state),
});
const mapDispatchToProps = dispatch => ({
    setTheme: (name) => {dispatch(setTheme(name))},
    setProcessPhotoWalkthrough: (name) => {dispatch(setProcessPhotoWalkthrough(name))},
    setShadeReferenceAnalysis: (name) => {dispatch(setShadeReferenceAnalysis(name))},
    setShadeTabs: (selectedTabsIds) => {dispatch(setShadeTabs(selectedTabsIds))},
    updateShadeGuides: (shadeGuides) => {dispatch(updateShadeGuides(shadeGuides))},
    changeWalkthroughSettings: (value) => dispatch(changeWalkthroughSettings(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Preferences);

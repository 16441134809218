import React from "react";
import PropTypes from 'prop-types';
import BaseInput from '../BaseInput';

const maxLength = 10;
export default class InputTel extends React.PureComponent {
    cleanValue = (value) => {
        return value.replace(/[^0-9]+/g, '').substring(0, maxLength);
    };
    formatPhone = (value) => {
        const length = value.length;

        if (length < 4) {
            return value;
        }

        if (length < 7) {
            return `(${value.slice(0, 3)}) ${value.slice(3)}`;
        }

        return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, maxLength)}`;
    };
    onChangeHandler = (value) => {
        let { onChange } = this.props;

        return onChange(this.cleanValue(value));
    };
    render() {
        let { placeholder, value, name, icon, autocomplete, isDisabled, errors, isRequired, className } = this.props;

        return (
            <BaseInput className={className} isRequired={isRequired} errors={errors} type={'tel'} value={this.formatPhone(value)} name={name} placeholder={placeholder} icon={icon} onChange={this.onChangeHandler} attributes={{autoComplete: autocomplete ? autocomplete : '', disabled: isDisabled}}/>
        );
    }
}

InputTel.propTypes = {
    isRequired: PropTypes.bool,
    errors: PropTypes.array,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.node,
    autocomplete: PropTypes.string,
    isDisabled: PropTypes.bool,
    className: PropTypes.string,
};

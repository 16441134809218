import React from "react";
import PropTypes from "prop-types";
import {Line} from "react-konva";
import {xMarkWidth} from "../../../config/photo";

export default class Xmark extends React.PureComponent {
    render() {
        let { x, y, width, height} = this.props;
        let halfWidth = width / 2;
        let halfHeight = height / 2;

        // drawing X mark by drawing a line from the center to each corner 4 times
        const center = [halfWidth, halfHeight];
        return (
            <Line x={x}
                  y={y}
                  strokeWidth={xMarkWidth}
                  points={[
                      ...center,
                      width, height,

                      ...center,
                      width, 0,

                      ...center,
                      0, height,

                      ...center,
                      0, 0]}
                  closed
                  stroke="red"/>
        )
    }
}

Xmark.propTypes = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

import React from "react";
import PropTypes from 'prop-types';

export default class PhotoPage extends React.PureComponent {
    render() {
        let { children } = this.props;

        return (
            <div className={'photo-page'}>
                <div className={'content'}>{children}</div>
                <div className={'photo'}></div>
            </div>
        )
    }
}

PhotoPage.propTypes = {
    children: PropTypes.node.isRequired,
};

import React from "react";
import PropTypes from 'prop-types';

export default class Icon extends React.PureComponent {
    render() {
        let { icon } = this.props;

        return (
            <span className={'icon'}>{icon}</span>
        )
    }
}

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
};

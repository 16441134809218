import React from "react";
import PropTypes from 'prop-types';
import BaseInput from '../BaseInput';
//import LockIcon from '../../../../assets/images/icons/lock.svg';
import EyeIcon from '../../../../assets/images/icons/eye.svg';

export default class InputPassword extends React.PureComponent {
    state = {
        isVisible: false,
    };
    togglePasswordVisibility = () => {
        this.setState({isVisible: !this.state.isVisible})
    };
    render() {
        let { placeholder, value, name, autocomplete, onChange, errors, isRequired, isDisabled } = this.props;
        let { isVisible } = this.state;

        let type = isVisible ? 'text' : 'password';
        const className = isVisible ? 'password show' : 'password';
        return (
            <BaseInput
                isRequired={isRequired}
                errors={errors}
                type={type}
                className={className}
                value={value}
                name={name}
                placeholder={placeholder}
                //icon={LockIcon}
                onChange={onChange}
                rightIcon={EyeIcon}
                rightIconClick={this.togglePasswordVisibility}
                attributes={{autoComplete: autocomplete,  disabled: isDisabled}}/>
        );
    }
}

InputPassword.propTypes = {
    isRequired: PropTypes.bool,
    errors: PropTypes.array,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    isDisabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    autocomplete: PropTypes.string.isRequired,
};

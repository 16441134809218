import React from "react";
import PropTypes from 'prop-types';
import Checkbox from '../elements/form/Checkbox';
import GreenButton from '../elements/form/buttons/GreenButton';
import Draggable from '../elements/Draggable';
import { totalHeaderHeight } from '../../config/ui';

export default class Walkthrough extends React.PureComponent {
    state = {
        isTurnOff: false,
        height: 0
    };

    toggleExpand = () => {
        let { toggle, isExpanded } = this.props;

        toggle(!isExpanded);
    };

    turnOffHandler = () => {
        let { isTurnOff } = this.state;

        this.setState({isTurnOff: !isTurnOff});
    };
    closeHandler = () => {
        let { close } = this.props;
        let { isTurnOff } = this.state;

        close(isTurnOff);
    };
    renderSteps = () => {
        const steps = [
            'Confirm shade guide matches shade tab in photo',
            'Click middle of shade tab',
            'Select shade tab name',
            'Right click & select Color Correct',
            'Size & move blue region (area to be mapped). Size & move green Value box (provides 3D Master Value group 0 to 5)',
            'Right click inside blue region & select Crop',
        ];

        return steps.map((step, index) => {
            let line = index + 1 < steps.length ? <div className={'line'}></div> : '';
            return (
                <div key={index} className={'step'}>
                    <div className={'num-container'}>
                        <div className={'num'}>{index + 1}</div>
                        {line}
                    </div>
                    <div className={'text'}>{step}</div>
                </div>
            )
        });
    };
    render() {
        let { isExpanded, isVisible } = this.props;
        let { isTurnOff, height } = this.state;

        let className = isExpanded ? 'expanded' : 'collapsed';

        if (!isVisible) {
            return false;
        }

        return (
            <Draggable bounds={{left: window.innerWidth * -1 + 234, top: 0, right: 0, bottom: window.innerHeight - height - totalHeaderHeight }}>
                <div className={`walkthrough ${className}`} ref={(el) => {
                    if (!el || height > 0) {
                        return false;
                    }

                    this.setState({height: el.clientHeight});
                }}>
                    <strong className={'control handle'}>
                        <div className={'toggler'} onClick={this.toggleExpand} onTouchStart={(e) => {e.preventDefault(); this.toggleExpand();}}></div>
                        <div className={'flex'}><div className={'dragger'}></div></div>
                        <div className={'closer'} onClick={this.closeHandler} onTouchStart={(e) => {e.preventDefault(); this.closeHandler();}}></div>
                    </strong>

                    <div className={'content'}>
                        {this.renderSteps()}

                        <Checkbox className={'dark'} onChange={this.turnOffHandler} text={'Turn off this dialog next time'} isChecked={isTurnOff}/>

                        <div className={'button-container'}>
                            <GreenButton className={'save-button'} text={'OK'} onClick={this.closeHandler}/>
                        </div>
                    </div>
                </div>
            </Draggable>
        );
    }
}

Walkthrough.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};

import React from "react";
import Select from '../elements/form/Select';
import PropTypes from 'prop-types';

export default class ShadeTabSelect extends React.PureComponent {
    state = {
        guideId: this.props.guideId,
        options: this.props.options,
        optionsById: {},
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.guideId.id !== prevState.guideId) {
            let optionsById = {};

            nextProps.options.forEach(option => {
                optionsById[option.value] = option;
            });

            return {...prevState, options: nextProps.options, guideId: nextProps.guideId, optionsById: optionsById};
        }

        return null;
    }

    renderOptions = (optionToRender) => {
        let { optionsById } = this.state;
        let { options } = this.props;

        let value = options.filter(option => option.name === optionToRender.name)[0].value;
        let optionData = optionsById[value];

        return <div className={'result-row'} {...optionToRender}><span className={'name'}>{optionData.name}</span> <span className={'shade-tab-color'} style={{backgroundColor: `#${optionData.color}`}}></span></div>
    };

    /*renderOptions = (option) => {
        let { optionsById } = this.state;

        let optionData = optionsById[option.value];

        return <button className="select-search__option" {...option}>{optionData.name} <span className={'shade-tab-color'} style={{backgroundColor: `#${optionData.color}`}}></span></button>;
    };*/
    renderValue = () => {
        let { selected } = this.props;
        let { optionsById } = this.state;

        const color = optionsById[selected] ? `#${optionsById[selected].color}` : 'transparent';

        return <div className={'shade-tab-color-value'} style={{backgroundColor: color}}></div>
    };
    /*renderValue = () => {
        let { selected } = this.props;
        let { optionsById } = this.state;

        const color = optionsById[selected] ? `#${optionsById[selected].color}` : 'transparent';

        return <div><input className={'select-search__input'} /><span className={'shade-tab-color value'} style={{backgroundColor: color}}></span></div>
    };*/
    changeHandler = (value) => {
        let { onChange } = this.props;

        onChange(value);
    };
    render() {
        let { options, selected, placeholder, isDisabled } = this.props;

        return (
            <Select
                hideLabel={true}
                isDisabled={isDisabled}
                className={'shade-tab-select'}
                placeholder={placeholder}
                renderValue={this.renderValue}
                onChange={this.changeHandler}
                options={options}
                value={selected}
                renderOptions={this.renderOptions}/>
        )
    }
}

ShadeTabSelect.propTypes = {
    placeholder: PropTypes.string,
    guideId: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    selected: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
};

import { connect } from 'react-redux';
import Header from '../components/Header';
import { showUserMenu, hideUserMenu } from '../actions/ui.actions';
import { signIn, signOut, getUser } from '../actions/user.actions';
import { loadCase } from '../actions/case.actions';
import { showProfile } from '../actions/ui.actions';
import { getTrialDays } from '../selectors/user.selector';

const mapStateToProps = state => ({
    isLogged: state.user.isLogged,
    username: state.user.userProfile.username,
    id: +state.user.userProfile.id,
    credits: state.user.userProfile.credit,

    isUserMenuShow: state.ui.isUserMenuShow,
    roleId: state.user.userProfile.role_id,
    trialDays: getTrialDays(state),
    subscriptionType: state.user.userProfile.subscription_type
});

const mapDispatchToProps = dispatch => ({
    showProfile: () => dispatch(showProfile()),
    showUserMenu: () => dispatch(showUserMenu()),
    hideUserMenu : () => dispatch(hideUserMenu()),
    signIn : (username, password) => dispatch(signIn({username, password})),
    signOut: () => dispatch(signOut()),
    getUser: () => dispatch(getUser()),
    loadCase: (caseId) => dispatch(loadCase(caseId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

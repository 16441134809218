import React from "react";
import PropTypes from 'prop-types';
import Input from '../BaseInput';

export default class InputAutocomplete extends React.PureComponent {
    state = {
        input: null,
        isFocused: false,
        activeIndex: null,
        value: this.props.value,
        isInternalChange: false,
        inputRef: null,
        resultsRef: null,
    };

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.value !== prevState.value) {
            return {...prevState, value: nextProps.value, isFocused: !prevState.isInternalChange, activeIndex: null, isInternalChange: false}
        }

        return null;
    }

    onChange = (value) => {
        let { onChange } = this.props;

        onChange(value);
    };
    filterResults = () => {
        let { value } = this.state;
        let { results } = this.props;

        let valueUC = value ? value.toUpperCase() : '';
        return results.filter((item) => item && item.toUpperCase().startsWith(valueUC));
    };
    renderResults = () => {
        let { isFocused, activeIndex, input, resultsRef } = this.state;
        let { renderOptions, results, isReadOnly } = this.props;

        if ((results.length === 0 || !isFocused) || isReadOnly) {
            return false;
        }

        const toTop = window.innerHeight / 2 < input.getBoundingClientRect().y;
        let className = toTop ? 'to-top' : 'to-bottom';
        let styles = {};

        if (resultsRef && toTop) {
            styles.bottom = input.getBoundingClientRect().height * 1;
        }

        return (
            <div ref={e => {
                if (!e || resultsRef) {
                    return false;
                }

                this.setState({
                    resultsRef: e,
                })
            }} style={styles} className={`results ${className}`}>
                {this.filterResults().map((text, index) => {
                    const options = {
                        onClick: () => { this.setValue(text) },
                        onMouseDown: (e) => {e.preventDefault()},
                        key: index,
                        className: 'result-row' + (activeIndex === index ? ' active' : ''),
                        name: text,
                    };
                    return renderOptions ? renderOptions(options) : <div key={options.key} {...options}>{text}</div>
                })}
            </div>
        )
    };

    setValue = (value) => {
        let { onChange } = this.props;

        this.setState({
            isFocused: false,
            isInternalChange: true,
        }, () => this.state.input.blur());

        onChange(value);
    };
    onFocusHandler = () => {
        const { onFocus } = this.props;

        this.setState({
            isFocused: true,
        });

        if (onFocus) {
            onFocus();
        }
    };
    onBlurHandler = () => {
        const { onBlur } = this.props;

        this.setState({
            isFocused: false,
            isInternalChange: true,
        });

        if (onBlur) {
            onBlur();
        }
    };
    onKeyDownHandler = (e) => {
        let { isFocused, activeIndex } = this.state;
        let { results } = this.props;

        if (e.keyCode === 40) {
            // arrow down
            if (activeIndex === null) {
                activeIndex = 0;
            } else {
                activeIndex++;

                if (activeIndex > results.length - 1) {
                    activeIndex = 0;
                }
            }
        } else if (e.keyCode === 38) {
            // arrow up
            if (activeIndex === null) {
                activeIndex = results.length - 1;
            } else {
                activeIndex--;

                if (activeIndex < 0) {
                    activeIndex = results.length - 1;
                }
            }
        } else if (e.keyCode === 13) {
            // enter
            if (isFocused) {
                e.preventDefault();
            }

            if (activeIndex !== null && typeof results[activeIndex] !== 'undefined') {
                this.setValue(this.filterResults()[activeIndex])
            } else {
                this.onBlurHandler();
            }
        }

        this.setState({
            activeIndex: activeIndex,
        });
    };
    handleFocus = () => {
        let { isReadOnly } = this.props;
        let { input } = this.state;

        if (!isReadOnly) {
            input.focus()
        }
    };
    render() {
        let { placeholder, value, name, icon, errors, isReadOnly, className, renderValue, isId, halfWidth } = this.props;
        let { input } = this.state;

        return (
            <div onClick={this.handleFocus} onMouseDown={this.handleFocus} className={halfWidth ? 'autocomplete-input-container input-group-half' : ('autocomplete-input-container input-group' + (className ? ` ${className}` : ''))}>
                <Input
                    isId={isId}
                    className={'autocomplete'}
                    errors={errors}
                    type={'text'}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    icon={icon}
                    onChange={this.onChange}
                    attributes={{
                    autoComplete: 'off',
                    readOnly: !!isReadOnly,
                    onFocus: this.onFocusHandler,
                    onBlur: this.onBlurHandler,
                    onKeyDown: this.onKeyDownHandler,
                    onClick: this.onFocusHandler,
                    ref: (el) => {
                        if (input || !el) {
                            return false;
                        }

                        this.setState({
                            input: el,
                        });
                    }
                }}/>
                {renderValue ? renderValue() : false}
                {this.renderResults()}
            </div>
        );
    }
}

InputAutocomplete.propTypes = {
    isId: PropTypes.bool,
    className: PropTypes.string,
    results: PropTypes.array,
    isReadOnly: PropTypes.bool,
    errors: PropTypes.array,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    renderOptions: PropTypes.func,
    renderValue: PropTypes.func,
    icon: PropTypes.node,
};

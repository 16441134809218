export const openFullscreen = ref => {
    if (ref.requestFullscreen) {
        ref.requestFullscreen();
    } else if (ref.mozRequestFullScreen) { /* Firefox */
        ref.mozRequestFullScreen();
    } else if (ref.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        ref.webkitRequestFullscreen();
    } else if (ref.msRequestFullscreen) { /* IE/Edge */
        ref.msRequestFullscreen();
    }
};

export const isFullScreen = () => {
    return document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
};

export const isChrome = () => {
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
};
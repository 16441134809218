import React from "react";
import ControlRow from './ControlRow';

export class RegionShadeValues extends React.PureComponent {
    

    render() {
        return (
            <div className={'shade-master-colors-area'}>
                <ControlRow title={'Shade Value'}>
                    <input className={''} value={this.props.shadeValue} readOnly={true} />
                </ControlRow>
                <ControlRow title={'3D Master'}>
                    <input className={''} value={this.props.master3dValue} readOnly={true} />
                </ControlRow>
                <ControlRow title={'Brightness'}>
                    <input className={''} value={this.props.brightnessValue} readOnly={true} />
                </ControlRow>
            </div>
        );
    }
}

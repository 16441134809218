import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Route as RouteComponent } from 'react-router-dom';

class PublicRoute extends React.PureComponent {

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps) {
        
    }

    render() {
        let { path, component, attributes } = this.props;

        return (
            <div>
                <RouteComponent exact path={path} component={component} {...attributes}/>
            </div>
        )
    }
}

PublicRoute.propTypes = {
    isLogged: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    component: PropTypes.object,
    attributes: PropTypes.object,
    isProtected: PropTypes.bool,
};

export default withRouter(PublicRoute);

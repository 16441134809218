import { connect } from 'react-redux';
import Edit from '../../../components/case/subMenus/Edit';
import { getEditValues, getIsChangeOrientationDisabled, getIsOriginalOnly, getIsOriginalSelected, getRedoAvailable, getUndoAvailable } from '../../../selectors/workArea.selector';
import {
    rotateImage,
    setGrayScale,
    setBrightness,
    setContrast,
    toggleCrop,
    grayscaleImage,
} from '../../../actions/workArea.actions';
import { redoSavedPhoto, saveFlippedPhoto, saveRotatedPhoto, undoSavedPhoto, updateGraphicAnnotations, updateShadeTabCoords, updateTextAnnotations } from '../../../actions/case.actions';
import { getActivePhotoId, getCurrentPhoto, getGraphicAnnotations, getShadeTabCoords, getTextAnnotations } from '../../../selectors/case.selector';

const mapStateToProps = state => ({
    ...getEditValues(state),
    isOriginalOnly: getIsOriginalOnly(state),
    isOriginalSelected : getIsOriginalSelected(state),
    isChangeOrientationDisabled: getIsChangeOrientationDisabled(state),
    activePhotoId: getActivePhotoId(state),
    undoAvailable: getUndoAvailable(state),
    redoAvailable: getRedoAvailable(state),
    textAnnotations: getTextAnnotations(state),
    currentPhoto: getCurrentPhoto(state),
    graphicObjects: getGraphicAnnotations(state),
    shadeTabCoords: getShadeTabCoords(state)
});

const mapDispatchToProps = dispatch => ({
    rotateImage: (degrees) => dispatch(rotateImage(degrees)),
    setGrayScale: (value) =>  dispatch(setGrayScale(value)),
    setBrightness: (value) =>  dispatch(setBrightness(value)),
    setContrast: (value) =>  dispatch(setContrast(value)),
    toggleCrop: (value) => dispatch(toggleCrop(value)),
    saveRotatedPhoto: (photoId, rotation) => dispatch(saveRotatedPhoto(photoId, rotation)),
    saveFlippedPhoto: (photoId, flipOrientation) => dispatch(saveFlippedPhoto(photoId, flipOrientation)),
    undoSavedPhoto: (photoId) => dispatch(undoSavedPhoto(photoId)),
    redoSavedPhoto: (photoId) => dispatch(redoSavedPhoto(photoId)),
    updateTextAnnotations: (photoId, value) => dispatch(updateTextAnnotations(photoId, value)),
    updateGraphicAnnotations: (photoId, value) => dispatch(updateGraphicAnnotations(photoId, value)),
    updateShadeTabCoords: (photoId, coords) => dispatch(updateShadeTabCoords(photoId, coords)),
    grayscaleImage: (photoId) => dispatch(grayscaleImage(photoId))
});
export default connect(mapStateToProps, mapDispatchToProps)(Edit);

import React from "react";
import PropTypes from 'prop-types';
import TopMenu from '../containers/case/TopMenu';
import LeftMenu from './case/LeftMenu';
//import PhotoContainer from './case/PhotoContainer';
//import ReactResizeDetector from 'react-resize-detector';
import PhotoContainer from '../containers/case/PhotoContainer';
import ScreenLoader from './elements/loader/ScreenLoader';
import { isLoaderTest } from '../config/app';
import SubMenu from "./case/SubMenu";
import { caseShape } from '../helpers/case';
import Walkthrough from './case/Walkthrough';
import Header from '../containers/Header';
import QcView from "../containers/case/QcView";
import { selectedPhotoType } from "../actions/case.actions";


export default class Case extends React.PureComponent {
    state = {
        percent: 0,
        isLoading: isLoaderTest,
        caseId: this.props.match.params.caseId,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        let newCaseId = nextProps.match.params.caseId;

        if (newCaseId !== prevState.caseId) {
            if (!nextProps.isLoader) {
                nextProps.loadCase(newCaseId);
            }
            return {...prevState, caseId: newCaseId};
        }

        return null;
    }

    componentDidMount() {
        let { loadCase, match, isLogged, currentCase } = this.props;

        if (isLogged && (!currentCase || currentCase.caseId !== match.params.caseId ) ) {
            loadCase(match.params.caseId);
        }
    }

    /*testLoader = () => {
        if (!isLoaderTest) {
            return false;
        }

        let percent = this.state.percent;
        let interval = null;

        interval = setInterval(() => {
            percent += 25;

            if (percent >= 100) {
                clearInterval(interval);

                setTimeout(() => {
                    this.setState({isLoading: false});
                }, 1000);
            }

            this.setState({percent: percent})
        }, 1000);
    };*/

    closeWalkthrough = (notShow) => {
        let {closeWalkthrough, changeWalkthroughSettings} = this.props;

        closeWalkthrough(notShow);
        changeWalkthroughSettings(notShow ? 'off' : 'on');
    }

    renderWalkthrough = () => {
        let {
            walkthrough,
            toggleWalkthroughExpand,
        } = this.props;

        return  <Walkthrough
            close={this.closeWalkthrough}
            isVisible={walkthrough.isWalkthrough}
            isExpanded={walkthrough.isExpanded}
            toggle={toggleWalkthroughExpand}
        />;
    };

    render() {
        let {
            leftMenuAction,
            isWorkAreaMenuShow,
            showWorkAreaMenu,
            hideWorkAreaMenu,
            showSubMenu,
            hideSubMenu,
            subMenu,
            isLoader,
            loaderPercent,
            loaderText,
            currentCase,
            disabledButtons,
            currentPhotoProcessingStep,
            currentShadeReferenceAnalysis,
            startProcessPhoto,
            isQcMode,
            lastMap, selectedPhotoType, isTripleAllowed, isDoubleAllowed, isShadeMapAvailable, generateShadeMap, isModifierAvailable
        } = this.props;
        let { caseId } = this.state;

        return (
            <div className={'case dark-page'}>
                <Header/>

                <ScreenLoader isLoading={isLoader} percent={loaderPercent}>
                    {loaderText}
                </ScreenLoader>
                <TopMenu showWorkAreaMenu={showWorkAreaMenu}
                hideWorkAreaMenu={hideWorkAreaMenu}
                 isWorkAreaMenuShow={isWorkAreaMenuShow}
                 currentPhotoProcessingStep={currentPhotoProcessingStep}
                 currentShadeReferenceAnalysis={currentShadeReferenceAnalysis}
                 startProcessPhoto={startProcessPhoto}
                 showSubMenu={showSubMenu}
                 />

                <div className={'work-area'}>
                    <LeftMenu
                        leftMenuAction={leftMenuAction}
                        isWorkAreaMenuShow={isWorkAreaMenuShow}
                        showSubMenu={showSubMenu}
                        hideSubMenu={hideSubMenu}
                        subMenu={subMenu}
                        disabledButtons={disabledButtons}
                        lastMap={lastMap}
                        selectedPhotoType={selectedPhotoType}
                        isTripleAllowed={isTripleAllowed}
                        isDoubleAllowed={isDoubleAllowed}
                        isShadeMapAvailable={isShadeMapAvailable}
                        generateShadeMap={generateShadeMap}
                        isModifierAvailable={isModifierAvailable}
                    />
                    <SubMenu
                        currentCase={currentCase}
                        menu={subMenu}
                        hideSubMenu={hideSubMenu}
                    />

                    { isQcMode ?
                        <QcView />
                        :    <PhotoContainer caseId={parseInt(caseId)} isWorkAreaMenuShow={isWorkAreaMenuShow}/> }

                    {this.renderWalkthrough()}
                </div>
            </div>
        )
    }
}

Case.propTypes = {
    subMenu: PropTypes.string.isRequired,
    leftMenuAction: PropTypes.func.isRequired,
    isWorkAreaMenuShow: PropTypes.bool.isRequired,
    showWorkAreaMenu: PropTypes.func.isRequired,
    hideWorkAreaMenu: PropTypes.func.isRequired,
    toggleWalkthroughExpand: PropTypes.func.isRequired,
    showSubMenu: PropTypes.func.isRequired,
    hideSubMenu: PropTypes.func.isRequired,
    closeWalkthrough: PropTypes.func.isRequired,
    loadCase: PropTypes.func.isRequired,
    isLoader: PropTypes.bool.isRequired,
    isQcMode: PropTypes.bool,
    loaderPercent: PropTypes.number.isRequired,
    loaderText: PropTypes.string.isRequired,
    disabledButtons: PropTypes.array.isRequired,
    currentCase: caseShape,
    currentPhotoProcessingStep: PropTypes.number.isRequired,
    currentShadeReferenceAnalysis: PropTypes.string.isRequired,
    startProcessPhoto: PropTypes.func.isRequired,
    walkthrough: PropTypes.shape({
        isWalkthrough: PropTypes.bool.isRequired,
        isExpanded: PropTypes.bool.isRequired,
    }),
    changeWalkthroughSettings: PropTypes.func,
};

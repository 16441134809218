import React from 'react';
import PropTypes from 'prop-types';
import { Label, Text } from 'react-konva';

export default class TextAnnotationLabel extends React.PureComponent {
	state = {
		offsetX: 0,
		offsetY: 0
	}

	render() {
		let { x, y, text, index, onDragEnd, onClick, color, fontSize, draggable } = this.props;
		let {offsetX, offsetY} = this.state;
		
		return (
			<Label
				ref={ (el) => {
					this.label = el
					if (this.label != null) {
						this.setState({
							offsetX: this.label.getWidth() / 2,
							offsetY: this.label.getHeight() / 2
						})	
					}
				} }
				x={x }
				y={y }
				draggable={draggable ?? true}
				onDragEnd={onDragEnd}
				index={index}
				onClick={onClick}
				onTouchEnd={onClick}
				offsetX={offsetX} offsetY={offsetY}
			>
				<Text  text={text} fill={color} padding={1} fontStyle={'bold'} fontSize={fontSize} />
			</Label>
		);
	}
}

TextAnnotationLabel.propTypes = {
	x: PropTypes.number.isRequired,
	y: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
};

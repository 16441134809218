import React from "react";
import PropTypes from 'prop-types';
import { stepsGroup } from '../../../constants/steps';

export default class FormSteps extends React.PureComponent {
    getSteps = () => (stepsGroup[this.props.stepGroup] || []);
    render() {
        const { currentStep } = this.props;
        const steps = this.getSteps();
        return (
            <div className={'form-steps'+ (currentStep === steps.length ? ' completed' : '')}>
                {steps.map((step, index) => {
                    return (
                        <div key={index} className={'step' + (currentStep >= step.num ? ' active' : '')}>
                            <div className={'num'}>{step.num}</div>
                            <div className={'text'}>{step.text}</div>
                        </div>
                    )
                })}
            </div>
        );
    }
}

FormSteps.propTypes = {
    currentStep: PropTypes.number.isRequired,
    stepGroup: PropTypes.string.isRequired,
};

import { connect } from "react-redux";
import ProfileCreate from "../../components/dentist/ProfileCreate";
import { getCreateProfile } from "../../selectors/user.selector";
import { getCountries, getStates, setStates } from "../../actions/settings.action";
import { getIsUsernameUnique, setDentistToAdd } from "../../actions/user.actions";

const mapStateToProps = state => ({
    userProfile: getCreateProfile(state),
    countries: state.settings.countries,
    states: state.settings.states,
    isUsernameUnique: state.user.isUsernameUnique,
});

const mapDispatchToProps = dispatch => ({
    getStates: countryId => dispatch(getStates(countryId)),
    setStates: states => dispatch(setStates(states)),
    getCountries: () => dispatch(getCountries()),
    setDentistToAdd: dentist => dispatch(setDentistToAdd(dentist)),
    getIsUsernameUnique: (username, nextPage) => dispatch(getIsUsernameUnique({username, nextPage}))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCreate);

import React from "react";
import PropTypes from "prop-types";
import SwitchButtons from '../../elements/form/SwitchButtons';
import ControlRow from './elements/ControlRow';
import { openFullscreen } from '../../../helpers/ui';
import Select from '../../elements/form/Select';
import { IMAGE_ORIGINAL } from "../../../constants/imageTypes";
import { RegionShadeValues } from "./elements/RegionShadeValues";


export default class Process extends React.PureComponent {
    shadeGuideSelectHandler = (guideId) => {
        let { selectShadeGuide, getShadeGuide } = this.props;

        selectShadeGuide(guideId);
        getShadeGuide(guideId);
    };
    renderShadeGuides = () => {
        let { shadeGuides } = this.props;

        if (!shadeGuides) {
            return false;
        }
        //console.log(shadeGuides)
        return <Select options={shadeGuides.options} value={shadeGuides.selected} onChange={this.shadeGuideSelectHandler} />;
    };

    toggleShadeGuideExpand = () => {
        let { isShadeGuideExpanded, expandShadeGuide, unexpandShadeGuide } = this.props;

        if (!isShadeGuideExpanded) {
            return expandShadeGuide();
        }

        return unexpandShadeGuide();
    };

    colorClickHandler = (name) => {
        let { selectedPhotoType, changeImageType } = this.props;

        changeImageType(name);
        selectedPhotoType(name);
    };
    regionClickHandler = (name) => {
        let { choseRegion } = this.props;

        choseRegion(name);
    };
    correctedClickHandler = (name) => {
        let { changeCorrectedImageType } = this.props;

        changeCorrectedImageType(name);
    }
    renderCorrectedButtons = (buttons) => {
        return <SwitchButtons className={'color'} buttons={buttons} onClick={this.correctedClickHandler} />
    };
    renderColorButtons = (buttons) => {
        return <SwitchButtons className={'color'} buttons={buttons} onClick={this.colorClickHandler} />
    };
    renderRegionButtons = (buttons) => {
        return <SwitchButtons className={'region'} buttons={buttons} onClick={this.regionClickHandler} />
    };
    fullScreenClickHandler = () => {
        let { photoContainerRef } = this.props;

        openFullscreen(photoContainerRef);

    };

    handleColorCorrect = () => {
        let { runColorCorrect } = this.props;

        runColorCorrect();
    }

    handleRestart = () => {
        let { restartOriginalImage, selectedPhotoType } = this.props;

        restartOriginalImage();
        selectedPhotoType(IMAGE_ORIGINAL);
    }

    render() {
        let { colorCorrectButtons, regionButtons, correctedImageButtons, isColorCorrectionAllowed,
            isRestartAllowed, shadeValue, master3dValue, brightnessValue } = this.props;

        return (
            <div className={'process'}>

                <ControlRow title={'Shade Guide:'} className={'switch-buttons-container column'}>
                    <div id="shadeGuides" className={'shade-guides'}>
                        {this.renderShadeGuides()}
                    </div>
                </ControlRow>

                <ControlRow title={'Color Correct'} className={'action'} isDisabled={!isColorCorrectionAllowed} onClick={this.handleColorCorrect} />
                <ControlRow title={''} className={'switch-buttons-container column'}>
                    {this.renderColorButtons(colorCorrectButtons)}
                    {this.renderCorrectedButtons(correctedImageButtons)}
                    {this.renderRegionButtons(regionButtons)}
                </ControlRow>

                <RegionShadeValues shadeValue={shadeValue} master3dValue={master3dValue} brightnessValue={brightnessValue}></RegionShadeValues>


                <ControlRow title={'Full screen'} className={'action'} onClick={this.fullScreenClickHandler}></ControlRow>
                <ControlRow title={'Restart'} className={'action'} isDisabled={!isRestartAllowed} onClick={this.handleRestart}></ControlRow>
            </div>
        )
    }
}
/*<GreenButton className={'padded'} text={'Full screen'} onClick={() => {}}/>
                <GreenButton className={'padded'} text={'Restart'} onClick={() => {}}/>*/
Process.propTypes = {
    shadeGuides: PropTypes.object.isRequired,
    selectShadeGuide: PropTypes.func.isRequired,
    expandShadeGuide: PropTypes.func.isRequired,
    unexpandShadeGuide: PropTypes.func.isRequired,
    isShadeGuideExpanded: PropTypes.bool.isRequired,
    colorCorrectButtons: PropTypes.array.isRequired,
    correctedImageButtons: PropTypes.array.isRequired,
    regionButtons: PropTypes.array.isRequired,
    changeImageType: PropTypes.func.isRequired,
    choseRegion: PropTypes.func.isRequired,
    runColorCorrect: PropTypes.func.isRequired,
    selectedPhotoType: PropTypes.func.isRequired,
    changeCorrectedImageType: PropTypes.func.isRequired,
    isColorCorrectionAllowed: PropTypes.bool,
    isRestartAllowed: PropTypes.bool,
    restartOriginalImage: PropTypes.func.isRequired,
    shadeValue: PropTypes.string,
    master3dValue: PropTypes.string,
    brightnessValue: PropTypes.string,
};

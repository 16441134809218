import React, { useState } from 'react'
import { dateToString, stringToSystemString } from "../../helpers/date";
import MatDialog, { MatDialogContent, MatDialogHeader } from './DraggableDialog';
import GreenButton from './form/buttons/GreenButton';
import InputDate from "./form/input/InputDate";

export default function ExportConnectedDentists ({isOpenDialog, onClose, onConfirm}) {

    function dateFromChangeHandler(v) {
        setDateFrom(v);
    }

    function dateToChangeHandler(v){
        setDateTo(v);
    }
    
    const [dateFrom, setDateFrom] = useState(dateToString(new Date()));
    const [dateTo, setDateTo] = useState(dateToString(new Date()));

    
    return (        
        <MatDialog
        isOpen={isOpenDialog ?? false}
        onClose={onClose}
        classes={{
            container: 'overlay',
            paper: 'modal add-edit-lab-specialist-popup export-dentists',
        }}>
        <MatDialogHeader onClose={onClose} title={'Export Dentists List'} isLightMode={true}>
            
        </MatDialogHeader>

        <MatDialogContent>
            <div className="light-page add-edit-lab-specialist-content">
                <div className="content-text">
                    Select Date Range of cases created by dentists
                </div>
                    <InputDate
                        isRequired={false}
                        placeholder={'From Date'}
                        name={'from_date'}
                        onChange={dateFromChangeHandler}
                        value={dateFrom}
                        
					/>
                    <InputDate
                        isRequired={false}
                        placeholder={'To Date'}
                        name={'to_date'}
                        onChange={dateToChangeHandler}
                        value={dateTo}
                        
					/>
            </div>
            <div className="footer">
                <GreenButton className="export-button" text="Export" onClick={() => {
                    console.log('call download list');
                    //onClose();
                    onConfirm(stringToSystemString(dateFrom), stringToSystemString(dateTo));
                }} disabled={false} />
                
            </div>
        </MatDialogContent>
    </MatDialog>
    )
    
}

//export default connect(mapStateToProps, mapDispatchToProps)(ExportConnectedDentists)
import React from "react";
import PropTypes from 'prop-types';
import Button from '../Button';

export default class GreenButton extends React.PureComponent {
    render() {
        let { text, className, onClick, isSubmit, isLoading, disabled } = this.props;

        let content = '';

        if (isLoading) {
            content = <img src={require('../../../../assets/images/loader-horizontal-white.gif')} alt={'Loading'} className={'button-loader'}/>;
            text = '';
        }
        return (
            <Button type={isSubmit ? 'submit' : 'button'} onClick={() => {
                if (isLoading || disabled) {
                    return false;
                }

                onClick();
            }} className={'green' + (className ? ` ${className}` : '')} text={text} disabled={disabled}>{content}</Button>
        )
    }
}

GreenButton.propTypes = {
    isLoading: PropTypes.bool,
    isSubmit: PropTypes.bool,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

import React from "react";
import PropTypes from 'prop-types';

export default class InputGroup extends React.PureComponent {
    render() {
        let { children, className } = this.props;

        className = className ? ` ${className}` : '';

        return (
            <div className={`input-group ${className}`}>
                {children}
            </div>
        )
    }
}

InputGroup.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

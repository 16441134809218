import React from "react";
import Icon from '../Icon';

export default class Check extends React.PureComponent {
    render() {
        return (
            <Icon icon={String.fromCharCode(59392)}/>
        )
    }
}

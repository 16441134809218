import React from "react";
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Header from '../../containers/Header';
import PhotoPage from '../elements/PhotoPage';
import FormSteps from './elements/FormSteps';
import Form from '../elements/Form';
import InputText from '../elements/form/input/InputText';
import Checkbox from '../elements/form/Checkbox';
import Button from '../elements/form/Button';
import PageWithSupportFooter from '../elements/PageWithSupportFooter';
import Row from '../elements/form/Row';

import { getTargetCountry, getTargetState } from "../../helpers/user";
import Collapse from "@material-ui/core/Collapse";
import LabSpecialistList from "../profile/LabSpecialistList";
import { DENTIST_ACCOUNT_CREATED, LOGIN } from "../../constants/url";
import Required from '../elements/icons/Required';
import { themeLight } from "../../config/app";

export default class SpecialistAdd extends React.PureComponent {
    state = {
        customerNumber: '',
        contactName: '',
        specialistName: '',
        specialistPhone: '',
        specialistEmail: '',
        isSpecialOffers: false,
        isPrivacy: false,
        addSpecialistAfterReceive: false,
        specialistsCollapseOpen: false,
    };
    componentDidMount() {
        const { userProfile, history } = this.props;

        if (!userProfile || (userProfile && !Object.keys(userProfile).length)) {
            history.push(LOGIN);
        }
    }
    componentDidUpdate(prevProps) {
        const { activeSpecialist } = this.props;

        if (this.state.addSpecialistAfterReceive && activeSpecialist && Object.keys(activeSpecialist).length) {
            this.handleSave(activeSpecialist);
            this.setState({addSpecialistAfterReceive: false});
        }
        if (this.props.profileCreated) {
            this.props.history.push(DENTIST_ACCOUNT_CREATED);
        }
    }
    customerNumberChangeHandler = (value) => {
        this.setState({customerNumber:value});
    };
    contactNameChangeHandler = (value) => {
        this.setState({contactName:value});
    };
    specialistNameChangeHandler = (value) => {
        this.setState({specialistName:value});
    };
    specialistPhoneChangeHandler = (value) => {
        this.setState({specialistPhone:value});
    };
    specialistEmailChangeHandler = (value) => {
        this.setState({specialistEmail:value});
    };
    specialOffersChangeHandler = () => {
        let { isSpecialOffers } = this.state;

        this.setState({isSpecialOffers: !isSpecialOffers});
    };
    privacyChangeHandler = () => {
        let { isPrivacy } = this.state;

        this.setState({isPrivacy: !isPrivacy});
    };
    submitHandler = () => {
        const { dentistToAdd, createDentistProfile } = this.props;
        createDentistProfile(dentistToAdd.profile, dentistToAdd.dentist);
    };
    searchAndAddSpecialist = id => {
        this.setState({
            addSpecialistAfterReceive: true,
        });
        this.props.getSpecialistById(id);
    };
    searchClickHandler = () => {
        const { customerNumber, contactName, specialistName, specialistPhone, specialistEmail } = this.state;
        const { searchSpecialists, userId } = this.props;
        const data = [
            `customerId=${customerNumber}`,
            `contactName=${contactName}`,
            `name=${specialistName}`,
            `phone=${specialistPhone}`,
            `email=${specialistEmail}`,
        ];

        searchSpecialists(data.join('&'), userId);
        this.setState({
            specialistsCollapseOpen: true,
        });
    };
    handleSave = activeSpecialist => {
        const { states, countries, addSpecialist, setSpecialist, userId } = this.props;

        const country = getTargetCountry(countries, activeSpecialist.country);
        const state = getTargetState(states, activeSpecialist.state);
        const targetSpecialist = {
            ...activeSpecialist,
            country,
            state,
        };
        addSpecialist(targetSpecialist, false, userId);
        setSpecialist({});
    };
    render() {
        const {
            customerNumber,
            contactName,
            specialistEmail,
            specialistName,
            specialistPhone,
            isSpecialOffers,
            isPrivacy,
            specialistsCollapseOpen,
        } = this.state;
        const { specialists } = this.props;
        return (
            <div className={'light-page create-dentist-profile specialist-add'}>
                <PhotoPage>
                    <Header theme={themeLight} isUserMenu={false}/>

                    <PageWithSupportFooter className={'specialist-add-inner'}>
                    <FormSteps currentStep={3} stepGroup={'dentist'}/>

                    <div className={'title'}>
                        Add your dental specialist(s)

                        <div className={'sub-title'}>Search existing specialist</div>
                    </div>


                    <Form onSubmit={this.submitHandler} isAjax={true}>
                        <InputText name={'customer_number'} onChange={this.customerNumberChangeHandler} value={customerNumber} placeholder={'Customer Number'} isRequired={false}/>
                        <InputText name={'specialist_contact_name'} onChange={this.contactNameChangeHandler} value={contactName} placeholder={'Contact Name'} isRequired={false}/>
                        <InputText name={'specialist_name'} onChange={this.specialistNameChangeHandler} value={specialistName} placeholder={'Specialist Name'} isRequired={false}/>
                        <InputText name={'specialist_phone'} onChange={this.specialistPhoneChangeHandler} value={specialistPhone} placeholder={'Specialist Phone'} isRequired={false}/>
                        <InputText name={'specialist_email'} onChange={this.specialistEmailChangeHandler} value={specialistEmail} placeholder={'Specialist email'} isRequired={false}/>

                        <Row className={'search-control'}>
                            <Button type={'button'} onClick={this.searchClickHandler}>Search</Button>
                        </Row>
                    </Form>
                        <Collapse className="specialist-collapse" in={specialistsCollapseOpen} timeout="auto" unmountOnExit>
                            <div className="specialists-container">
                                <LabSpecialistList
                                    className="table-container"
                                    rows={specialists}
                                    columns={['Customer#', 'Specialist Name', 'eMail']}
                                    handleClick={id => this.searchAndAddSpecialist(id)}
                                />
                            </div>
                        </Collapse>
                    <Form className={'bottom-form'} onSubmit={this.submitHandler} isAjax={true}>
                        <div className="div">
                            <Row className={`checkbox-control ${specialistsCollapseOpen ? specialists.length ? 'search-result' : 'no-result' : ''}`}>
                                <Checkbox text={'Yes, email me updates and special offers from Shadewave'} onChange={this.specialOffersChangeHandler} isChecked={isSpecialOffers}/>
                                <Checkbox isRequired={true} onChange={this.privacyChangeHandler} isChecked={isPrivacy}>
                                    I Agree to <NavLink to={'/'}>Shadewave Terms and Conditions</NavLink> and <NavLink to={'/'}>Shadewave Privacy Policy</NavLink>. Shadewave is HIPAA Compliant.<Required/>
                                </Checkbox>
                            </Row>

                            <Row className={`submit-control ${specialistsCollapseOpen ? specialists.length ? 'search-result' : 'no-result' : ''}`}>
                                <Button type={'submit'}>Submit</Button>
                            </Row>
                        </div>
                        </Form>
                    </PageWithSupportFooter>

                </PhotoPage>
            </div>
        );
    }
}

SpecialistAdd.propTypes = {
    searchSpecialists: PropTypes.func.isRequired,
    addSpecialist: PropTypes.func.isRequired,
    setSpecialist: PropTypes.func.isRequired,
    getSpecialistById: PropTypes.func.isRequired,
    getStates: PropTypes.func.isRequired,
    getCountries: PropTypes.func.isRequired,
    setStates: PropTypes.func.isRequired,
    activeSpecialist: PropTypes.object.isRequired,
    specialists: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    dentistToAdd: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    userProfile: PropTypes.object.isRequired,
};

const UserActionTypes = {
    SIGN_IN_START: 'SIGN_IN_START',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_EXPIRED: 'SIGN_IN_EXPIRED',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
    SIGN_OUT_START: 'SIGN_OUT_START',
    SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
    GET_USER : 'GET_USER',
    USER_LOADED : 'USER_LOADED',
    RESEND_USERNAME_OR_PASSWORD: 'RESEND_USERNAME_OR_PASSWORD',
    UPDATE_PASSWORD: 'UPDATE_PASSWORD',
    UPDATE_LAB_PROFILE: 'UPDATE_LAB_PROFILE',
    UPDATE_DENTIST: 'UPDATE_DENTIST',
    USER_NOT_AUTHENTICATED: 'USER_NOT_AUTHENTICATED',
    ADD_LAB: 'ADD_LAB',
    ADD_SPECIALIST: 'ADD_SPECIALIST',
    GET_LAB: 'GET_LAB',
    GET_SPECIALIST: 'GET_SPECIALIST',
    SET_LAB: 'SET_LAB',
    SET_SPECIALIST: 'SET_SPECIALIST',
    DELETE_LAB: 'DELETE_LAB',
    DELETE_SPECIALIST: 'DELETE_SPECIALIST',
    SEARCH_LABS: 'SEARCH_LABS',
    SEARCH_SPECIALISTS: 'SEARCH_SPECIALISTS',
    SET_LABS: 'SET_LABS',
    SET_SPECIALISTS: 'SET_SPECIALISTS',
    GET_PROFILE_FOR_SUBSCRIPTION: 'GET_PROFILE_FOR_SUBSCRIPTION',
    SET_PROFILE_FOR_SUBSCRIPTION: 'SET_PROFILE_FOR_SUBSCRIPTION',
    CREATE_LAB_PROFILE: 'CREATE_LAB_PROFILE',
    IS_PROFILE_CREATE: 'IS_PROFILE_CREATE',
    CREATE_DENTIST_PROFILE: 'CREATE_DENTIST_PROFILE',
    SET_DENTIST_TO_ADD: 'SET_DENTIST_TO_ADD',
    RESET_PROFILE_DATA: 'RESET_PROFILE_DATA',
    CREDITS_CHARGED: 'CREDITS_CHARGED',
    DELETE_DENTIST: 'DELETE_DENTIST',
    NO_CREDITS_AVAILABLE:'NO_CREDITS_AVAILABLE',
    GET_MAGICTOUCH_LABS_LIST: 'GET_MAGICTOUCH_LABS_LIST',
    MAGIC_TOUCH_LABS_LIST_LOADED: 'MAGIC_TOUCH_LABS_LIST_LOADED',
    GET_IS_USERNAME_UNIQUE: 'GET_IS_USERNAME_UNIQUE',
    USERNAME_UNIQUE_RESULT: 'USERNAME_UNIQUE_RESULT',
    CHECK_TRIAL_LINK_RESULT: 'CHECK_TRIAL_LINK_RESULT',
    CHECK_TRIAL_LINK: 'CHECK_TRIAL_LINK',
    CREATE_TRIAL_PROFILE: 'CREATE_TRIAL_PROFILE',
    CREATE_TRIAL_PROFILE_ERROR: 'CREATE_TRIAL_PROFILE_ERROR',
    GENERATE_BACKUP: 'GENERATE_BACKUP',
    BACKUP_URL_GENERATED: 'BACKUP_URL_GENERATED',
    GENERATE_EXPORT_DENTISTS: 'GENERATE_EXPORT_DENTISTS',
    EXPORT_DENTISTS_URL_GENERATED: 'EXPORT_DENTISTS_URL_GENERATED'    
};

export default UserActionTypes;

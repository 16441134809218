import React from "react";
import PropTypes from "prop-types";

export default class Search extends React.PureComponent {
    state = {
        value: ''
    };
    changeHandler = (e) => {
        let { onChange } = this.props;

        let value = e.target.value;

        //this.setState({value: value});

        onChange(value);
    };
    render() {
        let { value } = this.props;

        return (
            <div className={'search-container'}>
                <input type={'text'} value={value} onChange={this.changeHandler} placeholder={'Search'}/>
                <div className={'search-icon'}></div>
            </div>
        )
    }
}

Search.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

import { connect } from 'react-redux';
import EditDentistProfile from '../../components/profile/EditDentistProfile';
import { getCountries, getStates, setStates } from "../../actions/settings.action";
import {
    updateDentist,
    addLab,
    addSpecialist,
    getLabById,
    getSpecialistById,
    deleteLab,
    deleteSpecialist,
    setLab,
    setSpecialist,
    searchLabs,
    searchSpecialists,
    getMagicTouchLabsList,
} from "../../actions/user.actions";

const mapStateToProps = state => ({
    userProfile: state.user.userProfile,
    countries: state.settings.countries,
    states: state.settings.states,
    activeLab: state.user.activeLab,
    activeSpecialist: state.user.activeSpecialist,
    labs: state.user.labs,
    specialists: state.user.specialists,
    magictouch_labs: state.user.magictouch_labs,
});

const mapDispatchToProps = dispatch => ({
    getCountries: () => dispatch(getCountries()),
    getStates: countryId => dispatch(getStates(countryId)),
    updateDentist: (dentistProfile, dentistName) => dispatch(updateDentist({dentistProfile, dentistName})),
    addLab: (lab, getUsers) => dispatch(addLab({lab, getUsers})),
    addSpecialist: (specialist, getUsers) => dispatch(addSpecialist({specialist, getUsers})),
    getLabById: id => dispatch(getLabById(id)),
    getSpecialistById: id => dispatch(getSpecialistById(id)),
    deleteLab: id => dispatch(deleteLab({id})),
    deleteSpecialist: id => dispatch(deleteSpecialist({id})),
    setLab: lab => dispatch(setLab(lab)),
    setSpecialist: specialist => dispatch(setSpecialist(specialist)),
    searchLabs: (lab, userId) => dispatch(searchLabs({lab, userId})),
    searchSpecialists: (specialist, userId) => dispatch(searchSpecialists({specialist, userId})),
    setStates: states => dispatch(setStates(states)),
    getMagicTouchLabsList: token => dispatch(getMagicTouchLabsList({token})),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDentistProfile);

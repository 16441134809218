import { connect } from 'react-redux';
import SecureNotes from '../components/SecureNotes';
import { saveCaseMessage, saveCaseAttachment, resetCaseMessagesSaved, resetCaseMessagePhotosSaved, resetCasePhotoLoaded } from '../actions/case.actions';
import {showNotification} from "../actions/ui.actions";

const mapStateToProps = state => ({
    caseMessages: state.case.caseMessages,
    casePhotos: state.case.casePhotos,
    roles: state.case.roles,
    caseId: state.case.caseId,
    caseMessagePhotoCount: state.case.caseMessagePhotoCount,
    loadedCaseMessagePhotoCount: state.case.loadedCaseMessagePhotoCount,
    casePhotosLoaded: state.case.casePhotosLoaded,
    caseMessagesLoaded: state.case.caseMessagesLoaded,
    savingCaseMessageProcessFinished: state.case.savingCaseMessageProcessFinished,
    savingCaseMessagePhotoProcessFinished: state.case.savingCaseMessagePhotoProcessFinished,
    canCreateCase: state.user.userProfile.canCreateCase,
    isPerUseLicense: !!state.case.currentCase.isPerUseLicense,
    notLoadedPhotos: state.case.notLoadedPhotos,
    savedPhotoCount: state.case.savedPhotoCount,
    photosToSaveCount: state.case.photosToSaveCount,
    notSavedPhotos: state.case.notSavedPhotos,
});

const mapDispatchToProps = dispatch => ({
    saveCaseMessage: (caseId, message, roleList) => dispatch(saveCaseMessage({caseId, message, roleList})),
    saveCaseAttachment: (caseId, files) => dispatch(saveCaseAttachment({caseId, files})),
    resetCaseMessagesSaved: () => (dispatch(resetCaseMessagesSaved())),
    resetCaseMessagePhotosSaved: () => (dispatch(resetCaseMessagePhotosSaved())),
    resetCasePhotoLoaded: () => (dispatch(resetCasePhotoLoaded())),
    showNotification: notificationData => dispatch(showNotification(notificationData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecureNotes);

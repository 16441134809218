import * as userRoles from '../constants/user';

export const modifyUserProfile = (profile) => {
    return profile;
};
const PUBLIC_FIELDS = [
    'patient_id', 'patient_first', 'patient_last', 'user_phone', 'user_name', 'case_number', 'customer_id',
];
const FIELD_BY_ROLES = {
    dentist: ['specialist_name', 'lab_name', 'specialist_name'],
    lab: ['doctor_first', 'doctor_last'],
    other: ['doctor_first', 'doctor_last'],
};
export const isFieldAllowed = (field, role) => {
    if (PUBLIC_FIELDS.includes(field)) {
        return true;
    }

    if ([userRoles.ROLE_DENTIST, userRoles.ROLE_DENTIST_LIMITED, userRoles.ROLE_DENTIST_MOBILE].includes(role)) {
        return FIELD_BY_ROLES.dentist.includes(field);
    }
    if ([userRoles.ROLE_LAB, userRoles.ROLE_LAB_MOBILE, userRoles.ROLE_LAB_MOBILE_CASE_FREE].includes(role)) {
        return FIELD_BY_ROLES.lab.includes(field);
    }

    return FIELD_BY_ROLES.other.includes(field);
};

export const getTargetCountry = (countries, targetCountry) => {
    const country = countries.find(country => country.id === targetCountry || country.name === targetCountry);
    return country ? country.id : (targetCountry || targetCountry === 0) ? targetCountry : '';
};
export const getTargetState = (states, targetState) => {
    const state = states.find(state => state.id === targetState || state.name === targetState);
    return state ? state.id : (targetState || targetState === 0) ? targetState : '';
};


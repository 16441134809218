import uiReducer from './ui.reducer';
import userReducer from './user.reducer';
import caseReducer from './case.reducer';
import settingsReducer from './settings.reducer';
import workAreaReducer from './workArea.reducer';
import ascendReducer from './ascend.reducer';

const initialReducers = {
    ui : uiReducer,
    user : userReducer,
    case : caseReducer,
    settings : settingsReducer,
    workArea: workAreaReducer,
    ascend: ascendReducer,
};

export default initialReducers;

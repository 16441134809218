import Case from '../containers/Case';
import Lookup from '../containers/Lookup';

import CreateCase from '../containers/CreateCase';

import Main from '../containers/Main';
import LoginComponent from '../containers/login/LoginComponent';
import ProfileCreate from '../containers/dentist/ProfileCreate';
import LabAdd from '../containers/dentist/LabAdd';
import DentistAccountCreated from '../containers/dentist/DentistAccountCreated';
import SpecialistAdd from '../containers/dentist/SpecialistAdd';
import EditLabProfile from '../containers/profile/EditLabProfile';
import EditDentistProfile from '../containers/profile/EditDentistProfile';
import CreateLabProfile from '../containers/profile/CreateLabProfile';
import CreateProfile from '../containers/profile/CreateProfile';
import * as urls from "../constants/url";
import LabAccountCreated from "../containers/profile/LabAccountCreated";
import AscendLogin from '../components/ascend/AscendLogin';
import AscendSelectCase from '../components/ascend/AscendSelectCase';
import AscendImageSelect from '../components/ascend/AscendImageSelect';
import AscendFinish from '../components/ascend/AscendFinish';
import CreateTrialProfile from '../containers/profile/CreateTrialProfile';

const routes = [
    {
        path: urls.DASHBOARD,
        component: Main,
        isProtected: true,
    },
    {
        path: urls.CASE,
        component: Case,
        isProtected: true,
    },
    {
        path: urls.LOOKUP,
        component: Lookup,
        isProtected: true,
    },
    {
        path: urls.CREATE_CASE,
        component: CreateCase,
        isProtected: true,
    },
    {
        path: urls.LOGIN,
        component: LoginComponent,
        isProtected: false,
    },
    {
        path: urls.FORGOT_PASSWORD,
        component: LoginComponent,
        isProtected: false,
    },
    {
        path: urls.UPDATE_PASSWORD,
        component: LoginComponent,
        isProtected: false,
    },
    {
        path: urls.EDIT_LAB_PROFILE,
        component: EditLabProfile,
        isProtected: true,
    },
    {
        path: urls.EDIT_DENTIST_PROFILE,
        component: EditDentistProfile,
        isProtected: true,
    },
    {
        path: urls.CREATE_DENTIST_PROFILE,
        component: ProfileCreate,
        isProtected: false,
    },
    {
        path: urls.LAB_ADD,
        component: LabAdd,
        isProtected: false,
    },
    {
        path: urls.SPECIALIST_ADD,
        component: SpecialistAdd,
        isProtected: false,
    },
    {
        path: urls.DENTIST_ACCOUNT_CREATED,
        component: DentistAccountCreated,
        isProtected: false,
    },
    {
        path: urls.CREATE_LAB_PROFILE,
        component: CreateLabProfile,
        isProtected: false,
    },
    {
        path: urls.CREATE_PROFILE,
        component: CreateProfile,
        isProtected: false,
    },
    {
        path: urls.LAB_ACCOUNT_CREATED,
        component: LabAccountCreated,
        isProtected: false,
    },
    {
        path: urls.ASCEND_SELECT_CASE,
        component: AscendSelectCase,
        isProtected: false
    },
    {
        path: urls.ASCEND_SELECT_IMAGE,
        component: AscendImageSelect,
        isProtected: false
    },
    {
        path: urls.ASCEND_FINISH,
        component: AscendFinish,
        isProtected: false,
    },
    {
        path: urls.ASCEND,
        component: AscendLogin,
        isProtected: false
    },
    {
        path: urls.CREATE_TRIAL_PROFILE,
        component: CreateTrialProfile,
        isProtected: false,
    }
];

export default routes;

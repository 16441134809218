import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	InputLabel,
	Select,
	TextField,
} from '@material-ui/core';
//import { Test } from './AddMagicTouchProductDialog.styles';

class AddMagicTouchProductDialog extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			product: null,
			quantity: '',
			isBridge: false,
			pontic: '',
			shade: '',
			teeth_numbers: '',
			teeth_numbers_type: 0,
		};
	}

	componentDidMount = () => {
		
	};

	componentDidUpdate = () => {
		
	};

	handleClose = () => {
		this.props.onClose(null);
	};

	handleOk = () => {
		const {
			product,
			quantity,
			isBridge,
			pontic,
			shade,
			teeth_numbers,
			teeth_numbers_type,
		} = this.state;
		const { magictouch_products } = this.props;
		this.props.onClose({
			product_code: product,
			quantity: parseInt(quantity),
			teeth_numbers_type: parseInt(teeth_numbers_type),
			teeth_numbers: teeth_numbers,
			shade: shade,
			is_bridge: isBridge,
			pontic_universal: teeth_numbers_type === 0 ? pontic : '',
			pontic_fdi: teeth_numbers_type === 1 ? pontic : '',
			description: magictouch_products.find((p) => p.productID == product).description,
		});
	};

	handleProductChange = (e) => {
		
		this.setState({
			product: e.target.value,
		});
	};

	handleQuantityChange = (e) => {
		this.setState({
			quantity: e.target.value,
		});
	};

	handleTeethNumbersTypeChange = (e) => {
		this.setState({
			teeth_numbers_type: e.target.value,
		});
	};

	handleTeethNumbersChange = (e) => {
		this.setState({
			teeth_numbers: e.target.value,
		});
	};

	handleShadeChange = (e) => {
		this.setState({
			shade: e.target.value,
		});
	};

	handlePonticChange = (e) => {
		this.setState({
			pontic: e.target.value,
		});
	};

	handleBridgeChange = (e) => {
		this.setState({
			isBridge: !this.state.isBridge,
		});
	};

	render() {
		const { isOpen, magictouch_products } = this.props;
		const {
			product,
			quantity,
			isBridge,
			pontic,
			shade,
			teeth_numbers,
			teeth_numbers_type,
		} = this.state;
		return (
			<Dialog className={'dialog-modal'} open={isOpen} onClose={this.handleClose}>
				<DialogTitle>Please, select MagicTouch product</DialogTitle>
				<DialogContent>
					<FormGroup className={'products-box'}>
						<FormControl variant='outlined' className={'products-formcontrol'}>
							<InputLabel htmlFor='products'>Product</InputLabel>
							<Select
								native
								value={product ?? ''}
								label='Product'
								onChange={this.handleProductChange}
								inputProps={{
									name: 'products',
									id: 'products',
								}}
							>
								{magictouch_products?.map((p) => {
									return (
										<option value={p.productID}>
											{p.description} - ${p.price}
										</option>
									);
								})}
							</Select>
						</FormControl>
						<FormControl className={'products-formcontrol'}>
							<TextField
								id='quantity'
								label='Quantity'
								variant='outlined'
								value={quantity}
								onChange={this.handleQuantityChange}
							/>
						</FormControl>
						<FormControl variant='outlined' className={'products-formcontrol'}>
							<InputLabel htmlFor='teethtype_select'>Teeth Numbers Type</InputLabel>
							<Select
								native
								value={teeth_numbers_type}
								label='Teeth Numbers Type'
								onChange={this.handleTeethNumbersTypeChange}
								inputProps={{
									name: 'teethtype-select',
									id: 'teethtype-select',
								}}
							>
								<option value={0}>Universal</option>
								<option value={1}>FDI</option>
							</Select>
						</FormControl>
						<FormControl className={'products-formcontrol'}>
							<TextField
								id='teeth_numbers'
								label='Teeth Numbers'
								variant='outlined'
								value={teeth_numbers}
								onChange={this.handleTeethNumbersChange}
							/>
						</FormControl>
						<FormControl className={'products-formcontrol'}>
							<TextField
								id='shade'
								label='Shade'
								variant='outlined'
								value={shade}
								onChange={this.handleShadeChange}
							/>
						</FormControl>
						<FormControl className={'products-formcontrol'}>
							<TextField
								id='pontic'
								label='Pontic'
								variant='outlined'
								value={pontic}
								onChange={this.handlePonticChange}
							/>
						</FormControl>

						<FormControlLabel
							className={'products-formcontrol'}
							control={
								<Checkbox
									checked={isBridge}
									onChange={this.handleBridgeChange}
									name='isBridgeCheck'
									color='primary'
								/>
							}
							label='Is Bridge'
							labelPlacement='end'
						/>
					</FormGroup>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose}>Cancel</Button>
					<Button onClick={this.handleOk}>Ok</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

AddMagicTouchProductDialog.propTypes = {
	// bla: PropTypes.string,
};

AddMagicTouchProductDialog.defaultProps = {
	// bla: 'test',
};

const mapStateToProps = (state) => ({
	// blabla: state.blabla,
});

const mapDispatchToProps = (dispatch) => ({
	// fnBlaBla: () => dispatch(action.name()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMagicTouchProductDialog);

import React from "react";
import PropTypes from "prop-types";
import { ImageList, ImageListItem } from "@material-ui/core";
import ShadeColorCard from "./ShadeColorCard";
import MatDialog, {
	MatDialogHeader,
	MatDialogContent,
} from "../../elements/DraggableDialog";

export default class ShadeColorSelectorModal extends React.PureComponent {
	onClick = (refColor, studioColor, shadeName) => {
		let { color, coordinate, onShadeSelected, hideWindow } = this.props;

		onShadeSelected(color, refColor, studioColor, coordinate, shadeName);
		hideWindow();
	};

	render() {
		let { refColors, hideWindow } = this.props;

		let refColorsTransposed = [];

		let columns = refColors.length % 4 === 0 ? 4 : 5;
		let rows = Math.ceil(refColors.length / columns);
		for (let i = 0; i < rows * columns; i++) {
			let index = Math.floor(i / columns) + rows * (i % columns);
			if (index < refColors.length)
				refColorsTransposed.push(refColors[index]);
			else refColorsTransposed.push({ rgb: "545353", name: "" });
		}

		return (
			<MatDialog
				classes={{ paper: "shade-selector-modal" }}
				onClose={hideWindow}
				closeOnOutsideClick={false}
				isOpen={true}
			>
				<MatDialogHeader
					title={"Select Shade Tab"}
					onClose={hideWindow}
				></MatDialogHeader>

				<MatDialogContent>
					<ImageList cols={columns} rowHeight={29} gap={1}>
						{refColorsTransposed.map((item, index) => {
							return (
								<ImageListItem key={index}>
									<ShadeColorCard
										color={"#" + item.rgb}
										studioColor={"#" + item.rgb_studio}
										text={item.name}
										onSelectedShade={this.onClick}
									/>
								</ImageListItem>
							);
						})}
					</ImageList>
				</MatDialogContent>
			</MatDialog>
		);
	}
}

ShadeColorSelectorModal.propTypes = {
	color: PropTypes.object.isRequired,
};

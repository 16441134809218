import React from "react";
import PropTypes from 'prop-types';
import Header from '../../containers/Header';
import PhotoPage from '../elements/PhotoPage';
import FormSteps from './elements/FormSteps';
import Form from '../elements/Form';
import InputText from '../elements/form/input/InputText';
import InputEmail from '../elements/form/input/InputEmail';
import InputPassword from '../elements/form/input/InputPassword';
import Select from '../elements/form/Select';
import Button from '../elements/form/Button';
import { getTargetCountry, getTargetState } from "../../helpers/user";
import { dateToSystemString } from "../../helpers/date";
import {LAB_ADD, LOGIN} from "../../constants/url";
import PageWithSupportFooter from '../elements/PageWithSupportFooter';
import { themeLight } from "../../config/app";

export default class ProfileCreate extends React.PureComponent {
    state = {
        username: '',
        password: '',
        confirmPassword: '',
        doctorFirst: '',
        doctorLast: '',
        practiceName: '',
        streetAddress: '',
        city: '',
        country: '',
        state: '',
        postal: '',
        phone: '',
        email: '',
        webAddress: '',
        isOpencartSubscription: false,
        passwordsError: null,
        countryChanged: false,
    };
    componentDidMount() {
        const { userProfile, getStates, getCountries, states, countries } = this.props;
        if (userProfile && Object.keys(userProfile).length) {
            let country = '';
            let state = '';

            if (countries && countries.length) {
                country = getTargetCountry(countries, userProfile.country);
                state = getTargetState(states, userProfile.state);

                if ((country || country === 0) && country <= 2) {
                    getStates(country);
                }
            } else {
                getCountries();
            }

            if (userProfile && Object.keys(userProfile).length) {
                this.setState({
                    isOpencartSubscription: +userProfile.subscription_type === 1,
                    doctorFirst: userProfile.firstName,
                    doctorLast: userProfile.lastName,
                    streetAddress: userProfile.address,
                    city: userProfile.city,
                    postal: userProfile.postal_code,
                    phone: userProfile.phone,
                    email: userProfile.email,
                    country,
                    state,
                    username: (+userProfile.subscription_type === 1) ? (userProfile.email ? userProfile.email.replace('@', '_').replace('.', '_') : '') : '',
                });
            }
        } else {
            this.props.history.push(LOGIN);
        }
    }
    componentDidUpdate(prevProps) {
        const { userProfile, states, countries, getStates } = this.props;

        if (userProfile && Object.keys(userProfile).length) {
            const { states: prevStates } = prevProps;
            const { countryChanged } = this.state;

            if (states && states.length && ((!prevStates || (prevStates && !prevStates.length)) || (prevStates && prevStates.length && prevStates[0].id !== states[0].id))) {
                const targetState = countryChanged ? states[0].id : getTargetState(states, userProfile.state);
                const state = (targetState || targetState === 0) && !isNaN(targetState) ? targetState : states[0].id;

                this.setState({state});
            }

            if ((!prevProps.countries || (prevProps.countries && !prevProps.countries.length))) {
                if (countries && countries.length) {
                    const targetCountry = getTargetCountry(countries, userProfile.country);
                    const country = targetCountry ? targetCountry : countries[0].id;

                    this.setState({country});
                    if (country <=2) {
                        getStates(country);
                    } else {
                        this.setState({state: userProfile.state});
                    }
                }
            }
        }
    }
    usernameChangeHandler = (value) => {
        this.setState({username:value});
    };
    passwordChangeHandler = (value) => {
        this.setState({password:value});
    };
    confirmPasswordChangeHandler = (value) => {
        this.setState({confirmPassword:value});
    };
    doctorFirstChangeHandler = (value) => {
        this.setState({doctorFirst:value});
    };
    doctorLastChangeHandler = (value) => {
        this.setState({doctorLast:value});
    };
    practiceNameChangeHandler = (value) => {
        this.setState({practiceName:value});
    };
    streetAddressChangeHandler = (value) => {
        this.setState({streetAddress:value});
    };
    cityChangeHandler = (value) => {
        this.setState({city:value});
    };
    countryChangeHandler = (value) => {
        this.setState({country: value, state: '', countryChanged: true});
        this.handleGetStates(value);
    };

    stateChangeHandler = (value) => {
        this.setState({state:value});
    };
    handleGetStates = country => {
        const { getStates, setStates } = this.props;

        if (country <= 2) {
            getStates(country);
        } else {
            setStates([]);
            this.setState({state: ''});
        }
    };
    postalChangeHandler = (value) => {
        this.setState({postal:value});
    };

    phoneChangeHandler = (value) => {
        this.setState({phone:value});
    };

    emailChangeHandler = (value) => {
        this.setState({email:value});
    };

    webAddressChangeHandler = (value) => {
        this.setState({webAddress:value});
    };
    buttonClickHandler = () => {
        const { userProfile, setDentistToAdd } = this.props;
        const {
            username,
            city,
            country,
            doctorFirst,
            doctorLast,
            email,
            password,
            confirmPassword,
            phone,
            postal,
            practiceName,
            state,
            streetAddress,
            webAddress,
            isOpencartSubscription,
        } = this.state;
        const {
            getIsUsernameUnique
        } = this.props;

        const profile = {
            address: streetAddress,
            city: city,
            country: country,
            state: state,
            postal_code: postal,
            phone: phone,
            email: email,
            website: webAddress,
            practice_name: practiceName,
            emailUpdates: true,
            username: username,
            subscriptionDate: dateToSystemString(new Date()),
            isSubscribed: 1,
            id: userProfile.id,
            ownCamera: false,
        };
        if (!isOpencartSubscription) {
            if (password && password === confirmPassword) {
                profile.password = password;
                this.setState({passwordsError: null});
            } else {
                this.setState({passwordsError: ['Passwords should match!']});
                return;
            }
        }
        const dentist = {
            first_name: doctorFirst,
            last_name: doctorLast,
        };

        setDentistToAdd({
            profile,
            dentist
        });

        getIsUsernameUnique(username, LAB_ADD);
    };
    getCountryOptions = () => {
        const { countries } = this.props;
        return countries ? countries.map(country => ({...country, value: country.id})) : [];
    };
    getStateOptions = () => {
        const { states } = this.props;
        return states ? states.map(state => ({...state, value: state.id})) : [];
    };
    render() {
        const {
            username,
            city,
            country,
            doctorFirst,
            doctorLast,
            email,
            password,
            phone,
            postal,
            practiceName,
            state,
            streetAddress,
            webAddress,
            confirmPassword,
            isOpencartSubscription,
            passwordsError
        } = this.state;

        return (
            <div className={'light-page create-dentist-profile'}>
                <PhotoPage>
                    <Header theme={themeLight} isUserMenu={false}/>

                    <PageWithSupportFooter className={'profile-create-inner'}>
                    <FormSteps currentStep={1} stepGroup={'dentist'}/>

                    <div className={'title'}>
                        Create Dentist Profile
                    </div>

                    <Form onSubmit={this.buttonClickHandler} isAjax={true}>
                        <InputText name={'username'} errors={ !this.props.isUsernameUnique ? ['Username already exists'] : null} onChange={this.usernameChangeHandler} value={username} placeholder={'Choose Username'} isRequired={true} />
                        {isOpencartSubscription ?
                            <InputText name={'password'} autocomplete={'use-old-password'} onChange={() => {}} value={'Use password from store.shadewave.com'} placeholder={'Password'} isDisabled={true}/> :
                            <div className="passwords-group">
                                <InputPassword errors={passwordsError} name={'password'} autocomplete={'new-password'} onChange={this.passwordChangeHandler} value={password} placeholder={'Password'} isRequired={true}/>
                                <InputPassword errors={passwordsError} name={'confirm_password'} autocomplete={'new-password'} onChange={this.confirmPasswordChangeHandler} value={confirmPassword} placeholder={'Confirm Password'} isRequired={true}/>
                            </div>
                        }
                        <InputText name={'doctor_first'} onChange={this.doctorFirstChangeHandler} value={doctorFirst} placeholder={'Doctor First Name'} isRequired={true}/>
                        <InputText name={'doctor_last'} onChange={this.doctorLastChangeHandler} value={doctorLast} placeholder={'Doctor Last Name'} isRequired={true}/>
                        <InputText name={'practice_name'} onChange={this.practiceNameChangeHandler} value={practiceName} placeholder={'Practice Name'} isRequired={false}/>
                        <InputText name={'street_address'} onChange={this.streetAddressChangeHandler} value={streetAddress} placeholder={'Street Address'} isRequired={true}/>
                        <InputText name={'city'} onChange={this.cityChangeHandler} value={city} placeholder={'City'} isRequired={true}/>
                        <Select disableAutocomplete={true} placeholder={'Country'} onChange={this.countryChangeHandler} options={this.getCountryOptions()} value={country} isRequired={true}/>
                        {((country || country === 0) && country <= 2) ?
                            <Select disableAutocomplete={true} placeholder={'State/ Province'} onChange={this.stateChangeHandler} options={this.getStateOptions()} value={state} isDisabled={!country} isRequired={true}/> :
                            <InputText name={'state'} onChange={this.stateChangeHandler} value={state} placeholder={'State/ Province'} isRequired={true}/>}
                        <InputText name={'postal'} onChange={this.postalChangeHandler} value={postal} placeholder={'Postal Code'} isRequired={true}/>
                        <InputText name={'phone'} onChange={this.phoneChangeHandler} value={phone} placeholder={'Phone'} isRequired={true}/>
                        <InputEmail name={'email'} onChange={this.emailChangeHandler} value={email} placeholder={'Email Address'} isRequired={true} isDisabled={true} />
                        <InputText name={'web_address'} onChange={this.webAddressChangeHandler} value={webAddress} placeholder={'Web Address'} isRequired={false}/>

                        <Button type={'submit'}>NEXT</Button>
                        </Form>
                    </PageWithSupportFooter>

                </PhotoPage>
            </div>
        );
    }
}

ProfileCreate.propTypes = {
    getCountries: PropTypes.func.isRequired,
    getStates: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    setDentistToAdd: PropTypes.func.isRequired,
    isUsernameUnique: PropTypes.bool.isRequired,
};

import React from "react";
import PropTypes from 'prop-types';
import 'cropperjs/dist/cropper.css';
import CropperComponent from "react-cropper";

export default class Cropper extends React.PureComponent {

    componentDidUpdate(prevProps) {
        if (this.cropper) {
            if (prevProps.rotateDegrees !== this.props.rotateDegrees) {
                this.cropper.rotateTo(this.props.rotateDegrees);
            }
        }
    }

    onCropperInit = (cropper) => {
        this.cropper = cropper;
    }

    render() {
        let { className, src, height, width, onCrop, containerHeight, containerWidth, rotateDegrees } = this.props;

        return <CropperComponent
            className={'cropper' + (className ? ` ${className}` : '')}
            src={src}
            style={{ height: height, width: width, left: (containerWidth - width) / 2 + 19, top: (containerHeight - height) / 2 + 17 }}
            guides={false}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={true}
            responsive={true}
            autoCropArea={0.5}
            checkOrientation={true}
            crop={onCrop}
            toggleDragModeOnDblclick={false}
            rotateTo={rotateDegrees}
            crossOrigin={'use-credentials'}
            onInitialized={this.onCropperInit.bind(this)}
        />
    }
}

Cropper.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    onCrop: PropTypes.func.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    containerWidth: PropTypes.number.isRequired,
    containerHeight: PropTypes.number.isRequired,
};

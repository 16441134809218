import React from "react";
import PropTypes from "prop-types";
import { NotificationContainer, NotificationManager } from 'react-notifications';

const NOTIFICATION_TYPES = ['info', 'success', 'warning', 'error', ''];
const DEFAULT_AUTO_HIDE_DURATION = 3000;

export default class Notification extends React.Component {
    componentDidUpdate(prevProps) {
        const { notification} = this.props;
        const { notification: prevNotification } = prevProps;

        if (notification && prevNotification && notification.timestamp !== prevNotification.timestamp) {
            const { type, message, title, hideDuration } = notification;
            if (title && message) {
                this.createNotification(type, message, title, hideDuration);
            }
        }
    }

    createNotification = (type, message, title, hideDuration) => {
        type = NOTIFICATION_TYPES.includes(type) ? type : NOTIFICATION_TYPES[0];
        hideDuration = hideDuration || DEFAULT_AUTO_HIDE_DURATION;

        NotificationManager[type](message, title, hideDuration);
    };

    render() {

        return (<NotificationContainer/>);
    }
}

Notification.propTypes = {
    notification: PropTypes.shape({
        hideDuration: PropTypes.number,
        type: PropTypes.oneOf(NOTIFICATION_TYPES),
        message: PropTypes.string,
        title: PropTypes.string,
    }),
};

import React from "react";
import PropTypes from "prop-types";

export default class MenuButton extends React.PureComponent {
    render() {
        let { image, text, onClick, isActive, onDelete, onSelect, photoId, isChecked, isCorrected, onMouseDown, isQcMode, fileSize } = this.props;

        return (
            <div className={'menu-button' + (isCorrected ? ' corrected': '') + (isActive ? ' active' : '')} >
                <div className={'image-container'}>
                    <img src={image} alt={text} onClick={onClick} onMouseDown={onMouseDown}/>
                </div>
                <div className={'text'}>{text}</div>
                <div className={'text size'}>{fileSize}</div>
                {!isQcMode &&
                <div className={'closer'} onClick={(e) => {
                    e.stopPropagation();
                    onDelete()
                }}></div> }
                {!isQcMode &&
                <input className={'check'} type={'checkbox'} value={photoId} checked={isChecked} onChange={(e) => {
                    e.stopPropagation();
                    if (onSelect) onSelect(photoId);
                }}  /> }
            </div>
        )
    }
}

MenuButton.propTypes = {
    image: PropTypes.node,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
};

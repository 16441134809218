import React from "react";
import PropTypes from 'prop-types';
import { Route as RouteComponent } from 'react-router-dom';
import ScreenLoader from '../elements/loader/ScreenLoader';
import { DASHBOARD, LOGIN } from '../../constants/url';
import { withRouter } from 'react-router-dom';

/*
const isMatched = (path) => {
    // TODO change it usin react router dom
    let i = path.indexOf(':');

    let routePath;

    if (i > 0) {
        routePath = path.substr(0, i);
    } else {
        routePath = path;
    }

    return window.location.pathname.indexOf(routePath) === 0;
};*/

class ProtectedRoute extends React.PureComponent {

    checkIsUserAuthenticated() {
        const { path, isLogged, isLoggedExpired, history, getUser, signInSuccess } = this.props;

        
        if (this.props.path === '/fromAdmin') {
            
            this.props.history.push(DASHBOARD);
            
            if (!isLogged)
            {
                
                signInSuccess(null);
                getUser();
                return;
            }
        }

        if ( !isLogged && !isLoggedExpired && (!history || history.location) ) {
            console.log('redirect to login ', this.props);
            localStorage.lastpath = (this.props.location.pathname != null && this.props.location.pathname != LOGIN) ? this.props.location.pathname : null; 
            this.props.history.push(LOGIN);
        }
        
        if (isLogged) {
            if (!history || (history.length < 3 )) {
                getUser();
            }
        }
    }

    componentDidMount() {
        this.checkIsUserAuthenticated();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLogged !== this.props.isLogged || this.props.path === '/fromAdmin')
            this.checkIsUserAuthenticated();
    }

    render() {
        let { path, component, attributes, isLoader, loaderPercent, loaderText, userProfile } = this.props;

        if (isLoader || userProfile.id === 0) {
            return <ScreenLoader isLoading={true} percent={loaderPercent}>{loaderText}</ScreenLoader>
        }

        return (
            <div>
                <RouteComponent exact path={path} component={component} {...attributes}/>
            </div>
        )
    }
}

ProtectedRoute.propTypes = {
    path: PropTypes.string.isRequired,
    component: PropTypes.object,
    attributes: PropTypes.object,
    isProtected: PropTypes.bool,

    isLogged: PropTypes.bool.isRequired,
    isLoginPending: PropTypes.bool.isRequired,
    signIn: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    signInSuccess: PropTypes.func.isRequired,

    isLoader: PropTypes.bool.isRequired,
    loaderPercent: PropTypes.number.isRequired,
    loaderText: PropTypes.string.isRequired,
};

export default withRouter(ProtectedRoute);

import React from "react";
import PropTypes from 'prop-types';
import Header from '../../containers/Header';
import FormSteps from "../dentist/elements/FormSteps";
import { NavLink } from "react-router-dom";
import SupportInfo from "../elements/SupportInfo";
import { LOGIN } from "../../constants/url";
import { themeLight } from "../../config/app";

export default class LabAccountCreated extends React.PureComponent {
    componentDidMount() {
        const { userProfile, profileCreated } = this.props;

        if (!profileCreated || !userProfile || (userProfile && !Object.keys(userProfile).length)) {
            this.props.history.push(LOGIN);
        }
    }

    componentWillUnmount() {
        const { setProfileForSubscription, setProfileCreated } = this.props;
        setProfileForSubscription({});
        setProfileCreated(false);
    }

    render() {
        return (
            <div className={'light-page create-lab-profile account-created'}>
                <div>
                    <div className="content">
                        <Header theme={themeLight} isUserMenu={false}/>

                        <FormSteps currentStep={2} stepGroup={'lab'}/>

                        <div className={'message'}>
                            <img className={'telegram'} src={require('../../assets/images/telegram.png')} alt={'Account Created'}/>

                            <div className={'title'}>Account Created!</div>

                            <div className={'login-info'}>
                                Your account is successfully created. <NavLink to={LOGIN}>Login here</NavLink>
                            </div>
                        </div>
                        <SupportInfo />
                    </div>
                </div>
            </div>
        );
    }
}

LabAccountCreated.propTypes = {
    userProfile: PropTypes.func.isRequired,
    profileCreated: PropTypes.func.isRequired,
    setProfileCreated: PropTypes.func.isRequired,
    setProfileForSubscription: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Collapse, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import Checkbox from '../elements/form/Checkbox';
import Header from '../../containers/Header';
import AddEditLabSpecialist from './AddEditLabSpecialist';
import LabSpecialistList from './LabSpecialistList';
import ConfirmDialog from '../elements/ConfirmDialog';
import { getTargetCountry, getTargetState } from '../../helpers/user';
import Form from '../elements/Form';
import InputText from '../elements/form/input/InputText';
import InputEmail from '../elements/form/input/InputEmail';
import InputPassword from '../elements/form/input/InputPassword';
import Select from '../elements/form/Select';
import Button from '../elements/form/Button';
import SupportInfo from '../elements/SupportInfo';
import Required from '../elements/icons/Required';
import GreyButton from '../elements/form/buttons/GreyButton';
import { themeLight } from '../../config/app';
import { ExpandMore } from '@material-ui/icons';

class EditDentistProfile extends React.PureComponent {
	state = {
		customerId: '',
		first_name: '',
		last_name: '',
		first_name_2: '',
		last_name_2: '',
		first_name_3: '',
		last_name_3: '',
		practice_name: '',
		address: '',
		city: '',
		country: '',
		state: '',
		postal_code: '',
		phone: '',
		email: '',
		additional_emails: '',
		additional_emails_case_assigned: '',
		login_as_user: false,
		email_updates: false,
		labFormCustomerNumber: '',
		labFormContactName: '',
		labFormLabName: '',
		labFormPhone: '',
		labFormEmailAddress: '',
		specialistFormCustomerNumber: '',
		specialistFormContactName: '',
		specialistFormSpecialistName: '',
		specialistFormPhone: '',
		specialistFormEmailAddress: '',
		id: '',
		isViewLabOpen: false,
		isViewSpecialistOpen: false,
		labEditMode: false,
		specialistEditMode: false,
		labViewMode: false,
		specialistViewMode: false,
		labResult: [],
		specialistResult: [],
		labsCollapseOpen: false,
		specialistsCollapseOpen: false,
		addLabAfterReceive: false,
		addSpecialistAfterReceive: false,
		showConfirmDialogForLab: false,
		showConfirmDialogForSpecialist: false,
		website: '',
		username: '',
		password: '',
		confirmPassword: '',
		passwordsError: null,
		countryChanged: false,
		showCancelConfirmDialog: false,
		magictouch_enabled: false,
		magictouch_token: null,
		currentMTLabId: null,
		magictouch_defaults_expanded: false,
	};
	componentDidMount() {
		const { userProfile, countries, states, getCountries, getStates, getMagicTouchLabsList } = this.props;
		let country = '';
		let state = '';

		if (countries && countries.length) {
			country = getTargetCountry(countries, userProfile.country);
			state = getTargetState(states, userProfile.state);

			if ((country || country === 0) && country <= 2) {
				getStates(country);
			}
		} else {
			getCountries();
		}

		if (userProfile?.mt_enabled && userProfile?.mt_token) {
			getMagicTouchLabsList(userProfile?.mt_token);
		}

		let dentist0 = userProfile.dentists?.find((d) => d.idx == 0);
		let dentist1 = userProfile.dentists?.find((d) => d.idx == 1);
		let dentist2 = userProfile.dentists?.find((d) => d.idx == 2);

		this.setState({
			customerId: userProfile.customerId,
			first_name: dentist0 ? dentist0.first_name : '',
			last_name: dentist0 ? dentist0.last_name : '',
			id: dentist0 ? dentist0.id : '',
			first_name_2: dentist1 ? dentist1.first_name : '',
			last_name_2: dentist1 ? dentist1.last_name : '',
			id_2: dentist1 ? dentist1.id : '',
			first_name_3: dentist2 ? dentist2.first_name : '',
			last_name_3: dentist2 ? dentist2.last_name : '',
			id_3: dentist2 ? dentist2.id : '',
			practice_name: userProfile.practice_name || '',
			address: userProfile.address,
			city: userProfile.city,
			country,
			state,
			postal_code: userProfile.postal_code,
			phone: userProfile.phone,
			email: userProfile.email,
			additional_emails: userProfile.additional_emails,
			additional_emails_case_assigned: userProfile.additional_emails_case_assigned,
			login_as_user: userProfile.login_as_user,
			email_updates: userProfile.email_updates,
			labFormCustomerNumber: '',
			labFormContactName: '',
			labFormLabName: '',
			labFormPhone: '',
			labFormEmailAddress: '',
			specialistFormCustomerNumber: '',
			specialistFormContactName: '',
			specialistFormSpecialistName: '',
			specialistFormPhone: '',
			specialistFormEmailAddress: '',
			validThrough: userProfile.validThrough,
			website: userProfile.website,
			username: userProfile.username,
			magictouch_enabled: userProfile?.mt_enabled,
			magictouch_token: userProfile?.mt_token,
            currentMTLabId: userProfile?.mt_default_labid
		});
	}
	componentDidUpdate(prevProps, prevState) {
		const {
			userProfile,
			states,
			countries,
			getStates,
			addLab,
			addSpecialist,
			activeLab,
			activeSpecialist,
			setLab,
			setSpecialist,
            magictouch_labs, 
            
		} = this.props;
		const { addLabAfterReceive, addSpecialistAfterReceive, countryChanged, magictouch_enabled, magictouch_token, magictouch_defaults_expanded } = this.state;
		const { states: prevStates } = prevProps;

		if (
			states &&
			states.length &&
			(!prevStates ||
				(prevStates && !prevStates.length) ||
				(prevStates && prevStates.length && prevStates[0].id !== states[0].id))
		) {
			const targetState = countryChanged ? states[0].id : getTargetState(states, userProfile.state);
			const state = (targetState || targetState === 0) && !isNaN(targetState) ? targetState : states[0].id;

			this.setState({ state });
		}

		if (!prevProps.countries || (prevProps.countries && !prevProps.countries.length)) {
			if (countries && countries.length) {
				const targetCountry = getTargetCountry(countries, userProfile.country);
				const country = targetCountry ? targetCountry : countries[0].id;

				this.setState({ country });
				if (country <= 2) {
					getStates(country);
				} else {
					this.setState({ state: userProfile.state });
				}
			}
		}
		if (addLabAfterReceive && activeLab && Object.keys(activeLab).length) {
			const country = getTargetCountry(countries, activeLab.country);
			const state = getTargetState(states, activeLab.state);
			const lab = {
				...activeLab,
				country,
				state,
			};
			addLab(lab, true);
			setLab({});
			this.setState({ addLabAfterReceive: false });
		}
		if (addSpecialistAfterReceive && activeSpecialist && Object.keys(activeSpecialist).length) {
			const country = getTargetCountry(countries, activeSpecialist.country);
			const state = getTargetState(states, activeSpecialist.state);
			const specialist = {
				...activeSpecialist,
				country,
				state,
			};
			addSpecialist(specialist, true);
			setSpecialist({});
			this.setState({ addSpecialistAfterReceive: false });
		}

        if ( magictouch_labs != prevProps.magictouch_labs &&  magictouch_defaults_expanded && (!magictouch_enabled ||
            !magictouch_token ||
            !magictouch_labs ||
            magictouch_labs.length === 0) ) {
                this.setState({
                    magictouch_defaults_expanded: false
                })
            }
	}
	handleSave = () => {
		const { userProfile, updateDentist } = this.props;
		const {
			address,
			city,
			state,
			postal_code,
			country,
			phone,
			email,
			additional_emails,
			additional_emails_case_assigned,
			practice_name,
			login_as_user,
			email_updates,
			first_name,
			last_name,
			first_name_2,
			last_name_2,
			first_name_3,
			last_name_3,
			id,
			id_2,
			id_3,
			website,
			username,
			password,
			confirmPassword,
			magictouch_enabled,
			magictouch_token,
            currentMTLabId
		} = this.state;

		const dentistProfile = {
			address,
			city,
			state,
			postal_code,
			country,
			phone,
			email,
			additional_emails,
			additional_emails_case_assigned,
			practice_name,
			login_as_user,
			email_updates,
			website: website,
			username: username,
			password: false,
			magictouch_enabled,
			magictouch_token,
            magictouch_default_labid: currentMTLabId
		};
		if (+userProfile.subscription_type !== 1) {
			if (password && password === confirmPassword) {
				dentistProfile.password = password;
				this.setState({ passwordsError: null });
			} else if (password && password.length > 0) {
				this.setState({ passwordsError: ['Passwords should match!'] });
				return;
			}
		}
		let dentistNames = [
			{ first_name, last_name, id, index: 0 },
			{ first_name: first_name_2, last_name: last_name_2, id: id_2, index: 1 },
			{ first_name: first_name_3, last_name: last_name_3, id: id_3, index: 2 },
		];

		updateDentist(dentistProfile, dentistNames);
	};
	renderLabs = () => {
		const { userProfile } = this.props;
		const labs = userProfile && userProfile.labs ? userProfile.labs : [];
		return labs.map((lab, i) => {
			return (
				<div className='lab' key={i}>
					<label className='lab-name'>{lab.name}</label>
					<div className='actions'>
						<button className='action' onClick={() => this.viewLab(lab.id)}>
							View
						</button>
						<span>|</span>
						<button className='action' onClick={() => this.deleteLab(lab.id)}>
							Delete
						</button>
					</div>
				</div>
			);
		});
	};
	renderSpecialist = () => {
		const { userProfile } = this.props;
		const specialists = userProfile && userProfile.specialists ? userProfile.specialists : [];

		return specialists.map((specialist, i) => {
			return (
				<div className='specialist' key={i}>
					<label className='specialist-name'>{specialist.name}</label>
					<div className='actions'>
						<button className='action' onClick={() => this.viewSpecialist(specialist.id)}>
							View
						</button>
						<span>|</span>
						<button className='action' onClick={() => this.deleteSpecialist(specialist.id)}>
							Delete
						</button>
					</div>
				</div>
			);
		});
	};
	viewLab = (id) => {
		this.setState({
			labEditMode: false,
			labViewMode: true,
			isViewLabOpen: true,
		});
		this.props.getLabById(id);
	};
	deleteLab = (id) => {
		this.setState({
			showConfirmDialogForLab: true,
			targetLabId: id,
		});
	};
	closeLabConfirmDialog = () => {
		this.setState({
			showConfirmDialogForLab: false,
			targetLabId: null,
		});
	};
	handleConfirmLabDelete = () => {
		const { targetLabId } = this.state;
		if (targetLabId || targetLabId === 0) {
			this.props.deleteLab(targetLabId);
			this.closeLabConfirmDialog();
		}
	};
	viewSpecialist = (id) => {
		this.setState({
			specialistEditMode: false,
			specialistViewMode: true,
			isViewSpecialistOpen: true,
		});
		this.props.getSpecialistById(id);
	};
	deleteSpecialist = (id) => {
		this.setState({
			showConfirmDialogForSpecialist: true,
			targetSpecialistId: id,
		});
	};
	closeSpecialistConfirmDialog = () => {
		this.setState({
			showConfirmDialogForSpecialist: false,
			targetSpecialistId: null,
		});
	};
	handleConfirmSpecialistDelete = () => {
		const { targetSpecialistId } = this.state;
		if (targetSpecialistId || targetSpecialistId === 0) {
			this.props.deleteSpecialist(targetSpecialistId);
			this.closeSpecialistConfirmDialog();
		}
	};
	handleLabSearch = () => {
		const { labFormCustomerNumber, labFormContactName, labFormLabName, labFormPhone, labFormEmailAddress } =
			this.state;
		const { userProfile, searchLabs } = this.props;
		const data = [
			`customerId=${encodeURIComponent(labFormCustomerNumber)}`,
			`contactName=${encodeURIComponent(labFormContactName)}`,
			`name=${encodeURIComponent(labFormLabName)}`,
			`phone=${encodeURIComponent(labFormPhone)}`,
			`email=${encodeURIComponent(labFormEmailAddress)}`,
		];
		searchLabs(data.join('&'), userProfile.userId);
		if (!this.state.labsCollapseOpen) {
			this.setState({ labsCollapseOpen: true });
		}
	};
	handleSpecialistSearch = () => {
		const {
			specialistFormCustomerNumber,
			specialistFormContactName,
			specialistFormSpecialistName,
			specialistFormPhone,
			specialistFormEmailAddress,
		} = this.state;
		const { userProfile, searchSpecialists } = this.props;
		const data = [
			`customerId=${encodeURIComponent(specialistFormCustomerNumber)}`,
			`contactName=${encodeURIComponent(specialistFormContactName)}`,
			`name=${encodeURIComponent(specialistFormSpecialistName)}`,
			`phone=${encodeURIComponent(specialistFormPhone)}`,
			`email=${encodeURIComponent(specialistFormEmailAddress)}`,
		];
		searchSpecialists(data.join('&'), userProfile.userId);
		if (!this.state.specialistsCollapseOpen) {
			this.setState({ specialistsCollapseOpen: true });
		}
	};
	toggleViewLabDialog = (isViewLabOpen) => {
		this.setState({
			labEditMode: false,
			labViewMode: false,
			isViewLabOpen,
		});
	};
	handleViewSpecialistDialog = (isViewSpecialistOpen) => {
		this.setState({
			specialistEditMode: false,
			specialistViewMode: false,
			isViewSpecialistOpen,
		});
	};
	searchAndAddLab = (id) => {
		this.setState({
			addLabAfterReceive: true,
		});
		this.props.getLabById(id);
	};
	searchAndAddSpecialist = (id) => {
		this.setState({
			addSpecialistAfterReceive: true,
		});
		this.props.getSpecialistById(id);
	};
	firstNameChangeHandler = (first_name) => {
		this.setState({ first_name });
	};
	lastNameChangeHandler = (last_name) => {
		this.setState({ last_name });
	};
	firstName2ChangeHandler = (first_name_2) => {
		this.setState({ first_name_2 });
	};
	lastName2ChangeHandler = (last_name_2) => {
		this.setState({ last_name_2 });
	};
	firstName3ChangeHandler = (first_name_3) => {
		this.setState({ first_name_3 });
	};
	lastName3ChangeHandler = (last_name_3) => {
		this.setState({ last_name_3 });
	};
	practiceNameChangeHandler = (practice_name) => {
		this.setState({ practice_name });
	};
	addressChangeHandler = (address) => {
		this.setState({ address });
	};
	cityChangeHandler = (city) => {
		this.setState({ city });
	};
	postalCodeChangeHandler = (postal_code) => {
		this.setState({ postal_code });
	};
	phoneChangeHandler = (phone) => {
		this.setState({ phone });
	};
	emailChangeHandler = (email) => {
		this.setState({ email });
	};
	additionalEmailChangeHandler = (additional_emails) => {
		this.setState({ additional_emails });
	};
	additionalEmailCaseAssignChangeHandler = (additional_emails_case_assigned) => {
		this.setState({ additional_emails_case_assigned });
	};
	websiteChangeHandler = (website) => {
		this.setState({ website });
	};
	usernameChangeHandler = (username) => {
		this.setState({ username });
	};
	passwordChangeHandler = (password) => {
		this.setState({ password });
	};
	confirmPasswordChangeHandler = (confirmPassword) => {
		this.setState({ confirmPassword });
	};
	countryChangeHandler = (country) => {
		this.setState({ country, state: '', countryChanged: true });
		this.handleGetStates(country);
	};
	stateChangeHandler = (state) => {
		this.setState({ state });
	};
	magicTouchTokenChangeHandler = (magictouch_token) => {
		this.setState({ magictouch_token });
	};
	handleGetStates = (country) => {
		const { getStates, setStates } = this.props;

		if (country <= 2) {
			getStates(country);
		} else {
			setStates([]);
		}
	};
	getCountryOptions = () => {
		const { countries } = this.props;
		return countries ? countries.map((country) => ({ ...country, value: country.id })) : [];
	};
	getStateOptions = () => {
		const { states } = this.props;
		return states ? states.map((state) => ({ ...state, value: state.id })) : [];
	};
	labCustomerNumberChangeHandler = (labFormCustomerNumber) => {
		this.setState({ labFormCustomerNumber });
	};
	labContactNameChangeHandler = (labFormContactName) => {
		this.setState({ labFormContactName });
	};
	labNameChangeHandler = (labFormLabName) => {
		this.setState({ labFormLabName });
	};
	labPhoneChangeHandler = (labFormPhone) => {
		this.setState({ labFormPhone });
	};
	labEmailChangeHandler = (labFormEmailAddress) => {
		this.setState({ labFormEmailAddress });
	};
	specialistCustomerNumberChangeHandler = (specialistFormCustomerNumber) => {
		this.setState({ specialistFormCustomerNumber });
	};
	specialistContactNameChangeHandler = (specialistFormContactName) => {
		this.setState({ specialistFormContactName });
	};
	specialistNameChangeHandler = (specialistFormSpecialistName) => {
		this.setState({ specialistFormSpecialistName });
	};
	specialistPhoneChangeHandler = (specialistFormPhone) => {
		this.setState({ specialistFormPhone });
	};
	specialistEmailChangeHandler = (specialistFormEmailAddress) => {
		this.setState({ specialistFormEmailAddress });
	};
	handleCancel = () => {
		this.setState({ showCancelConfirmDialog: true });
	};
	cancelEditing = () => {
		this.closeCancelConfirmDialog();
		this.props.history.goBack();
	};
	closeCancelConfirmDialog = () => {
		this.setState({ showCancelConfirmDialog: false });
	};

	getMTLabs = (mt_labs) => {
		
		if (!mt_labs) return [];
		let list = mt_labs.map((d) => {
			return {
				value: d.labID,
				name: d.labID + ' - ' + d.labFullName,
			};
		});
		
		return list;
	};

	onMagicTouchTokenValidate = () => {
		const { getMagicTouchLabsList, magictouch_labs } = this.props;
		const { magictouch_token } = this.state;

		if (magictouch_token || magictouch_labs?.length > 0) {
			getMagicTouchLabsList(magictouch_token);
		}
        else{
            this.setState({
                magictouch_defaults_expanded: false
            })
        }


	};

	onMagicTouchDefaultsExpanded = (e, expanded) => {
		this.setState({
			magictouch_defaults_expanded: expanded,
		});
	};

    defaultMTLabChangeHandler = (labID) => {
        this.setState({
            currentMTLabId: labID
        })
    }

	render() {
		const {
			userProfile,
			countries,
			states,
			addLab,
			addSpecialist,
			activeLab,
			activeSpecialist,
			setLab,
			setSpecialist,
			labs,
			specialists,
			setStates,
			getStates,
			magictouch_labs,
		} = this.props;
		const {
			email_updates,
			login_as_user,
			country,
			labFormCustomerNumber,
			labFormContactName,
			labFormLabName,
			labFormPhone,
			labFormEmailAddress,
			specialistFormCustomerNumber,
			specialistFormContactName,
			specialistFormSpecialistName,
			specialistFormPhone,
			specialistFormEmailAddress,
			validThrough,
			customerId,
			state,
			isViewLabOpen,
			isViewSpecialistOpen,
			labEditMode,
			specialistEditMode,
			labViewMode,
			specialistViewMode,
			labsCollapseOpen,
			specialistsCollapseOpen,
			practice_name,
			address,
			city,
			postal_code,
			phone,
			email,
			additional_emails,
			additional_emails_case_assigned,
			first_name,
			last_name,
			first_name_2,
			last_name_2,
			first_name_3,
			last_name_3,
			showConfirmDialogForLab,
			showConfirmDialogForSpecialist,
			website,
			username,
			password,
			confirmPassword,
			passwordsError,
			showCancelConfirmDialog,
			magictouch_enabled,
			magictouch_token,
			currentMTLabId,
			magictouch_defaults_expanded,
		} = this.state;

		
		return (
			<div className='light-page edit-dentist-profile-container'>
				<Header theme={themeLight} />
				<div className={'edit-dentist-profile'}>
					<div className={'profile-info'}>
						<div className='edit-dentist-title'>
							<label>Edit Dentist Profile</label>
							<label className='account-details-title'>Edit your account details</label>
						</div>
						<Form id={'editDentistProfileForm'} onSubmit={this.handleSave} isAjax={true}>
							<InputText
								name={'customerId'}
								isDisabled={true}
								onChange={() => {}}
								value={customerId}
								placeholder={'Customer Number'}
								isRequired={true}
							/>
							<InputText
								name={'firstName'}
								onChange={this.firstNameChangeHandler}
								value={first_name}
								placeholder={'Doctor First Name'}
								isRequired={true}
							/>
							<InputText
								name={'lastName'}
								onChange={this.lastNameChangeHandler}
								value={last_name}
								placeholder={'Doctor Last Name'}
								isRequired={true}
							/>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls='panel1a-content'
									id='panel1a-header'
								>
									<Typography className={''}>More Doctors</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<div className={'more-doctors-form'}>
										<InputText
											name={'firstName2'}
											onChange={this.firstName2ChangeHandler}
											value={first_name_2}
											placeholder={'Second Doctor First Name'}
											isRequired={false}
										/>
										<InputText
											name={'lastName2'}
											onChange={this.lastName2ChangeHandler}
											value={last_name_2}
											placeholder={'Second Doctor Last Name'}
											isRequired={false}
										/>
										<InputText
											name={'firstName3'}
											onChange={this.firstName3ChangeHandler}
											value={first_name_3}
											placeholder={'Third Doctor First Name'}
											isRequired={false}
										/>
										<InputText
											name={'lastName3'}
											onChange={this.lastName3ChangeHandler}
											value={last_name_3}
											placeholder={'Third Doctor Last Name'}
											isRequired={false}
										/>
									</div>
								</AccordionDetails>
							</Accordion>
							<InputText
								name={'practiceName'}
								onChange={this.practiceNameChangeHandler}
								value={practice_name}
								placeholder={'Practice Name'}
							/>
							<InputText
								name={'address'}
								onChange={this.addressChangeHandler}
								value={address}
								placeholder={'Address'}
								isRequired={true}
							/>
							<InputText
								name={'city'}
								onChange={this.cityChangeHandler}
								value={city}
								placeholder={'City'}
								isRequired={true}
							/>
							<Select
								disableAutocomplete={true}
								placeholder={'Country'}
								onChange={this.countryChangeHandler}
								options={this.getCountryOptions()}
								value={country}
								isRequired={true}
							/>
							{(country || country === 0) && country <= 2 ? (
								<Select
									disableAutocomplete={true}
									placeholder={'State/ Province'}
									onChange={this.stateChangeHandler}
									options={this.getStateOptions()}
									value={state}
									isDisabled={!country}
									isRequired={true}
								/>
							) : (
								<InputText
									name={'state'}
									onChange={this.stateChangeHandler}
									value={state}
									placeholder={'State / Province'}
									isRequired={true}
								/>
							)}
							<InputText
								name={'postalCode'}
								onChange={this.postalCodeChangeHandler}
								value={postal_code}
								placeholder={'Postal Code'}
								isRequired={true}
							/>
							<InputText
								name={'phone'}
								onChange={this.phoneChangeHandler}
								value={phone}
								placeholder={'Phone'}
								isRequired={true}
							/>
							<InputEmail
								name={'email'}
								onChange={this.emailChangeHandler}
								value={email}
								placeholder={'Email Address'}
								isRequired={true}
							/>
							<label className={'additional-email-case-assign update'}>
								Additional Emails When Case Is Created Or Updated Or License Renewed:
							</label>
							<InputEmail
								name={'additionalEmails'}
								onChange={this.additionalEmailChangeHandler}
								value={additional_emails}
								placeholder={'Email Address'}
								isMultiple={true}
							/>
							<label className={'additional-email-case-assign'}>
								Additional Emails When Case Is Assigned:
							</label>
							<InputEmail
								name={'additionalEmailsCaseAssign'}
								onChange={this.additionalEmailCaseAssignChangeHandler}
								value={additional_emails_case_assigned}
								placeholder={'Email Address'}
								isMultiple={true}
							/>
							<InputText
								className={'website'}
								name={'website'}
								onChange={this.websiteChangeHandler}
								value={website}
								placeholder={'Web Address'}
							/>
							<InputText
								name={'username'}
								onChange={this.usernameChangeHandler}
								value={username}
								placeholder={'Choose Username'}
								isRequired={false}
							/>

							{userProfile && +userProfile.subscription_type === 1 ? (
								<InputText
									name={'pass'}
									onChange={() => {}}
									value={'Use password from store.shadewave.com'}
									placeholder={'Password'}
									isDisabled={true}
								/>
							) : (
								<div className='passwords-group'>
									<InputPassword
										errors={passwordsError}
										name={'password'}
										autocomplete={'new-password'}
										onChange={this.passwordChangeHandler}
										value={password}
										placeholder={'Password'}
										isRequired={false}
									/>
									<InputPassword
										errors={passwordsError}
										name={'confirmPassword'}
										autocomplete={'new-password'}
										onChange={this.confirmPasswordChangeHandler}
										value={confirmPassword}
										placeholder={'Confirm Password'}
										isRequired={false}
									/>
								</div>
							)}
							<Checkbox
								onChange={() => this.setState({ login_as_user: !login_as_user })}
								isChecked={login_as_user}
							>
								Allow access to my account for website administrator
								<Required />
							</Checkbox>
							<Checkbox
								onChange={() => this.setState({ email_updates: !email_updates })}
								isChecked={email_updates}
							>
								I want to receive email updates
								<Required />
							</Checkbox>
                            { false && <div>
							<Checkbox
								onChange={() => this.setState({ magictouch_enabled: !magictouch_enabled })}
								isChecked={magictouch_enabled}
							>
								MagicTouch Integration
							</Checkbox>
							<InputText
								name={'magicTouchToken'}
								onChange={this.magicTouchTokenChangeHandler}
								value={magictouch_token}
								placeholder={'MagicTouch API Token'}
								isDisabled={!magictouch_enabled}
								onValidate={this.onMagicTouchTokenValidate}
							/>
							<Accordion
								disabled={
									!magictouch_enabled ||
									!magictouch_token ||
									!magictouch_labs ||
									magictouch_labs.length === 0
								}
								expanded={magictouch_defaults_expanded}
								onChange={this.onMagicTouchDefaultsExpanded}
							>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls='panel-mt-defaults-content'
									id='panel-mt-defaults-header'
								>
									<Typography className={''}>MagicTouch Defaults</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<div className={'more-doctors-form'}>
										<Select
											isRequired={false}
											placeholder={'Default Lab'}
											onChange={this.defaultMTLabChangeHandler}
											options={this.getMTLabs(magictouch_labs)}
											value={currentMTLabId}
											isDisabled={
												!magictouch_enabled ||
												!magictouch_token ||
												!magictouch_labs ||
												magictouch_labs.length === 0
											}
										/>
									</div>
								</AccordionDetails>
							</Accordion> 
                            </div>
                            }
							<input type='submit' className={'hidden'} />
						</Form>
					</div>
					<div className='lab-specialist-container'>
						<div className='info-container'>
							<div className='customer-number-container'>
								<label className='name'>Your Customer Number</label>
								<label className='value'>{customerId}</label>
							</div>
							<div className='renewal-date-container'>
								<label className='name'>Renewal date</label>
								<label className='value'>{validThrough}</label>
							</div>
						</div>
						<div className='lab-specialist'>
							<div className={'lab-form-container'}>
								<div className='lab-container'>
									<label className='lab-title'>Your Labs</label>
									<Divider variant='fullWidth' className='horizontal-line top' />
									<div className='labs'>{this.renderLabs()}</div>
									{userProfile && userProfile.labs && userProfile.labs.length > 0 && (
										<Divider variant='fullWidth' className='horizontal-line' />
									)}
									<Form className={'lab-form'} onSubmit={this.handleLabSearch} isAjax={true}>
										<label className='add-title'>Add lab</label>
										<InputText
											name={'labFormCustomerNumber'}
											onChange={this.labCustomerNumberChangeHandler}
											value={labFormCustomerNumber}
											placeholder={'Customer Number'}
										/>
										<InputText
											name={'labFormContactName'}
											onChange={this.labContactNameChangeHandler}
											value={labFormContactName}
											placeholder={'Contact Name'}
										/>
										<InputText
											name={'labFormLabName'}
											onChange={this.labNameChangeHandler}
											value={labFormLabName}
											placeholder={'Lab Name'}
										/>
										<InputText
											name={'labFormPhone'}
											onChange={this.labPhoneChangeHandler}
											value={labFormPhone}
											placeholder={'Lab Phone'}
										/>
										<InputEmail
											name={'labFormEmailAddress'}
											onChange={this.labEmailChangeHandler}
											value={labFormEmailAddress}
											placeholder={'Lab email Address'}
										/>
										<Button className='search-button' type={'submit'} text='Search' />
									</Form>
									<Collapse
										className='labs-collapse'
										in={labsCollapseOpen}
										timeout='auto'
										unmountOnExit
									>
										<div className='labs-container'>
											<LabSpecialistList
												className='table-container'
												rows={labs}
												columns={['Customer#', 'Lab Name', 'eMail']}
												handleClick={(id) => this.searchAndAddLab(id)}
											/>
										</div>
									</Collapse>
								</div>
							</div>
							<div className={'specialist-form-container'}>
								<div className='specialist-container'>
									<label className='specialist-title'>Your Specialists</label>
									<Divider variant='fullWidth' className='horizontal-line top' />
									<div className='specialists'>{this.renderSpecialist()}</div>
									{userProfile && userProfile.specialists && userProfile.specialists.length > 0 && (
										<Divider variant='fullWidth' className='horizontal-line' />
									)}
									<Form
										className={'specialist-form'}
										onSubmit={this.handleSpecialistSearch}
										isAjax={true}
									>
										<label className='add-title'>Add specialist</label>
										<InputText
											name={'specialistFormCustomerNumber'}
											onChange={this.specialistCustomerNumberChangeHandler}
											value={specialistFormCustomerNumber}
											placeholder={'Customer Number'}
										/>
										<InputText
											name={'specialistFormContactName'}
											onChange={this.specialistContactNameChangeHandler}
											value={specialistFormContactName}
											placeholder={'Contact Name'}
										/>
										<InputText
											name={'specialistFormSpecialistName'}
											onChange={this.specialistNameChangeHandler}
											value={specialistFormSpecialistName}
											placeholder={'Specialist Name'}
										/>
										<InputText
											name={'specialistFormPhone'}
											onChange={this.specialistPhoneChangeHandler}
											value={specialistFormPhone}
											placeholder={'Specialist Phone'}
										/>
										<InputEmail
											name={'specialistFormEmailAddress'}
											onChange={this.specialistEmailChangeHandler}
											value={specialistFormEmailAddress}
											placeholder={'Specialist Email Address'}
										/>
										<Button className='search-button' type={'submit'} text='Search' />
									</Form>
									<Collapse
										className='specialists-collapse'
										in={specialistsCollapseOpen}
										timeout='auto'
										unmountOnExit
									>
										<div className='specialists-container'>
											<LabSpecialistList
												className='table-container'
												rows={specialists}
												columns={['Customer#', 'Specialist Name', 'eMail']}
												handleClick={this.searchAndAddSpecialist}
											/>
										</div>
									</Collapse>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='footer'>
					<div className='buttons-container'>
						<input className='save-button' type='submit' form='editDentistProfileForm' value={'SAVE'} />
						<GreyButton className='cancel-button' text='CANCEL' onClick={this.handleCancel} />
					</div>
					<SupportInfo />
				</div>
				<AddEditLabSpecialist
					handleClose={() => this.toggleViewLabDialog(false)}
					handleSave={addLab}
					isOpen={isViewLabOpen}
					countries={countries}
					states={states}
					getStates={getStates}
					isEditMode={labEditMode}
					isViewMode={labViewMode}
					data={activeLab}
					reset={setLab}
					setStates={setStates}
					isLab={true}
				/>
				<AddEditLabSpecialist
					handleClose={() => this.handleViewSpecialistDialog(false)}
					handleSave={addSpecialist}
					isOpen={isViewSpecialistOpen}
					countries={countries}
					states={states}
					getStates={getStates}
					isEditMode={specialistEditMode}
					isViewMode={specialistViewMode}
					data={activeSpecialist}
					reset={setSpecialist}
					setStates={setStates}
				/>
				<ConfirmDialog
					isOpen={showConfirmDialogForLab}
					title={'Delete Lab'}
					message={'Are you sure you want to delete this lab?'}
					no={this.closeLabConfirmDialog}
					yes={this.handleConfirmLabDelete}
				/>
				<ConfirmDialog
					isOpen={showConfirmDialogForSpecialist}
					title={'Delete Specialist'}
					message={'Are you sure you want to delete this specialist?'}
					no={this.closeSpecialistConfirmDialog}
					yes={this.handleConfirmSpecialistDelete}
				/>
				<ConfirmDialog
					isOpen={showCancelConfirmDialog}
					title={'Cancel editing'}
					className={'cancel-editing-confirm-dialog'}
					message={'Are you sure you want to leave without saving?'}
					no={this.closeCancelConfirmDialog}
					yes={this.cancelEditing}
				/>
			</div>
		);
	}
}

EditDentistProfile.propTypes = {
	userProfile: PropTypes.object.isRequired,
	countries: PropTypes.array.isRequired,
	getStates: PropTypes.func.isRequired,
	states: PropTypes.array.isRequired,
	updateDentist: PropTypes.func.isRequired,
	getCountries: PropTypes.func.isRequired,
	addLab: PropTypes.func.isRequired,
	addSpecialist: PropTypes.func.isRequired,
	deleteLab: PropTypes.func.isRequired,
	deleteSpecialist: PropTypes.func.isRequired,
	activeLab: PropTypes.object.isRequired,
	activeSpecialist: PropTypes.object.isRequired,
	setLab: PropTypes.func.isRequired,
	setSpecialist: PropTypes.func.isRequired,
	labs: PropTypes.array.isRequired,
	specialists: PropTypes.array.isRequired,
	searchLabs: PropTypes.func.isRequired,
	searchSpecialists: PropTypes.func.isRequired,
	setStates: PropTypes.func.isRequired,
	getMagicTouchLabsList: PropTypes.func.isRequired,
	magictouch_labs: PropTypes.array.isRequired,
};

export default withRouter(EditDentistProfile);

import React from "react";
import PropTypes from 'prop-types';
import InputAutocomplete from '../../elements/form/input/InputAutocomplete';
//import { isChrome } from '../../../helpers/ui';
import Required from '../icons/Required';
import { Tooltip } from 'react-tooltip';

export default class Select extends React.PureComponent {
    state = {
        search: '',
        options: this.props.options,
        isReset: false,
    };

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.isReset !== prevState.isReset) {
            return {...prevState, search: '', isReset: nextProps.isReset};
        }
        if (JSON.stringify(nextProps.options) !== JSON.stringify(prevState.options)) {
            return {...prevState, options: nextProps.options, search: '', isReset: false}
        }
        return null;
    };

    componentDidMount() {
        let { value, isTriggerChangeOnReady, onChange } = this.props;

        if (isTriggerChangeOnReady) {
            onChange(value);
        }
    }

    changeHandler = (value) => {
        const { onChange, options } = this.props;

        let valueToReturn = options.filter(option => option.name === value);
        let currentValue = null
        if (valueToReturn.length > 0) {
            onChange(valueToReturn ? valueToReturn[0].value : 0);
            if (valueToReturn)
                currentValue = valueToReturn[0].name;
        }

        this.setState({
            search: value,
            currentValue: currentValue
        }, () => {})
    };
    onFocusHandler = () => {
        this.setState({
            search: '',
            currentValue: null
        }, () => {})
    };
    onBlurHandler = () => {
        const { value, options } = this.props;
        const { currentValue } = this.state;
        this.setState({
            search: currentValue ? currentValue : (options.find(it => it.value == value)?.name ?? ''),
        }, () => {})
    };
    getTitle = () => {
        let { options, value } = this.props;

        if (options.length === 0 || !value) {
            return false;
        }

        const selected = options.filter(option => option.value === value);

        if (selected.length === 0) {
            return false;
        }

        return selected[0].name;
    };
    render() {
        const { className, name, options, value, renderOptions, renderValue, placeholder, isDisabled, isRequired, hideLabel, isId, placeholderLabel, datatip } = this.props;
        const { search } = this.state;
        //console.log('options', 'renderoptions', options, renderOptions)

        let valueToShow = options.filter(option => option.value === value);

        valueToShow = valueToShow.length > 0 ? valueToShow[0].name : (this.props.isLoading ? 'Loading list...' : (placeholder));
        return (
            <div className={'select-box' + (className ? ` ${className}` : '') + (isDisabled ? ' disabled': '') + (isRequired ? ' required' : '')} data-tip={datatip}>
                <InputAutocomplete
                    isId={isId}
                    renderOptions={renderOptions}
                    renderValue={renderValue}
                    className={'select-search'}
                    onChange={this.changeHandler}
                    name={name ? name : 'select'}
                    value={search}
                    results={options.map(option => {
                        return option.name;
                    })}
                    placeholder={valueToShow}
                    onFocus={this.onFocusHandler}
                    onBlur={this.onBlurHandler}
                    isReadOnly={isDisabled}
                />
                {!hideLabel && <label className={`placeholder ${valueToShow ? 'has-value' : ''}`}>{placeholderLabel ?? placeholder}{isRequired ? <Required/> : ''}</label>}
                {datatip && <Tooltip place="top" type="light" effect="solid" border={true} /> }
            </div>
        )
    }
}
//
// {children ? <span className={'text'}>{children}</span> : ''}
Select.propTypes = {
    isId: PropTypes.bool,
    getOptions: PropTypes.func,
    options: PropTypes.array.isRequired,
    //value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    isReset: PropTypes.bool,
    isDisabled: PropTypes.bool,
    renderOptions: PropTypes.func,
    renderValue: PropTypes.func,
    children: PropTypes.node,
    name: PropTypes.string,
    isTriggerChangeOnReady: PropTypes.bool,
    isRequired: PropTypes.bool,
    hideLabel: PropTypes.bool,
    disableAutocomplete: PropTypes.bool,
};

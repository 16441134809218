import React from "react";
import PropTypes from 'prop-types';
import SupportInfo from './SupportInfo';

export default class PageWithSupportFooter extends React.PureComponent {
    render() {
        let { children, className, isHeader } = this.props;

        if (!children) {
            return false;
        }
        return (
            <div className={'page-with-footer-wrapper' + (typeof isHeader !== 'undefined' && !isHeader ? ' no-header' : '')}>
                <div className={`inner ${className}`}>
                    {children}
                </div>

                <SupportInfo/>
            </div>
        )
    }
}


PageWithSupportFooter.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isHeader: PropTypes.bool,
};

import React from "react";
import PropTypes from 'prop-types';

export default class Textarea extends React.PureComponent {
    onChangeHandler = e => {
        let { onChange } = this.props;

        onChange(e.target.value);
    };
    renderErrors = errors => {
        if (!errors) {
            return false;
        }

        return errors.map((error, index) => {
            return <div className={'error'} key={index}>{error}</div>
        })
    };
    renderLabel = () => {
        const { placeholder, hideLabel, value} = this.props;

        if (value && hideLabel) {
            return '';
        }
        return <label className="label textarea-label">{placeholder}</label>;
    };
    render() {
        let { placeholder, value, name, icon, className, errors, disabled } = this.props;

        let styles = {
            //backgroundImage: `url(${icon})`,
        };
        return (
            <div className={'input-group' + (className ? ` ${className}` : '') + (errors ? ' has-error' : '')}>
                <textarea disabled={disabled} name={name} style={styles} placeholder={placeholder} value={value} onChange={this.onChangeHandler} {...this.props.attributes}/>

                {this.renderLabel()}
                {icon ? <img className={'input-icon'} alt={'icon'} src={icon}/> : false}
                {this.renderErrors(errors)}
            </div>
        );
    }
}

Textarea.propTypes = {
    errors: PropTypes.array,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.node,
    hideLabel: PropTypes.bool,
    disabled: PropTypes.bool,
};

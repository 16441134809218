import React from "react";
import PropTypes from "prop-types";

export default class ShadeTabsBlock extends React.PureComponent {
    render() {
        let { title, children, className } = this.props;

        return (
            <div className={`block ${className}`}>
                <div className={'title'}>{title}</div>
                <div className={'inner'}>{children}</div>
            </div>
        )
    }
}

ShadeTabsBlock.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

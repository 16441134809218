import { createSelector } from 'reselect';
import { ROLE_DENTIST, ROLE_DENTIST_LIMITED, ROLE_DENTIST_MOBILE, ROLE_LAB, ROLE_LAB_MOBILE, ROLE_LAB_MOBILE_CASE_FREE } from '../constants/user';

const getUser = state => state.user;

export const getUserProfile = createSelector(
    [getUser],
    (user) => {
        if (!user) return null;
        return user.userProfile;
    }
)

export const isDentist = createSelector(
    [getUser],
    (user) => {
        return [ROLE_DENTIST, ROLE_DENTIST_LIMITED, ROLE_DENTIST_MOBILE].includes(user.userProfile.role_id);
    }
);

export const isLab = createSelector(
    [getUser],
    (user) => {
        return [ROLE_LAB, ROLE_LAB_MOBILE, ROLE_LAB_MOBILE_CASE_FREE].includes(user.userProfile.role_id);
    }
);

export const getCreateProfile = createSelector(
    [getUser],
    (user) => {
        const { profileSubscription } = user;
        return profileSubscription && profileSubscription.profile ? profileSubscription.profile : {};
    }
);

export const getUserIdForAdd = createSelector(
    [getUser],
    (user) => {
        const { profileSubscription } = user;
        return profileSubscription && profileSubscription.profile ? profileSubscription.profile.id : '';
    }
);

export const getTrialDays = createSelector(
    [getUserProfile],
    (profile) => {
        return Math.floor((Date.parse(profile.validThrough) - Date.now()) / (1000 * 60 * 60 * 24) ) + 1 
    }
)

export const expiredUserRole = createSelector(
    [getUser],
    (user) => {
        return parseInt(user.expiredUserData?.role_id);
    }
)
import React from "react";
import Login from "../../containers/login/Login";
import ForgotPassword from "../../containers/login/ForgotPassword";
import UpdatePassword from "../../containers/login/UpdatePassword";
import { LOGIN, FORGOT_PASSWORD, UPDATE_PASSWORD, DASHBOARD } from "../../constants/url";
import { withRouter } from 'react-router-dom';
import Logo from "../header/Logo";
import PageWithSupportFooter from '../elements/PageWithSupportFooter';
import PhotoPage from '../elements/PhotoPage';

class LoginComponent extends React.PureComponent {
    state = {
        isLogged: null
    };
    renderTargetPage() {
        const { location } = this.props;

        if (location) {
            const { pathname } = location;

            if (pathname === FORGOT_PASSWORD) {
                return <ForgotPassword />
            } else if (pathname === LOGIN) {
                return <Login />
            } else if (pathname === UPDATE_PASSWORD) {
                return <UpdatePassword {...this.props} />
            }
        }
    }

    onLogoClick = () => {
        this.props.history.push(DASHBOARD);
    };

    render() {
        return (
            <div className="login-container light-page">
                <PhotoPage>
                    <PageWithSupportFooter isHeader={false} className={'login-inner'}>
                        <div className="login-form-container">
                            <div>
                                <Logo theme={'light'} onClick={this.onLogoClick} />
                            </div>
                            <div className={'form-container'}>
                                {this.renderTargetPage()}
                            </div>
                        </div>
                    </PageWithSupportFooter>

                </PhotoPage>
            </div>
        )
    }
}

export default withRouter(LoginComponent);

import React from "react";
import PropTypes from 'prop-types';
import Check from '../icons/Check';

export default class Checkbox extends React.PureComponent {
    render() {
        let { text, className, onChange, isChecked, error, children, disable, isRequired } = this.props;

        className = className ? ` ${className}` : '';

        if (isChecked) {
            className += ' checked';
        }
        if (disable) {
            className += ' disabled';
        }
        return (
            <div className="checkbox-container">
                <label className={'checkbox' + className}>
                    <input disabled={disable ? 'disabled' : ''} type={'checkbox'} required={isRequired} defaultChecked={isChecked} value={isChecked} onChange={onChange}/>
                    <Check/>
                    {text ? <span className={'text'}>{text}</span> : ''}
                    {children ? <div className={'text'}>{children}</div> : ''}
                </label>
                {error ? <div className={'field-error'}>{error}</div> : ''}
            </div>
        )
    }
}

Checkbox.propTypes = {
    error: PropTypes.string,
    children: PropTypes.node,
    text: PropTypes.string,
    className: PropTypes.string,
    disable: PropTypes.bool,
    isRequired: PropTypes.bool,
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

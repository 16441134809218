import { connect } from 'react-redux';
import Photos from '../../../components/case/subMenus/Photos';
import { setActivePhoto, uploadPhoto, deletePhoto, hideUploadPhotoError, downloadPhotos, setQcLeftImage, setQcRightImage, setQcMode, saveRotatedPhoto, notifyLabAboutPhotos } from "../../../actions/case.actions";
import { togglePhotoUploadProgress } from "../../../actions/ui.actions";
import {getActivePhotoId, getCurrentCase, getIsQcModeDisabled, getPhotos} from "../../../selectors/case.selector";

const mapStateToProps = state => ({
    activePhotoId: getActivePhotoId(state),
    photos: getPhotos(state),
    currentCase: getCurrentCase(state),
    isPhotoUploadProgress: state.ui.isPhotoUploadProgress,
    uploadPhotoError: state.ui.uploadPhotoError,
    isQcMode: state.case.isQcMode,
    isQcModeDisabled: getIsQcModeDisabled(state),
    rotateDegrees: state.workArea.rotateDegrees, 
});

const mapDispatchToProps = dispatch => ({
    hideUploadPhotoError : () => dispatch(hideUploadPhotoError()),
    setActivePhoto : (photoId) => dispatch(setActivePhoto(photoId)),
    uploadPhoto : (file, fileContent, caseId) => dispatch(uploadPhoto(file, fileContent, caseId)),
    togglePhotoUploadProgress : (value) => dispatch(togglePhotoUploadProgress(value)),
    deletePhoto : (photoId) => dispatch(deletePhoto(photoId)),
    downloadPhotos: (caseId, photosList) => dispatch(downloadPhotos(caseId, photosList)),
    setQcLeftImage: (photo) => dispatch(setQcLeftImage(photo)),
    setQcRightImage: (photo) => dispatch(setQcRightImage(photo)),
    setQcMode: (value) => dispatch(setQcMode(value)),
    saveRotatedPhoto: (photoId, rotation) => dispatch(saveRotatedPhoto(photoId, rotation)),
    notifyLabAboutPhotos: (caseId) => dispatch(notifyLabAboutPhotos(caseId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Photos);

import React from "react";
import PropTypes from "prop-types";
import ControlRow from './elements/ControlRow';
import { defaultContrast, defaultBrightness } from '../../../constants/workAreaDefauls';
import GreenButton from "../../elements/form/buttons/GreenButton";
import Slider from '../../elements/Slider';
import SliderComponent from '@material-ui/core/Slider';
import { GRAPHIC_ARROW, GRAPHIC_CIRCLE, GRAPHIC_LINE } from "../../../constants/graphicTypes";

export default class Edit extends React.PureComponent {
    state = {

    };
    execute = (callback, param) => {
        callback(param);
    };

    rotate = (degrees) => {
        let { rotateImage, rotateDegrees } = this.props;

        let resultDegrees = rotateDegrees + degrees;

        if (resultDegrees < 0) {
            resultDegrees = 360 + resultDegrees;
        } else if (resultDegrees >= 360) {
            resultDegrees = resultDegrees - 360;
        }

        this.execute(rotateImage, resultDegrees);
    };
    handleRotateChange = (degrees) => {
        let { rotateImage } = this.props;
        
        let resultDegrees = degrees;

        if (resultDegrees < 0) {
            resultDegrees = 360 + resultDegrees;
        } else if (resultDegrees >= 360) {
            resultDegrees = resultDegrees - 360;
        }

        this.execute(rotateImage, resultDegrees);
        this.setState({
            rotateDegrees: degrees
        })
    }
    degreesChangeHandler = (e) => {
        let { rotateImage } = this.props;

        if (e.target.value === '') {
            return this.execute(rotateImage, null);
        }

        let v = parseInt(e.target.value);

        if (isNaN(v) ) {
            v = 0;
        }
        if (v < 0) {
            v = v + 360;
        }
        if (v > 360) {
            v = 360;
        }

        this.execute(rotateImage, v);
    };
    setGrayScaleHandler = (name) => {
        let { grayscaleImage, activePhotoId } = this.props;

        grayscaleImage(activePhotoId)
    };
    getGrayScaleButton = () => {
        let { isGrayScale, isOriginalSelected } = this.props;

        return [
            {name: 'on', text: 'on', isCurrent: isGrayScale, isDisabled: !isOriginalSelected},
            {name: 'off', text: 'off', isCurrent: !isGrayScale, isDisabled: !isOriginalSelected},
        ]
    };

    brightnessChangeHandler = (value) => {
        let { setBrightness } = this.props;

        setBrightness(value);
    };
    contrastChangeHandler = (value) => {
        let { setContrast } = this.props;

        setContrast(value);
    };


    toggleCrop = () => {
        let { toggleCrop, isCrop } = this.props;

        toggleCrop(!isCrop);
    };

    saveRotation = () => {
        let { saveRotatedPhoto, rotateDegrees, activePhotoId, textAnnotations, updateTextAnnotations, currentPhoto, updateGraphicAnnotations, graphicObjects, shadeTabCoords, updateShadeTabCoords } = this.props;

        let angle = rotateDegrees * Math.PI / 180;
        let width = currentPhoto.imgOriginal?.width;
        let height = currentPhoto.imgOriginal?.height;
        //console.log(width, height)
        saveRotatedPhoto(activePhotoId, rotateDegrees);
        
        let newWidth = 2* (Math.abs(width/2*Math.cos(angle)) + Math.abs(height/2*Math.sin(angle)) )
        let newHeight = 2* (Math.abs(height/2*Math.cos(angle)) + Math.abs(width/2*Math.sin(angle)) )
        //console.log(angle, width, height, currentPhoto)
        if ((textAnnotations?.length ?? 0) > 0) {
            updateTextAnnotations(
                activePhotoId,
                textAnnotations.map((item) => {
                    let x = item.x - width/2;// + item.width/2;
                    let y = item.y - height/2;// + item.height/2;
                    let x1 =  x*Math.cos(angle) - y*Math.sin(angle) + newWidth/2 
                    let y1 = x*Math.sin(angle) + y*Math.cos(angle) + newHeight/2
                    return { ...item, x: x1 /*- item.width/2*/, y: y1 /*- item.height/2*/ };
                })
            );
        }
        if ((graphicObjects?.length ?? 0) > 0) {
            updateGraphicAnnotations(
                activePhotoId,
                graphicObjects.map((g) => {
                    let result = g;
                    if (g.graph_type === GRAPHIC_LINE || g.graph_type === GRAPHIC_ARROW) {
                        let x1 = g.points[0] - width/2;
                        let y1 = g.points[1] - height/2;
                        let x1n =  x1*Math.cos(angle) - y1*Math.sin(angle) + newWidth/2 
                        let y1n = x1*Math.sin(angle) + y1*Math.cos(angle) + newHeight/2
                        let x2 = g.points[2] - width/2;
                        let y2 = g.points[3] - height/2;
                        let x2n =  x2*Math.cos(angle) - y2*Math.sin(angle) + newWidth/2 
                        let y2n = x2*Math.sin(angle) + y2*Math.cos(angle) + newHeight/2
                        result = { ...g, points: [x1n, y1n, x2n, y2n] };
                    }
                    else if (g.graph_type === GRAPHIC_CIRCLE) {
                        let x = g.x - width/2;
                        let y = g.y - height/2;
                        let x1 =  x*Math.cos(angle) - y*Math.sin(angle) + newWidth/2 
                        let y1 = x*Math.sin(angle) + y*Math.cos(angle) + newHeight/2
                        result = { ...g, x: x1, y : y1 };
                    }
                        
                    return result;
                    
                }),
            );
        }
        if (shadeTabCoords ) {
            let shadeCoords = {}
            if (shadeTabCoords.left) {
                let x = shadeTabCoords.left.x - width/2;// + shadeTabCoords.left.width/2;
                let y = shadeTabCoords.left.y - height/2;// + shadeTabCoords.left.height/2;
                let x1 =  x*Math.cos(angle) - y*Math.sin(angle) + newWidth/2 
                let y1 = x*Math.sin(angle) + y*Math.cos(angle) + newHeight/2
                shadeCoords = {...shadeCoords, ...{left : {...shadeTabCoords.left, x : x1 /*- shadeTabCoords.left.width/2*/, y: y1 /*- shadeTabCoords.left.height/2*/, ver: '1.1'}}}
            }
            if (shadeTabCoords.right) {
                let x = shadeTabCoords.right.x - width/2;// + shadeTabCoords.right.width/2;
                let y = shadeTabCoords.right.y - height/2;// + shadeTabCoords.right.width/2;
                let x1 =  x*Math.cos(angle) - y*Math.sin(angle) + newWidth/2 
                let y1 = x*Math.sin(angle) + y*Math.cos(angle) + newHeight/2
            shadeCoords = {...shadeCoords, ...{right : {...shadeTabCoords.right, x : x1 /*- shadeTabCoords.right.width/2*/, y: y1 /*- shadeTabCoords.right.height/2*/, ver: '1.1'}}}
            }
            updateShadeTabCoords(currentPhoto.id, {
                ...shadeTabCoords,
                ...shadeCoords,
            });
        }


    }

    saveFlipped = (flipOrientation) => {
        let { saveFlippedPhoto, activePhotoId, currentPhoto, textAnnotations, shadeTabCoords, updateTextAnnotations, updateGraphicAnnotations, updateShadeTabCoords, graphicObjects } = this.props;

        let width = currentPhoto.imgOriginal?.width;
        let height = currentPhoto.imgOriginal?.height;

        saveFlippedPhoto(activePhotoId, flipOrientation);
        
        if ((textAnnotations?.length ?? 0) > 0) {
            updateTextAnnotations(
                activePhotoId,
                textAnnotations.map((item) => {
                    return { ...item, x: ((flipOrientation === 1) ? item.x : (width -  item.x /*- item.width*/)), y: ((flipOrientation === 1) ? (height - item.y /*- item.height*/) : item.y) };
                })
            );
        }
        if ((graphicObjects?.length ?? 0) > 0) {
            updateGraphicAnnotations(
                activePhotoId,
                graphicObjects.map((g) => {
                    let result = g;
                    if (g.graph_type === GRAPHIC_LINE || g.graph_type === GRAPHIC_ARROW) {
                        result = { ...g, points: [ ((flipOrientation === 1) ? g.points[0] : (width - g.points[0])),
                                                 ((flipOrientation === 1) ? (height - g.points[1]) : g.points[1]),
                                                 ((flipOrientation === 1) ? g.points[2] : (width - g.points[2])), 
                                                 ((flipOrientation === 1) ? (height - g.points[3]) : g.points[3]) 
                                                ] };
                    }
                    else if (g.graph_type === GRAPHIC_CIRCLE) {
                        result = { ...g, x: ((flipOrientation === 1) ? g.x : (width - g.x) ) , y : ((flipOrientation === 0) ? g.y : (height - g.y) ) };
                    }
                        
                    return result;
                }),
            );
        }
        if (shadeTabCoords ) {
            let shadeCoords = {}
            if (shadeTabCoords.left) {
                shadeCoords = {...shadeCoords, ...{
                    left : {
                        x : ((flipOrientation === 1) ? shadeTabCoords.left.x : (width - shadeTabCoords.left.x /*- shadeTabCoords.left.width*/) ), 
                        y: ((flipOrientation === 0) ? shadeTabCoords.left.y : (height - shadeTabCoords.left.y /*- shadeTabCoords.left.height*/) ),
                        ver: '1.1'
                    }}}
            }
            if (shadeTabCoords.right) {
                shadeCoords = {...shadeCoords, ...{
                    right : {
                        x : ((flipOrientation === 1) ? shadeTabCoords.right.x : (width - shadeTabCoords.right.x /*- shadeTabCoords.right.width*/) ), 
                        y: ((flipOrientation === 0) ? shadeTabCoords.right.y : (height - shadeTabCoords.right.y /*- shadeTabCoords.right.height*/) ),
                        ver: '1.1'
                    }}}
            }
            updateShadeTabCoords(currentPhoto.id, {
                ...shadeTabCoords,
                ...shadeCoords,
            });
        }
    }

    onUndo = () => {
        let { undoSavedPhoto, activePhotoId } = this.props;

        undoSavedPhoto(activePhotoId);
    }

    onRedo = () => {
        let { redoSavedPhoto, activePhotoId } = this.props;

        redoSavedPhoto(activePhotoId)
    }

    render() {
        let { rotateDegrees, brightness, contrast, isOriginalOnly, isChangeOrientationDisabled, undoAvailable, redoAvailable } = this.props;

        return (
            <div className={'edit'}>
                { (undoAvailable !== '') && <ControlRow title={'Undo ' + undoAvailable} className={'action'} onClick={this.onUndo} /> }
                { (redoAvailable !== '') && <ControlRow title={'Redo ' + redoAvailable} className={'action'} onClick={this.onRedo} /> }
                <ControlRow title={'Crop'} className={'action'} onClick={this.toggleCrop} isDisabled={!isOriginalOnly}/>

                <ControlRow title={'Rotate'} className={'rotate'} isDisabled={isChangeOrientationDisabled}>
                    <img onClick={() => {if (!isChangeOrientationDisabled) this.rotate(-90);}} src={require('../../../assets/images/icons/rotate-left.png')} alt={'rotate left'} disabled={isChangeOrientationDisabled }/>
                    <img onClick={() => {if (!isChangeOrientationDisabled) this.rotate(90);}} src={require('../../../assets/images/icons/rotate-right.png')} alt={'rotate right'} disabled={isChangeOrientationDisabled }/>
                </ControlRow>

                <ControlRow title={''} className={'column incremental-rotate'} isDisabled={isChangeOrientationDisabled}>
                    <Slider className={'degrees-slider-2'} defaultValue={0} buttonStep={1} isResetButton={true} allChanges={true} current={rotateDegrees <= 180 ? rotateDegrees : (rotateDegrees - 360)} min={-180} max={180} onChange={this.handleRotateChange} isDisabled={isChangeOrientationDisabled}/>
                    {false && <SliderComponent className={"degrees-slider"} valueLabelDisplay='on' value={rotateDegrees <= 180 ? rotateDegrees : (rotateDegrees - 360)} disabled={isChangeOrientationDisabled} onChange={this.handleRotateChange} min={-180} max={180} step={1} aria-labelledby="continuous-slider" /> }
                    <GreenButton className={'save-degrees-btn'} onClick={this.saveRotation} text={'Save'} disabled={rotateDegrees === 0}/>
                </ControlRow>
                <ControlRow title={'Flip'} className={'rotate'} isDisabled={isChangeOrientationDisabled}>
                    <img onClick={() => {if (!isChangeOrientationDisabled) this.saveFlipped(1);}} src={require('../../../assets/images/icons/flip-right.svg').default} alt={'flip vertical'} className={ isChangeOrientationDisabled ? 'disabled flip' : 'flip'} title={'Vertical'}  />
                    <img onClick={() => {if (!isChangeOrientationDisabled) this.saveFlipped(0);}} src={require('../../../assets/images/icons/flip-up.svg').default} alt={'flip horizontal'} className={ isChangeOrientationDisabled ? 'disabled flip' : 'flip'} title={'Horizontal'} />
                </ControlRow>


                <ControlRow title={'Gray Scale'} className={'action'} onClick={this.setGrayScaleHandler} isDisabled={!isOriginalOnly}/>

                <ControlRow title={'Brightness'} className={'column'}>
                    <Slider defaultValue={defaultBrightness} isResetButton={true} allChanges={true} current={brightness} min={-100} max={100} onChange={this.brightnessChangeHandler}/>
                </ControlRow>

                <ControlRow title={'Contrast'} className={'column'}>
                    <Slider defaultValue={defaultContrast} isResetButton={true} allChanges={true}  current={contrast} min={-50} max={100} onChange={this.contrastChangeHandler}/>
                </ControlRow>
            </div>
        )
    }
}

Edit.propTypes = {
    rotateDegrees: PropTypes.number,
    isGrayScale: PropTypes.bool.isRequired,
    brightness: PropTypes.number.isRequired,
    contrast: PropTypes.number.isRequired,

    isOriginalOnly: PropTypes.bool.isRequired,
    isOriginalSelected: PropTypes.bool.isRequired,
    isChangeOrientationDisabled: PropTypes.bool.isRequired,

    rotateImage: PropTypes.func.isRequired,
    setGrayScale: PropTypes.func.isRequired,
    setBrightness: PropTypes.func.isRequired,
    setContrast: PropTypes.func.isRequired,
    activePhotoId: PropTypes.string,
    saveFlippedPhoto: PropTypes.func.isRequired,
    undoSavedPhoto: PropTypes.func.isRequired,
    redoSavedPhoto: PropTypes.func.isRequired,
    undoAvailable: PropTypes.string.isRequired,
    redoAvailable: PropTypes.string.isRequired,
    textAnnotations: PropTypes.array.isRequired,
    updateTextAnnotations: PropTypes.func.isRequired,
    currentPhoto: PropTypes.object,
    shadeTabCoords: PropTypes.object,
    updateShadeTabCoords: PropTypes.func.isRequired
    
};

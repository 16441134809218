import React from "react";
import PropTypes from 'prop-types';
import Button from '../Button';

export default class GreyButton extends React.PureComponent {
    render() {
        let { text, className, onClick, disabled } = this.props;

        return (
            <Button onClick={onClick} className={'grey' + (className ? ` ${className}` : '')} text={text} disabled={disabled}/>
        )
    }
}

GreyButton.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

import React from "react";
import { Circle, Group, Rect, RegularPolygon } from 'react-konva';

export default class EditButtonKonva extends React.PureComponent {
    
    render() {
        const { x, y, width, height, onClick, id, onTouchStart } = this.props;
        
        return <Group id={id+'g'} x={x+width/2} y={y+width/2} offsetX={x+width/2} offsetY={y+width/2} rotation={-45} onClick={onClick} onTouchStart={onTouchStart}>
            <Circle
                id={id+'c'}
                x = {x + width/2}
                y = {y + height/2}
                radius = {width/2}
                stroke="#3366ff"
                strokeWidth={2}
                tension={0.5}
                lineCap="round"
                fill="#3366ff"
            />
            
                <Rect 
                    id={id+'r'}
                    x={x + 3*width/8  }
                    y={y + height/2 - height/6 }
                    fill="#ffffff"
                    width={width/2}
                    height={height/4+height/12}
                    
                    rotation={0}
                />
                <RegularPolygon
                    id={id+'p'}
                    x={x+3*width/16}
                    y={y + height/2 }
                    sides={3}
                    radius={height/6}
                    fill='#ffffFF'
                    stroke='#ffffff'
                    strokeWidth={1}
                    rotation={30}
                />
            
            

        
        </Group>
    }
}
import React from "react";
import PropTypes from "prop-types";

export default class SwitchButtons extends React.PureComponent {
    renderButtons = (buttons) => {
        let { onClick } = this.props;

        return buttons.map((button, index) => {
            return <div key={index} className={'switch-button' + (button.isCurrent ? ' active' : '') + (button.isDisabled ? ' disabled' : '')} onClick={() => {if (!button.isDisabled) onClick(button.name);}}>{button.text}</div>
        })
    };
    render() {
        let { buttons, className } = this.props;

        return (
            <div className={'switch-buttons' + (className ? ` ${className}` : '')}>
                {this.renderButtons(buttons)}
            </div>
        )
    }
}

SwitchButtons.propTypes = {
    buttons: PropTypes.array.isRequired,
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

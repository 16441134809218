import React from "react";
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";

export class Row extends React.PureComponent {
    render() {
        let { children, onClick } = this.props;

        return (
            <div className={'row'} onClick={onClick}>{children}</div>
        )
    }
}
Row.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
};
export class RowLink extends React.PureComponent {
    render() {
        let { children, to } = this.props;

        // TODO make it NavLink instead
        return (
            <NavLink{...this.props} to={to} className={'row'}>{children}</NavLink>
        )
    }
}
RowLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export class Body extends React.PureComponent {
    render() {
        let { children, attributes, className } = this.props;
        const classes = ['body'];

        if (className) {
            classes.push(className);
        }

        return (
            <div className={classes.join(' ')}{...attributes}>{children}</div>
        )
    }
}
Body.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export class Header extends React.PureComponent {
    render() {
        let { children, className } = this.props;
        let headerClassName = 'header';

        if (className) {
            headerClassName += ` ${className}`;
        }
        return (
            <div className={headerClassName}>{children}</div>
        )
    }
}

Header.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export class Cell extends React.PureComponent {
    handleClick = () => {
        const { handleClick } = this.props;

        if (typeof handleClick === 'function') {
            handleClick();
        }
    };
    render() {
        const { className, children, tooltipText } = this.props;
        let cellClassName = 'cell';

        if (className) {
            cellClassName += ` ${className}`;
        }
        return (
            <div className={cellClassName} title={tooltipText ? tooltipText : ''} onClick={this.handleClick} onTouchEnd={this.handleClick}>
                {children}
            </div>
        )
    }
}
Cell.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    tooltipText: PropTypes.string,
    handleClick: PropTypes.func,
};

export default class Table extends React.PureComponent {
    render() {
        let { className, children, attributes } = this.props;

        return (
            <div className={'table' + (className ? ` ${className}` : '')} {...attributes}>{children}</div>
        )
    }
}
Table.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

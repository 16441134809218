import React from "react";
import { PureComponent } from "react";
import MatDialog, { MatDialogContent, MatDialogHeader } from "./DraggableDialog";
import GreenButton from "./form/buttons/GreenButton";
import { connect } from "react-redux";
import InputGroup from "./form/InputGroup";
import InputText from "./form/input/InputText";
import TransparentButton from "./form/buttons/TransparentButton";
import Select from "./form/Select";

class EditCaseLookupDialog extends PureComponent {
    state = {
        currentDoctorIndex: '0', 
        doctorFirst: this.props.caseDetails?.doctorFirst, 
        doctorLast: this.props.caseDetails?.doctorLast, 
        patientFirst: this.props.caseDetails?.patientFirst, 
        patientLast: this.props.caseDetails?.patientLast,
        doctors: this.props.userProfile.dentists,
    }

    componentDidMount = () => {
        
    }

    componentDidUpdate = (prevProps) => {

        if (prevProps.isOpenBackup !== this.props.isOpenBackup) {
            if (this.props.isOpenBackup) {
                //this.setState( {
                //    isGeneratingBackup: false
                //})
            }
        }
        if (prevProps.initialDoctorIndex !== this.props.initialDoctorIndex) {
            this.setState({
                currentDoctorIndex: this.props.initialDoctorIndex
            })
        }
        console.log(this.props.caseDetails)
        if (prevProps.caseDetails !== this.props.caseDetails) {
            let doctorIndex = '0'
            if (this.props.userProfile.dentists?.length > 0) {
                let doctor = this.props.userProfile.dentists.find(doctor => doctor.first_name === this.props.caseDetails?.doctorFirst && doctor.last_name === this.props.caseDetails?.doctorLast )
                if (doctor != null) {
                    doctorIndex = doctor.id + ' ' + doctor.idx;
                }
            }
            this.setState({
                currentDoctorIndex: doctorIndex,
                patientFirst: this.props.caseDetails.patientFirst,
                patientLast: this.props.caseDetails.patientLast,
                doctorFirst: this.props.caseDetails.doctorFirst,
                doctorLast: this.props.caseDetails.doctorLast,
            })
        }
    }

    

    

    handleCloseEditCaseLookupDialog = () => {
        
        this.setState( {
            //isOpenBackup: false,
            //isGeneratingBackup: false
        })
        this.props.onClose();
    }

    doctorChangeHandler = (value) => {
        let doctor = this.state.doctors.find((d) => (d.id + ' ' + d.idx) === value);
		this.setState(
            { 
                currentDoctorIndex: value,
                doctorFirst: doctor?.first_name,
                doctorLast: doctor?.last_name
            }
        );
	};

    getDoctorOptions = (doctors) => {
		let list = doctors.map((d) => {
			return {
				value: d.id + ' ' + d.idx,
				name: d.first_name + ' ' + d.last_name,
			};
		});

		return list;
	};

    doctorFirstChangeHandler = (value) => {
		this.setState({ doctorFirst: value });
	};
	doctorLastChangeHandler = (value) => {
		this.setState({ doctorLast: value });
	};
	patientFirstChangeHandler = (value) => {
		this.setState({ patientFirst: value });
	};
	patientLastChangeHandler = (value) => {
		this.setState({ patientLast: value });
	};
    onSave = () => {
        const { doctorFirst, doctorLast, patientFirst, patientLast } = this.state;
        const { caseDetails } = this.props;
        let data = {
            doctorFirst, 
            doctorLast, 
            patientFirst, 
            patientLast, 
            caseId: caseDetails?.id,
            isPatientChanged: (patientFirst !== caseDetails?.patientFirst || patientLast !== caseDetails?.patientLast),
            isDentistChanged: (doctorFirst !== caseDetails?.doctorFirst || doctorLast !== caseDetails?.doctorLast)
        }
        this.props.onSave(data);
    }


    render() {
        const {isOpenDialog, onClose, onSave, isAscend, isDentist } = this.props;
        const {currentDoctorIndex, doctorFirst, doctorLast, patientFirst, patientLast, doctors, errors} = this.state;

        return <MatDialog
        isOpen={isOpenDialog ?? false}
        onClose={this.handleCloseEditCaseLookupDialog}
        classes={{
            container: 'overlay',
            paper: 'modal add-edit-lab-specialist-popup edit-case',
        }}>
        <MatDialogHeader onClose={this.handleCloseEditCaseLookupDialog} title={'Edit Case Metadata'} isLightMode={true}>
            
        </MatDialogHeader>

        <MatDialogContent>
            <div className="light-page add-edit-lab-specialist-content">
                <div className="content-text">
                    Change Case Doctor or Patient names
                </div>
                <InputGroup>
                    <Select className={'edit-case'}
                        isRequired={true}
                        placeholder={isDentist ? 'Doctor Name' : 'Select Doctor Name Or Enter Below'}
                        placeholderLabel={'Doctor Name'}
                        onChange={this.doctorChangeHandler}
                        options={this.getDoctorOptions(doctors)}
                        value={currentDoctorIndex}
                        datatip={isDentist ? 'To add more Doctor Names go to Edit Profile page' : null}
                    />
                    {errors && errors['doctor'] ? (
                        <div className={'error'} key={'doctor-error'}>
                            {errors['doctor']}
                        </div>
                    ) : null}
                    
                </InputGroup>
                {   !isDentist && <InputText isRequired={true} placeholder={'Doctor First Name'} name={'doctor_first'} onChange={this.doctorFirstChangeHandler} value={doctorFirst}/> }
                {   !isDentist && <InputText isRequired={true} placeholder={'Doctor Last Name'} name={'doctor_last'} onChange={this.doctorLastChangeHandler} value={doctorLast}/> }
                    
                <InputText
                    isRequired={true}
                    placeholder={'Patient First Name'}
                    name={'patient_first'}
                    onChange={this.patientFirstChangeHandler}
                    value={patientFirst}
                    isDisabled={isAscend}
                />
                <InputText
                    isRequired={true}
                    placeholder={'Patient Last Name'}
                    name={'patient_last'}
                    onChange={this.patientLastChangeHandler}
                    value={patientLast}
                    isDisabled={isAscend}
                />
                    
                    
                
            </div>
            <div className="footer-row">
                <GreenButton className="confirm-button" text="Save" onClick={this.onSave} />
                <TransparentButton className="close-button" text="Close" onClick={onClose} />
            </div>
        </MatDialogContent>
    </MatDialog>
    }
}

EditCaseLookupDialog.propTypes = {
    //generateBackup: PropTypes.func.isRequired,
    //backupUrl: PropTypes.string
};

const mapStateToProps = state => ({
    // blabla: state.blabla,
    //backupUrl: state.user.backupUrl
    userProfile: state.user.userProfile
});

const mapDispatchToProps = dispatch => ({
    //generateBackup: (fromDate, toDate) => dispatch(generateBackup({fromDate, toDate}))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditCaseLookupDialog)
import React from "react";
import DetailsField from './elements/DetailsField';
import {caseShape} from "../../../helpers/case";

export default class Details extends React.PureComponent {
    renderFields = () => {
        let { currentCase } = this.props;

        if (!currentCase) {
            return false;
        }

        let data = [
            {title: 'Case', value: currentCase.yourCaseId},
            {title: 'Patient'},
            {title: 'First Name', value: currentCase.patientFirst},
            {title: 'Last Name', value: currentCase.patientLast},
            {title: 'Phone', value: currentCase.phone},
            {title: 'E-mail', value: currentCase.email},
            {title: 'Gender', value: currentCase.sex},

            {title: 'Doctor'},
            {title: 'First Name', value: !!currentCase.doctorFirst ? currentCase.doctorFirst : currentCase.dentist_first_name },
            {title: 'Last Name', value: !!currentCase.doctorLast ? currentCase.doctorLast : currentCase.dentist_last_name },

            /*{title: 'System', value: '148 Studio Vita'},
            {title: 'Shade'},
            {title: 'Guide', value: '148 Studio Vita'},
            {title: 'Shade Tab', value: 'A2'},*/
            {title: 'Comment', value: currentCase.comment},
        ];

        if (currentCase.practiceName !== '') {
            data.push({title: 'Practice'});
            data.push({title: 'Name', value: currentCase.practiceName});
        }

        if (currentCase.labId !== '0') {
            data.push({title: 'Lab'});
            data.push({title: 'ID', value: currentCase.labId});
            data.push({title: 'Name', value: currentCase.labName});
        }

        data.push({title: 'Default Standard'});
        data.push({title: 'Shade Guide', value: currentCase.shade_guide_name ?? ''});
        data.push({title: 'Shade Tab', value: currentCase.shade_tab_name ?? ''});

        return data.map((caseInfo, index) => {
            return <DetailsField title={caseInfo.title} value={typeof caseInfo.value !== 'undefined' ? caseInfo.value : null} key={index}/>
        });
    };

    render() {
        let { currentCase } = this.props;

        if (!currentCase) {
            return false;
        }

        return (
            <div className={'details'}>
                <div className={'date-info'}>
                    <div className={'date'}>
                        {currentCase.dateDay}
                    </div>
                    <div className={'info'}>
                        {currentCase.dateDayOfWeek}<br/>
                        {currentCase.dateMonth} {currentCase.dateYear}
                    </div>
                </div>


                <div className={'fields'}>
                    {this.renderFields()}
                </div>
            </div>
        )
    }
}

Details.propTypes = {
    currentCase: caseShape,
};

import { takeLatest, put, all, call } from 'redux-saga/effects';
import SettingsActionTypes from "../actions/settings.types";
import swApi from "../libraries/swApi";
import { getCountries, setCountries, setStates } from "../actions/settings.action";
import AscendActionTypes from '../actions/ascend.types';
import { ascendCasesLoaded, imagesSelectedForAcquire, openAscendSession } from '../actions/ascend.actions';
import history from '../history';
import { ASCEND_FINISH, ASCEND_SELECT_CASE } from '../constants/url';
import { getUser, signInSuccess } from '../actions/user.actions';

export function* doSignInAscend( { payload : {username, password, ascendkey} } ) {
    try {
        
        let response = yield call(swApi.makeRequest, 'signIn', [username, password]);
        yield call(swApi.checkResponse, response);
        yield put(signInSuccess(response.data));
        yield put(getUser());
        yield put(getCountries());        

        response = yield call(swApi.makeRequest, 'setSessionUser', [ascendkey], 'SWDentrix');
        yield call(swApi.checkResponse, response);
        let sessionData = response.data;
        yield put(openAscendSession({session : sessionData, ascendkey: ascendkey}));

        response = yield call(swApi.makeRequest, 'getCases', [ascendkey], 'SWDentrix');
        yield call(swApi.checkResponse, response);
        yield put(ascendCasesLoaded(response.data));

        yield history.push(ASCEND_SELECT_CASE);
    } catch (error) {
        console.log(error);
    }
}

export function* doGetSession({payload : { ascendkey }}) {
    try {
           let response = yield call(swApi.makeRequest, 'getSessionInfo', [ascendkey], 'SWDentrix');
            yield call(swApi.checkResponse, response);
            yield put(openAscendSession({session : response.data, ascendkey: ascendkey}));
        } catch (error) {
            console.log(error);
        }
}

export function* doGetCases({payload : { ascendkey}}) {
    try {
    let response = yield call(swApi.makeRequest, 'getCases', [ascendkey], 'SWDentrix');
        yield call(swApi.checkResponse, response);
        yield put(ascendCasesLoaded(response.data));
    } catch (error) {
        console.log(error);
    }
}

export function* doSetAcquiredImages( { payload : { ascendkey, images }}) {
    try {
        
        let response = yield call(swApi.makeRequest, 'setImagesForAquire', [ascendkey, images], 'SWDentrix');
        yield call(swApi.checkResponse, response);
        yield put(imagesSelectedForAcquire(images));

        yield history.push(ASCEND_FINISH);
    } catch (error) {
        console.log(error);
    }
}



export function* onSignInAscend() {
    yield takeLatest(AscendActionTypes.SIGN_IN, doSignInAscend);
}

export function* onSetAcquiredImages() {
    yield takeLatest(AscendActionTypes.SET_ACQUIRED_IMAGES, doSetAcquiredImages);
}

export function* onGetCases() {
    yield takeLatest(AscendActionTypes.GET_CASES, doGetCases);

}

export function* onGetSession() {
    yield takeLatest(AscendActionTypes.GET_SESSION, doGetSession);
}

export function* ascendSaga() {
    yield all([
        call(onSignInAscend),
        call(onSetAcquiredImages),
        call(onGetCases),
        call(onGetSession),
    ]);
}
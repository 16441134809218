import React from "react";
import PropTypes from "prop-types";
import Image from "./Image";
import { Stage, Layer, Line, Group } from "react-konva";
import { withResizeDetector } from "react-resize-detector";
import { getImageDimensions } from "../../../helpers/photo";
//import withResizeDetector from "react-resize-detector/build/types/withResizeDetector";


class QcImage extends React.PureComponent {
	state = {
		container: null,
		containerWidth: 0,
		containerHeight: 0,
		imageWidth: 0,
		imageHeight: 0,
		imageX: 0,
		imageY: 0,
		ratio: 1,
		ratioX: 1,
		ratioY: 1,
		stageX: 0,
        stageY: 0,
        
    };
    
    xCH = null;
    yCH = null;

	componentDidUpdate(prevProps) {
		let { width, height, image } = this.props;
		let { containerWidth, containerHeight } = this.state;
        
        /*if (image !== prevProps.image ) {
            
            this.xCH= null;
            this.yCH= null;
        }*/
		if (
			image !== prevProps.image ||
			(width && height && (Math.abs(containerWidth - width) > 1 || Math.abs(containerHeight - height) > 1))
		) {
            
            this.handleImageLoad({ width, height });
            
		}
	}

    componentDidMount() {
        this.xCH = this.props.crosshairPosition?.x ?? null;
        this.yCH = this.props.crosshairPosition?.y ?? null;
    }
    
    componentWillUnmount() {
       
    }

	handleImageLoad = (params) => {
		let { containerWidth, containerHeight } = this.state;
		let { image } = this.props;

		if (!image) {
			return false;
		}
		
		if (params) {
			let { width, height } = params;

			if (width) {
				containerWidth = width;
			}
			if (height) {
				containerHeight = height;
			}
		}

		let dimensions = getImageDimensions(image, containerWidth, containerHeight);
        
        if (this.xCH && this.state.imageWidth && dimensions.imageWidth) {
            this.xCH = dimensions.imageX  + (dimensions.imageWidth / this.state.imageWidth) * (this.xCH - this.state.imageX);
        }
        if (this.yCH && this.state.imageHeight && dimensions.imageHeight) {
            this.yCH = dimensions.imageY + (dimensions.imageHeight / this.state.imageHeight) * (this.yCH - this.state.imageY);
        }
		this.setState(dimensions);
    };
    
    dragBoundFunc = (pos) => {
        let {imageRef, stageScale} = this.props;
        let { imageWidth, imageHeight, imageX, imageY } = this.state;
        
        let px = pos.x + imageX + imageWidth * (1-stageScale)/2;
        let py = pos.y + imageY + imageHeight * (1-stageScale)/2;
        let w = imageWidth*stageScale;
        let h = imageHeight*stageScale;
        let cPos = imageRef.absolutePosition();
        let cW = imageRef.width();
        let cH = imageRef.height();
        if (px + w < cPos.x + 100) {
            px = cPos.x + 100 - w;
        }
        if (py + h < cPos.y + 100) {
            py = cPos.y + 100 - h;
        }
        if (px > cPos.x + cW - 100) {
            px = cPos.x + cW - 100;
        }
        if (py > cPos.y + cH - 100) {
            py = cPos.y + cH - 100;
        }
        px -= imageX +imageWidth * (1-stageScale)/2;
        py -= imageY +imageHeight * (1-stageScale)/2;
        
        return {
            x: px,
            y: py
        }
    }

	renderPhoto = () => {
		let { brightness, contrast, isGrayScale, image, imageType, rotateDegrees, stageScale, centerCHMode } = this.props;
		let { imageWidth, imageHeight, imageX, imageY } = this.state;

		if (!image) {
			return false;
		}

		if (imageWidth < 1) {
			return false;
		}

		const filters = {
			brightness,
			contrast,
			isGrayScale,
		};

		return (
			<Image
                ref={(node) => {
                        this.imageCtrl = node;
                    }
                }
				image={image}
				x={imageX}
				y={imageY}
				width={imageWidth}
				height={imageHeight}
				imageType={imageType}
				draggable={!centerCHMode}
                rotation={rotateDegrees ? rotateDegrees : 0}
                scaleX={stageScale}
                scaleY={stageScale}
                dragBoundFunc={this.dragBoundFunc}
				{...filters}
			/>
		);
	};

	renderCrosshairs = () => {
        let {containerWidth, containerHeight} = this.state;
		return (
            <Group>
			<Line
				
				//name={"rectangle-horz"}
				dashEnabled={true}
				dash={[10, 5]}
				points={[0, containerHeight/2, 45, containerHeight/2]}
				stroke={"white"}
				strokeEnabled={true}
				fillEnabled={true}
				fill={"lime"}
				opacity={1}
				draggable={false}
				visible={true}
				
			/>
            <Line
				
				//name={"rectangle-horz"}
				dashEnabled={true}
				dash={[10, 5]}
				points={[(Math.floor((containerWidth-45)/15)+1)*15, containerHeight/2, containerWidth, containerHeight/2]}
				stroke={"white"}
				strokeEnabled={true}
				fillEnabled={true}
				fill={"lime"}
				opacity={1}
				draggable={false}
				visible={true}
				
			/>
            <Line
				
				//name={"rectangle-horz"}
				dashEnabled={true}
				dash={[10, 5]}
				points={[containerWidth/2, 0, containerWidth/2, 45]}
				stroke={"white"}
				strokeEnabled={true}
				fillEnabled={true}
				fill={"lime"}
				opacity={1}
				draggable={false}
				visible={true}
				
			/>
            <Line
				
				//name={"rectangle-horz"}
				dashEnabled={true}
				dash={[10, 5]}
				points={[containerWidth/2, (Math.floor((containerHeight-45)/15)+1)*15, containerWidth/2, containerHeight]}
				stroke={"white"}
				strokeEnabled={true}
				fillEnabled={true}
				fill={"lime"}
				opacity={1}
				draggable={false}
				visible={true}
				
			/>
            <Line
				ref={(node) => {
                    this.vertLine = node;
                }
                }
				//name={"rectangle-horz"}
				dashEnabled={true}
				dash={[10, 5]}
				points={[this.xCH ?? containerWidth/2, 0, this.xCH ?? containerWidth/2, this.yCH ?? containerHeight/2, this.xCH ?? containerWidth/2, containerHeight]}
				stroke={"gray"}
				strokeEnabled={true}
				fillEnabled={true}
				fill={"lime"}
				opacity={1}
				draggable={false}
				visible={true}
				
			/>
            <Line
				ref={(node) => {
                    this.horizLine = node;
                }
                }
				//name={"rectangle-horz"}
				dashEnabled={true}
				dash={[10, 5]}
				points={[0, this.yCH ?? containerHeight/2,  this.xCH ?? containerWidth/2, this.yCH ?? containerHeight/2, containerWidth, this.yCH ?? containerHeight/2]}
				stroke={"gray"}
				strokeEnabled={true}
				fillEnabled={true}
				fill={"lime"}
				opacity={1}
				draggable={false}
				visible={true}
				
			/>
            </Group>
		);
	};

	handleTouchStart = (e) => {
		//console.log(e);
	};

	handleTouchMove = (e) => {
		//console.log(e);
	};

	handleTouchEnd = (e) => {
		//console.log(e);
	};

	handleClick = (e) => {
		if (this.props.centerCHMode ) {
            this.horizLine.points([0, e.evt.layerY, e.evt.layerX, e.evt.layerY, this.state.containerWidth, e.evt.layerY ]);
            this.vertLine.points([e.evt.layerX, 0, e.evt.layerX, e.evt.layerY, e.evt.layerX, this.state.containerHeight ]);
            
            this.xCH = e.evt.layerX;
            this.yCH = e.evt.layerY;
            this.horizLine.getStage().draw();
            this.props.centerCHFinished(
                {
                    x: this.xCH,
                    y: this.yCH
                }
            );
        }
    };
    
    handleMove = (e) => {
        if (this.props.centerCHMode ) {
            
            this.horizLine.points([]);
            this.horizLine.points([0, e.evt.layerY, e.evt.layerX, e.evt.layerY, this.state.containerWidth, e.evt.layerY ]);
            this.vertLine.points([e.evt.layerX, 0, e.evt.layerX, e.evt.layerY, e.evt.layerX, this.state.containerHeight ]);
            
            this.xCH = e.evt.layerX;
            this.yCH = e.evt.layerY;
            this.horizLine.getStage().draw();
           
        }
    }

	render = () => {
        let { crosshairVisible } = this.props;
        let { containerWidth, containerHeight, stageX, stageY } = this.state;

		return (
			<div className={"qc-image"}>
				<Stage
					ref={this.props.stageRef}
					
					x={stageX + containerWidth / 2}
					y={stageY + containerHeight / 2}
					width={containerWidth}
					height={containerHeight}
					draggable={false}
					onTouchStart={this.handleTouchStart}
					onTouchMove={this.handleTouchMove}
					onTouchEnd={this.handleTouchEnd}
					offset={{ x: containerWidth / 2, y: containerHeight / 2 }}
                    onClick={this.handleClick}
                    onMouseMove={this.handleMove}
				>
					<Layer>
						{this.renderPhoto()}
						{crosshairVisible && this.renderCrosshairs()}
					</Layer>
				</Stage>
			</div>
		);
	};
}

QcImage.propTypes = {
	rotateDegrees: PropTypes.number,
	brightness: PropTypes.number,
	contrast: PropTypes.number,
	isGrayScale: PropTypes.bool,
	stageScale: PropTypes.number,
    imageType: PropTypes.string,
    centerCHMode: PropTypes.bool,
    
};

export default withResizeDetector(QcImage, {
	refreshMode: "debounce",
	refreshRate: 100,
	refreshOptions: { leading: false, trailing: true },
	handleHeight: true,
	handleWidth: true,
});

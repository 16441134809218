import React from "react";
import PropTypes from "prop-types";
import QcImage from "./photo/QcImage";
import { RegionShadeValues } from "./subMenus/elements/RegionShadeValues";
import { QcEdit } from "./photo/QcEdit";
import Checkbox from "../elements/form/Checkbox";
import { zoomScaleBy } from "../../config/photo";
import GreenButton from "../elements/form/buttons/GreenButton";
import ReactToPrint from "react-to-print";




export default class QcView extends React.PureComponent {

    state = {
        centerLeftCHMode: false,
        centerRightCHMode: false,
        qcWidth: 0,
        qcHeight: 0,
    }
    
    handleLeftZoom = (value) => {
        let { leftqc, updateLeftQcValues } = this.props;
        let newZoom = (value > 0) ? (leftqc.zoom * zoomScaleBy) : (leftqc.zoom / zoomScaleBy);
        updateLeftQcValues({ zoom : newZoom });
    }

    handleRightZoom = (value) => {
        let { rightqc, updateRightQcValues } = this.props;
        let newZoom = (value > 0) ? (rightqc.zoom * zoomScaleBy) : (rightqc.zoom / zoomScaleBy);
        updateRightQcValues({ zoom : newZoom });
    }

    handleLeftRotate = (value) => {
        let { leftqc, updateLeftQcValues } = this.props;
        let newRotation = leftqc.rotation + value;
        if (newRotation < 0) newRotation += 360;
        if (newRotation >= 360) newRotation -= 360;
        updateLeftQcValues({ rotation : newRotation });
    }

    handleRightRotate = (value) => {
        let { rightqc, updateRightQcValues } = this.props;
        let newRotation = rightqc.rotation + value;
        if (newRotation < 0) newRotation += 360;
        if (newRotation >= 360) newRotation -= 360;
        updateRightQcValues({ rotation : newRotation });
    }

    handleOriginal = () => {
        let { isQcOriginal, updateQcValues } = this.props;
        updateQcValues({isQcOriginal : !isQcOriginal});
    }

    handleGrayScale = () => {
        let { isQcGrayScale, updateQcValues } = this.props;
        updateQcValues({isQcGrayScale : !isQcGrayScale});
    }

    handleCrosshair = () => {
        let { isQcCrosshair, updateQcValues } = this.props;
        updateQcValues({isQcCrosshair : !isQcCrosshair});
    }

    closeQc = () => {
        let {setQcMode} = this.props;
        setQcMode(false);
    }

    leftCenterCHFinished = (pos) => {
        this.setState({
            centerLeftCHMode: false
        })
        this.props.updateLeftQcValues({ crosshairPos : pos });
    }

    rightCenterCHFinished = (pos) => {
        this.setState({
            centerRightCHMode: false
        })
        this.props.updateRightQcValues({ crosshairPos: pos } );
    }

    setLeftCHCenter = () => {
        this.setState({
            centerLeftCHMode: true
        })
    }

    setRightCHCenter = () => {
        this.setState({
            centerRightCHMode: true
        })
    }

    printQc = () => {

    }

    handleBeforePrint = () => {
        
        return new Promise((resolve, reject) => {
            let ratio = 2000 / this.leftImage.width();
            let urlLeft = this.leftImage.toDataURL({
                pixelRatio: ratio
            });
            let urlRight = this.rightImage.toDataURL({
                pixelRatio: ratio
            });
            this.setState({ dataUrlLeft: urlLeft, dataUrlRight: urlRight,
                 qcWidthLeft: this.leftImage.width()*ratio, qcHeightLeft: this.leftImage.height()*ratio,
                 qcWidthRight: this.rightImage.width()*ratio, qcHeightRight: this.rightImage.height()*ratio,
                 }, () => resolve());
        });
    }

    renderPrintImage = () => {
        // eslint-disable-next-line
        let { dataUrlLeft, qcWidthLeft, qcHeightLeft, dataUrlRight, qcWidthRight, qcHeightRight } = this.state;
        return (
            // eslint-disable-next-line jsx-a11y/alt-text
            <div className={'qc-images'}>
                <div className={'qc-image-left'}><img src={dataUrlLeft} width={qcWidthLeft} alt="" /></div>
                <div className={'qc-image-right'}><img src={dataUrlRight} width={qcWidthRight} alt="" /></div>
            </div>
            
        );
    };

    render = () => {
        let { leftqc, rightqc, isQcGrayScale, leftPhoto, rightPhoto, leftShadeValues, rightShadeValues, isQcOriginal, isQcCrosshair, printData } = this.props;
        let { centerLeftCHMode, centerRightCHMode } = this.state;
        let disabled = !leftPhoto || !rightPhoto;
        return (
			<div className={"qc-main"}>
				<div className={"qc-images-container"}>
					<div
						className={"qc-image-container"}
						onWheel={(e) => {
							e.preventDefault();
							this.handleLeftZoom(-e.deltaY);
						}}
					>
						<QcImage
                            stageRef={(e) => {this.leftImage = e }}
							image={leftPhoto}
							stageScale={leftqc ? leftqc.zoom : 1.0}
							isGrayScale={isQcGrayScale}
							rotateDegrees={leftqc ? leftqc.rotation : 0}
							brightness={0}
							contrast={0}
							imageType={"left" + isQcOriginal}
							centerCHMode={centerLeftCHMode}
                            centerCHFinished={this.leftCenterCHFinished}
                            crosshairVisible={isQcCrosshair}
                            crosshairPosition={leftqc?.crosshairPos ?? null}
                            imageRef={this.leftImage}
                            
						/>
						
					</div>
					<div
						className={"qc-image-container"}
						onWheel={(e) => {
							e.preventDefault();
							this.handleRightZoom(-e.deltaY);
						}}
					>
						<QcImage
                            stageRef={(e) => {this.rightImage = e }}
							image={rightPhoto}
							stageScale={rightqc ? rightqc.zoom : 1.0}
							isGrayScale={isQcGrayScale}
							rotateDegrees={rightqc ? rightqc.rotation : 0}
							brightness={0}
							contrast={0}
							imageType={"right" + isQcOriginal}
							centerCHMode={centerRightCHMode}
                            centerCHFinished={this.rightCenterCHFinished}
                            crosshairVisible={isQcCrosshair}
                            crosshairPosition={rightqc?.crosshairPos ?? null}
                            imageRef={this.rightImage}
						/>
						
					</div>
				</div>
				<div className={"qc-controls"}>
					<div className={"qc-controls-left"}>
						<QcEdit
							onZoom={this.handleLeftZoom}
							onRotate={this.handleLeftRotate}
							disabled={disabled}
						></QcEdit>
                        <GreenButton text={"Move Crosshair"} onClick={this.setLeftCHCenter} disabled={disabled || !isQcCrosshair || centerLeftCHMode }/>
					</div>
					<div className={"qc-controls-left-middle"}></div>
					<div className={"qc-controls-middle"}>
						<div className={"qc-controls-checkboxes"}>
							<div className={"column "}>
								<Checkbox isChecked={isQcGrayScale} onChange={this.handleGrayScale} disable={disabled}>
									Grayscale
								</Checkbox>
							</div>
							<div className={"column "}>
								<Checkbox isChecked={isQcCrosshair} onChange={this.handleCrosshair} disable={disabled}>
									Crosshairs
								</Checkbox>
							</div>
							<div className={"column "}>
								<Checkbox
									isChecked={isQcOriginal}
									onChange={this.handleOriginal}
									disable={disabled}
								>
									Original
								</Checkbox>
							</div>
						</div>
						<div className={"row"}>
							<RegionShadeValues
								shadeValue={leftShadeValues ? leftShadeValues.valueBarShade : ""}
								master3dValue={leftShadeValues ? leftShadeValues.valueBarMaster : ""}
								brightnessValue={leftShadeValues ? leftShadeValues.valueBarBrightness : ""}
							/>
							<RegionShadeValues
								shadeValue={rightShadeValues ? rightShadeValues.valueBarShade : ""}
								master3dValue={rightShadeValues ? rightShadeValues.valueBarMaster : ""}
								brightnessValue={rightShadeValues ? rightShadeValues.valueBarBrightness : ""}
							/>
						</div>
					</div>
					<div className={"qc-controls-right-middle"}></div>
					<div className={"qc-controls-right"}>
						<QcEdit
							onZoom={this.handleRightZoom}
							onRotate={this.handleRightRotate}
							disabled={disabled}
						></QcEdit>
                        <GreenButton text={"Move Crosshair"} onClick={this.setRightCHCenter} disabled={disabled || !isQcCrosshair || centerRightCHMode}/>
					</div>
				</div>
				<GreenButton className={"qc-close-btn"} text={"Close QC"} onClick={this.closeQc} />
                <ReactToPrint content={() => this.printRef}
                    trigger={() => {
                        return <GreenButton className={"qc-print-btn"} text={"Print"} disabled={disabled} />;
                    }}
                    copyStyles={true} 
                    onBeforeGetContent={this.handleBeforePrint}
                    removeAfterPrint={true} />
                <div style={{ overflow: 'hidden', height: 0 }} >
                    <div className={'qc-print-area'}  ref={el => (this.printRef = el)}>
                        <div className={'qc-top-annotation'}>
                            <div className={'block-text-annotation'}>
                                <label className={'text-label'}>Doctor First Name: {printData.doctorFirstName}</label>
                                <label className={'text-label'}>Doctor Last Name : {printData.doctorLastName}</label>
                                <label className={'text-label'}>Name of Lab      : {printData.labName}</label>
                                <label className={'text-label'}>Name of Patient  : {printData.patientName}</label>
                            </div>
                        </div>
                        {this.renderPrintImage()}
                        <div className={'qc-bottom-annotations'}>
                            <div className={'block-text-annotation'}>
                                <label className={'text-label'}>Shade Value   : {printData.leftShadeValue}</label>
                                <label className={'text-label'}>3D Master     : {printData.leftMaster3dValue}</label>
                                <label className={'text-label'}>Brightness    : {printData.leftBrightnessValue}</label>
                                <label className={'text-label'}>Shade Guide Used: {printData.shadeGuide}</label>
                                <label className={'text-label'}>Shade Tabs Used: {printData.shadeTabs}</label>
                            </div>
                            <div className={'block-text-annotation'}>
                                <label className={'text-label'}>Shade Value   : {printData.rightShadeValue}</label>
                                <label className={'text-label'}>3D Master     : {printData.rightMaster3dValue}</label>
                                <label className={'text-label'}>Brightness    : {printData.rightBrightnessValue}</label>
                                <label className={'text-label'}>Shade Guide Used: {printData.secondShadeGuide}</label>
                                <label className={'text-label'}>Shade Tabs Used: {printData.secondShadeTabs}</label>
                            </div>
                        </div>
                       
                    </div>
                </div>
            
                
			</div>
		);
    }
}

QcView.propTypes = {
    leftqc: PropTypes.object.isRequired,
    rightqc: PropTypes.object.isRequired,
    isQcGrayScale: PropTypes.bool.isRequired,
    isQcOriginal: PropTypes.bool.isRequired,
    isQcCrosshair: PropTypes.bool.isRequired,
    leftPhoto: PropTypes.object,
    rightPhoto: PropTypes.object,
    leftShadeValues: PropTypes.object,
    rightShadeValues: PropTypes.object,
    updateLeftQcValues: PropTypes.func.isRequired,
    updateRightQcValues: PropTypes.func.isRequired,
    updateQcValues: PropTypes.func.isRequired,
    setQcMode: PropTypes.func.isRequired,
    printData: PropTypes.object.isRequired,
};

import React from "react";
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { dateToString, stringToDate } from '../../../helpers/date';
import Required from '../icons/Required';

export default class BaseInput extends React.PureComponent {
    renderRightIcon = () => {
        let { rightIcon, rightIconClick } = this.props;

        if (!rightIcon) {
            return false;
        }

        return <div onClick={rightIconClick} className={'right-icon'} style={{backgroundImage: `url(${rightIcon})`}}></div>
    };
    onChangeHandler = e => {
        let { onChange } = this.props;

        onChange(e.target.value);
    };
    renderErrors = errors => {
        if (!errors) {
            return false;
        }

        return errors.map((error, index) => {
            return <div className={'error'} key={index}>{error}</div>
        })
    };
    renderInput = () => {
        let { type, value, name, placeholder, attributes, isRequired, isId } = this.props;

        if (type === 'date') {
            return <DatePicker setDefaultLocale={'es'} selected={value ? stringToDate(value) : ''} onChange={(date) => {
                let { onChange } = this.props;

                onChange(dateToString(date));
            }} {...attributes} />;
        }

        return <input
            required={isRequired}
            name={name}
            id={typeof isId !== 'undefined' && !isId ? null : name}
            type={type}
            value={value ?? ''}
            placeholder={placeholder}
            onChange={this.onChangeHandler} {...attributes}
        />;
    };

    render() {
        let { placeholder, name, icon, className, errors, isRequired, isDisabled } = this.props;

        return (
            <div className={'input-group' + (className ? ` ${className}` : '') + (errors ? ' has-error' : '')}>
                {this.renderInput()}

                <label htmlFor={name} className={'label'}>{isRequired ? <Required/> : ''}<span className={'input-placeholder' + (isDisabled ? ' disabled':'')}>{placeholder}</span></label>
                {icon ? <img className={'input-icon'} alt={'icon'} src={icon}/> : false}
                {this.renderErrors(errors)}
                {this.renderRightIcon()}
            </div>
        );
    }
}

BaseInput.propTypes = {
    isId: PropTypes.bool,
    isRequired: PropTypes.bool,
    errors: PropTypes.array,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.node,
    rightIconClick: PropTypes.func,
    rightIcon: PropTypes.node,
    isDisabled: PropTypes.bool,
};





import React from "react";
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Divider, IconButton, Link, Slider, SvgIcon, Typography } from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import Checkbox from "../elements/form/Checkbox";
import Header from "../../containers/Header";
import { getTargetCountry, getTargetState } from "../../helpers/user";
import Form from '../elements/Form';
import InputText from '../elements/form/input/InputText';
import InputEmail from '../elements/form/input/InputEmail';
import InputPassword from '../elements/form/input/InputPassword';
import Select from '../elements/form/Select';
import Button from "../elements/form/Button";
import SupportInfo from "../elements/SupportInfo";
import ConfirmDialog from "../elements/ConfirmDialog";
import GreyButton from "../elements/form/buttons/GreyButton";
import { themeLight } from "../../config/app";
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from "react-to-print";
import { ExpandMore } from "@material-ui/icons";
import AlertDialog from "../elements/AlertDialog";
import GetAppIcon from '@material-ui/icons/GetApp';
import ExportConnectedDentists from "../elements/ExportConnectedDentists";


function IconSortDateAsc() {
    return (<SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" version="1.1" viewBox="0 0 40 40">
            <g fill="#000">
                <path strokeWidth="0.616" d="M.718 23.182C-.239 22.065-.239 1.96.718.843c.965-1.124 21.616-1.124 22.58 0 .86 1.003.967 15.824.114 15.824-.335 0-.57-2.327-.57-5.652v-5.65H1.175V22.65h6.557c3.991 0 6.557.26 6.557.665 0 .994-12.711.87-13.57-.133zM2.885 2.705c0-.366-.256-.665-.57-.665-.314 0-.57.3-.57.665 0 .366.256.665.57.665.314 0 .57-.3.57-.665zm1.71 0c0-.366-.256-.665-.57-.665-.313 0-.57.3-.57.665 0 .366.257.665.57.665.314 0 .57-.3.57-.665zm1.711 0c0-.366-.256-.665-.57-.665-.313 0-.57.3-.57.665 0 .366.257.665.57.665.314 0 .57-.3.57-.665z"></path>
                <path strokeWidth="0.673" d="M7.5 21.263c0-.395 1.703-.71 3.831-.71 2.129 0 3.831.315 3.831.71 0 .394-1.702.71-3.83.71-2.129 0-3.831-.316-3.831-.71zm9.58-1.062c-1.588-1.88-1.347-3.314.301-1.793.86.794.973.144.973-5.58 0-3.844.26-6.477.639-6.477.378 0 .638 2.633.638 6.478 0 5.723.113 6.373.973 5.58 1.648-1.522 1.889-.088.301 1.792-.823.974-1.684 1.772-1.912 1.772-.23 0-1.09-.798-1.913-1.772zM7.5 18.423c0-.391 1.437-.71 3.193-.71s3.192.319 3.192.71c0 .39-1.436.71-3.192.71-1.756 0-3.192-.32-3.192-.71zm0-2.84c0-.391 1.15-.711 2.555-.711 1.404 0 2.553.32 2.553.71 0 .39-1.149.71-2.553.71-1.405 0-2.554-.32-2.554-.71zm0-2.841c0-.39 1.006-.71 2.235-.71 1.23 0 2.235.32 2.235.71 0 .39-1.006.71-2.235.71-1.229 0-2.234-.32-2.234-.71zm0-2.84c0-.391.72-.71 1.597-.71.878 0 1.596.319 1.596.71 0 .39-.718.71-1.596.71-.878 0-1.596-.32-1.596-.71zm0-2.84c0-.391.432-.711.959-.711.526 0 .957.32.957.71 0 .39-.43.71-.957.71-.527 0-.958-.32-.958-.71z"></path>
            </g>
        </svg>


    </SvgIcon>);
}



function IconSortNameAsc() {
    return (<SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" version="1.1" viewBox="0 0 48 48">
            <g>
                <path fill="#000" strokeWidth="0.665" d="M15.812 21.846l-2.438-2.154h3.875V9.846C17.25.41 17.31 0 18.687 0c1.377 0 1.437.41 1.437 9.846v9.846H24l-2.438 2.154C20.22 23.031 18.927 24 18.687 24c-.24 0-1.534-.97-2.875-2.154zM0 22.448c0-.515 1.335-2.177 2.966-3.693L5.933 16H2.966C.48 16 0 15.801 0 14.77c0-1.114.48-1.232 5.031-1.232 6.101 0 6.379.622 2.065 4.63l-2.967 2.755h2.967c2.487 0 2.966.199 2.966 1.23 0 1.114-.479 1.232-5.03 1.232-4.027 0-5.032-.188-5.032-.937zM2.014 4.923C3.31 1.535 4.252 0 5.03 0c.78 0 1.72 1.535 3.017 4.923 1.77 4.62 1.804 4.923.555 4.923-.732 0-1.53-.277-1.775-.615-.244-.339-1.234-.616-2.199-.616-.965 0-1.754.277-1.754.616 0 .338-.618.615-1.373.615-1.31 0-1.285-.23.512-4.923zm3.736.615c0-.338-.324-.615-.719-.615-.395 0-.719.277-.719.615 0 .339.324.616.72.616.394 0 .718-.277.718-.616z"></path>
            </g>
        </svg>
    </SvgIcon>);
}



function IconSortNameDesc() {
    return (<SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" version="1.1" viewBox="0 0 48 48">
            <g>
                <path fill="#000" strokeWidth="0.704" d="M17.19 23.715c-.268-.23-.486-4.829-.486-10.22V3.692l-1.91 1.542c-1.474 1.19-2.114 1.365-2.81.766-.698-.6-.228-1.371 2.062-3.388C15.677 1.175 17.386 0 17.843 0c.457 0 2.166 1.175 3.797 2.612C23.93 4.629 24.4 5.4 23.703 6c-.697.6-1.337.425-2.811-.766l-1.91-1.542v9.873c0 8.984-.415 11.335-1.792 10.15zM0 21.72c.009-1.313 3.494-8.002 4.17-8.002.676 0 4.162 6.689 4.17 8.002.009 1.243-1.137 1.54-1.89.49-.259-.36-1.284-.653-2.28-.653-.995 0-2.02.294-2.278.653-.755 1.05-1.9.753-1.892-.49zm4.825-3.103c-.24-.538-.535-.98-.655-.98s-.414.442-.654.98c-.264.591-.004.98.654.98.659 0 .919-.389.655-.98zM.752 9.692c0-.417.87-2.034 1.931-3.592l1.931-2.834h-1.93c-1.21 0-1.932-.366-1.932-.98 0-.726.886-.98 3.418-.98 4 0 4.15.444 1.488 4.352L3.727 8.492h1.93c1.21 0 1.932.366 1.932.98 0 .725-.887.98-3.419.98-2.09 0-3.418-.296-3.418-.76z"></path>
            </g>
        </svg>
    </SvgIcon>);
}



function IconSortDateDesc() {
    return (<SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" version="1.1" viewBox="0 0 40 40">
            <g fill="#000">
                <path strokeWidth="0.616" d="M.718 23.182C-.239 22.065-.239 1.96.718.843c.965-1.124 21.616-1.124 22.58 0 .86 1.003.967 15.824.114 15.824-.335 0-.57-2.327-.57-5.652v-5.65H1.175V22.65h6.557c3.991 0 6.557.26 6.557.665 0 .994-12.711.87-13.57-.133zM2.885 2.705c0-.366-.256-.665-.57-.665-.314 0-.57.3-.57.665 0 .366.256.665.57.665.314 0 .57-.3.57-.665zm1.71 0c0-.366-.256-.665-.57-.665-.313 0-.57.3-.57.665 0 .366.257.665.57.665.314 0 .57-.3.57-.665zm1.711 0c0-.366-.256-.665-.57-.665-.313 0-.57.3-.57.665 0 .366.257.665.57.665.314 0 .57-.3.57-.665z"></path>
                <path strokeWidth="0.673" d="M7.5 7.061c0 .395 1.703.71 3.831.71 2.129 0 3.831-.315 3.831-.71 0-.394-1.702-.71-3.83-.71-2.129 0-3.831.316-3.831.71zm9.58 1.062c-1.588 1.88-1.347 3.314.301 1.793.86-.794.973-.144.973 5.58 0 3.844.26 6.477.639 6.477.378 0 .638-2.633.638-6.478 0-5.723.113-6.373.973-5.58 1.648 1.522 1.889.088.301-1.792-.823-.974-1.684-1.772-1.912-1.772-.23 0-1.09.798-1.913 1.772zM7.5 9.901c0 .391 1.437.71 3.193.71s3.192-.319 3.192-.71c0-.39-1.436-.71-3.192-.71-1.756 0-3.192.32-3.192.71zm0 2.84c0 .391 1.15.71 2.555.71 1.404 0 2.553-.319 2.553-.71 0-.39-1.149-.71-2.553-.71-1.405 0-2.554.32-2.554.71zm0 2.841c0 .39 1.006.71 2.235.71 1.23 0 2.235-.32 2.235-.71 0-.39-1.006-.71-2.235-.71-1.229 0-2.234.32-2.234.71zm0 2.84c0 .391.72.71 1.597.71.878 0 1.596-.319 1.596-.71 0-.39-.718-.71-1.596-.71-.878 0-1.596.32-1.596.71zm0 2.84c0 .391.432.711.959.711.526 0 .957-.32.957-.71 0-.39-.43-.71-.957-.71-.527 0-.958.32-.958.71z"></path>
            </g>
        </svg>
    </SvgIcon>);
}


class EditLabProfile extends React.PureComponent {

    SORT_DATE_ASC = 1;
    SORT_DATE_DESC = -1;
    SORT_NAME_ASC = 2;
    SORT_NAME_DESC = -2;

    state = {
        login_as_user: false,
        email_updates: false,
        labFirstName: '',
        labLastName: '',
        labName: '',
        address: '',
        country: '',
        state: '',
        city: '',
        postal_code: '',
        phone: '',
        email: '',
        additional_emails: '',
        additional_emails_case_assigned: '',
        website: '',
        username: '',
        password: '',
        confirmPassword: '',
        countryChanged: false,
        showCancelConfirmDialog: false,
        showDeleteDentistConfirmDialog: false,
        selectedDentistId: null,
        sortDentistsFirst: null,
        sortDentistsSecond: null,
        magictouch_enabled: false,
		magictouch_token: null,
		magictouch_default_labid: null,
		magictouch_defaults_expanded: false,
        auto_credit_renew: false,
        auto_credit_value: 100,
        isOpenExportDentists: false,
        showAutoRenewWarningDialog: false
    };
    componentDidMount() {
        const { userProfile, countries, states, getStates, getCountries } = this.props;
        let country = '';
        let state = '';

        if (countries && countries.length) {
            country = getTargetCountry(countries, userProfile.country);
            state = getTargetState(states, userProfile.state);

            if ((country || country === 0) && country <= 2) {
                getStates(country);
            }
        } else {
            getCountries();
        }
        this.setState({
            login_as_user: userProfile.login_as_user,
            email_updates: userProfile.email_updates,
            labFirstName: userProfile.firstName,
            labLastName: userProfile.lastName,
            labName: userProfile.labName,
            address: userProfile.address,
            country,
            state,
            city: userProfile.city,
            postal_code: userProfile.postal_code,
            phone: userProfile.phone,
            email: userProfile.email,
            additional_emails: userProfile.additional_emails,
            additional_emails_case_assigned: userProfile.additional_emails_case_assigned,
            website: userProfile.website,
            username: userProfile.username,
            magictouch_enabled: userProfile?.mt_enabled,
			magictouch_token: userProfile?.mt_token,
            magictouch_default_labid: userProfile?.mt_default_labid,
            auto_credit_renew: userProfile.auto_credit_renew == '1',
            auto_credit_value: userProfile.auto_credit_value ?? 0
        });
    }
    componentDidUpdate(prevProps) {
        const { userProfile, states, countries, getStates, magictouch_labs } = this.props;
        const { states: prevStates } = prevProps;
        const { countryChanged,  magictouch_enabled, magictouch_token, magictouch_defaults_expanded } = this.state;

        if (states && states.length && ((!prevStates || (prevStates && !prevStates.length)) || (prevStates && prevStates.length && prevStates[0].id !== states[0].id))) {
            const targetState = countryChanged ? states[0].id : getTargetState(states, userProfile.state);
            const state = (targetState || targetState === 0) && !isNaN(targetState) ? targetState : states[0].id;

            this.setState({ state });
        }

        if ((!prevProps.countries || (prevProps.countries && !prevProps.countries.length))) {
            if (countries && countries.length) {
                const targetCountry = getTargetCountry(countries, userProfile.country);
                const country = targetCountry ? targetCountry : countries[0].id;

                this.setState({ country });
                if (country <= 2) {
                    getStates(country);
                } else {
                    this.setState({ state: userProfile.state });
                }
            }
        }

        if ( magictouch_labs != prevProps.magictouch_labs &&  magictouch_defaults_expanded && (!magictouch_enabled ||
            !magictouch_token ||
            !magictouch_labs ||
            magictouch_labs.length === 0) ) {
                this.setState({
                    magictouch_defaults_expanded: false
                })
            }
    }
    handleSave = () => {
        const { userProfile } = this.props;
        const { password, confirmPassword, countryChanged, ...payload } = this.state;

        if (+userProfile.subscription_type !== 1) {
            if (password && password === confirmPassword) {
                payload.password = password;
            }
        }
        this.props.updateLabProfile(payload);
    };
    firstNameChangeHandler = labFirstName => {
        this.setState({ labFirstName });
    };
    lastNameChangeHandler = labLastName => {
        this.setState({ labLastName });
    };
    nameChangeHandler = labName => {
        this.setState({ labName });
    };
    addressChangeHandler = address => {
        this.setState({ address });
    };
    cityChangeHandler = city => {
        this.setState({ city });
    };
    postalCodeChangeHandler = postal_code => {
        this.setState({ postal_code });
    };
    phoneChangeHandler = phone => {
        this.setState({ phone });
    };
    emailChangeHandler = email => {
        this.setState({ email });
    };
    additionalEmailChangeHandler = additional_emails => {
        this.setState({ additional_emails });
    };
    additionalEmailCaseAssignChangeHandler = additional_emails_case_assigned => {
        this.setState({ additional_emails_case_assigned });
    };
    websiteChangeHandler = website => {
        this.setState({ website });
    };
    usernameChangeHandler = username => {
        this.setState({ username });
    };
    passwordChangeHandler = password => {
        this.setState({ password });
    };
    confirmPasswordChangeHandler = confirmPassword => {
        this.setState({ confirmPassword });
    };
    countryChangeHandler = country => {
        this.setState({ country, state: '', countryChanged: true });
        this.handleGetStates(country);
    };
    stateChangeHandler = state => {
        this.setState({ state });
    };
    handleGetStates = country => {
        const { getStates, setStates } = this.props;

        if (country <= 2) {
            getStates(country);
        } else {
            setStates([]);
            this.setState({ state: '' });
        }
    };
    getCountryOptions = () => {
        const { countries } = this.props;
        return countries ? countries.map(country => ({ ...country, value: country.id })) : [];
    };
    getStateOptions = () => {
        const { states } = this.props;
        return states ? states.map(state => ({ ...state, value: state.id })) : [];
    };
    handleCancel = () => {
        this.setState({ showCancelConfirmDialog: true });
    };
    cancelEditing = () => {
        this.closeCancelConfirmDialog();
        this.props.history.goBack();
    };
    closeCancelConfirmDialog = () => {
        this.setState({ showCancelConfirmDialog: false });
    };

    magicTouchTokenChangeHandler = (magictouch_token) => {
		this.setState({ magictouch_token });
	};
    getMTLabs = (mt_labs) => {
		
		if (!mt_labs) return [];
		let list = mt_labs.map((d) => {
			return {
				value: d.labID,
				name: d.labID + ' - ' + d.labFullName,
			};
		});
		
		return list;
	};

	onMagicTouchTokenValidate = () => {
		const { getMagicTouchLabsList, magictouch_labs } = this.props;
		const { magictouch_token } = this.state;

		if (magictouch_token || magictouch_labs?.length > 0) {
			getMagicTouchLabsList(magictouch_token);
		}
        else{
            this.setState({
                magictouch_defaults_expanded: false
            })
        }


	};

	onMagicTouchDefaultsExpanded = (e, expanded) => {
		this.setState({
			magictouch_defaults_expanded: expanded,
		});
	};

    defaultMTLabChangeHandler = (labID) => {
        this.setState({
            magictouch_default_labid: labID
        })
    }

    renderDentistList = (toPrint) => {
        const { userProfile: { dentists } } = this.props;

        const mainDentists = dentists?.filter(d => ( d.idx == 0 || d.idx == null ))

        return mainDentists?.sort(this.sortDentistsList).map(({ first_name, last_name, id, validThrough, idx }) => {
            let isExpired = Date.parse(validThrough) < Date.now();
            return (
                <div key={id+idx} className="dentist">
                    <label className="name">{[last_name, first_name].join(' ')}</label>
                    <div className="dentist-list-date-button">
                        <label className={'dentist-list-label-date ' + (isExpired ? 'expired' : '')} >{(isExpired ? 'Expired ' : '') + validThrough}</label>
                        {!toPrint && <button type="button" className="delete-dentist-button" onClick={() => this.deleteDentist(id)}>Delete</button>}
                    </div>
                </div>
            );
        })

    }

    changeSortDate = () => {
        let {sortDentistsFirst, sortDentistsSecond} = this.state;

        if (sortDentistsFirst === this.SORT_NAME_ASC || sortDentistsFirst === this.SORT_NAME_DESC) {
            sortDentistsSecond = sortDentistsFirst;
        }

        if (sortDentistsFirst === this.SORT_DATE_ASC || sortDentistsFirst === this.SORT_DATE_DESC) {
            sortDentistsFirst = -sortDentistsFirst;
        }
        else {
            sortDentistsFirst = this.SORT_DATE_ASC;
        }

        this.setState({
            sortDentistsFirst,
            sortDentistsSecond
        })

    }

    changeSortName = () => {
        let {sortDentistsFirst, sortDentistsSecond} = this.state;

        if (sortDentistsFirst === this.SORT_DATE_ASC || sortDentistsFirst === this.SORT_DATE_DESC) {
            sortDentistsSecond = sortDentistsFirst;
        }

        if (sortDentistsFirst === this.SORT_NAME_ASC || sortDentistsFirst === this.SORT_NAME_DESC) {
            sortDentistsFirst = -sortDentistsFirst;
        }
        else {
            sortDentistsFirst = this.SORT_NAME_ASC;
        }

        this.setState({
            sortDentistsFirst,
            sortDentistsSecond
        })

    }

    exportDentistList = () => {
        this.setState({
            isOpenExportDentists: true
        })
    }

    onCloseExportDentistsDialog = () => {
        this.setState({
            isOpenExportDentists: false
        })
    }

    downloadExportDentists = (from, to) => {
        const {generateExportDentists} = this.props;
        this.onCloseExportDentistsDialog();
        generateExportDentists(from, to);
    }

    renderExportDentistsDialog() {
        const { isOpenExportDentists} = this.state;
        console.log('isOpenExportDentists', isOpenExportDentists);
        return <ExportConnectedDentists onClose={this.onCloseExportDentistsDialog} isOpenDialog={isOpenExportDentists} onConfirm={this.downloadExportDentists} />
    }


    renderMoreInformation = () => {
        const { userProfile: { dentists, customerId, validThrough } } = this.props;
        const {sortDentistsFirst, sortDentistsSecond} = this.state;

        return (
            <div className="more-information">
                <Divider variant="fullWidth" className="horizontal-line" />
                <div className="customer-number-container">
                    <label className="title">Your Customer Number</label>
                    <label className="value">{customerId}</label>
                </div>
                <div className="renewal-date-container">
                    <label className="title">Renewal date</label>
                    <label className="value">{validThrough}</label>
                </div>
                <Divider variant="fullWidth" className="horizontal-line" />
                <div className="connected-dentists-container">
                    <div className={'dentist'}>
                        <label className="title">Your connected dentists</label>
                        <div>
                            <IconButton className={'dentists-print-icon'} color="primary" aria-label="Print list" component="span" onClick={this.changeSortDate}>
                                { (sortDentistsFirst === this.SORT_DATE_DESC || sortDentistsSecond === this.SORT_DATE_DESC) ? <IconSortDateDesc /> : <IconSortDateAsc /> }
                            </IconButton>
                            <IconButton className={'dentists-print-icon'} onClick={this.changeSortName} color="primary" component="span">
                                { (sortDentistsFirst === this.SORT_NAME_DESC || sortDentistsSecond === this.SORT_NAME_DESC) ? <IconSortNameDesc /> : <IconSortNameAsc /> }
                            </IconButton>
                            <ReactToPrint
                                content={() => this.printRef}
                                trigger={() => {
                                    return <IconButton className={'dentists-print-icon'} color="primary" aria-label="Print list" component="span" >
                                        <PrintIcon fontSize="large"/>
                                    </IconButton>
                                }}
                                copyStyles={true}
                                onBeforeGetContent={this.handleBeforePrint}
                                removeAfterPrint={true} />
                            <IconButton onClick={this.exportDentistList} className={'dentists-print-icon'} color="primary" aria-label="Export list" component="span" >
                                <GetAppIcon fontSize="large"/>
                            </IconButton>
                        </div>
                    </div>
                    {this.renderDentistList(false)}
                </div>
                <div style={{ overflow: 'hidden', height: 0 }} >
                    <div className={'connected-dentists-container'} ref={el => (this.printRef = el)}>
                        <div className="dentist">
                            <label className="title">List of Connected Dentists</label>
                        </div>
                        {this.renderDentistList(true)}
                    </div>
                </div>
            </div>
        );
    };
    deleteDentist = selectedDentistId => {
        this.setState({
            selectedDentistId,
            showDeleteDentistConfirmDialog: true,
        });
    };
    closeDeleteDentistConfirmDialog = () => {
        this.setState({
            selectedDentistId: null,
            showDeleteDentistConfirmDialog: false,
        });
    };
    confirmDeleteDentist = () => {
        this.props.deleteDentist(this.state.selectedDentistId);
        this.closeDeleteDentistConfirmDialog();
    };
    sortDentistsList = (v1, v2) => {
        const { sortDentistsFirst, sortDentistsSecond } = this.state

        let v1name = (v1.last_name + v1.first_name).toString();
        let v2name = (v2.last_name + v2.first_name).toString();
        
        let compareResult = sortDentistsFirst;

        // if values are equal by first condition, sort by second condition
        if ( ( ( Math.abs(sortDentistsFirst) == 1) && (v1.validThrough == v2.validThrough) && (Math.abs(sortDentistsSecond) == 2)) ||
        ( ( Math.abs(sortDentistsFirst) == 2) && (v1name == v2name) && (Math.abs(sortDentistsSecond) == 1) ) ) { 
            compareResult = sortDentistsSecond; 
        }

        switch (Math.abs(compareResult)) {
            case 1:
                return (v1.validThrough > v2.validThrough) ? compareResult : -compareResult; // compare dates and multiply by positive or negative value (asc or desc)
            case 2:
                return v1name.localeCompare(v2name) * compareResult ; // compare strings and multiply by positive or negative value (asc or desc)
            default:
                return 0; 
        }

        
    }

    closeAutoRenewWarningDialog = () => {
        this.setState({
            showAutoRenewWarningDialog: false
        })
        window.open('https://store.shadewave.com/index.php?route=product/product&product_id=63', '_blank', 'noreferrer')
    }

    

    render() {
        const { userProfile, magictouch_labs } = this.props;
        const {
            email_updates,
            login_as_user,
            country,
            state,
            labFirstName,
            labLastName,
            labName,
            address,
            city,
            postal_code,
            phone,
            email,
            additional_emails,
            additional_emails_case_assigned,
            website,
            username,
            password,
            confirmPassword,
            showCancelConfirmDialog,
            showDeleteDentistConfirmDialog,
            showAutoRenewWarningDialog,
            magictouch_enabled,
			magictouch_token,
			magictouch_default_labid,
			magictouch_defaults_expanded,
            auto_credit_renew,
            auto_credit_value
        } = this.state;

        const slider_marks = [
            {value: 100, label: '100'}, {value: 200, label: '200'}, {value: 300, label: '300'}, {value: 400, label: '400'}, {value: 500, label: '500'}
        ]
        
        return (
            <div className="light-page edit-lab-profile-container">
                <Header theme={themeLight} />
                <div className="edit-lab-title">
                    <label>Edit Lab Profile</label>
                </div>
                <Form className={'edit-lab-profile'} onSubmit={this.handleSave} isAjax={true}>
                    <div className={'fields'}>
                        <div className="profile-info">
                            <InputText name={'firstName'} onChange={this.firstNameChangeHandler} value={labFirstName} placeholder={'First Name'} isRequired={true} />
                            <InputText name={'lastName'} onChange={this.lastNameChangeHandler} value={labLastName} placeholder={'Last Name'} isRequired={true} />
                            <InputText name={'name'} onChange={this.nameChangeHandler} value={labName} placeholder={'Lab Name'} isRequired={true} />
                            <InputText name={'address'} onChange={this.addressChangeHandler} value={address} placeholder={'Address'} isRequired={true} />
                            <Select disableAutocomplete={true} placeholder={'Country'} onChange={this.countryChangeHandler} options={this.getCountryOptions()} value={country} isRequired={true} />
                            {((country || country === 0) && country <= 2) ?
                                <Select disableAutocomplete={true} placeholder={'State/ Province'} onChange={this.stateChangeHandler} options={this.getStateOptions()} value={state} isDisabled={!country} isRequired={true} /> :
                                <InputText name={'state'} onChange={this.stateChangeHandler} value={state} placeholder={'State / Province'} isRequired={true} />}
                            <InputText name={'city'} onChange={this.cityChangeHandler} value={city} placeholder={'City'} isRequired={true} />
                            <InputText name={'postalCode'} onChange={this.postalCodeChangeHandler} value={postal_code} placeholder={'Postal Code'} isRequired={true} />
                            <InputText name={'phone'} onChange={this.phoneChangeHandler} value={phone} placeholder={'Phone'} isRequired={true} />
                            <InputEmail name={'email'} onChange={this.emailChangeHandler} value={email} placeholder={'Email Address'} isRequired={true} />
                            <label className={'additional-email-case-assign update'}>Additional Emails When Case Is Created Or Updated  Or License Renewed:</label>
                            <InputEmail name={'additionalEmails'} onChange={this.additionalEmailChangeHandler} value={additional_emails} placeholder={'Email Address'} isMultiple={true} />
                            <label className={'additional-email-case-assign'}>Additional Emails When Case Is Assigned:</label>
                            <InputEmail name={'additionalEmailsCaseAssign'} onChange={this.additionalEmailCaseAssignChangeHandler} value={additional_emails_case_assigned} placeholder={'Email Address'} isMultiple={true}/>

                            <Checkbox
								onChange={() => this.setState({ auto_credit_renew: !auto_credit_renew })}
								isChecked={auto_credit_renew}
							>
								Auto Renew Use Case Credits
							</Checkbox>
                            { auto_credit_renew && !userProfile.has_payment_profile &&
                                <Link 
                                    //href="https://store.shadewave.com/index.php?route=product/product&product_id=63"
                                    href="#"
                                    //target="_blank"
                                    //rel="noreferrer" 
                                    color="error"
                                    variant="body1"
                                    onClick={() => {
                                        this.setState({
                                            showAutoRenewWarningDialog: true
                                        })
                                    }}
                                    >
                                    Important notice, click to view
                                </Link>
                            }
                            <label className={'additional-email-case-assign'}>Auto Renew Case Credits Package Size:</label>
                            <Slider
                                className='slider'
                                defaultValue={
                                    auto_credit_value
                                }
                                key={`slider-${auto_credit_value}`} 
                                aria-labelledby='discrete-slider'
                                valueLabelDisplay='off'
                                step={100}
                                marks={slider_marks}
                                min={100}
                                max={500}
                                onChangeCommitted={(e, val) =>
                                    this.setState({ auto_credit_value: val})
                                }
                            />
                            
                        { false && <div>
                            <Checkbox
								onChange={() => this.setState({ magictouch_enabled: !magictouch_enabled })}
								isChecked={magictouch_enabled}
							>
								MagicTouch Integration
							</Checkbox>
							<InputText
								name={'magicTouchToken'}
								onChange={this.magicTouchTokenChangeHandler}
								value={magictouch_token}
								placeholder={'MagicTouch API Token'}
								isDisabled={!magictouch_enabled}
								onValidate={this.onMagicTouchTokenValidate}
							/>
							<Accordion
								disabled={
									!magictouch_enabled ||
									!magictouch_token ||
									!magictouch_labs ||
									magictouch_labs.length === 0
								}
								expanded={magictouch_defaults_expanded}
								onChange={this.onMagicTouchDefaultsExpanded}
							>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls='panel-mt-defaults-content'
									id='panel-mt-defaults-header'
								>
									<Typography className={''}>MagicTouch Defaults</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<div className={'more-doctors-form'}>
										<Select
											isRequired={false}
											placeholder={'Default Lab'}
											onChange={this.defaultMTLabChangeHandler}
											options={this.getMTLabs(magictouch_labs)}
											value={magictouch_default_labid}
											isDisabled={
												!magictouch_enabled ||
												!magictouch_token ||
												!magictouch_labs ||
												magictouch_labs.length === 0
											}
										/>
									</div>
								</AccordionDetails>
							</Accordion>
                            </div>
                            }
                        </div>
                        <div className="additional-info">
                            <InputText className={'website'} name={'website'} onChange={this.websiteChangeHandler} value={website} placeholder={'Web Address'} />
                            <InputText name={'username'} onChange={this.usernameChangeHandler} value={username} placeholder={'Choose Username'} isRequired={true} />

                            {userProfile && +userProfile.subscription_type === 1 ?
                                <InputText name={'pass'} onChange={() => { }} value={'Use password from store.shadewave.com'} placeholder={'Password'} isDisabled={true} /> :
                                <div className="passwords-group">
                                    <InputPassword name={'password'} autocomplete={'new-password'} onChange={this.passwordChangeHandler} value={password} placeholder={'Password'} isRequired={false} />
                                    <InputPassword name={'confirmPassword'} autocomplete={'new-password'} onChange={this.confirmPasswordChangeHandler} value={confirmPassword} placeholder={'Confirm Password'} isRequired={false} />
                                </div>
                            }
                            <Checkbox
                                className={'allow-access-checkbox'}
                                text={'Allow access to my account for website administrator'}
                                onChange={() => this.setState({ login_as_user: !login_as_user })}
                                isChecked={login_as_user} />
                            <Checkbox
                                className={'receive-email-checkbox'}
                                text={'I want to receive email updates'}
                                onChange={() => this.setState({ email_updates: !email_updates })}
                                isChecked={email_updates} />

                            <div className="more-information-container">{this.renderMoreInformation()}</div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="buttons-container">
                            <Button type={'submit'} className="save-button" text="SAVE" />
                            <GreyButton className="cancel-button" text="CANCEL" onClick={this.handleCancel} />
                        </div>
                        <SupportInfo />
                    </div>
                </Form>
                <ConfirmDialog
                    isOpen={showCancelConfirmDialog}
                    title={'Cancel editing'}
                    className={'cancel-editing-confirm-dialog'}
                    message={'Are you sure you want to leave without saving?'}
                    no={this.closeCancelConfirmDialog}
                    yes={this.cancelEditing}
                />
                <ConfirmDialog
                    isOpen={showDeleteDentistConfirmDialog}
                    title={'Delete Dentist'}
                    className={'remove-connected-dentist-confirm-dialog'}
                    message={'Are you sure you want to delete this dentist?'}
                    no={this.closeDeleteDentistConfirmDialog}
                    yes={this.confirmDeleteDentist}
                />
                <AlertDialog
                        message={"To enable auto-renew use case credits, please, buy the product Case Uses in Store (store.shadewave.com) with option Auto purchase enabled.<br/>In the Store use account with the same email as in your Shadewave profile - "+email+"."}
                        isOpen={showAutoRenewWarningDialog}
                        closeOnOutsideClick={true}
                        isLightMode={true}
                        handleClose={this.closeAutoRenewWarningDialog}
                        ok={this.closeAutoRenewWarningDialog} />
                { this.renderExportDentistsDialog() }

            </div>
        )
    }
}

EditLabProfile.propTypes = {
    userProfile: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    getStates: PropTypes.func.isRequired,
    states: PropTypes.array.isRequired,
    updateLabProfile: PropTypes.func.isRequired,
    getCountries: PropTypes.func.isRequired,
    setStates: PropTypes.func.isRequired,
    deleteDentist: PropTypes.func.isRequired,
    getMagicTouchLabsList: PropTypes.func.isRequired,
	magictouch_labs: PropTypes.array.isRequired,
};

export default withRouter(EditLabProfile);

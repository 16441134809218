import React from "react";
import PropTypes from 'prop-types';
import Header from '../containers/Header';
import Form from './elements/Form';
import InputAutocomplete from './elements/form/input/InputAutocomplete';
import Button from './elements/form/Button';
import Table, { Header as TableHeader, Body, Cell, Row } from './elements/Table';
import { CASES_LOADER_COMPONENT_NAME, CASES_LOAD } from '../constants/loaderText';
import ElementLoader from './elements/loader/ElementLoader';
import { NavLink } from "react-router-dom";
import Select from "./elements/form/Select";
import PageWithSupportFooter from './elements/PageWithSupportFooter';
import ConfirmDialog from './elements/ConfirmDialog';
import AlertDialog from './elements/AlertDialog';
import { initialCasesAmount, casesLoadAmount } from '../config/lookup';
import { themeLight } from '../config/app';
import BackupDialog from "./elements/BackupDialog";
import EditCaseLookupDialog from "./elements/EditCaseLookupDialog";

const selectPlaceholder = '- None -';
const updateTypeLab = 'Lab';
const updateTypeSpecialist = 'Specialist';

const loadTypeLookup = 'lookup';
const loadTypeAll = 'all';

export default class Lookup extends React.PureComponent {
    state = {
        loadType: loadTypeAll,
        customerId: '',
        userPhone: '',
        patientFirstName: '',
        patientLastName: '',
        doctorFirstName: '',
        doctorLastName: '',
        doctorFirst: '',
        doctorLast: '',
        patientId: '',
        altId: '',
        caseNumber: '',
        labName: '',
        specialistName: '',
        deleteCaseId: 0,
        updateCaseId: 0,
        updateName: '',
        updateType: '',
        updateId: 0,
        selectedId: 0,
        resetLabCaseId: 0,
        resetSpecialistCaseId: 0,
        isCasesLoaded: false,
        isInitialLoading: true,
        lastCaseId: 0,
        isCasesLoading: false,
        labByCase: {},
        statusCaseId: 0
    };

    static getDerivedStateFromProps(nextProps, prevState){
        const casesAmount = nextProps.cases.length;
        const isCasesLoading = nextProps.loaderComponent === CASES_LOADER_COMPONENT_NAME;

        if (casesAmount > 0 && isCasesLoading && !nextProps.isCasesLoading) {
            const lastCase = nextProps.cases[nextProps.cases.length - 1];

            let isCasesLoaded = casesAmount % casesLoadAmount !== 0 || nextProps.cases.length === 0 || prevState.lastCaseId === lastCase.id;

            return {...prevState, isCasesLoaded, casesLoaded: casesAmount, isCasesLoading, lastCaseId: lastCase ? lastCase.id : prevState.lastCaseId};
        }

        return null;
    }
    componentDidMount() {
        let { getConnectedSpecialists, getLabs, isDentist, loadCases } = this.props;

        if (isDentist) {
            getConnectedSpecialists();
            getLabs();
        }

        this.getOptions({});

        loadCases(initialCasesAmount);

        window.addEventListener('scroll', this.loadCases);
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.loadCases);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    finishEditAltId() {
        const caseId = this.state.altIdEditableId;
        this.setState({
            altIdEditableId: null
        });
        this.updateAltId(caseId, this.state.altIdValue);
        this.getOptions({});
    }

    handleClickOutside = (e) => {
        if (this.inputAltIDRef && !this.inputAltIDRef.contains(e.target)) {
            this.finishEditAltId();
        }
    }

    updateAltId = (caseId, altId) => {
        const {updateAlternativeId} = this.props
        updateAlternativeId(caseId, altId)
    }

    loadCases = () => {
        let { isCasesLoaded, loadType } = this.state;
        let { cases } = this.props;

        if (isCasesLoaded) {
            return false;
        }

        if (document.documentElement.offsetHeight - (document.documentElement.scrollTop + window.innerHeight) > 300) {
            return false;
        }

        if (this.isCasesLoading()) {
            return false;
        }

        let lastCase = cases[cases.length - 1];

        this.setState({
            isInitialLoading: false,
        });

        this.loadFilteredCases(loadType, lastCase ? lastCase.id : 0, lastCase ? lastCase.systemDate : null);
    };
    messageShown = () => {
        let { errorMessageShown } = this.props;

        errorMessageShown();
    };

    customerIdChangeHandler = (value) => {
        this.setState({customerId: value})
    };
    userPhoneChangeHandler = (value) => {
        this.setState({userPhone: value})
    };
    patientFirstChangeHandler = (value) => {
        this.setState({patientFirstName: value})
    };
    patientLastChangeHandler = (value) => {
        this.setState({patientLastName: value})
    };
    doctorFirstNameChangeHandler = (value) => {
        this.setState({doctorFirstName: value});
        this.getOptions({doctorFirstName: value});
    };
    doctorLastNameChangeHandler = (value) => {
        this.setState({doctorLastName: value});
        this.getOptions({doctorLastName: value});
    };
    doctorFirstChangeHandler = (value) => {
        this.setState({doctorFirst: value});
    };
    doctorLastChangeHandler = (value) => {
        this.setState({doctorLast: value});
    };
    patientIdHandler = (value) => {
        this.setState({patientId: value})
    };
    altIdHandler = (value) => {
        this.setState({altId: value})
    }
    caseNumberHandler = (value) => {
        this.setState({caseNumber: value})
    };
    labNameHandler = (value) => {
        this.setState({labName: value});
        this.getOptions({labName: value});
    };
    specialistNameHandler = (value) => {
        this.setState({specialistName: value})
    };
    loadFilteredCases = (loadType, lastCaseId, lastDate) => {
        let { loadCases } = this.props;

        if (!loadType) {
            loadType = this.state.loadType;
        }

        const limit = initialCasesAmount;

        if (loadType === loadTypeAll) {
            loadCases(limit, {}, lastCaseId, lastDate);
        } else {
            let { patientFirstName, patientLastName, doctorLastName, doctorFirstName, patientId, caseNumber, labName, specialistName, altId, doctorFirst, doctorLast } = this.state;

            let terms = {
                patientFirstName,
                patientLastName,
                doctorFirstName,
                doctorLastName,
                patientId,
                caseNumber,
                userName: labName,
                specialistName,
                altId,
                doctorFirst,
                doctorLast
            };

            loadCases(limit, terms, lastCaseId, lastDate);
        }
    };
    searchHandler = () => {
        this.setState({
            loadType: loadTypeLookup,
            isInitialLoading: true,
        });

        this.loadFilteredCases(loadTypeLookup);
    };
    viewAllHandler = () => {
        this.setState({
            loadType: loadTypeAll,
            isInitialLoading: true,
        });

        this.loadFilteredCases(loadTypeAll);
    };
    scrollHandler = (e) => {
        /*let { casesLoaded, content } = this.state;

        const table = e.target;

        if (table.scrollTop + table.clientHeight + 50 > content.clientHeight) {
            this.setState({casesLoaded: casesLoaded + viewCaseShowLimit})
        }*/
    };

    labChangeHandler = (caseId, value) => {
        let { setCaseLab } = this.props;

        setCaseLab(caseId, value);
    };
    specialistChangeHandler = (caseId, value) => {
        let { setCaseSpecialist } = this.props;

        setCaseSpecialist(caseId, value);
    };

    onChangeStatus = (caseId, fromStatus) => {
        this.setState({
            fromStatus: fromStatus,
            statusCaseId: caseId
        })
    }

    renderSpecialistSelect = (caseId, selectedSpecialist) => {
        let { resetSpecialistCaseId } = this.state;
        let { specialists, specialistErrorCaseIds, specialistByCaseUpdated } = this.props;

        let options = [
            {
                value: '0',
                name: selectPlaceholder,
            }
        ];
        specialists.forEach(specialist => {
            options.push({
                value: specialist.user_id.toString(),
                name: specialist.name
            });
        });

        

        let selectValue = (typeof specialistByCaseUpdated[caseId] !== 'undefined' ? specialistByCaseUpdated[caseId] : selectedSpecialist);
        
        if (!selectValue) {
            selectValue = 0;
        }
        return <Select
            hideLabel={true}
            isId={false}
            isReset={specialistErrorCaseIds.includes(caseId.toString()) || resetSpecialistCaseId === caseId}
            placeholder={selectPlaceholder}
            options={options}
            onChange={(value) => {
                const specialist = specialists.find(specialist => specialist.id === value);
                const selectedSpecialistObj = specialists.find(specialist => specialist.id === selectedSpecialist);

                this.setUpdateState(caseId, updateTypeSpecialist, specialist ? specialist.name : '', value, selectedSpecialistObj ? selectedSpecialist : 0);
                //this.specialistChangeHandler(caseId, value)
            }}
            value={selectValue.toString()}/>
    };
    renderLabSelect = (caseId, selectedLab) => {
        let { resetLabCaseId } = this.state;
        let { labs, labErrorCaseIds, labByCaseUpdated } = this.props;

        let options = [
            {
                value: '0',
                name: selectPlaceholder,
            }
        ];
        labs.forEach(lab => {
            options.push({
                value: lab.user_id,
                name: lab.name
            });
        });

        let selectValue = (typeof labByCaseUpdated[caseId] !== 'undefined' ? labByCaseUpdated[caseId] : selectedLab) ;
        if (!selectValue) {
            selectValue = 0;
        }
        return (
            <Select
                isId={false}
                hideLabel={true}
                isReset={labErrorCaseIds.includes(caseId.toString()) || resetLabCaseId === caseId}
                placeholder={selectPlaceholder}
                options={options}
                onChange={(value) => {
                    const lab = labs.find(lab => lab.user_id === value);
                    const selectedLabObj = labs.find(lab => lab.user_id === selectedLab);

                    this.setUpdateState(caseId, updateTypeLab, lab ? lab.name : '', value, selectedLabObj ? selectedLab : 0);
                    //this.labChangeHandler(caseId, value)
                }}
                value={selectValue.toString()}/>
        )
    };
    setUpdateState = (updateCaseId, updateType, updateName, updateId, selectedId) => {
        // eslint-disable-next-line
        if (updateId == selectedId) {
            return false;
        }
        this.setState({
            updateCaseId,
            updateType,
            updateName,
            updateId,
            selectedId,
        });
    };
    renderResizeElement = (content, className) => {
        return <div className={`resize-element ${className}`}>{content}</div>
    };
    isCasesLoading = () => {
        let { loaderComponent } = this.props;

        return loaderComponent === CASES_LOADER_COMPONENT_NAME;
    };
    renderCasesLoader = () => {
        let { isInitialLoading } = this.state;

        if (isInitialLoading) {
            return false;
        }

        if (!this.isCasesLoading()) {
            return false;
        }

        return <img alt={'Loader'} className={'cases-loader'} src={require('../assets/images/loader-horizontal-green.gif')}/>
    };
    renderCases = (cases) => {
        let { isInitialLoading } = this.state;
        let { isDentist, hasAscendCase } = this.props;

        if (this.isCasesLoading() && isInitialLoading) {
            return (
                <ElementLoader isLoading={true} percent={75}>
                    {CASES_LOAD}
                </ElementLoader>
            )
        }

        if (cases.length === 0) {
            return false;
        }

        let headerFields = [
            {className: 'date', title: 'Date'},
            {className: 'doctor-first', title: 'Doctor First Name'},
            {className: 'doctor-last', title: 'Doctor Last Name'},
            {className: 'patient-first', title: 'Patient First Name'},
            {className: 'patient-last', title: 'Patient Last Name'},
            
        ];

        if (isDentist) {
            headerFields = [...headerFields,
                {className: 'lab', title: 'Lab'},
                {className: 'specialist', title: 'Specialist'},
            ]
        }

        

        headerFields = [...headerFields,
            {className: 'case', title: 'Case'},
            {className: 'alt-id', title: 'Alt ID'}

        ];

        if (hasAscendCase) {
            headerFields = [...headerFields,
                {className: 'ascend-case', title: ''},
            ]
        }

        headerFields = [...headerFields,
            {className: 'image', title: 'Image'},
            {className: 'status', title: 'Status'},
            {className: 'action', title: 'Edit/Delete'},
        ];

        return (
            <Table  className={'cases'} attributes={{
                onScroll: this.scrollHandler,
            }}>
                <TableHeader>
                    <Row>
                        {headerFields.map((field, index) => {
                            return <Cell className={field.className} key={index}>{field.title}</Cell>
                        })}
                    </Row>
                </TableHeader>
                <Body attributes={{
                    ref: (e) => {

                    }
                }}>
                {cases.map((caseDetails, index) => {
                    let imageLinks = [];

                    let i = 1;
                    caseDetails.photoIds.forEach((photoId, index) => {
                        imageLinks.push(<a key={index} className={'photo-link'} href={`${process.env.REACT_APP_API_URL}tool/flex-helper.php?action=getPhoto&id=${photoId}`}>{i}</a>);
                        i++
                    });
                    const altIdEditable = this.state.altIdEditableId === caseDetails.id
                    //console.log(caseDetails)
                    return (
                        <Row key={index}>
                            <Cell className={'date'}><div>{caseDetails.date}</div>{this.renderResizeElement(<NavLink to={`/case/${caseDetails.id}`}>{caseDetails.case}</NavLink>, 'case')}</Cell>
                            <Cell className={'doctor-first'}>{caseDetails.doctorFirst}{this.renderResizeElement(caseDetails.doctorLast, 'doctor-last')}</Cell>
                            <Cell className={'doctor-last'}>{caseDetails.doctorLast}</Cell>
                            <Cell className={'patient-first'}>{caseDetails.patientFirst}{this.renderResizeElement(caseDetails.patientLast, 'patient-last')}</Cell>
                            <Cell className={'patient-last'}>{caseDetails.patientLast}</Cell>
                            {isDentist ? <Cell className={'input-cell lab'}>{this.renderLabSelect(caseDetails.id, caseDetails.labId)}</Cell> : ''}
                            {isDentist ? <Cell className={'input-cell specialist'}>{this.renderSpecialistSelect(caseDetails.id, caseDetails.specialist)}</Cell> : ''}
                            <Cell className={'case'}><NavLink to={`/case/${caseDetails.id}`}>{caseDetails.case}</NavLink></Cell>
                            <Cell className={'alt-id ' + (altIdEditable?'input-cell':'')} 
                                handleClick={() => 
                                            {
                                                this.setState(
                                                    {
                                                        altIdEditableId: caseDetails.id, 
                                                        altIdValue: caseDetails.alt_id
                                                    })
                                            }}
                            >
                                { (altIdEditable) ? 
                                    <input
                                        autoFocus
                                        ref={(el) => {this.inputAltIDRef = el} }
									    required={false}
									    placeholder={'Alt ID'}
									    id={'alt_id_inpt'}
									    onChange={(e) => {
                                            this.setState(
                                                {
                                                    altIdValue : e.target.value
                                                })
                                        }}
									    value={this.state.altIdValue}	
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.finishEditAltId();
                                            }
                                        }}
								    />
                                    : caseDetails.alt_id }</Cell>
                            {hasAscendCase ? <Cell className={'ascend-case'}>{caseDetails.is_ascend ? <img className={'dentrix-image'} src={require('../assets/images/icons/dentrix-trans.png')} alt="Dentrix Case"/>: null}</Cell> : null }
                            <Cell className={'image'}>{imageLinks}</Cell>
                            <Cell className={'status'}><div className={'status ' + caseDetails.status} onClick={() => this.onChangeStatus(caseDetails.id, caseDetails.status)}>{caseDetails.status}</div></Cell>
                            <Cell className={'action'}><div onClick={() => {
                                this.setState({
                                    deleteCaseId: caseDetails.id,
                                })
                            }} className={'remove'}></div>
                            <div onClick={() => {
                                this.setState({
                                    caseEdit: caseDetails,
                                    isOpenEdit: true
                                })
                            }} className={'edit'}></div>
                            </Cell>
                        </Row>
                    )
                })}
                </Body>
            </Table>
        )
    };

    openBackupDialog = () => {
        this.props.backupUrlGenerated(null);
        this.setState(
            {
                isOpenBackup: true
            }
        );
    }

    renderResultTitle = (cases) => {
        if (cases.length === 0) {
            return false;
        }
        return (
            <div className={'lookup-title'}>
                <div>
                    <div className={'page-title'}>Result</div>
                    <div className={'sub-title'}>Case Look Up</div>
                </div>
                
                <Button type={'button'} onClick={this.openBackupDialog} className={'lookup-btn-backup'} >
                    <span>Download Backup</span>
                </Button>
                
            </div>
        )
    };
    getOptions = (overridenInput) => {
        let { autoCompleteSend, isDentist } = this.props;
        let { doctorFirstName, doctorLastName, labName} = this.state;

        let searchObj = Object.assign({
            doctorFirstName : doctorFirstName,
            doctorLastName: doctorLastName,
            labName : labName
        }, overridenInput);

        let search = isDentist ? searchObj.labName : (searchObj.doctorFirstName + ((searchObj.doctorLastName && searchObj.doctorLastName.length > 0) ? ('%' + searchObj.doctorLastName) : '')) ;
        autoCompleteSend(search, '');
    };

    deleteCase = () => {
        let { deleteCase } = this.props;
        let { deleteCaseId } = this.state;

        deleteCase(deleteCaseId);
        this.setState({deleteCaseId: 0})
    };
    resetUpdateState = (resetLabCaseId, resetSpecialistCaseId) => {
        let newState = {
            updateCaseId: 0,
        };

        if (resetLabCaseId > 0) {
            newState.resetLabCaseId = resetLabCaseId;
        }
        if (resetSpecialistCaseId > 0) {
            newState.resetSpecialistCaseId = resetSpecialistCaseId;
        }
        this.setState(newState);

        this.messageShown();
    };
    renderSpecialistLabConfirm = () => {
        let { updateCaseId, updateName, updateType, updateId, selectedId } = this.state;

        if (selectedId === '' || !selectedId) {
            selectedId = 0;
        }

        if (updateCaseId === 0 || updateId === selectedId) {
            return false;
        }

        const message = updateId === 0
            ? `Would you like to unassign ${updateType} from the case #${updateCaseId}`
            : `Would you like to assign ${updateType} ${updateName} to the case #${updateCaseId}`;

        return <ConfirmDialog
            isLightMode={true}
            title={`Set ${updateType} to the case`}
            message={message}
            yes={() => {
                switch (updateType) {
                    case updateTypeLab:
                        this.labChangeHandler(updateCaseId, updateId);
                        break;
                    default:
                        // specialist
                        this.specialistChangeHandler(updateCaseId, updateId);
                        break;
                }

                this.resetUpdateState();
            }}
            no={() => {
                switch (updateType) {
                    case updateTypeLab:
                        this.resetUpdateState(updateCaseId, 0);
                        break;
                    default:
                        // specialist
                        this.resetUpdateState(0, updateCaseId);
                        break;
                }

            }}
            isOpen={true}/>
    };
    renderChangeStatusConfirm = () => {
        let { statusCaseId, fromStatus } = this.state;
        const { markCaseComplete, markCaseNew } = this.props;

        if (statusCaseId === 0) {
            return false;
        }
        let newStatus = (fromStatus === 'new') ? 'completed' : 'new';

        return <ConfirmDialog
            isLightMode={true}
            title={'Change Case Status'}
            message={`Do you want to change status of the case to "${newStatus}"`}
            yes={() => {
                if (newStatus === 'new')
                    markCaseNew(statusCaseId)
                else
                    markCaseComplete(statusCaseId)
                this.setState({statusCaseId: 0})
            }}
            no={() => this.setState({statusCaseId: 0})}
            isOpen={true}/>
    };
    renderDeleteConfirm = () => {
        let { deleteCaseId } = this.state;

        if (deleteCaseId === 0) {
            return false;
        }

        return <ConfirmDialog
            isLightMode={true}
            title={'Delete case'}
            message={`Do you want to delete case #${deleteCaseId}`}
            yes={() => this.deleteCase(deleteCaseId)}
            no={() => this.setState({deleteCaseId: 0})}
            isOpen={true}/>
    };
    renderMessage = () => {
        let { message, messageTitle, messageType } = this.props;

        if (message === '') {
            return false;
        }

        return <AlertDialog title={messageTitle} className={`light ${messageType}`} message={message} ok={this.messageShown} isOpen={true}/>
    };

    
    onCloseBackupDialog = () => {
        this.setState({
            isOpenBackup: false
        })
        this.props.backupUrlGenerated(null)
    }
    

    renderBackup() {
        const { isOpenBackup} = this.state;

        return <BackupDialog onClose={this.onCloseBackupDialog} isOpenBackup={isOpenBackup} />
        
    }

    onCloseEditCaseDialog = () => {
        this.setState({
            isOpenEdit: false
        })
    }

    onSaveEditCaseDialog = (data) => {
        const { updateCaseDetails } = this.props;
        const { caseEdit } = this.state;
        this.setState({
            isOpenEdit: false
        })
        if (data.isPatientChanged || data.isDentistChanged)
            updateCaseDetails(caseEdit.id, data)
    }


    renderEditCaseDialog() {
        const { isOpenEdit, caseEdit} = this.state;
        const { isDentist } = this.props;

        return <EditCaseLookupDialog onClose={this.onCloseEditCaseDialog} isOpenDialog={isOpenEdit} onSave={this.onSaveEditCaseDialog} isAscend={caseEdit?.is_ascend} caseDetails={caseEdit} isDentist={isDentist}  />
        
    }

    render() {
        let { patientFirstName, patientLastName, doctorFirstName, doctorLastName, patientId, altId, caseNumber, labName, specialistName, doctorFirst, doctorLast } = this.state;
        let { cases, autoCompleteData, isDentist } = this.props;

        return (
            <div className={'light-page lookup'}>
                <Header theme={themeLight}/>

                {this.renderDeleteConfirm()}

                {this.renderSpecialistLabConfirm()}

                {this.renderMessage()}

                {this.renderBackup()}
                {this.renderEditCaseDialog()}
                {this.renderChangeStatusConfirm()}

                <PageWithSupportFooter className={'inner'}>
                    <div className={'page-title'}>Look Up Case</div>
                    <div className={'sub-title'}>Case Search</div>

                    <Form onSubmit={this.searchHandler} isAjax={true}>
                        <div className={'fields'}>
                            <InputAutocomplete placeholder={'Patient First Name'} onChange={this.patientFirstChangeHandler} name={'patient_first'} value={patientFirstName} results={autoCompleteData.patientFirstName ?? []}/>
                            {isDentist
                                ? <InputAutocomplete placeholder={'Lab Name'} onChange={this.labNameHandler} name={'lab_name'}  value={labName} results={autoCompleteData.userName ?? []}/>
                                : <InputAutocomplete placeholder={'Doctor First Name'} onChange={this.doctorFirstNameChangeHandler} name={'doctor_first'}  value={doctorFirstName} results={autoCompleteData.userName ?? []}/>
                            }

                            <InputAutocomplete halfWidth={true} placeholder={'Patient ID'} name={'patient_id'} onChange={this.patientIdHandler} value={patientId} results={autoCompleteData.patientId ?? []}/>
                            <InputAutocomplete halfWidth={true} placeholder={'Alt ID'} name={'alt_id'} onChange={this.altIdHandler} value={altId} results={autoCompleteData.altId ?? []}/>

                            <InputAutocomplete placeholder={'Patient Last Name'} name={'patient_last'} onChange={this.patientLastChangeHandler} value={patientLastName}  results={autoCompleteData.patientLastName ?? []}/>
                            {isDentist
                                ? <InputAutocomplete placeholder={'Specialist Name'} name={'specialist_name'} onChange={this.specialistNameHandler} value={specialistName} results={autoCompleteData.specialistName ?? []}/>
                                : <InputAutocomplete placeholder={'Doctor Last Name'} name={'doctor_last'} onChange={this.doctorLastNameChangeHandler} value={doctorLastName}  results={autoCompleteData.userLastName ?? []}/>
                            }
                            <InputAutocomplete placeholder={'Case Number'} name={'case_number'} onChange={this.caseNumberHandler} value={caseNumber} results={autoCompleteData.caseNumber ?? []}/>
                            {isDentist &&
                                 <InputAutocomplete placeholder={'Doctor First Name'} onChange={this.doctorFirstChangeHandler} name={'doctor_first'}  value={doctorFirst} results={autoCompleteData.doctorFirst ?? []}/>
                            }
                            {isDentist &&
                                 <InputAutocomplete placeholder={'Doctor Last Name'} onChange={this.doctorLastChangeHandler} name={'doctor_last'}  value={doctorLast} results={autoCompleteData.doctorLast ?? []}/>
                            }
                        </div>

                        <div className={'row row-buttons'}>
                            <div className={'button-container'}>
                                <Button type={'submit'}>
                                    <img src={require('../assets/images/icons/search.png')} alt={'Search'}/>

                                    <span>Look up</span>
                                </Button>
                            </div>
                            <div className={'button-container'}>
                                <Button type={'button'} onClick={this.viewAllHandler} className={'transparent'}>
                                    <span>VIEW ALL CASES</span>
                                </Button>
                            </div>
                        </div>
                    </Form>

                    <div className={'results'}>
                    {this.renderResultTitle(cases)}
                    {this.renderCases(cases)}
                    {this.renderCasesLoader()}
                </div>

                </PageWithSupportFooter>
            </div>
        )
    }
}

Lookup.propTypes = {
    specialistErrorCaseIds: PropTypes.array.isRequired,
    labErrorCaseIds: PropTypes.array.isRequired,
    cases: PropTypes.array.isRequired,
    loadCases: PropTypes.func.isRequired,
    getLabs: PropTypes.func.isRequired,
    errorMessageShown: PropTypes.func.isRequired,
    autoCompleteSend: PropTypes.func.isRequired,
    setCaseLab: PropTypes.func.isRequired,
    setCaseSpecialist: PropTypes.func.isRequired,
    getConnectedSpecialists: PropTypes.func.isRequired,
    labs: PropTypes.array.isRequired,
    specialists: PropTypes.array.isRequired,
    isDentist: PropTypes.bool.isRequired,
    isLab: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    labByCaseUpdated: PropTypes.object.isRequired,
    specialistByCaseUpdated: PropTypes.object.isRequired,
    hasAscendCase: PropTypes.bool.isRequired,
    updateAlternativeId: PropTypes.func.isRequired,
    backupUrlGenerated: PropTypes.func.isRequired,
    
};

import React from "react";
import PropTypes from "prop-types";
import MatDialog, { MatDialogContent, MatDialogHeader } from "./DraggableDialog";
import Button from '../elements/form/Button';

export default class AlertDialog extends React.PureComponent {
    handleClose = () => {
        const { handleClose } = this.props;

        if (typeof handleClose === 'function') {
            handleClose();
        }
    };
    renderMessage = () => {
        const { message } = this.props;

        if (typeof message === 'string' && typeof message.split === 'function') {
            return message.split('<br/>').map(s => {
                let ss = s + '\n';
                return ss;
            });
        }
        return message;
    };

    render() {
        const { isOpen, ok, className, hideOkButton, isLightMode, closeOnOutsideClick, title } = this.props;

        return (
            <MatDialog
                isOpen={isOpen ?? false}
                onClose={this.handleClose}
                closeOnOutsideClick={closeOnOutsideClick}
                classes={{
                    container: 'overlay',
                    paper: `modal shadewave-alert-dialog ${className ? className : ''} ${isLightMode ? 'light' : 'dark'}`,
                }}>
                {title ? <MatDialogHeader onClose={ok}>{title}</MatDialogHeader> : false}
                <MatDialogContent>
                    <div className="message-container">
                        <label className="message">
                        {this.renderMessage()}
                        </label>
                    </div>
                    {!hideOkButton && <div className="footer">
                        <Button type={'button'} onClick={ok}>OK</Button>
                    </div>}
                </MatDialogContent>
            </MatDialog>
        )
    }
}

AlertDialog.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node,]).isRequired,
    ok: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
    hideOkButton: PropTypes.bool,
    handleClose: PropTypes.func,
    isLightMode: PropTypes.bool,
    closeOnOutsideClick: PropTypes.bool,
};

import React from "react";
import PropTypes from 'prop-types';
import { typeBlankLink } from "../../config/leftMenu";

export default class LeftMenuButton extends React.PureComponent {
    renderContent = () => {
        let { text, icon, } = this.props;

        return (
            <div>
                <img src={icon} alt={text}/>
                <span className={'text'}>{text}</span>
            </div>
        )
    };
    render() {
        let { onClick, isActive, isDisabled, type, action, isLast } = this.props;

        const containerClass = `button ${isDisabled ? 'disabled' : (isActive ? 'active' : '')} ${isLast ? 'last' : ''}`;

        switch (type) {
            case typeBlankLink:
                return (
                    <a rel={'noopener noreferrer'} target={'_blank'} className={containerClass} href={action}>
                        {this.renderContent()}
                    </a>
                );
            default:
                return (
                    <div className={containerClass} onClick={isDisabled ? null : onClick} >
                        {this.renderContent()}
                    </div>
                );
        }
    }
}

LeftMenuButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    icon: PropTypes.node.isRequired,
    type: PropTypes.string,
    action: PropTypes.string,
    isDisabled: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
};

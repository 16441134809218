import { createSelector } from 'reselect'
import { isStateExists } from '../helpers/state';

const getLookup = state => state.lookup;

export const getAutoCompleteData = createSelector(
    [getLookup],
    (lookupState) => {
        if (!isStateExists(lookupState) || typeof lookupState.autoCompleteData === 'undefined') {
            return {};
        }

        return lookupState.autoCompleteData;
    }
);

export const getLabErrorCaseIds = createSelector(
    [getLookup],
    (lookupState) => {
        if (!isStateExists(lookupState)) {
            return [];
        }

        return lookupState.labErrorCaseIds;
    }
);

export const getSpecialistErrorCaseIds = createSelector(
    [getLookup],
    (lookupState) => {
        if (!isStateExists(lookupState)) {
            return [];
        }

        return lookupState.specialistErrorCaseIds;
    }
);

export const getLabByCaseUpdated = createSelector(
    [getLookup],
    (lookupState) => {
        if (!isStateExists(lookupState)) {
            return {};
        }

        return lookupState.labByCaseUpdated;
    }
);

export const getSpecialistByCaseUpdated = createSelector(
    [getLookup],
    (lookupState) => {
        if (!isStateExists(lookupState)) {
            return {};
        }

        return lookupState.specialistByCaseUpdated;
    }
);

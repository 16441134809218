import React from "react";
import PropTypes from "prop-types";
import addIcon from "../../assets/images/icons/add.svg";
import GreenButton from "../elements/form/buttons/GreenButton";
import MatDialog, { MatDialogHeader, MatDialogContent } from '../elements/DraggableDialog';
import Select from '../elements/form/Select';
import InputText from '../elements/form/input/InputText';
import InputEmail from '../elements/form/input/InputEmail';
import { getTargetCountry, getTargetState } from "../../helpers/user";

const DEFAULT_STATE = {
    contactName: '',
    name: '',
    address: '',
    city: '',
    country: '',
    state: '',
    phone: '',
    email: '',
    countryChanged: false,
};

export default class AddEditLabSpecialist extends React.PureComponent {
    state = {
        ...DEFAULT_STATE
    };
    componentDidUpdate(prevProps, prevState) {
        const { isViewMode, data, countries, states } = this.props;

        let country;

        if (isViewMode && data && Object.keys(data).length) {
            const { address, city, contactName, country: labCountry, email, name, phone, state: labState } = data;
            const targetCountry = getTargetCountry(countries, labCountry);
            const targetState = getTargetState(states, labState);
            country = targetCountry ? targetCountry : '';
            let state = targetState ? targetState : '';

            this.setState({
                contactName,
                name,
                address,
                city,
                email,
                phone,
                country,
                state,
            });
        }
        if (country && country !== prevState.country) {
            this.countryChangeHandler(country);
        }
    }
    handleAdd = () => {
        const { isViewMode } = this.props;

        if (isViewMode) {
        } else {
            this.props.handleSave({...this.state});
        }
    };
    handleChange = (e, field) => {
        this.setState({[field]: e.target.value});
    };
    handleClose = () => {
        const { handleClose, reset } = this.props;
        this.setState({...DEFAULT_STATE});
        handleClose();
        reset({});
    };
    contactNameChangeHandler = contactName => {
        this.setState({contactName});
    };
    labNameChangeHandler = name => {
        this.setState({name});
    };
    addressChangeHandler = address => {
        this.setState({address});
    };
    cityChangeHandler = city => {
        this.setState({city});
    };
    phoneChangeHandler = phone => {
        this.setState({phone});
    };
    emailChangeHandler = email => {
        this.setState({email});
    };
    countryChangeHandler = country => {
        this.setState({country, state: '', countryChanged: true});
        this.handleGetStates(country);
    };
    stateChangeHandler = state => {
        this.setState({state});
    };
    handleGetStates = country => {
        const { getStates, setStates } = this.props;

        if (country <= 2) {
            getStates(country);
        } else {
            setStates([]);
            this.setState({state: ''});
        }
    };
    getCountryOptions = () => {
        const { countries } = this.props;
        return countries ? countries.map(country => ({...country, value: country.id})) : [];
    };
    getStateOptions = () => {
        const { states } = this.props;
        return states ? states.map(state => ({...state, value: state.id})) : [];
    };
    getTitle = () => {
        return this.props.isLab ? 'Lab' : 'Specialist';
    };

    render() {
        const { isOpen,  handleSave, isViewMode, isEditMode } = this.props;
        const {
            contactName,
            name,
            address,
            city,
            country,
            state,
            phone,
            email,
        } = this.state;
        const title = this.getTitle();

        return (
            <MatDialog
                isOpen={isOpen}
                onClose={this.handleClose}
                classes={{
                    container: 'overlay',
                    paper: 'modal add-edit-lab-specialist-popup',
                }}>
                <MatDialogHeader onClose={this.handleClose} title={''} isLightMode={true}>
                    <div className="add-edit-lab">
                        {!isViewMode && <img src={addIcon} className="add-icon" alt="add" onClick={handleSave}/>}
                        <label className={`add-title ${isViewMode ? 'view' : ''}`}>
                            {isViewMode ? `View ${title}` : isEditMode ? `Edit ${title}` : `Add New ${title}`}
                        </label>
                    </div>
                </MatDialogHeader>

                <MatDialogContent>
                    <div className="light-page add-edit-lab-specialist-content">
                        <InputText name={'contactName'} isDisabled={isViewMode} onChange={this.contactNameChangeHandler} value={contactName} placeholder={'Contact Name'} isRequired={true}/>
                        <InputText name={'name'} isDisabled={isViewMode} onChange={this.labNameChangeHandler} value={name} placeholder={`${title} Name`} isRequired={true}/>
                        <InputText name={'address'} isDisabled={isViewMode} onChange={this.addressChangeHandler} value={address} placeholder={`${title} Address`} isRequired={true}/>
                        <InputText name={'city'} isDisabled={isViewMode} onChange={this.cityChangeHandler} value={city} placeholder={`${title} City`} isRequired={true}/>
                        <Select disableAutocomplete={true} placeholder={`${title} Country`} isDisabled={isViewMode} onChange={this.countryChangeHandler} options={this.getCountryOptions()} value={country} isRequired={true}/>
                        {((country || country === 0) && country <= 2) ?
                            <Select disableAutocomplete={true} placeholder={`${title} State / Province`} onChange={this.stateChangeHandler} options={this.getStateOptions()} value={state} isDisabled={!country || isViewMode} isRequired={true}/> :
                            <InputText name={'state'} isDisabled={!country || isViewMode} onChange={this.stateChangeHandler} value={state} placeholder={`${title} State / Province`} isRequired={true}/>
                        }
                        <InputText name={'phone'} isDisabled={isViewMode} onChange={this.phoneChangeHandler} value={phone} placeholder={`${title} Phone`} isRequired={true}/>
                        <InputEmail name={'email'} isDisabled={isViewMode} onChange={this.emailChangeHandler} value={email} placeholder={`${title} Email Address`} isRequired={true}/>
                    </div>
                    <div className="footer">
                        <label className="access-text">
                            *Access to case given upon membership
                        </label>
                        {!isViewMode && <GreenButton className="save-button" text="Save" onClick={this.handleAdd} />}
                        {isViewMode && <GreenButton className="close-button" text="Close" onClick={this.handleClose} />}
                    </div>
                </MatDialogContent>
            </MatDialog>
        )
    }
}

AddEditLabSpecialist.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    getStates: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    isViewMode: PropTypes.bool,
    isEditMode: PropTypes.bool,
    data: PropTypes.object,
    isLab: PropTypes.bool,
};
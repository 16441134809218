import UserActionTypes from './user.types';

export const signIn = loginAndPassword => ({
    type: UserActionTypes.SIGN_IN_START,
    payload: loginAndPassword
});

export const signInSuccess = data => ({
    type: UserActionTypes.SIGN_IN_SUCCESS,
    payload : data
});

export const signInExpired = (payload) => ({
  type: UserActionTypes.SIGN_IN_EXPIRED,
  payload
});


export const signInFailure = message => ({
    type: UserActionTypes.SIGN_IN_FAILURE,
    payload : message
});

export const signOut = () => ({
    type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
    type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const getUser = () => ({
    type: UserActionTypes.GET_USER
});

export const userLoaded = data => ({
    type: UserActionTypes.USER_LOADED,
    profile: data
});

export const resendUsernameOrPassword = resetData => ({
    type: UserActionTypes.RESEND_USERNAME_OR_PASSWORD,
    payload: resetData
});

export const updatePassword = updatePasswordData => ({
    type: UserActionTypes.UPDATE_PASSWORD,
    payload: updatePasswordData
});

export const userNotAuthenticated = () => ({
    type: UserActionTypes.USER_NOT_AUTHENTICATED
});

export const updateLabProfile = profile => ({
    type: UserActionTypes.UPDATE_LAB_PROFILE,
    payload: profile
});

export const updateDentist = dentistData => ({
    type: UserActionTypes.UPDATE_DENTIST,
    payload: dentistData
});

export const addLab = labData => ({
    type: UserActionTypes.ADD_LAB,
    payload: labData
});

export const addSpecialist = specialistData => ({
    type: UserActionTypes.ADD_SPECIALIST,
    payload: specialistData
});

export const getLabById = id => ({
    type: UserActionTypes.GET_LAB,
    payload: id
});

export const getSpecialistById = id => ({
    type: UserActionTypes.GET_SPECIALIST,
    payload: id
});

export const setLab = lab => ({
    type: UserActionTypes.SET_LAB,
    payload: lab
});

export const setSpecialist = specialist => ({
    type: UserActionTypes.SET_SPECIALIST,
    payload: specialist
});

export const deleteLab = id => ({
    type: UserActionTypes.DELETE_LAB,
    payload: id
});

export const deleteSpecialist = id => ({
    type: UserActionTypes.DELETE_SPECIALIST,
    payload: id
});

export const searchLabs = labData => ({
    type: UserActionTypes.SEARCH_LABS,
    payload: labData
});


export const searchSpecialists = specialistData => ({
    type: UserActionTypes.SEARCH_SPECIALISTS,
    payload: specialistData
});

export const setLabs = labData => ({
    type: UserActionTypes.SET_LABS,
    payload: labData
});

export const setSpecialists = specialistData => ({
    type: UserActionTypes.SET_SPECIALISTS,
    payload: specialistData
});

export const getProfileForSubscription = subscriptionData => ({
    type: UserActionTypes.GET_PROFILE_FOR_SUBSCRIPTION,
    payload: subscriptionData
});

export const checkTrialLink = (payload) => ({
    type: UserActionTypes.CHECK_TRIAL_LINK,
    payload
})

export const setCheckTrialLinkResult = (payload) => ({
    type: UserActionTypes.CHECK_TRIAL_LINK_RESULT,
    payload
})

export const createTrialProfile = (payload) => ({
    type: UserActionTypes.CREATE_TRIAL_PROFILE,
    payload
})

export const createTrialProfileError = (payload) => ({
    type: UserActionTypes.CREATE_TRIAL_PROFILE_ERROR,
    payload
})


export const setProfileForSubscription = profileSubscription => ({
    type: UserActionTypes.SET_PROFILE_FOR_SUBSCRIPTION,
    payload: profileSubscription
});

export const createLabProfile = profile => ({
    type: UserActionTypes.CREATE_LAB_PROFILE,
    payload: profile
});

export const setProfileCreated = created => ({
    type: UserActionTypes.IS_PROFILE_CREATE,
    payload: created
});

export const createDentistProfile = dentistData => ({
    type: UserActionTypes.CREATE_DENTIST_PROFILE,
    payload: dentistData
});

export const setDentistToAdd = dentist => ({
    type: UserActionTypes.SET_DENTIST_TO_ADD,
    payload: dentist
});

export const resetProfileData = () => ({
    type: UserActionTypes.RESET_PROFILE_DATA
});

export const creditsCharged = () => ({
    type: UserActionTypes.CREDITS_CHARGED,
});

export const deleteDentist = id => ({
    type: UserActionTypes.DELETE_DENTIST,
    payload: id
});

export const noCreditsAvailable = () => ({
    type: UserActionTypes.NO_CREDITS_AVAILABLE,
})

export const magicTouchLabsListLoaded = (payload) => ({
    type: UserActionTypes.MAGIC_TOUCH_LABS_LIST_LOADED,
    payload
})

export const getMagicTouchLabsList = (payload) => ({
    type: UserActionTypes.GET_MAGICTOUCH_LABS_LIST,
    payload
})

export const getIsUsernameUnique = (payload) => ({
    type: UserActionTypes.GET_IS_USERNAME_UNIQUE,
    payload
})

export const usernameUniqueResult = (payload) => ({
    type: UserActionTypes.USERNAME_UNIQUE_RESULT,
    payload
})

export const generateBackup = (payload) => ({
  type: UserActionTypes.GENERATE_BACKUP,
  payload
})

export const backupUrlGenerated = (payload) => ({
  type: UserActionTypes.BACKUP_URL_GENERATED,
  payload
})

export const generateExportDentists = (payload) => ({
  type: UserActionTypes.GENERATE_EXPORT_DENTISTS,
  payload
})


export const exportDentistsUrlGenerated = (payload) => ({
  type: UserActionTypes.EXPORT_DENTISTS_URL_GENERATED,
  payload
})






import UserActionTypes from "../actions/user.types";
import * as userFuncs from "../helpers/user";

// TODO change inititalState on auth ready
const initialState = {
    isLoginPending: false,
    isLogged : localStorage.isLogged ? (localStorage.isLogged === 'true') : false,
    isLoggedExpired: false,
    expiredUserData: null,
    userProfile: parseJson(localStorage.userProfile, { username: '', id:'', credit: 0}),
    error: null,
    activeLab: {},
    activeSpecialist: {},
    labs: [],
    specialists: [],
    profileSubscription: {},
    profileCreated: false,
    dentistToAdd: {},
    magictouch_labs: [],
    isUsernameUnique: true,
    isTrialLinkValid: false,
    trialProfileCreationResult: ''
};

function parseJson(str, defaultObj = null)
{
    try {
        return JSON.parse(str);
    } catch (e) {
    }
    return defaultObj;
}

function userReducer(state = initialState, action) {
    switch (action.type) {
        case UserActionTypes.SIGN_IN_START:
        case UserActionTypes.GET_USER:
            return { ...state, isLoginPending: true };
        case UserActionTypes.USER_LOADED:
            localStorage.userProfile = JSON.stringify(userFuncs.modifyUserProfile(action.profile));
            localStorage.isLogged = true;
            return {
                ...state,
                userProfile: userFuncs.modifyUserProfile(action.profile),
                error: null,
                isLogged: true,
                isLoginPending: false,
              };
        case UserActionTypes.SIGN_IN_SUCCESS:
            localStorage.isLogged = true;
            return {
                ...state,
                isLogged: true,
            };
        case UserActionTypes.SIGN_IN_EXPIRED:
            return {...state, isLoggedExpired : true, isLogged: false, expiredUserData: action.payload}
        case UserActionTypes.SIGN_OUT_SUCCESS:
            localStorage.clear();
            return {...state, isLogged:false, isLoggedExpired: false, userProfile: { username: '', id:'', credit: 0}};
        case UserActionTypes.SIGN_IN_FAILURE:
            localStorage.clear();
            return initialState;
        case UserActionTypes.USER_NOT_AUTHENTICATED:
            localStorage.clear();
            return {...state, isLogged:false, userProfile: { username: '', id:'', credit: 0}};
        case UserActionTypes.SET_LAB:
            return { ...state, activeLab: action.payload };
        case UserActionTypes.SET_SPECIALIST:
            return { ...state, activeSpecialist: action.payload };
        case UserActionTypes.SET_LABS:
            return { ...state, labs: action.payload };
        case UserActionTypes.SET_SPECIALISTS:
            return { ...state, specialists: action.payload };
        case UserActionTypes.SET_PROFILE_FOR_SUBSCRIPTION:
            return { ...state, profileSubscription: action.payload };
        case UserActionTypes.IS_PROFILE_CREATE:
            return { ...state, profileCreated: action.payload };
        case UserActionTypes.RESET_PROFILE_DATA:
            return { ...state, profileCreated: false, dentistToAdd: {}, profileSubscription: {}};
        case UserActionTypes.SET_DENTIST_TO_ADD:
            return { ...state, dentistToAdd: action.payload };
        case UserActionTypes.CREDITS_CHARGED:
            let uP =  {...state.userProfile, credit: state.userProfile.credit - 1};
            localStorage.userProfile = JSON.stringify(uP);
            return {...state, userProfile: {...state.userProfile, credit: state.userProfile.credit - 1}};
        case UserActionTypes.MAGIC_TOUCH_LABS_LIST_LOADED:
            return {...state, magictouch_labs: action.payload }
        case UserActionTypes.USERNAME_UNIQUE_RESULT:
            return {...state, isUsernameUnique: action.payload }
        case UserActionTypes.CHECK_TRIAL_LINK:
            return {...state, isTrialLinkValid: false}
        case UserActionTypes.CHECK_TRIAL_LINK_RESULT:
            return {...state, isTrialLinkValid: action.payload }
        case UserActionTypes.CREATE_TRIAL_PROFILE_ERROR:
            return {...state, trialProfileCreationResult: action.payload }
        case UserActionTypes.BACKUP_URL_GENERATED:
            return {...state, backupUrl: action.payload }
        default:

            return state;
    }
}

export default userReducer;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class AscendFinish extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    componentDidMount = () => {
        
    }


    static getDerivedStateFromError(error) {
        // getDerivedStateFromError -> Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
    }

    getSnapshotBeforeUpdate = (prevProps, prevState) => {
    }

    componentDidUpdate = () => {
        
        //let { ascendkey, setAscendCase } = this.props;
        //if (this.props.match.params.key !== ascendkey) {
        //
        //} 

    }

    componentWillUnmount = () => {
        
    }

    

    render() {
        let { error, message, imagesSelected } = this.props;
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }
        let rowclass = error ? 'ascend-row ascend-error' : 'ascend-row ascend-success';
        return (
            <div className="light-page ascend-selectcase-container">
                <div className="ascend-selectcase-form">
                    <div className={rowclass}>
                        { error ?
                          <div><h2>Error occured: {`${message}`}.</h2><h2> Please, create new session from Dentrix Ascend</h2></div>
                        : ( imagesSelected ? <div><h2>Images selected to acquire successfully.</h2><h2> Please return to Dentrix Ascend application to see acquired images.</h2></div> 
                            :<div><h2>Session was ended without image selection.</h2><h2> Please return to Dentrix Ascend application to start new session.</h2></div> 
                        )
                        }
                    </div>
                    
                </div>
            </div>
        );
    }
}

AscendFinish.propTypes = {
    // bla: PropTypes.string,
};

AscendFinish.defaultProps = {
    // bla: 'test',
};

const mapStateToProps = state => ({
    // blabla: state.blabla,
    error: state.ascend.error,
    message: state.ascend.finishMessage,
    imagesSelected: state.ascend.imagesSelected
});

const mapDispatchToProps = dispatch => ({
   
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AscendFinish);

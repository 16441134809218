export const GET_USER = 'Getting user information';
export const SIGN_IN = GET_USER;

export const CASE_LOAD = 'We are opening your case';
export const CASES_LOAD = 'Loading cases';

export const PHOTO_CORRECTING = "Correcting image";
export const PHOTO_MAP_GENERATING = "Generating map image";
export const SAVING_IMAGE_TO_SERVER = "Saving image to server";
export const CREATE_CROPPED_PHOTO = "Creating cropped photo";
export const CREATE_GRAYSCALE_PHOTO = "Creating grayscale photo";
export const UNDO_IMAGE = "Undo image processing";
export const REDO_IMAGE = "Redo image processing";


export const CASES_LOADER_COMPONENT_NAME = 'cases';

import React from "react";
import PropTypes from 'prop-types';
import Checkbox from "../elements/form/Checkbox";
import Header from "../../containers/Header";
import { SUPPORT_CENTER_URL } from "../../constants/links";
import { getTargetCountry, getTargetState } from "../../helpers/user";
import { dateToSystemString } from "../../helpers/date";
import Form from '../elements/Form';
import InputText from '../elements/form/input/InputText';
import InputEmail from '../elements/form/input/InputEmail';
import InputPassword from '../elements/form/input/InputPassword';
import Select from '../elements/form/Select';
import FormSteps from '../dentist/elements/FormSteps';
import { LAB_ACCOUNT_CREATED, LOGIN } from "../../constants/url";
import Button from "../elements/form/Button";
import Required from '../elements/icons/Required';
import { themeLight } from "../../config/app";

export default class CreateLabProfile extends React.PureComponent {
    state = {
        login_as_user: true,
        email_updates: true,
        first_name: '',
        last_name: '',
        name: '',
        address: '',
        country: '',
        state: '',
        city: '',
        postal_code: '',
        phone: '',
        email: '',
        additional_emails: '',
        additional_emails_case_assigned: '',
        website: '',
        username: '',
        password: '',
        confirmPassword: '',
        passwordsError: null,
        countryChanged: false,
    };
    componentDidMount() {
        const { userProfile, countries, states, getCountries, getStates } = this.props;

        if (userProfile && Object.keys(userProfile).length) {
            let country = '';
            let state = '';

            if (countries && countries.length) {
                country = getTargetCountry(countries, userProfile.country);
                state = getTargetState(states, userProfile.state);

                if ((country || country === 0) && country <= 2) {
                    getStates(country);
                }
            } else {
                getCountries();
            }

            if (userProfile && Object.keys(userProfile).length) {
                this.setState({
                    first_name: userProfile.firstName || '',
                    last_name: userProfile.lastName || '',
                    address: userProfile.address || '',
                    city: userProfile.city || '',
                    postal_code: userProfile.postal_code || '',
                    phone: userProfile.phone || '',
                    email: userProfile.email || '',
                    country,
                    state,
                });
            }
        } else {
            this.props.history.push(LOGIN);
        }
    }
    componentDidUpdate(prevProps) {
        const { userProfile, states, countries, getStates } = this.props;

        if (userProfile && Object.keys(userProfile).length) {
            const { states: prevStates } = prevProps;
            const { countryChanged } = this.state;

            if (states && states.length && ((!prevStates || (prevStates && !prevStates.length)) || (prevStates && prevStates.length && prevStates[0].id !== states[0].id))) {
                const targetState = countryChanged ? states[0].id : getTargetState(states, userProfile.state);
                const state = (targetState || targetState === 0) && !isNaN(targetState) ? targetState : states[0].id;

                this.setState({state});
            }

            if ((!prevProps.countries || (prevProps.countries && !prevProps.countries.length))) {
                if (countries && countries.length) {
                    const targetCountry = getTargetCountry(countries, userProfile.country);
                    const country = targetCountry ? targetCountry : countries[0].id;

                    this.setState({country});
                    if (country <=2) {
                        getStates(country);
                    } else {
                        this.setState({state: userProfile.state});
                    }
                }
            }
        }
        if (!prevProps.profileCreated && this.props.profileCreated) {
            this.props.history.push(LAB_ACCOUNT_CREATED);
        }
    }
    handleSave = () => {
        const { userProfile, createLabProfile } = this.props;
        const {
            email_updates,
            login_as_user,
            additional_emails,
            additional_emails_case_assigned,
            country,
            state,
            first_name,
            last_name,
            name,
            address,
            city,
            postal_code,
            phone,
            email,
            website,
            username,
            password,
            confirmPassword,
        } = this.state;

        const payload = {
            email_updates,
            country,
            state,
            first_name,
            last_name,
            name,
            address,
            city,
            postal_code,
            phone,
            email,
            website,
            username,
            isSubscribed: 1,
            subscriptionDate: dateToSystemString(new Date()),
            id: userProfile.id,
            additional_emails,
            additional_emails_case_assigned,
            login_as_user,
        };
        if (+userProfile.subscription_type !== 1) {
            if (password && password === confirmPassword) {
                payload.password = password;
                this.setState({passwordsError: null});
            } else {
                this.setState({passwordsError: ['Passwords should match!']});
                return;
            }
        }
        createLabProfile(payload);
    };
    firstNameChangeHandler = first_name => {
        this.setState({first_name});
    };
    lastNameChangeHandler = last_name => {
        this.setState({last_name});
    };
    nameChangeHandler = name => {
        this.setState({name});
    };
    addressChangeHandler = address => {
        this.setState({address});
    };
    cityChangeHandler = city => {
        this.setState({city});
    };
    postalCodeChangeHandler = postal_code => {
        this.setState({postal_code});
    };
    phoneChangeHandler = phone => {
        this.setState({phone});
    };
    emailChangeHandler = email => {
        this.setState({email});
    };
    additionalEmailChangeHandler = additional_emails => {
        this.setState({additional_emails});
    };
    additionalEmailCaseAssignChangeHandler = additional_emails_case_assigned => {
        this.setState({additional_emails_case_assigned});
    };
    websiteChangeHandler = website => {
        this.setState({website});
    };
    usernameChangeHandler = username => {
        this.setState({username});
    };
    passwordChangeHandler = password => {
        this.setState({password});
    };
    confirmPasswordChangeHandler = confirmPassword => {
        this.setState({confirmPassword});
    };
    countryChangeHandler = country => {
        this.setState({country, state: '', countryChanged: true});
        this.handleGetStates(country);
    };
    stateChangeHandler = state => {
        this.setState({state});
    };
    handleGetStates = country => {
        const { getStates, setStates } = this.props;

        if (country <= 2) {
            getStates(country);
        } else {
            setStates([]);
            this.setState({state: ''});
        }
    };
    getCountryOptions = () => {
        const { countries } = this.props;
        return countries ? countries.map(country => ({...country, value: country.id})) : [];
    };
    getStateOptions = () => {
        const { states } = this.props;
        return states ? states.map(state => ({...state, value: state.id})) : [];
    };
    render() {
        const { userProfile } = this.props;
        const {
            email_updates,
            login_as_user,
            country,
            state,
            first_name,
            last_name,
            name,
            address,
            city,
            postal_code,
            phone,
            email,
            additional_emails,
            additional_emails_case_assigned,
            website,
            username,
            password,
            confirmPassword,
            passwordsError
        } = this.state;

        return (
            <div className="light-page create-lab-profile-container">
                <Header theme={themeLight}/>
                <div className="stepper-container">
                    <FormSteps currentStep={1} stepGroup={'lab'}/>
                </div>

                <div>
                    <div className="create-lab-title">
                        <label>Create Lab Profile</label>
                    </div>
                    <Form className={'create-lab-profile'} onSubmit={this.handleSave} isAjax={true}>
                        <div className={'fields'}>
                            <div className="profile-info">
                                <InputText name={'firstName'} onChange={this.firstNameChangeHandler} value={first_name} placeholder={'First Name'} isRequired={true}/>
                                <InputText name={'lastName'} onChange={this.lastNameChangeHandler} value={last_name} placeholder={'Last Name'} isRequired={true}/>
                                <InputText name={'name'} onChange={this.nameChangeHandler} value={name} placeholder={'Lab Name'} isRequired={true}/>
                                <InputText name={'address'} onChange={this.addressChangeHandler} value={address} placeholder={'Address'} isRequired={true}/>
                                <Select disableAutocomplete={true} placeholder={'Country'} onChange={this.countryChangeHandler} options={this.getCountryOptions()} value={country} isRequired={true}/>
                                {((country || country === 0) && country <= 2) ?
                                    <Select disableAutocomplete={true} placeholder={'State/ Province'} onChange={this.stateChangeHandler} options={this.getStateOptions()} value={state} isDisabled={!country} isRequired={true}/> :
                                    <InputText name={'state'} onChange={this.stateChangeHandler} value={state} placeholder={'State / Province'} isRequired={true}/>}
                                <InputText name={'city'} onChange={this.cityChangeHandler} value={city} placeholder={'City'} isRequired={true}/>
                                <InputText name={'postalCode'} onChange={this.postalCodeChangeHandler} value={postal_code} placeholder={'Postal Code'} isRequired={true}/>
                                <InputText name={'phone'} onChange={this.phoneChangeHandler} value={phone} placeholder={'Phone'} isRequired={true}/>
                                <InputEmail name={'email'} onChange={this.emailChangeHandler} value={email} placeholder={'Email Address'} isRequired={true}/>
                            </div>
                            <div className="additional-info">
                                <label className={'additional-email-case-assign update'}>Additional Emails When Case Is Created Or Updated Or License Renewed:</label>
                                <InputEmail name={'additionalEmails'} onChange={this.additionalEmailChangeHandler} value={additional_emails} placeholder={'Email Address'} isMultiple={true}/>
                                <label className={'additional-email-case-assign'}>Additional Emails When Case Is Assigned:</label>
                                <InputEmail name={'additionalEmailsCaseAssign'} onChange={this.additionalEmailCaseAssignChangeHandler} value={additional_emails_case_assigned} placeholder={'Email Address'} isMultiple={true}/>
                                <InputText className={'website'} name={'website'} onChange={this.websiteChangeHandler} value={website} placeholder={'Web Address'} />
                                <InputText name={'username'} onChange={this.usernameChangeHandler} value={username} placeholder={'Choose Username'} isRequired={true}/>

                                {userProfile && +userProfile.subscription_type === 1 ?
                                    <InputText name={'pass'} onChange={() => {}} value={'Use password from store.shadewave.com'} placeholder={'Password'} isDisabled={true} /> :
                                    <div className="passwords-group">
                                        <InputPassword errors={passwordsError} name={'password'} autocomplete={'new-password'} onChange={this.passwordChangeHandler} value={password} placeholder={'Password'} isRequired={true}/>
                                        <InputPassword errors={passwordsError} name={'confirmPassword'} autocomplete={'new-password'} onChange={this.confirmPasswordChangeHandler} value={confirmPassword} placeholder={'Confirm Password'} isRequired={true}/>
                                    </div>
                                }
                                <Checkbox onChange={() => this.setState({login_as_user: !login_as_user})} isChecked={login_as_user}>
                                    Allow access to my account for website administrator<Required/>
                                </Checkbox>
                                <Checkbox onChange={() => this.setState({email_updates: !email_updates})} isChecked={email_updates}>
                                    I want to receive email updates<Required/>
                                </Checkbox>
                            </div>
                        </div>
                        <div className="footer">
                            <Button type={'submit'} className="save-button" text="SAVE" />
                            <label className="footer-text">Premier Dental Shade Matching Software <a href={SUPPORT_CENTER_URL}>Support Center</a></label>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

CreateLabProfile.propTypes = {
    createLabProfile: PropTypes.func.isRequired,
    getCountries: PropTypes.func.isRequired,
    getStates: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    profileCreated: PropTypes.bool.isRequired,
    setStates: PropTypes.func.isRequired,
};

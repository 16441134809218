import React from "react";
import PropTypes from "prop-types";

export default class ShadeColorCard extends React.PureComponent {

    padZero(str, len) {
        len = len || 2;
        let zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    invertColor(hex, bw) {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        if (hex.length === 8)
        {
            console.log('hex has 8', hex);
            hex = hex.slice(2);
        }

        if (hex.length !== 6 && hex.length !== 8) {
            console.log('invalid hex', hex);
            throw new Error('Invalid HEX color.');
        }

        let r = parseInt(hex.slice(0, 2), 16),
            g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);
        if (bw) {
            return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                ? '#000000'
                : '#FFFFFF';
        }
        // invert color components
        r = (255 - r).toString(16);
        g = (255 - g).toString(16);
        b = (255 - b).toString(16);
        // pad each with zeros and return
        return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
    }

    onClick = () => {
        let { onSelectedShade, text, color, studioColor } = this.props;

        if (text !== null && text !== '')
            onSelectedShade(color, studioColor, text);
    }

    render() {
        let { color, text } = this.props;
        let textColor = this.invertColor(color, true);
        
        return (
            <div className={'shade-selector-label'} style={{background: color, color: textColor }} onClick={this.onClick}>
                {text}
            </div>
        )
    }
}

ShadeColorCard.propTypes = {
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { getCaseImages } from '../../selectors/ascend.selector';
import { IconButton } from '@material-ui/core';
import Button from '../elements/form/Button';
import { acquireImages, getCases } from '../../actions/ascend.actions';
//import { Test } from './AscendImageSelect.styles';

class AscendImageSelect extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            selectedImages: [],
        };
    }

    componentDidMount = () => {
        
        let { getCases, ascendkey } = this.props;
        getCases(ascendkey);
    }


    static getDerivedStateFromError(error) {
        // getDerivedStateFromError -> Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
    }

    getSnapshotBeforeUpdate = (prevProps, prevState) => {
        
    }

    componentDidUpdate = () => {
        
    }

    componentWillUnmount = () => {
        
    }

    changeSelection = (id) => {
        if (this.state.selectedImages.includes(id)) {
            this.setState({ selectedImages: this.state.selectedImages.filter(im => im !== id) });
        }
        else {
            this.setState({ selectedImages: [...this.state.selectedImages, id] });
        }
    }

    onAcquireImages = () => {
        let { acquireImages, ascendkey } = this.props;
        acquireImages(ascendkey, this.state.selectedImages);
    }

    onEndSession = () => {
        let { acquireImages, ascendkey } = this.props;
        acquireImages(ascendkey, []);
    }

    render() {
        let { tileData } = this.props;

        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }
        return (
            <div className="light-page ascend-images-container">
                
                <h1>{ tileData.length > 0 ? 'Select images for aquire' : 'There is no images to acquire' }</h1> 
                
                <h1>&nbsp;</h1>
                { (tileData.filter(item => !item.acquired ).length > 0) ?
                <div className="ascend-half-container">
                    
                    <h2>New images</h2>
                    <div className={'ascend-images-root'}>
                        <ImageList cols={3} className={'ascend-images-gridList'}>

                            {tileData.filter(item => !item.acquired ).map((tile) => (

                                <ImageListItem key={tile.id}>
                                    <div className={'ascend-images-img-container'}>
                                        <img src={`${process.env.REACT_APP_API_URL}tool/flex-helper.php?action=getPhoto&id=${tile.id}`} alt={tile.filenameOrig} className={'ascend-images-img'} onClick={(e) => { this.changeSelection(tile.id) }} />
                                    </div>
                                    <ImageListItemBar onClick={(e) => { this.changeSelection(tile.id) }} className={'ascend-image-tilebar'}
                                        title={tile.filenameOrig}
                                        actionIcon={
                                            <IconButton onClick={(e) => { this.changeSelection(tile.id) }}>
                                                {this.state.selectedImages.includes(tile.id) ?
                                                    <StarIcon /> : <StarBorderIcon />
                                                }

                                            </IconButton>

                                        }
                                        position="left"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </div>
                </div> : null
                }
                { (tileData.filter(item => item.acquired === true).length > 0) ?
                <div className="ascend-half-container">
                    <h1>&nbsp;</h1>
                    <h2>Images acquired earlier</h2>
                    <div className={'ascend-images-root'}>
                        <ImageList cols={3} className={'ascend-images-gridList'}>

                            {tileData.filter(item => item.acquired === true).map((tile) => (

                                <ImageListItem key={tile.id}>
                                    <div className={'ascend-images-img-container'}>
                                        <img src={`${process.env.REACT_APP_API_URL}tool/flex-helper.php?action=getPhoto&id=${tile.id}`} alt={tile.filenameOrig} className={'ascend-images-img'} onClick={(e) => { this.changeSelection(tile.id) }} />
                                    </div>
                                    <ImageListItemBar onClick={(e) => { this.changeSelection(tile.id) }} className={'ascend-image-tilebar'}
                                        title={tile.filenameOrig}
                                        actionIcon={
                                            <IconButton onClick={(e) => { this.changeSelection(tile.id) }}>
                                                {this.state.selectedImages.includes(tile.id) ?
                                                    <StarIcon /> : <StarBorderIcon />
                                                }

                                            </IconButton>

                                        }
                                        position="left"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </div>
                </div> : null
                }
                {
                    tileData.length > 0 ?
                    <div className="ascend-half-container cont-row">
                        <Button text={'Acquire Selected Images' } onClick={this.onAcquireImages} isDisabled={this.state.selectedImages.length === 0} />
                        <Button text={'End Session' } onClick={this.onEndSession} />
                    </div> : null
                }
               

            </div>
        );
    }
}

AscendImageSelect.propTypes = {
    // bla: PropTypes.string,
};

AscendImageSelect.defaultProps = {
    // bla: 'test',
};

const mapStateToProps = state => ({
    // blabla: state.blabla,
    tileData: getCaseImages(state),
    selectedCaseId : state.ascend.currentCase,
    ascendkey: state.ascend.ascendkey,
});

const mapDispatchToProps = dispatch => ({
    // fnBlaBla: () => dispatch(action.name()),
    acquireImages: (ascendkey, images) => dispatch(acquireImages({ascendkey, images})),
    getCases: (ascendkey) => dispatch(getCases({ascendkey})),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AscendImageSelect);

import React from "react";
import PropTypes from 'prop-types';
import Logo from './header/Logo';
import UserMenu from './header/UserMenu';
import ProfileEdit from '../containers/dentist/ProfileEdit';
import { withRouter } from "react-router-dom";
import { DASHBOARD } from "../constants/url";


class Header extends React.PureComponent {
    componentDidMount() {
        // TODO remove it, temporary
        if (process.env.REACT_APP_SITE_TITLE) {
            document.title = process.env.REACT_APP_SITE_TITLE;
        }

        // TODO remove it
        //this.props.signOut();
        //this.props.signIn('den3121', '1234567');
        //this.props.getUser();
        //this.props.signIn('labuser', '1234567');
    }

    renderUserMenu = () => {
        let {
            username,
            credits,
            id,
            isUserMenuShow,
            showUserMenu,
            hideUserMenu,
            showProfile,
            theme,
            roleId,
			signOut,
            isUserMenu,
            trialDays,
            subscriptionType
        } = this.props;

        if (typeof isUserMenu !== 'undefined' && !isUserMenu) {
            return false;
        }

        return <UserMenu
            theme={theme}
            username={username}
            id={id}
            roleId={roleId}
            credits={credits}
            signOut={signOut}
            isUserMenuShow={isUserMenuShow}
            showProfile={showProfile}
            showUserMenu={showUserMenu}
            hideUserMenu={hideUserMenu}
            trialDays={trialDays}
            subscriptionType={subscriptionType}
        />
    };

    onLogoClick = () => {
        this.props.history.push(DASHBOARD);
    };

    render() {
        const { isLogged, theme, className} = this.props;

        return (
            <header className={`header ${theme ? theme : 'default'} ${className ? className : ''}`}>
                <Logo theme={theme} onClick={this.onLogoClick}/>

                {isLogged && (
                    <div className={'user-menu-container'}>
                        <ProfileEdit/>
                        {this.renderUserMenu()}
                    </div>
                )}
            </header>
        )
    }
}

Header.propTypes = {
    theme: PropTypes.string,
    isLogged: PropTypes.bool.isRequired,
    username: PropTypes.string,
    id: PropTypes.number,
    credits: PropTypes.number,
    isUserMenu: PropTypes.bool,

    isUserMenuShow: PropTypes.bool.isRequired,

    showUserMenu: PropTypes.func.isRequired,
    hideUserMenu: PropTypes.func.isRequired,
    showProfile: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    roleId: PropTypes.number.isRequired,
    className: PropTypes.string,
    trialDays: PropTypes.number,
    subscriptionType: PropTypes.number
};

export default withRouter(Header);

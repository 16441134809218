import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Form from '../elements/Form';
import InputText from '../elements/form/input/InputText';
import InputPassword from '../elements/form/input/InputPassword';
import Button from "../elements/form/Button";
import { signInAscend } from '../../actions/ascend.actions';
//import { Test } from './AscendLogin.styles';

class AscendLogin extends PureComponent { 
    state = {
        username: '',
        password: '',
        ascendkey: this.props.match.params.key,
    };

    /*static getDerivedStateFromProps(nextProps, prevState) {
        let key = nextProps.match.params.key;

        if (key !== prevState.key) {
            console.log('new state ', {...prevState, ascendkey: key});
            return {...prevState, ascendkey: key};
        }

        return null;
    }*/

    login = () => {
        const { username, password, ascendkey } = this.state;
        this.props.signInAscend(username, password, ascendkey);
    };
    usernameChangeHandler = username => {
        this.setState({username});
    };
    passwordChangeHandler = password => {
        this.setState({password});
    };
    render() {
        const { username, password } = this.state;

        return (
            <div className="light-page ascend-login-container">
                <div className="ascend-login-form">
                    <p className="auth-page-text">Dentrix Ascend Login</p>
                    <p className="auth-info">Login into your Shadewave account</p>
                    <Form onSubmit={this.login} isAjax={true}>
                        <InputText name={'username'} onChange={this.usernameChangeHandler} value={username} placeholder={'Email address / Username'} isRequired={true} />
                        <InputPassword name={'password'} autocomplete={'password'} onChange={this.passwordChangeHandler} value={password} placeholder={'Password'} isRequired={true}/>
                        <Button type={'submit'} text="LOGIN" className="login-button" />
                    </Form>
                </div>
            </div>
        )
    }
}

AscendLogin.propTypes = {
  // bla: PropTypes.string,
};

AscendLogin.defaultProps = {
  // bla: 'test',
};

const mapStateToProps = state => ({
  // blabla: state.blabla,
  ascendkey: state.ascend.key,
});

const mapDispatchToProps = dispatch => ({
  // fnBlaBla: () => dispatch(action.name()),
  signInAscend: (username, password, ascendkey) => dispatch(signInAscend({username, password, ascendkey}))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AscendLogin);

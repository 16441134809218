import { createSelector } from 'reselect'

const getAscend = state => state.ascend;

export const getCaseImages = createSelector(
    [getAscend],
    (ascendState) => {
        
        if (!ascendState.currentCase)
            return [];

        let currentCase = ascendState.cases?.find(c => c.id === ascendState.currentCase);

        if (!currentCase)
            return [];

        return currentCase.images;
    }
);
import React from "react";
import PropTypes from "prop-types";
import Button from "../elements/form/Button";
import { getUrlKeys } from "../../helpers/url";
import Form from '../elements/Form';
import InputPassword from '../elements/form/input/InputPassword';

export default class UpdatePassword extends React.PureComponent {
    state = {
        password: '',
        confirmPassword: '',
        passwordsError: null,
    };
    checkAndUpdatePassword = () => {
        const { password, confirmPassword } = this.state;
        const { search } = this.props.location;
        const { key, email } = getUrlKeys(search);

        if (password !== confirmPassword) {
            this.setState({passwordsError: ['Passwords should match!']});
            return;
        }
        if (password && confirmPassword && password === confirmPassword && key && email) {
            this.props.updatePassword(email, password, key);
        }
    };
    passwordChangeHandler = password => {
        this.setState({password});
    };
    confirmPasswordChangeHandler = confirmPassword => {
        this.setState({confirmPassword});
    };
    render() {
        const { password, confirmPassword, passwordsError } = this.state;
        return (
            <div className="update-password-form">
                <p className="auth-page-text">Enter New Password</p>

                <Form onSubmit={this.checkAndUpdatePassword} isAjax={true}>
                    <InputPassword errors={passwordsError} name={'password'} autocomplete={'off'} onChange={this.passwordChangeHandler} value={password} placeholder={'Password'} isRequired={true}/>
                    <InputPassword errors={passwordsError} name={'confirmPassword'} autocomplete={'off'} onChange={this.confirmPasswordChangeHandler} value={confirmPassword} placeholder={'Confirm Password'} isRequired={true}/>
                    <Button text="UPDATE PASSWORD" type={'submit'} className="update-password" />
                </Form>
            </div>
        )
    }
}

UpdatePassword.propTypes = {
    updatePassword: PropTypes.func.isRequired,
};
import React from "react";
import {SUPPORT_CENTER_URL} from "../../constants/links";

export default class SupportInfo extends React.PureComponent {
    render() {
        return (
            <div className={'support-info'}>
                Premier Dental Shade Matching Software <a href={SUPPORT_CENTER_URL}>Support Center</a>
            </div>
        )
    }
}


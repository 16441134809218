import React from 'react';
import PropTypes from 'prop-types';
import BaseInput from '../BaseInput';

export default class InputText extends React.PureComponent {


    onBlur = () => {
        const { onValidate } = this.props;

        if (onValidate) 
            onValidate();
    }

    onKeyDown = (e) => {
        const { onValidate } = this.props;

        if (e.keyCode === 13) {
            if (onValidate) 
                onValidate();
        }
    }

	render() {
		let { placeholder, value, name, icon, autocomplete, isDisabled, onChange, errors, isRequired, className } =
			this.props;

		return (
			<BaseInput
				className={className}
				isRequired={isRequired}
				isDisabled={isDisabled}
				errors={errors}
				type={'text'}
				value={value}
				name={name}
				placeholder={placeholder}
				icon={icon}
				onChange={onChange}
				attributes={{ autoComplete: autocomplete ? autocomplete : '', 
                    disabled: isDisabled,
                    onBlur: this.onBlur,
                    onKeyDown: this.onKeyDown
                }}
			/>
		);
	}
}

InputText.propTypes = {
	isRequired: PropTypes.bool,
	errors: PropTypes.array,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	icon: PropTypes.node,
	autocomplete: PropTypes.string,
	isDisabled: PropTypes.bool,
	className: PropTypes.string,
};

import React  from 'react';
import Photos from '../containers/case/subMenus/Photos';
import Details from '../containers/case/subMenus/Details';
import Process from '../containers/case/subMenus/Process';
import Edit from '../containers/case/subMenus/Edit';
import Maps from '../containers/case/subMenus/Maps';
import Preferences from '../containers/case/subMenus/Preferences';
import ViewCases from '../containers/case/subMenus/ViewCases';

export const TYPE_RESIZEABLE = 'resizeable';
export const TYPE_MODAL = 'modal';
export const closeMenuOnResizeOffset = -100; // offset in px from the right side
const subMenus = {
    photos: {
        component: <Photos/>,
        title: 'Photos',
        type: TYPE_RESIZEABLE,
    },
    details: {
        component: <Details/>,
        title: 'Case Details',
        type: TYPE_RESIZEABLE,
    },
    process: {
        component: <Process/>,
        title: 'Process',
        type: TYPE_RESIZEABLE,
    },
    edit: {
        component: <Edit/>,
        title: 'Edit',
        type: TYPE_RESIZEABLE,
    },
    maps: {
        component: <Maps/>,
        title: 'Maps',
        type: TYPE_RESIZEABLE,
    },
    preferences: {
        component: <Preferences/>,
        title: 'Preferences',
        type: TYPE_RESIZEABLE,
    },
    cases: {
        component: <ViewCases/>,
        title: 'View Cases',
        type: TYPE_MODAL,
    },
};

export default subMenus;

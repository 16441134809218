import React from "react";
import PropTypes from 'prop-types';
import ModalComponent from 'react-modal';

export class ModalHeader extends React.PureComponent {
    render() {
        let { children, title, onClose } = this.props;

        return (
            <div className={'modal-header'}>
                <div className={'title'}>{title}</div>
                {children}
                <div className={'closer'} onClick={onClose} onTouchEnd={onClose}></div>
            </div>
        )
    }
}

ModalHeader.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export class ModalContent extends React.PureComponent {
    render() {
        let { children, } = this.props;

        return (
            <div className={'content'}>
                {children}
            </div>
        )
    }
}

ModalContent.propTypes = {
    children: PropTypes.node.isRequired,
};

export default class Modal extends React.PureComponent {
    componentWillMount() {
        ModalComponent.setAppElement('body');
    }
    render() {
        let { children, onClose, className, overlayClassName, shouldCloseOnOverlayClick } = this.props;

        const isShouldCloseOnOverlayClick = (typeof shouldCloseOnOverlayClick !== 'undefined') ? shouldCloseOnOverlayClick : true;

        return (
            <ModalComponent
                isOpen={true}
                onRequestClose={onClose}
                className={`modal ${className}`}
                overlayClassName={'overlay' + (overlayClassName ? ` ${overlayClassName}` : '')}
                shouldCloseOnOverlayClick={isShouldCloseOnOverlayClick}
            >
                {children}
            </ModalComponent>
        )
    }
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    overlayClassName: PropTypes.string,
    shouldCloseOnOverlayClick: PropTypes.bool,
};

import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";

export default class DetailsField extends React.PureComponent {
    isValue = (value) => {
        return value !== false && value !== null;
    };
    renderValue = (value) => {
        if (!this.isValue(value)) {
            return false;
        }

        return <div className={'field-value'} data-tip={value}> {value}<Tooltip place="right" type="light" effect="solid"/></div>;
    };
    render() {
        let { title, value } = this.props;

        return (
            <div className={'field'}>
                <div className={'field-title' + (!this.isValue(value) ? ' category' : '')}><span className={'text'}>{title}</span> {this.isValue(value) ? <span className={'colon'}>:</span> : ''}</div>
                {this.renderValue(value)}
            </div>
        )
    }
}

DetailsField.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string,
};
